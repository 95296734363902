import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {NotificationContainer,NotificationManager} from "react-notifications";
import NumberFormat from 'react-number-format'

import { updateGestionEvento } from "../../actions/evento";
import { getEventosPorUsuario,setStatusByKey } from "../../actions/juego";

import "../../config";

const Sala = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { listEventos: currentEventos,eventoSeleccionado: eventoSeleccionado} = useSelector((state) => state.juego);
  let history = useHistory();
  const dispatch = useDispatch();

  let [errors] = useState({"tiempoNoticia":""},{"tiempoJugada":""},{"tiempoVarPrecios":""},{"tiempoRecSalados":""});

  let [currentEventObj, setCurrentEventObj] = useState(null)
  let [tiempoNoticia, setTiempoNoticia] = useState("");
  let [tiempoJugada, setTiempoJugada] = useState("");
  let [tiempoVarPrecios, setTiempoVarPrecios] = useState("");
  let [tiempoRecSalados, setTiempoRecSalados] = useState("");



  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  if(currentEventObj===null && currentEventos!==null && eventoSeleccionado>0){
    const arrTmp = currentEventos.filter(value => value["EventoId"] === eventoSeleccionado)
    if(arrTmp.length>0){
        setCurrentEventObj(arrTmp[0])
        setTiempoNoticia(arrTmp[0]["TiempoNoticia"])
        setTiempoJugada(arrTmp[0]["TiempoJugada"])
        setTiempoVarPrecios(arrTmp[0]["TiempoVarPrecios"])
        setTiempoRecSalados(arrTmp[0]["TiempoRecSalados"])
    }
    else{
      dispatch(setStatusByKey("eventoSeleccionado",-1))    
      return <Redirect to="/seleccionar" />; 
    }    
  }

  function configurarEvento() {
     let valid = true
     if(tiempoNoticia == ""){
        errors["tiempoNoticia"] = "Ingrese tiempo"
        valid = false
     }
     if(tiempoJugada == ""){
        errors["tiempoJugada"] = "Ingrese tiempo"
        valid = false
     }
     if(tiempoVarPrecios == ""){
        errors["tiempoVarPrecios"] = "Ingrese tiempo"
        valid = false
     }
     if(tiempoRecSalados == ""){
        errors["tiempoRecSalados"] = "Ingrese tiempo"
        valid = false
     }
     if(!valid){
        return
    }
    dispatch(updateGestionEvento(currentEventObj["EventoId"], tiempoNoticia, tiempoJugada, tiempoVarPrecios, tiempoRecSalados))
      .then(() => {     
  
      })
      .catch(() => {});

      NotificationManager.success(
        "Información actualizada",
        "Sistema",
        3000,
        () => {},
        4
    ); 
      
        
  }
  function closeWindow() {
    props.closeConfigEventClick();
  }
  return (
    <div
      className={[
        "overlay",
        "fade-in",
        props.openConfigEvent ? "animationShow" : "animationHide",
      ].join(" ")}
    >
      <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
          <h2>
            Configurar evento <i className="fas fa-cog"></i>
          </h2>
          <div>
            <p>
              <i className="far fa-clock"></i> <span>Tiempo noticia:</span>{" "}
              <NumberFormat  className="alinear-input" value={tiempoNoticia} thousandSeparator={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
            const {formattedValue, value} = values;
            setTiempoNoticia(value);
            errors["tiempoNoticia"]=""
        }}/>
              {errors["tiempoNoticia"]!==""?<div className='camporequerido'>{errors["tiempoNoticia"]}</div>:""}
            </p>
          </div>
          <div>
          <i className="far fa-clock"></i> <span>Tiempo jugada:</span>
              <NumberFormat  className="alinear-input" value={tiempoJugada} thousandSeparator={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
            const {formattedValue, value} = values;
            setTiempoJugada(value);
            errors["tiempoJugada"]=""
        }}/>
              {errors["tiempoJugada"]!==""?<div className='camporequerido'>{errors["tiempoJugada"]}</div>:""}
          </div>
          <div>
          <i className="far fa-clock"></i> <span>Tiempo precios:</span>
              <NumberFormat  className="alinear-input" value={tiempoVarPrecios} thousandSeparator={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
            const {formattedValue, value} = values;
            setTiempoVarPrecios(value);
            errors["tiempoVarPrecios"]=""
        }}/>                            
              {errors["tiempoVarPrecios"]!==""?<div className='camporequerido'>{errors["tiempoVarPrecios"]}</div>:""}
          </div>
          <div>
          <i className="fas fa-clock"></i> <span>Tiempo saldos :</span> 
              <NumberFormat  className="alinear-input" value={tiempoRecSalados} thousandSeparator={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
            const {formattedValue, value} = values;
            setTiempoRecSalados(value);
            errors["tiempoRecSalados"]=""
        }}/>        
               {errors["tiempoRecSalados"]!==""?<div className='camporequerido'>{errors["tiempoRecSalados"]}</div>:""}
          </div>
          <div className="dividerLine"></div>
          <div className="textCenter">
            <div className="btnGuardar" onClick={configurarEvento}>
              GUARDAR
            </div>{" "}
            <div className="btnCancelar" onClick={closeWindow}>
              CANCELAR
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Sala);
