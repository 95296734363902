import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

import {getNoticiaEmisor,deleteNoticiaEmisor,setStatusByKey} from "../../actions/administracion";
import "../../config";
import EditarNoticiaEmisor from "./EditarNoticiaEmisor"


const GestionNoticiaEmisor = (props) => {    
    const { listaNoticiasEmisor,listaNoticiasEmisorCount,noticiasEmisorLoading} = useSelector((state) => state.administracion);
    let [editGestionNoticiaOpen, setEditGestionNoticiaOpen] = useState("")
    let [noticiaSeleccionado, setNoticiaSeleccionado] = useState("null") 
    let [getNews, setGetNews] = useState(false) 
       
    const dispatch = useDispatch();

    function closeGestionNoticias(){
		dispatch(setStatusByKey("listaNoticiasEmisor",null))
        props.close();
    }
    if(listaNoticiasEmisor===null && getNews===false){
        setGetNews(true)
        dispatch(getNoticiaEmisor(props.noticiaSeleccionado["NoticiaId"],"0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }

    const gridStyle = { minHeight: '300px',height: '100%', margin: 20 }
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Editar',
            onClick:()=>{   
                setNoticiaSeleccionado(rowProps.data)
                setEditGestionNoticiaOpen("EDIT")
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar noticia',
                    message: '¿Seguro desea eliminar el emisor?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{
                            dispatch(deleteNoticiaEmisor(rowProps.data["NoticiaId"],rowProps.data["NoticiaEmisorId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {}
                      }
                    ]
                  });
               
            }
        },        
        ]
    }, [])  
    let columns= [
        /*{ name: 'Codigo',header: 'Codigo', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 100, },
        { name: 'Titulo',header: 'Titulo', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 200, },*/
        { name: 'EmisorNombre',header: 'Emisor', defaultFlex: 1, xdefaultLocked: 'Descripcion',minWidth: 400, },
        { name: 'RangoIni',header: 'Rango Inicial', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'RangoFin',header: 'Rango Final', defaultFlex: 1, xdefaultLocked: 'end' },          
        
    ]

    const closeEdit = ()=>{
        setEditGestionNoticiaOpen("")
    }
    const crearNuevo = ()=>{
        setNoticiaSeleccionado(null)
        setEditGestionNoticiaOpen("EDIT")
    } 

return (
<div className="overlay fade-in cleartop"> 
    <div className={['loading_cont',noticiasEmisorLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>    
    {editGestionNoticiaOpen==="EDIT" ? <EditarNoticiaEmisor closeEdit={closeEdit} noticiaSeleccionado={noticiaSeleccionado} noticiaId={props.noticiaSeleccionado["NoticiaId"]} />  : ""}  
    <div className="borderContent ventana-maximizada">
        <div className="innerShadow-maximizada">
            <h2>
            Noticias Emisor
            <div className="floatright"> 
                <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                    <MenuItem onClick={crearNuevo}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                </Menu>
            </div>
            </h2> 
            <DataGrid
                idProperty="NoticiaEmisorId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}
                selection={{ mode: 'single' }}                    
                dataSource={listaNoticiasEmisor!==null?listaNoticiasEmisor:[]}
                renderRowContextMenu={renderRowContextMenu}
                theme="amber-dark"
            />
             
            <div className="dividerLine"></div>
            <div className="textCenter">
                <div className="btnCancelar" onClick={closeGestionNoticias}>
                    SALIR
                </div>
            </div>      
        </div>       
    </div>
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionNoticiaEmisor);