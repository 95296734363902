import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import {updateGestionNoticiaEspecial} from "../../actions/evento";
import {getItemsCatalogo} from "../../actions/administracion";

import "../../config";
const EditarGestionEventoNoticia = (props) => {
    const dispatch = useDispatch();
    const { listaCatalogos} = useSelector((state) => state.administracion);
    let [errors] = useState({"tipoNoticiaId":""},{"jugadaErr":""},{"montoErr":""},{"userSelectErr":""});
    
    let [tipoNoticiaId, setTipoNoticiaId] = useState({"value":props.noticiaPorEventoSeleccionado!==null?props.noticiaPorEventoSeleccionado["TipoNoticiaId"]:"-1","label":props.noticiaPorEventoSeleccionado!==null?props.noticiaPorEventoSeleccionado["TipoNoticiaNombre"]:""});
    let [jugada, setJugada] = useState(props.noticiaPorEventoSeleccionado!==null?props.noticiaPorEventoSeleccionado["Jugada"]:"0")
    let [tipo] = useState("P")

    function closeEditGU (){
        props.closeEditGestionNoticia();
    }
    if(listaCatalogos===null){
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        });
    }
    let cambioEstado=useState(null)
    
    if(props.noticiaPorEventoSeleccionado!==null && props.noticiaPorEventoSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.noticiaPorEventoSeleccionado!==null && props.noticiaPorEventoSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.noticiaPorEventoSeleccionado===null){
        cambioEstado ={value:'A', label:"Activo"}
    }
    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 
    function actualizar(){
      
        let valid=true
       
        if(jugada == "" || jugada == "0"){
            errors["jugadaErr"] = "Ingrese jugada"
            valid = false
        }
        if(tipoNoticiaId["value"] == "-1"){
            errors["tipoNoticiaId"] = "Seleccione un tipo de noticia"
            valid = false
        }
        if(!valid){
            return
        }

        dispatch(updateGestionNoticiaEspecial(
            props.noticiaPorEventoSeleccionado!==null?props.noticiaPorEventoSeleccionado["EventoNotBoomId"]:"-1",
            props.eventoSeleccionadoId,
            tipoNoticiaId["value"],
            jugada,            
            estadoSelect["value"],
            "0","","","ASC",""            
            )).then(() => {     
    
             }).catch(() => {}
        );        
        closeEditGU()
    }

    return (
<div className="overlay3 fade-in">
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
            <div id="formulario-estilo">
                <h2>
                    Ayuda del evento                    
                </h2>  
                <div className='vertical-noscroll customScroll'>               
                    <div className="columna-1-formulario-ventana-perfil-edit-evento-user">
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-calendar-day"></i> <span>Tipo:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={tipoNoticiaId} onChange={(e) => {
                                    setTipoNoticiaId(e)
                                    errors["tipoNoticiaId"] = ""
                                }} placeholder="Seleccione" options={listaCatalogos!==null && listaCatalogos["NOT_ESPECIAL"]?listaCatalogos["NOT_ESPECIAL"]:[]}
                                /> 
                                {errors["tipoNoticiaId"]!==""?<div className='camporequerido'>{errors["tipoNoticiaId"]}</div>:""}
                            </div>                       
                        </div>    
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-edit"></i> <span>Jugada:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <NumberFormat  
                                    className="input-form" value={jugada} thousandSeparator={false }  allowNegative={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""}   onValueChange={(values) => {
                                    const {formattedValue, value} = values;
                                    setJugada(value);      
                                    errors["JugadaErr"]=""                              
                                }}
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    return floatValue >= 0 &&  floatValue <= 999;
                                  }}/>                                 
                                {errors["jugadaErr"]!==""?<div className='camporequerido'>{errors["jugadaErr"]}</div>:""}                                           
                            </div>
                        </div>       
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <Select  className="input-form" id="estadoSelectCombo"  value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)
                                }} placeholder="Seleccione" options={optionsEstado}/>
                            </div>
                        </div>                   
                    </div> 
                    
                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizar} >
                        GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeEditGU}>
                            SALIR
                        </div>
                    </div>
                </div> 
            </div>      
        </div>
    </div>
</div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarGestionEventoNoticia);