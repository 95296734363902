import React, { useState, useEffect,useCallback } from "react";

const MensajeJuego = (props) => {    
    function closeWindowUsuario(){
        props.closeMessageJuegoClick()
    }
    return (
        <div
          className={[
            "windowContentInner-alertas",
            "fade-in"
          ].join(" ")}
        > 
            <div className="borderContent ventanaMensajesDirector">
                <div className="innerShadow-height-100">
                    <h2>
                        Mensaje del director de rueda
                    </h2>                                          
                    <div className='contenedorMensajeNeg' dangerouslySetInnerHTML={{__html: props.mensajeNotificacion}}></div>
                    <div className="dividerLine"></div>
                    <div className="textCenter">
                        <div className="btnCancelar" onClick={closeWindowUsuario}>
                            SALIR
                        </div>
                    </div> 
                </div>
            </div>
        </div>
      );
}
export default MensajeJuego;