import { parseBooleans } from "xml2js/lib/processors";
import {
    GET_EVENT_BY_USER,
    GET_EVENT_TITLE_BY_USER,
    GET_EVENT_BY_USER_FAIL,
    GET_EVENT_BY_USER_INIT, 
    UPDATE_STATUS_BY_KEY,   
    GET_PORTAFOLIO_CLIENTE_ACT,
    GET_PORTAFOLIO_CLIENTE_ACT_FAIL,
    GET_PORTAFOLIO_CLIENTE_ACT_INI,
    GET_NEGOCIACIONES_CLIENTE,
	GET_VARIATION_PRICE,
    GET_VARIATION_PRICE_FAIL,
  } from "../actions/types";

const eventos = null;//JSON.parse(localStorage.getItem("eventos"));
const titulosEvento = JSON.parse(localStorage.getItem("titulosEvento"));
const titulosRVCount = localStorage.getItem("titulosRVCount")
const eventoSeleccionado = localStorage.getItem("eventoSeleccionado")
const eventoFinalizado = localStorage.getItem("eventoFinalizado")!==null?JSON.parse(localStorage.getItem("eventoFinalizado")):null;
const mostrarPosicion = localStorage.getItem("mostrarPosicion")!==null?parseBooleans(localStorage.getItem("mostrarPosicion")):false;
const listaPosicion = localStorage.getItem("listaPosicion")!==null?JSON.parse(localStorage.getItem("listaPosicion").toString().replaceAll("\\\"","\"")):[];
const habilitarAyu = localStorage.getItem("habilitarAyudas")!==null?JSON.parse(localStorage.getItem("habilitarAyudas")):false;

const initialState = {
  eventoSeleccionado: eventoSeleccionado!==null?parseInt(eventoSeleccionado):-1,
  listEventos : eventos!==null?eventos:null,
  listTitleEvent: titulosEvento!==null?titulosEvento:null,
  loadingEventosPorUsr : true,
  titulosRVCount: titulosRVCount!==null?parseInt(titulosRVCount):0,
  showLablesTime:false,
  allTime:-1,
  timeLastPlay:-1,
  estadoCuentaCliente: [],
  estadoCuentaClienteEfec:0,
  loadEstadoCuenta: false,
  forceSelectEvento: false,
  negociacionesList:[],
  mostrarPortafilioCl: false,
  modalidadCodigo:"",
  eventoFinalizado:eventoFinalizado,
  mostrarPosicion:mostrarPosicion,
  listaPosicion:listaPosicion,
  listaVariacionPrecio:[],
  srcAudioAct:"",
  muteAudioGen:false,
  enableAudio10seg:false,
  habilitarAyudas:habilitarAyu,
  loadingAccionEvt:false,
  jugadaIndex:1,
  showRankingUsr:false
}

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_STATUS_BY_KEY:
          switch(payload.key){
            case 'habilitarAyudas':
              return {
                ...state,
                habilitarAyudas:payload.value,                
              };
            case 'showLablesTime':
              return {
                ...state,
                showLablesTime:payload.value,                
              };
            case 'allTime':
              return {
                ...state,
                allTime:payload.value  
              };
            case 'timeLastPlay':
              return {
                ...state,
                timeLastPlay:payload.value
              };
            case 'eventoSeleccionado':
              localStorage.setItem("eventoSeleccionado", payload.value);
              return {
                ...state,
                eventoSeleccionado:payload.value
              };
            case 'forceSelectEvento':
              return {
                ...state,
                forceSelectEvento:payload.value
              };
            case 'mostrarPortafilioCl':
              return {
                ...state,
                mostrarPortafilioCl:payload.value
              };
            case 'modalidadCodigo':
              return {
                ...state,
                modalidadCodigo:payload.value
              };
            case 'eventoFinalizado':
                localStorage.setItem("eventoFinalizado", JSON.stringify(payload.value));
                return {
                  ...state,
                  eventoFinalizado:payload.value
                };
            case 'mostrarPosicion':
              localStorage.setItem("mostrarPosicion", payload.value);
              return {
                ...state,
                mostrarPosicion:payload.value
              };
            case 'listaPosicion':
                localStorage.setItem("listaPosicion", payload.value.toString().replaceAll("\\\"","\""));
                return {
                  ...state,
                  listaPosicion:JSON.parse(payload.value.toString().replaceAll("\\\"","\""))
                };
            case 'srcAudioAct':
              return {
                ...state,
                srcAudioAct:payload.value
              };
			      case 'muteAudioGen':
              return {
                ...state,
                muteAudioGen:payload.value
              };
            case 'enableAudio10seg':
              return {
                ...state,
                enableAudio10seg:payload.value
              };
            case 'loadingAccionEvt':
              return {
                ...state,
                loadingAccionEvt:payload.value
              };
            case 'jugadaIndex':
              return {
                ...state,
                jugadaIndex:payload.value
              };
            case 'showRankingUsr':
              return {
                ...state,
                showRankingUsr:payload.value
              };
          }                    
        break
        case GET_EVENT_BY_USER:
            return {
                ...state,
                loadingEventosPorUsr: false,
                listEventos: payload.data                
              };
        case GET_EVENT_TITLE_BY_USER:
          return {
            ...state,
            loadingEventosPorUsr: false,
            listTitleEvent: payload.data,
            titulosRVCount:payload.data.filter(value => value["TipoRentaCod"] === 'RV').length,
          };
        case GET_EVENT_BY_USER_INIT:
          return {
            ...state,
            loadingEventosPorUsr: true
          };
        case GET_EVENT_BY_USER_FAIL:
          return {
            ...state,
            loadingEventosPorUsr: false,
            listEventos: [],
          };
        case GET_PORTAFOLIO_CLIENTE_ACT_INI:
          return {
            ...state,
            estadoCuentaCliente: [],
            estadoCuentaClienteEfec:0,
            loadEstadoCuenta: true,
          };
        case GET_PORTAFOLIO_CLIENTE_ACT:
          return {
            ...state,
            loadEstadoCuenta: false,
            estadoCuentaCliente:payload.data[0],
            estadoCuentaClienteEfec:payload.data[1]["saldo"]
          };
        case GET_PORTAFOLIO_CLIENTE_ACT_FAIL:
          return {
            ...state,
            loadEstadoCuenta: false,
            estadoCuentaCliente: [],
            estadoCuentaClienteEfec:0
          };
        case GET_NEGOCIACIONES_CLIENTE:
          return {
            ...state,
            negociacionesList: payload.data 
          };   
		case GET_VARIATION_PRICE:
          return {
            ...state,
            listaVariacionPrecio: payload.data 
        };          
        case GET_VARIATION_PRICE_FAIL:
          return {
            ...state,
            listaVariacionPrecio: payload.data 
          };   
        default:
            return state;
    }
  }
