import React, { useState, useEffect } from "react";

const Top10 = (props) => {    
    return (
        <div className='nojuego'> 
                <div className="customBoxAviso">
                    <div className="innerShadow">
                        No tienes juegos disponibles, puedes inscribirte ahora!!
                    </div>                        
                </div>
            </div>
    );
}
export default Top10;