import React, { useState,useEffect} from "react";
import { Redirect,useHistory  } from 'react-router-dom';
import xml2js from 'xml2js'
import { connect,useDispatch,useSelector } from "react-redux";
import ImageVideoGallery from '../components/general/ImageVideoGallery';
import Websocket from 'react-websocket';
import {NotificationManager} from "react-notifications";

import {formatTime,enableFuncionalityForRole,formatNumber,listaAyudas} from "./general/Util"

import { showBanner } from "../actions/banner";
import { getEventosPorUsuario,getTitulosEventoById,setStatusByKey,getNegociacionesPorUsuario,getVariacionPrecio } from "../actions/juego";


import AperturaRueda from "./AperturaRueda";
import Juego from "./Juego";
import axios from "axios";
import '../config'


import { Steps } from 'intro.js-react'
import 'intro.js/introjs.css';


let refWebSocket = null
let refJuego = null
const Sala = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);  
  const { listBanner: currentBanner } = useSelector((state) => state.banner);    
  const { loadingEventosPorUsr,modalidadCodigo } = useSelector((state) => state.juego);      
  const { listEventos: currentEventos,eventoSeleccionado: eventoSeleccionado,negociacionesList:negociaciones,srcAudioAct,muteAudioGen,listTitleEvent,habilitarAyudas,loadingAccionEvt} = useSelector((state) => state.juego);
  
  let refApertura = React.createRef();       

  let [seconds, setSeconds] = useState(-1);
  let [textHours, setTextHours] = useState("");
  let [interTime, setInterTime] = useState(0);
  let [status, setStatus] = useState("SALA");//
  let [eventoId, setEventoId] = useState(eventoSeleccionado);
  let [showNoticia, setShowNoticia] = useState(false);
  let [upDownPrecio, setUpDownPrecio] = useState(false);  
  let [forceLoadCurrentObj, setForceLoadCurrentObj] = useState(false);  
  let [mostrarNotificacion, setMostrarNotificacion] = useState(false);    
  let [blockJuego, setBlockJuego] = useState(false);    
  let [startJuego, setStartJuego] = useState(true);    
  let [enableChat, setEnableChat] = useState(currentUser!==null?enableFuncionalityForRole(currentUser["role"],"CHAT"):false);    
  let [listMessage,setListMessage] = useState([]);  
  let [timeOutOrn,setTimeOutOrn] = useState(0);  
  
  
  let [listUpDownPrecio, setListUpDownPrecio] = useState([]);
  let [userList, setUserList] = useState([]);
  let [enableUserList, setEnableUserList] = useState(currentUser!==null?enableFuncionalityForRole(currentUser["role"],"LIST_USR"):false);  
  let [negocacionesFirst, setNegocacionesFirst] = useState(true);  
  let [currentEventObj, setCurrentEventObj] = useState(null);
  let [iniciaJuego, setIniciaJuego] = useState(false);  
  let [enableViewEstCta, setEnableViewEstCta] = useState(currentUser!==null?enableFuncionalityForRole(currentUser["role"],"VER_ESTCTA"):false);  
  let [enableSendEstCta, setEnableSendEstCta] = useState(currentUser!==null?enableFuncionalityForRole(currentUser["role"],"ENV_ESTCTA"):false);  
  let [enableIngOrdenes, setEnableIngOrdenes] = useState(currentUser!==null?enableFuncionalityForRole(currentUser["role"],"ING_ORND"):false);  
  let [enableIniciaJuego, setEnableIniciaJuego] = useState(currentUser!==null?enableFuncionalityForRole(currentUser["role"],"STAT_GAME"):false);    
  
  let [noticiaPos, setNoticiaPos] = useState("");
  let [noticiaNeg, setNoticiaNeg] = useState("");
  let [mensajeNotificacion, setMensajeNotificacion] = useState("");  

  let [noticiaPosObj, setNoticiaPosObj] = useState(localStorage.getItem("setNoticiaPosObj")!==null?JSON.parse(localStorage.getItem("setNoticiaPosObj")):null);
  let [noticiaNegObj, setNoticiaNegObj] = useState(localStorage.getItem("setNoticiaNegObj")!==null?JSON.parse(localStorage.getItem("setNoticiaNegObj")):null);

  let [textoNotificacionEvento, setTextoNotificacionEvento] = useState("");
  let [hideMessageEvent, setHideMessageEvent] = useState(true);  
  let [jugada, setJugada] = useState(1);    
  let [pasoJugada, setPasoJugada] = useState("");      
  let [isFirstLoad, setIsFirstLoad] = useState(true);  
  let [tipoNotEspecial, setTipoNotEspecial] = useState("");
  let [labelButtonManual, setLabelButtonManual] = useState("");

  let history = useHistory();
  const dispatch = useDispatch();
  let [steep,setSteep] = useState([])
  let [lastSteepKey,setLastSteepKey] = useState("")
  let [openOrderBol,setOpenOrderBol] = useState(false)
  let [showBtnNotEsp,setShowBtnNotEsp] = useState(false)

  let [loadCssClass,setLoadCssClass] = useState(true);         
  if(loadCssClass===true){
    setLoadCssClass(false)
    document.getElementById('root').classList.remove("root-fn");        
  }

  function openOrden(bolOpen){
    setOpenOrderBol(bolOpen)
    setHelpByState(lastSteepKey,bolOpen)
  }

  function setHelpByState(typeAct,bolOpen){

    if(typeAct==="")return
    setLastSteepKey(typeAct)
    let helpAy = []
    let strKeys = "" //default
    
    if(typeAct==="ORN" && bolOpen===false)
      typeAct = "ORN_AB"
    
    if(currentUser["role"]=="ROLE_ADMIN" || currentUser["role"]=="ROLE_RUEDA"){
      switch(typeAct){
        case 'MNO':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoPrecioUpDown,.pasoUserRow,.pasoOpcionesUsr,.pasoOpcionesJug,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'VOR':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoUserRow,.pasoOpcionesUsr,.pasoOpcionesJug,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'ORN':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoUserRow,.pasoOpcionesUsr,.pasoOpcionesJug,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'ORN_SB':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoUserRow,.pasoOpcionesUsr,.pasoOpcionesJug,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'ORN_AB':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoUserRow,.pasoOpcionesUsr,.pasoOpcionesJug,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'NOT':
          strKeys = '.pasoNotPositiva,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoNotNegativa,.pasoUserRow,.pasoOpcionesUsr,.pasoOpcionesJug,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'ERF':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoUserRow,.pasoOpcionesUsr,.pasoOpcionesJug,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'INI':
          strKeys = '.colBarras,.pasoDescJugada,.pasoUserRow,.pasoOpcionesUsr,.ayudaBarAccCantidad,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'START':
          strKeys = '.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.ayudaIniciarJuego,.pasoUserRow,.pasoOpcionesUsr,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'ESA':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoUserRow,.pasoOpcionesUsr,.pasoOpcionesJug,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        default:
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoUserRow,.pasoOpcionesUsr,.pasoOpcionesJug,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
      }
    }else{
      switch(typeAct){
        case 'MNO':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoPrecioUpDown,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.chatBtn,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'VOR':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.chatBtn,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'ORN':
          strKeys = '.pasoNegociacion,.pasoNegRV,.pasoNegRF,.pasoItemComra,.pasoItemVenta,.btnCompra,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.chatBtn,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'ORN_SB':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.chatBtn,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'ORN_AB':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.btnCompra,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.chatBtn,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break          
        case 'NOT':
          strKeys = '.pasoNotPositiva,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoNotNegativa,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.chatBtn,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'ERF':
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.chatBtn,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
        case 'INI':
        strKeys = '.colBarras,.pasoDescJugada,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.chatBtn,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
        break
        case 'START':
          strKeys = '.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.chatBtn,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
        break
        default:
          strKeys = '.pasoRentaFija,.colBarras,.pasoDescJugada,.ayudaBarAcc,.ayudaBarAccLogo,.ayudaBarAccCantidad,.pasoHistorialTran,.pasoFitroHT,.pasoHTLeft,.pasoHTRight,.chatBtn,.ayudaUsuarioPer,.timeProximaJugada,.timeTotal,.pasoConfig,.pasoSound,.pasoAyuda'
          break
      }
    }
    let arr = strKeys.split(',')
    arr.forEach(element => {      
      if(currentEventObj!==null && currentEventObj["JuegaRentaFija"]===false && element==='.pasoRentaFija'){
        
      }else{
        let it = listaAyudas.filter(value => value["element"] === element)
        if(it.length>0)
          helpAy.push(it[0])      
      }      
    });
    setSteep(helpAy)
  }
  
  function onExit(){
    dispatch(setStatusByKey("habilitarAyudas",false))
  }
  function enableAudio10Seg(enable){
    /*if(modalidadCodigo==="VIRTUAL")
      enable = true;*/

    dispatch(setStatusByKey("enableAudio10seg",enable))
  }
  function handleData(data) {
    let result = JSON.parse(data);

    dispatch(setStatusByKey("loadingAccionEvt",false))
    switch(result["TYPE"]){
      case "INI":
        showMessageEvent(result["DESC_AC"],result["DUR_AC"])
        setPasoJugada(result["NETX_EVENT"]);
        setHelpByState("INI",false)
        result["TYPE"] = "CTL"
        dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
        dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
        dispatch(setStatusByKey("showLablesTime",true))    
        
        setJugada(result["JUG"])

        setCodigoModalidad(result["MOD"])
        if(result["DESC_AC"]==="" && result["MOD"]!=="VIRTUAL"){
          setIniciaJuego(true)
          setTextoNotificacionEvento("Juego por iniciar")
        }
 
        if(localStorage.getItem("LastNew")!==null && localStorage.getItem("LastNew")!==""){
          try{
          var arrNot = JSON.parse(localStorage.getItem("LastNew"));
          arrNot.forEach(element => {
            switch(element["TipoNoticia"]){
              case 'TN_ALZA':
                setNoticiaPosObj(element)
                localStorage.setItem("setNoticiaPosObj", JSON.stringify(element));
                setNoticiaPos(element["Descripcion"])
                break
              case 'TN_BAJA':
                setNoticiaNegObj(element)
                localStorage.setItem("setNoticiaNegObj", JSON.stringify(element));
                setNoticiaNeg(element["Descripcion"])
                break
            }
          });
          }catch(ex){

          }
        }        
        break
      case "UORN":
        if(result["MSG"]===currentUser["userId"].toString() || enableFuncionalityForRole(currentUser["role"],"UPORN"))
          obtenerNegociaciones()
        break
      case "LIST_USR":
        if(!enableUserList) return
        let parser = new xml2js.Parser();
        parser.parseString(result["MSG"],
          function(err, result) {
            try{
              var usuarios = result["CustomType"]["userList"][0]["d2p1:KeyValueOfstringClienteListwMlRxXJ_S"]
              usuarios.sort(function (a, b) {
                  return a["d2p1:Value"][0]["strNick"][0].localeCompare(b["d2p1:Value"][0]["strNick"][0]);
              })
              setUserList(usuarios)
            }catch(ex){

            }
          }
        );
        break
      case "AYUDA":
        if(currentUser["role"]==="ROLE_USER")
          dispatch(setStatusByKey("mostrarPortafilioCl",true))
        break
      case "RANKING":
        if(currentUser["role"]==="ROLE_USER")
          dispatch(setStatusByKey("showRankingUsr",true))
        break
      case "USRBLOCK":
        history.push('/suspension')
        break;
      case "START":
        /*setNoticiaPos("")
        setNoticiaNeg("")
        setNoticiaPosObj(null)
        setNoticiaNegObj(null)*/
        //arranca el evento
        //dispatch(setStatusByKey("srcAudioAct",global.config.URL_AUDIO + "iniciojuego.mp3"))
        break
    }        
    switch(result["TYPE"]){      
      case "CTL":
        setShowBtnNotEsp(false)
        switch(result["MSG"]){
          case "CL_FORCE_CLOSE":
            history.push('/moreconnection')
            break;              
          default:            
            break;
        }
        setJugada(result["JUG"])
        setLastSteepKey(result["NETX_EVENT"])
        setHelpByState(result["NETX_EVENT"],false)
        switch(result["NETX_EVENT"]){ 
          case "IJV":
            enableAudio10Seg(false)
            showMessageEvent(result["DESC_AC"],result["DUR_AC"])
            setPasoJugada(result["NETX_EVENT"]);
            setHelpByState("INI",false)
            result["TYPE"] = "CTL"
            dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
            dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
            dispatch(setStatusByKey("showLablesTime",true))    
            
            setJugada(result["JUG"])

            setCodigoModalidad(result["MOD"])
            setIniciaJuego(true)
            setStartJuego(true)
            setTextoNotificacionEvento("Juego por iniciar")
            break
          case "START":
            enableAudio10Seg(false)
            setStartJuego(true)

            setNoticiaPos("")
            setNoticiaNeg("")
            setNoticiaPosObj(null)
            setNoticiaNegObj(null)

            break
          case "FEVT":            
            enableAudio10Seg(false)
            break
          case "AYU":
            setStartJuego(false)
            enableAudio10Seg(false)
            setLableButtonManual(result["MOD"],result["LBTN"])            
            setTipoNotEspecial("")
            setPasoJugada(result["NETX_EVENT"]);
            dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
            dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
            showMessageEvent(result["DESC_AC"],result["DUR_AC"])
            setShowNoticia(false)
            setUpDownPrecio(false)

            if(enableFuncionalityForRole(currentUser["role"],"MOS_AYU")){
              dispatch(setStatusByKey("mostrarPortafilioCl",true))
            }
            break
          case "NES":
            setStartJuego(false)
            enableAudio10Seg(false)
            setLableButtonManual(result["MOD"],result["LBTN"])
            setPasoJugada(result["NETX_EVENT"]);
            dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
            dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
            showMessageEvent(result["DESC_AC"],result["DUR_AC"])

            localStorage.setItem("LastNew", result["MSG"]);

            var arrNot = JSON.parse(result["MSG"]);
            arrNot.forEach(element => {
              switch(element["TipoNoticia"]){
                case 'NE_BOOM':
                  setTipoNotEspecial("NE_BOOM")
                  setNoticiaPos(element["Descripcion"])
                  setNoticiaPosObj(element)
                  break
                case 'NE_CRUSH':
                  setTipoNotEspecial("NE_CRUSH")
                  setNoticiaNeg(element["Descripcion"])
                  setNoticiaNegObj(element)
                  break
                case 'NE_SPLIT':
                  setTipoNotEspecial("NE_SPLIT")
                  setNoticiaPos(element["Descripcion"])
                  setNoticiaPosObj(element)
                  break
                case 'NE_CONTRASPLIT':
                  setTipoNotEspecial("NE_CONTRASPLIT")
                  setNoticiaNeg(element["Descripcion"])
                  setNoticiaNegObj(element)
                  break
              }
            });
            
            setShowNoticia(true)                        
            setUpDownPrecio(false)

            break
          case "ENE":
            setStartJuego(false)
            enableAudio10Seg(false)
            setLableButtonManual(result["MOD"],result["LBTN"])
            setTipoNotEspecial("")
            setShowNoticia(false)                        
            setUpDownPrecio(false)
            setPasoJugada(result["NETX_EVENT"]);
            if(result["JUG_T"]!="")
              dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
            if(result["JUG_T"]!="")
              dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
            showMessageEvent(result["DESC_AC"],result["DUR_AC"])

            loadTitle()
            obtenerNegociaciones()
            loadVariacionNETitulos()

            break
          case "JUG":
            setStartJuego(false)
            enableAudio10Seg(false)
            loadTitle()
            setLableButtonManual(result["MOD"],result["LBTN"])
            setTipoNotEspecial("")
            setPasoJugada(result["NETX_EVENT"]);
            localStorage.setItem("LastNew", "");
            dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
            dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
            showMessageEvent(result["DESC_AC"],result["DUR_AC"])
            setShowNoticia(false)
            setUpDownPrecio(false)

            dispatch(setStatusByKey("enableAudio10seg",false))
            mostrarVencimientos();
            break                   
          case "NOT":
            setStartJuego(false)
            enableAudio10Seg(false)
            loadTitle()
            setLableButtonManual(result["MOD"],result["LBTN"])
            setTipoNotEspecial("")
            setPasoJugada(result["NETX_EVENT"]);
            dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
            dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
            showMessageEvent(result["DESC_AC"],result["DUR_AC"])

            dispatch(setStatusByKey("srcAudioAct",global.config.URL_AUDIO + "desplieguenoticia.mp3"))              

            localStorage.setItem("LastNew", result["MSG"]);

            var arrNot = JSON.parse(result["MSG"]);
            arrNot.forEach(element => {
              switch(element["TipoNoticia"]){
                case 'TN_ALZA':
                  setNoticiaPosObj(element)
                  localStorage.setItem("setNoticiaPosObj", JSON.stringify(element));
                  setNoticiaPos(element["Descripcion"])
                  break
                case 'TN_BAJA':
                  setNoticiaNeg(element["Descripcion"])
                  localStorage.setItem("setNoticiaNegObj", JSON.stringify(element));
                  setNoticiaNegObj(element)
                  break
              }
            });
            
            setShowNoticia(true)                        
            setUpDownPrecio(false)
            break
          case "ORN":            
            setStartJuego(false)
            enableAudio10Seg(true)
            dispatch(setStatusByKey("srcAudioAct",global.config.URL_AUDIO + "ingresoordenes.mp3"))
            setLableButtonManual(result["MOD"],result["LBTN"])
            setTipoNotEspecial("")
            setPasoJugada(result["NETX_EVENT"]);
            dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])-1))
            dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
            showMessageEvent(result["DESC_AC"],result["DUR_AC"])
            setShowNoticia(false)
            setUpDownPrecio(false)
            if(currentUser["role"]==="ROLE_USER"){
              clearTimeout(timeOutOrn)
              timeOutOrn = setTimeout(() => {            
                setPasoJugada("")
                setHelpByState('ORN_SB',false)
                dispatch(setStatusByKey("timeLastPlay",0))
                dispatch(setStatusByKey("srcAudioAct",global.config.URL_AUDIO + "fintiempoingordenes.mp3"))
              }, parseInt(result["JUG_T"])* 1000);
              setTimeOutOrn(timeOutOrn)
            }            
            break          
          case "MNO":     
            clearTimeout(timeOutOrn)
            setStartJuego(false)
            enableAudio10Seg(false)
            dispatch(setStatusByKey("srcAudioAct",global.config.URL_AUDIO + "variacionprecio.mp3"))
            setLableButtonManual(result["MOD"],result["LBTN"])
            setTipoNotEspecial("")
            setPasoJugada(result["NETX_EVENT"]);
            dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
            dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
            showMessageEvent(result["DESC_AC"],result["DUR_AC"])
            setShowNoticia(false)
            var arrNot = JSON.parse(result["MSG"]);
            
            setListUpDownPrecio(arrNot)
            setUpDownPrecio(true)
            try{
              dispatch(getVariacionPrecio(eventoSeleccionado))
              .then(() => {            
              })
              .catch(() => {
              });
            }catch(ex){}
            
            loadTitle()
            break
          case "VOR":
            clearTimeout(timeOutOrn)
            setStartJuego(false)
            enableAudio10Seg(false)
            setLableButtonManual(result["MOD"],result["LBTN"])
            setTipoNotEspecial("")
            setPasoJugada(result["NETX_EVENT"]);
            dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
            dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
            showMessageEvent(result["DESC_AC"],result["DUR_AC"])
            setShowNoticia(false)
            setUpDownPrecio(false)

            dispatch(setStatusByKey("srcAudioAct",global.config.URL_AUDIO + "estadoordenes.mp3"))
			
			      obtenerNegociaciones()
            loadTitle()
            mostrarOrdenesEstado();
            break
          case "ESA":
            enableAudio10Seg(false)
            setStartJuego(false)
            setLableButtonManual(result["MOD"],result["LBTN"])
            setTipoNotEspecial("")
            setPasoJugada(result["NETX_EVENT"]);
            dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
            dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
            showMessageEvent(result["DESC_AC"],result["DUR_AC"])
            setShowNoticia(false)
            setUpDownPrecio(false)
            setShowBtnNotEsp(true)
            loadTitle()

            mostrarVencimientos();
            break
          case "FEV":     
            setStartJuego(false)
            enableAudio10Seg(false)
            setForceLoadCurrentObj(true)       
            if(result["MSG"]==="FE"){
              dispatch(setStatusByKey("eventoFinalizado",currentEventObj))
              dispatch(setStatusByKey("mostrarPosicion",false))
              dispatch(getEventosPorUsuario(currentUser["userId"]))
              .then(() => {
              })
              .catch(() => {
              });

              setLableButtonManual(result["MOD"],result["LBTN"])
              setTipoNotEspecial("")
              dispatch(setStatusByKey("showLablesTime",false))    

              dispatch(setStatusByKey("srcAudioAct",""))   
              dispatch(setStatusByKey("eventoSeleccionado",null))
              setIsFirstLoad(true)
              
              localStorage.removeItem("titulosEvento");
              localStorage.removeItem("eventos");
              localStorage.removeItem("mostrarPosicion");
              localStorage.removeItem("eventoSeleccionado");
              localStorage.removeItem("listaPosicion");              

              history.push('/finevento')
            }else{
              dispatch(getEventosPorUsuario(currentUser["userId"]))
              .then(() => {
              })
              .catch(() => {
              });

              NotificationManager.warning(
                  "Finalizar Evento",
                  "El evento esta en proceso de finalización, cuando cumplan el plazo los títulos de RF se dará por finalizado",
                  5000,
                  () => {},
                  4
              );    
            }
            break
            case "ERF":
              setStartJuego(false)
              enableAudio10Seg(false)
              setLableButtonManual(result["MOD"],result["LBTN"])            
              setTipoNotEspecial("")              
              dispatch(setStatusByKey("timeLastPlay",parseInt(result["JUG_T"])))
              dispatch(setStatusByKey("allTime",parseInt(result["TIME"])))
              setPasoJugada(result["NETX_EVENT"]);              
              setShowNoticia(false)
              setUpDownPrecio(false)
              setShowBtnNotEsp(true)

              if(listTitleEvent.filter(value => value["TipoRentaCod"] === 'RF' && value["Maduracion"]===1).length>0){
                showMessageEvent(result["DESC_AC"],result["DUR_AC"])
                dispatch(setStatusByKey("srcAudioAct",global.config.URL_AUDIO + "ventarf.mp3"))              
              }else{
                showMessageEvent("Estamos por iniciar la siguiente jugada",result["DUR_AC"])
              }
              break
            
        }
        break;
      case "MSG":
        /*let rolTmp = currentUser["role"];
        if(result['TO'].toString()==="-1000" && (rolTmp==='ROLE_ADMIN' || rolTmp==='ROLE_RUEDA'))
          addMessageHistory(data,result['FROM'],'I')
        else
          addMessageHistory(data,result['TO'],'SI')*/
          addMessageHistory(data,result['FROM'],'I')
        break;      
      default:
        break;
    }
    dispatch(setStatusByKey("jugadaIndex",jugada))
  }
  function setLableButtonManual(mod,label){
    if(mod!=="VIRTUAL"){
      setIniciaJuego(false)
      setLabelButtonManual(label)
    }      
  }
  function showMessageEvent(str,time){
    setTextoNotificacionEvento(str)
    setHideMessageEvent(false)
    setTimeout(() => {
      setHideMessageEvent(true)
    }, parseInt(time)*1000);
  }
  function checktime(currentEventos) {
    if(currentEventos===null || eventoSeleccionado<0 || currentEventObj===undefined || currentEventObj===null || status==="JUEGO") return "00:00";
    if(currentEventObj!==null && eventoSeleccionado>0){
      
      if(currentEventObj["secodsTime"]<0){
        loadTitle()
        setStatus("JUEGO")        
        return "00:00"   
      }      
      if(seconds<0){
        setSeconds(currentEventObj["secodsTime"]) 
        seconds = currentEventObj["secodsTime"]
        
        if(interTime===0){
          const intervalT = setInterval(() => {
            if(seconds===20)
                dispatch(setStatusByKey("srcAudioAct",global.config.URL_AUDIO + "20segundos.mp3"))

            if(seconds<=11 && seconds>1)
                dispatch(setStatusByKey("srcAudioAct",global.config.URL_AUDIO + (seconds-1) + ".mp3"))    

            setSeconds(seconds => seconds - 1);   
            seconds = seconds - 1
            setTextHours(formatTime(seconds));

            if(seconds===0){
              clearInterval(interTime)   
              loadTitle()
              //setStatus("JUEGO")
              openBolsa()
            }
          }, 1000,seconds);
          
          setInterTime(intervalT);                      
        }else{
          if(seconds===0){
              clearInterval(interTime)   
              loadTitle()
              //setStatus("JUEGO")
              openBolsa()
              //history.push('/juego')                                 
          }          
        }
      }      
      return "";//<h1>{textHours}</h1>;
    }else{
      history.push('/nojuegos') 
    }
    return "00:00"   
  }

  if(isFirstLoad===true){
    setIsFirstLoad(false)
    dispatch(showBanner()).then(() => {}).catch(() => {});
    try{
        dispatch(getEventosPorUsuario(currentUser["userId"]))
          .then(() => {
          })
          .catch(() => {
          });
    }catch(ex){
  
    }
  }  
  
  const openBolsa = () => {    
    setStatus("APERTURA")    
    dispatch(setStatusByKey("showLablesTime",true))
    
    //refApertura.startApertura()
    /*setTimeout(()=>{
      setStatus("JUEGO")
      //history.push('/juego')
    },5000)*/
  }; 

  const loadJuego = () => {    
    setStatus("JUEGO")
  }; 


  function loadTitle(){
    if(currentEventObj!==null){
      //setHelpByState(lastSteepKey)
      dispatch(getTitulosEventoById(currentEventObj!==null?currentEventObj["EventoId"]:1))
      .then(() => {               
      })
      .catch(() => {
      });
    }    
  }

  function changeStatus(str){
    setStatus(str)
  }

  if (!currentUser) {
    return <Redirect to="/login" />;
  }  

  if(currentEventos!==null && currentEventos.length===1 && eventoSeleccionado<0){
    setEventoId(currentEventos[0]["EventoId"])
    dispatch(setStatusByKey("eventoSeleccionado",eventoId))
  }
  if(currentEventos!==null && currentEventos.length>0 && (eventoSeleccionado===null || eventoSeleccionado<0)){
    return <Redirect to="/seleccionar" />;
  }

  function btnNegociacion(){
    setStatus("NEGO")    
  }

  function obtenerNegociaciones(){
    let userId = currentUser["userId"]
    if(enableFuncionalityForRole(currentUser["role"],"UPORN"))
      userId = -1

    dispatch(getNegociacionesPorUsuario(eventoSeleccionado,userId))
        .then(() => {
        })
        .catch(() => {
        });
  }
  let forceUp = false
  if(forceLoadCurrentObj===true){
    const arrTmp = currentEventos.filter(value => value["EventoId"] === eventoSeleccionado)
    if(arrTmp.length>0 && arrTmp[0]["EstadoCodigo"]!==currentEventObj["EstadoCodigo"]){
      setForceLoadCurrentObj(false)
      setCurrentEventObj(arrTmp[0])
    }

  }
  // && currentEventObj["EstadoCodigo"]
  if(currentEventos!==null &&eventoSeleccionado>0 && currentEventObj===null){
    const arrTmp = currentEventos.filter(value => value["EventoId"] === eventoSeleccionado)
    if(arrTmp.length>0){
      setCurrentEventObj(arrTmp[0])
      setCodigoModalidad(arrTmp[0]["ModalidadCodigo"])
    }      
    else{
      dispatch(setStatusByKey("eventoSeleccionado",-1))    
      return <Redirect to="/seleccionar" />; 
    }      
  }
  
  if(currentEventos!==null && currentEventos.length===0){
    history.push('/nojuegos') 
  }

  if(negocacionesFirst){
    setNegocacionesFirst(false)
    obtenerNegociaciones()
  }

  function sendMessageChat(msg,clientId){
    addMessageHistory(msg,clientId,'S')
    refWebSocket.sendMessage(msg)
  }
  function addMessageHistory(msg,clientId,type){
    let lsTmp = listMessage[clientId]
    if(listMessage[clientId]===undefined || listMessage[clientId]===null)
      lsTmp = []
    
    let itemMsg = {};
    itemMsg["msg"] = msg;
    itemMsg["tipo"] = type;
    lsTmp.push(itemMsg)
    listMessage[clientId] = lsTmp
    setListMessage(listMessage)
  }
  function sendMessageLocal(msg){
    refWebSocket.sendMessage(msg)
  }
  function mostrarNotificacionVentana(message){
    setMensajeNotificacion(message)
    setMostrarNotificacion(true)
  }
  function setCodigoModalidad(mod){    
    dispatch(setStatusByKey("modalidadCodigo",mod))
  }
  function mostrarVencimientos(){
    const params = {"strEventoId":eventoId,"strJugada":jugada,"strUsuario":currentUser["userId"]}
    //const params = {"strEventoId":"36","strJugada":"4","strUsuario":"22"}
    axios.post(global.config.URL_SERVICE + "ObtenerVencimientosPorJugada", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");     
      const result = JSON.parse(itemR);      
      if(result.length>0){
        setMensajeNotificacion(generateErrorsVencimiento(result))
        setMostrarNotificacion(true)
      }      
    });
  }
  function generateErrorsVencimiento(result){
    let html ='<div class="tituloErrorNeg">Te devolvemos el capital y el interés bajo los siguientes detalles</div><br/><table>'
    html = html + '<tr class="tituloErrorNegTabla"><td>OPERACIÓN</td><td>EMISOR</td><td>TIPO VALOR</td><td>CANTIDAD</td><td>MONTO</td></tr>'
    let totalMonto = 0;
    result.forEach(element => {
      totalMonto = totalMonto + element["ValorNominal"]
      html = html + '<tr><td>' + element["Operacion"] + '</td><td>' + element["Emisor"] + '</td><td>' + element["TipoValor"] + '</td><td class="textalingright">' + element["Cantidad"] + '</td><td class="textalingright">$ ' + formatNumber(element["ValorNominal"]) + '</td></tr>'
    });
    html = html + '<tr><td class="nobordertdtd"></td><td class="nobordertdtd"></td><td class="nobordertdtd"></td><td class="nobordertdtd">Total:</td><td class="textalingright">$ ' + formatNumber(totalMonto) + '</td></tr></table>'
    html = html + '<br/><div class="tituloErrorNeg">Este valor está acreditado a tu portafolio en efectivo</div><br/>'
    return html
  }
  function mostrarOrdenesEstado(){ 
    const params = {"strEventoId":eventoId,"strJugada":jugada,"strUsuario":currentUser["userId"]}
    //const params = {"strEventoId":"32","strJugada":"4"}
    axios.post(global.config.URL_SERVICE + "ObtenerNegociacionesErrorPorJugada", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");     
      const result = JSON.parse(itemR);      
      if(result>0){
        setMensajeNotificacion("Su transacción fue procesada exitosamente...!!")
        setMostrarNotificacion(true)
      }
      if(result.length>0){
        setMensajeNotificacion(generateErrorsNegociacion(result))
        setMostrarNotificacion(true)
      }      
    });
  }
  function generateErrorsNegociacion(result){
    let html =''
    html = html + '<tr class="tituloErrorNegTabla"><td>OPERACIÓN</td><td>EMISOR</td><td>TIPO VALOR</td><td>CANTIDAD</td></tr>'

    let razon = 'No compro título de renta fija'
    let bolRF = false
    result.forEach(element => {
      if(element["Razon"]===razon){
        setBlockJuego(true) 
        bolRF = true        
      }
      html = html + '<tr><td>' + element["Operacion"] + '</td><td>' + element["Emisor"] + '</td><td>' + element["TipoValor"] + '</td><td>' +  formatNumber(element["Monto"]) + '</td></tr>'
      html = html + '<tr><td colspan="4">' + element["Razon"] + '</td></tr>'
    });
    if(bolRF){
      html ='<div class="tituloErrorNeg">' + razon + '</div><br/><table>' + html + '</table>'    
    }else{
      html ='<div class="tituloErrorNeg">Su transacción no fue procesada</div><br/><table>' + html + '</table>'    
    }

    
    return html
  }
  function closeMessageJuegoClick(){
    /*if(blockJuego===true){
      history.push('/finjuego') 
    }*/
    setMostrarNotificacion(false)      
  }

  function onBeforeChangeSteep(index){
    //console.log(steep[index])
  }
  function loadVariacionNETitulos(){
    const params = {"strEventoId":eventoId,"strJugada":jugada,"strUsuarioId":currentUser["userId"]}
    axios.post(global.config.URL_SERVICE + "ObtenerOperacionesEpeciales", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");     
      const result = JSON.parse(itemR);      
      
      if(result.length>0){
          let html =''
          //let html ='<div class="tituloErrorNeg">Resultados noticias especiales</div><br/><table>'
          //html = html + '<tr class="tituloErrorNegTabla"><td>EMISOR</td><td>TIPO VALOR</td><td>SALDO<br/>ANTERIOR</td><td>' + strTitle + '</td><td>MONTO</td></tr>'
          let totalMonto = 0;
          let strTipoNoticia = ''
          result.forEach(element => {
            strTipoNoticia = element["Razon"]
            totalMonto = totalMonto + element["ValorNominal"]
            html = html + '<tr><td>' + element["Emisor"] + '</td><td>' + element["TipoValor"] + '</td><td class="textalingright">' + element["SaldoAnterior"] + '</td><td class="textalingright">' + element["Cantidad"] + '</td><td class="textalingright">' + (element["SaldoAnterior"]+ (strTipoNoticia.indexOf("CONTRASPLIT")>=0?element["Cantidad"]*-1:element["Cantidad"])) + '</td></tr>'
          });
          let htmlT ='<div class="tituloErrorNeg">Resultados noticias especiales</div><br/><table>'
          htmlT = htmlT + '<tr class="tituloErrorNegTabla"><td>EMISOR</td><td>TIPO VALOR</td><td>SALDO<br/>ANTERIOR</td><td>' + (strTipoNoticia==="NOTICIA SPLIT"?"SPLIT":"CONTRASPLIT") + '</td><td>SALDO<br/>ACTUAL</td></tr>'

          html = htmlT + html
          html = html + '</table>'
          html = html + '<br/><div class="tituloErrorNeg">' + strTipoNoticia + ': El resultado de esta noticia permite ' + (strTipoNoticia==="NOTICIA SPLIT"?'incrementar':'reducir') + ' su número de acciones.</div><br/>'

        setMensajeNotificacion(html)
        setMostrarNotificacion(true)
      }      
    });
  }
  
  return (    
    <div className={showNoticia===true?"style1":""}>
      <Steps
          nextLabel={"Siguiente"}
          prevLabel={"Anterior"}
          enabled={habilitarAyudas}
          steps={steep}
          initialStep={0}
          onExit={onExit}
          onBeforeChange={onBeforeChangeSteep}/>

	    <audio id='playAudioGen' src={srcAudioAct} autoPlay={true} playsInline muted={muteAudioGen}></audio>
      <div className={((loadingEventosPorUsr===true || loadingAccionEvt===true) && eventoId>0)? "containerLoading overlay-loading-action":"containerLoading overlay-loading-action displaynone"}>
        <div className='single6'></div>
      </div>        
      <div className='displaynone'>
      {
        currentEventos!==null?checktime(currentEventos):""
      }
      </div>
      {eventoId>0?<Websocket ref={Websocket => {
                  refWebSocket = Websocket;
                }} url={global.config.URL_WEB_SOCKET + "/" + eventoId + "?USR=" + currentUser["userId"] + "&CS=1&ROL=" + currentUser["role"] + "&UNAME=" + encodeURIComponent(currentUser["displayName"]) + "&IMG=" + encodeURIComponent(currentUser["fileNombreUrl"]!==null?currentUser["fileNombreUrl"]:"")} onMessage={handleData.bind(this)}/>:""}
      <div className={status==="SALA"?"":"displaynone"}>
        <div className="customTime">{(seconds<=20 && seconds>=0) ?seconds:""}</div>
        <div className="customBox aviso">
          <div className="innerShadow">
              <div className="avisoTxt">Bienvenido al Juego de la Bolsa</div>
              <div className="customTimer"><i className="fas fa-clock"></i><h1>{textHours}</h1></div>
              <div className="avisoTxtsmall">Para inicio del juego</div>
          </div>
        </div>        
        <div className="col">
            <div className="videoContainer">
              <ImageVideoGallery items={currentBanner} />;           
            </div>               
        </div>            
        {/*<input type="button" name="previous" className="buttonTemporal previous action-button" value="Saltar - Temporal" onClick={temporal}/>       */} 
      </div>
      <div className={status==="APERTURA"?"":"displaynone"}>
        {status==="APERTURA"?<AperturaRueda changeStatus={changeStatus}></AperturaRueda>:""}
      </div>
      <div className={status==="JUEGO"?"":"displaynone"}>
        <Juego ref={Juego => {
                  refJuego = Juego;
                }} showNoticia={showNoticia} noticiaPos={noticiaPos} noticiaNeg={noticiaNeg} noticiaPosObj={noticiaPosObj} noticiaNegObj={noticiaNegObj} evento={eventoId} jugada={jugada} upDownPrecio={upDownPrecio} listUpDownPrecio={listUpDownPrecio} textoEstadoJugada={textoNotificacionEvento} pasoJugada={pasoJugada} userList={userList} enableUserList={enableUserList} negociaciones={negociaciones} currentEventObj={currentEventObj} tipoNotEspecial={tipoNotEspecial} iniciaJuego={iniciaJuego} sendMessage={sendMessageLocal} labelButtonManual={labelButtonManual} enableViewEstCta={enableViewEstCta} enableSendEstCta={enableSendEstCta} enableIngOrdenes={enableIngOrdenes} enableIniciaJuego={enableIniciaJuego} mensajeNotificacion={mensajeNotificacion} mostrarNotificacion={mostrarNotificacion} closeMessageJuegoClick={closeMessageJuegoClick} startJuego={startJuego} mostrarNotificacionVentana={mostrarNotificacionVentana} enableChat={enableChat} listMessage={listMessage} sendMessageChat={sendMessageChat} openOrden={openOrden} showBtnNotEsp={showBtnNotEsp}/>
      </div>      
      <div className={["alertContainerJuego",hideMessageEvent===true?"displaynone":""].join(' ')}>{textoNotificacionEvento}</div>
    </div>
  );
};
const mapStateToProps = state => ({
  listBanner: state.listBanner,
  listEventos: state.listEventos,
  loadingEventosPorUsr: state.loadingEventosPorUsr,
  showLablesTime: state.showLablesTime,
});
export default connect(mapStateToProps, {    
})(Sala);