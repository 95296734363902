import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {getTitulosPotEvento,deleteEventoTitulo} from "../../actions/titulosPorEvento";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/amber-dark.css'
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import EditarTitulosPorEvento from "./EditarTitulosPorEvento";
import {setStatusByKey} from '../../actions/titulosPorEvento'
import "../../config";
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu
} from '@szhsin/react-menu';

const TitulosPorEvento = (props) => {
    let [editTitulosPorEventoOpen, setEditTitulosPorEventoOpen] = useState("")
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'100%', margin: 20 }
    //obtengo el id del evento
    let eventoSeleccionadoId = props.eventoSeleccionadoId;
    const [tituloPorEventoSeleccionado, setTituloPorEventoSeleccionado] = useState(null)
    
    const {listaTitulosPorEvento} = useSelector((state) => state.titulosPorEvento);
    
    let [loadService, setLoadService] = useState(false)
    if(loadService===false){
        setLoadService(true)
        dispatch(getTitulosPotEvento(eventoSeleccionadoId))
        .then(() => {
        })
        .catch(() => {
        });
    }
      const closeEditTitulosPorEventoClick = () =>{                  
        setEditTitulosPorEventoOpen("");
    } 
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Editar',
            onClick:()=>{   
                setTituloPorEventoSeleccionado(rowProps.data)
                setEditTitulosPorEventoOpen("EDIT")
                

            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar título',
                    message: 'Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{
                            dispatch(deleteEventoTitulo(rowProps.data["EventoTituloId"],rowProps.data["EventoId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {}
                      }
                    ]
                  });
               
            }
        }
        ]
    }, [])  
    let columns= [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Emisor',header: 'Emisor', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 300, },
        { name: 'TipoValorNombre',header: 'Tipo valor', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, },
        { name: 'TipoRentaNombre',header: 'Tipo renta', defaultFlex: 1, xdefaultLocked: 'end'},
        { name: 'Precio',header: 'Precio', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'Plazo',header: 'Plazo', defaultFlex: 1, xdefaultLocked: 'end' },      
        { name: 'Rendimiento',header: 'Rendimiento', defaultFlex: 1, xdefaultLocked: 'end' },      
        { name: 'ValorNominal',header: 'Valor nominal', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'SaldoTitulo',header: 'Saldo', defaultFlex: 1, xdefaultLocked: 'end' },
    ]
    //abrir nuevo 
    const crearNuevoTitulo = ()=>{
        setTituloPorEventoSeleccionado(null)
         setEditTitulosPorEventoOpen("EDIT")
    }
    function closeWindowTitulosPorEvento(){
        setLoadService(false)
       props.closeTitulosPorEventoClick();
    }

return (
<div className="overlay3 fade-in">
    {editTitulosPorEventoOpen==="EDIT" ? <EditarTitulosPorEvento closeEditTitulosPorEventoClick={closeEditTitulosPorEventoClick} tituloPorEventoSeleccionado={tituloPorEventoSeleccionado} eventoSeleccionadoId={eventoSeleccionadoId}/>  : ""}
    <div className="borderContent ventanaGeneral">
        <div className="innerShadow-height-100">
            <h2>
             Títulos por evento
            <div className="floatright">
                <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                    <MenuItem onClick={crearNuevoTitulo}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                </Menu>
            </div>
            </h2> 
             <DataGrid
                idProperty="EventoTituloId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}
                selection={{ mode: 'single' }}                    
                dataSource={listaTitulosPorEvento!==null?listaTitulosPorEvento:[]}
                renderRowContextMenu={renderRowContextMenu}
                theme="amber-dark"
            />
            <div className="dividerLine"></div>
            <div className="textCenter">
                <div className="btnCancelar" onClick={closeWindowTitulosPorEvento} >
                    SALIR
                </div>
            </div>      
        </div>
       
    </div>
    
</div>

);
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(TitulosPorEvento);