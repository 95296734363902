import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import {updateTitulo,getItemsCatalogo} from "../../actions/administracion";
import "../../config";

const EditarTitulo = (props) => {
    const dispatch = useDispatch();

    const { user: currentUser } = useSelector((state) => state.auth);
    const { listaCatalogos} = useSelector((state) => state.administracion);

    if(listaCatalogos===undefined || listaCatalogos===null){
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        });
      }

    let [tituloId, setTituloId] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["TituloId"]:"-1")
    let [codigo, setCodigo] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["Codigo"]:"")    
    let [emisor, setEmisor] = useState({"value":props.itemSeleccionado!==null?props.itemSeleccionado["Emisor"]:"-1","label":props.itemSeleccionado!==null?props.itemSeleccionado["EmisorNombre"]:""});
    let [tipoRenta, setTipoRenta] = useState({"value":props.itemSeleccionado!==null?props.itemSeleccionado["TipoRenta"]:"-1","label":props.itemSeleccionado!==null?props.itemSeleccionado["TipoRentaNombre"]:""});
    let [tipoValor, setTipoValor] = useState({"value":props.itemSeleccionado!==null?props.itemSeleccionado["TipoValor"]:"-1","label":props.itemSeleccionado!==null?props.itemSeleccionado["TipoValorNombre"]:""});    
    let [valorNominal, setValorNominal] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["ValorNominal"]:"0")    
    let [precio, setPrecio] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["Precio"]:"0")    
    let [rendimiento, setRendimiento] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["Rendimiento"]:"0")    
    let [plazo, setPlazo] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["Plazo"]:"0")    
    
    let [errors] = useState({"Codigo":""},{"Emisor":""},{"TipoRenta":""},{"TipoValor":""},{"ValorNominal":""},{"Precio":""},{"Rendimiento":""},{"Plazo":""});
    
    let cambioEstado={value:'A', label:"Activo"}
    
    if(props.itemSeleccionado!==null && props.itemSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.itemSeleccionado!==null && props.itemSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }

    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 
    function actualizarU(){        
        let valid=true
        
        if(codigo === ""){
            errors["Codigo"] = "Ingrese un código"
            valid = false
        }
        if(emisor["value"] === "-1"){
            errors["Emisor"] = "Seleccione un emisor"
            valid = false
        }
        if(tipoRenta["value"] === "-1"){
            errors["TipoRenta"] = "Seleccione un tipo de renta"
            valid = false
        }
        if(tipoValor["value"] === "-1"){
            errors["TipoValor"] = "Seleccione un tipo valor"
            valid = false
        }
        if(valorNominal.toString() === ""){
            errors["ValorNominal"] = "Campo requerido"
            valid = false
        }
        if(precio.toString() === ""){
            errors["Precio"] = "Campo requerido"
        
            valid = false
        }
        if(tipoRenta["label"].toUpperCase()==="RENTA FIJA" && rendimiento.toString() === ""){
            errors["Rendimiento"] = "Campo requerido"
            valid = false
        }
        if(tipoRenta["label"].toUpperCase()==="RENTA FIJA" && plazo.toString() === ""){
            errors["Plazo"] = "Campo requerido"
            valid = false
        }
        if(!valid){
            return
        }

        dispatch(updateTitulo(
            props.itemSeleccionado!==null?props.itemSeleccionado["TituloId"]:"-1",
            codigo,
            emisor["value"],
            tipoRenta["value"],
            tipoValor["value"],
            valorNominal, 
            precio, rendimiento, plazo, 0,
            estadoSelect["value"],            
            )).then(() => {     
    
            }).catch(() => {}
        );
        props.closeEdit()
    }
return (
<div className='overlay3 fade-in'>
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
            <div id="formulario-estilo">
                <h2>
                    Títulos
                </h2>  
                <div className='vertical-scroll customScroll'>      
                    <div className="columna-1-formulario-ventana-titulo">
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Código:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                    type="text"
                                    value={codigo}
                                    maxLength={20}
                                    placeholder="Ingresar código"
                                    className="input-form"
                                    onChange={(e) => {
                                        setCodigo(e.target.value.toUpperCase());
                                        errors["Codigo"] = ""
                                    }}
                                ></input>
                                {errors["Codigo"]!==""?<div className='camporequerido'> {errors["Codigo"]}</div>:""}                                     
                            </div>                       
                        </div>   
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Emisor:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={emisor} onChange={(e) => {
                                    setEmisor(e)     
                                    errors["Emisor"] = ""                                                                                                                                                                                       
                                }} placeholder="Seleccione" options={listaCatalogos!==null && listaCatalogos["EMISOR"]?listaCatalogos["EMISOR"]:[]}
                                /> 
                                {errors["Emisor"]!==""?<div className='camporequerido'> {errors["Emisor"]}</div>:""}
                            </div>                       
                        </div>    
						<div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Tipo renta:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={tipoRenta} onChange={(e) => {
                                    setTipoRenta(e)         
                                    errors["TipoRenta"] = ""
									setTipoValor({"value":"-1","label":""});    
                                }} placeholder="Seleccione" options={listaCatalogos!==null && listaCatalogos["TIPO_RENTA"]?listaCatalogos["TIPO_RENTA"]:[]}
                                /> 
                                {errors["TipoRenta"]!==""?<div className='camporequerido'> {errors["TipoRenta"]}</div>:""}
                            </div>                       
                        </div>
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Tipo valor:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={tipoValor} onChange={(e) => {
                                    setTipoValor(e)                                                                                                                                                                                            
                                    errors["TipoValor"] = ""
                                }} placeholder="Seleccione" options={listaCatalogos!==null?tipoRenta["label"].toUpperCase()==="RENTA VARIABLE"?listaCatalogos["TIPO_VALOR"].filter(value => value["label"] === 'ACCIONES'):listaCatalogos["TIPO_VALOR"].filter(value => value["label"] !== 'ACCIONES') :[]}
                                /> 
                                {errors["TipoValor"]!==""?<div className='camporequerido'> {errors["TipoValor"]}</div>:""}
                            </div>                       
                        </div>                             
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)                                                                                                                                                                                            
                                    }} placeholder="Seleccione" options={optionsEstado!==null?optionsEstado:[]}
                                /> 
                            </div>                       
                        </div>     
                        <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-dollar-sign"></i> <span>{tipoRenta["label"].toUpperCase()==="RENTA VARIABLE"?"Cantidad:":"Valor nominal:"}</span>                
                                    </label>
                                </div>
                                <div className="col-70">
                                    <NumberFormat  
                                        className="input-form" value={valorNominal} thousandSeparator={false}  allowNegative={false}  decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                        const {formattedValue, value} = values;                                       
                                        setValorNominal(value);                                          
                                         errors["ValorNominal"] = ""                                    
                                    }}
                                    isAllowed={(values) => {
                                        const {floatValue} = values;
                                        return floatValue >= 0 &&  floatValue <= 999999.99;
                                      }}/>
                                    {errors["ValorNominal"]!==""?<div className='camporequerido'>{errors["ValorNominal"]}</div>:""} 
                                </div>
                            </div>
                        <div className={["row-form"]}>
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-dollar-sign"></i> <span>Precio:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <NumberFormat  
                                    className="input-form" value={precio} thousandSeparator={false}  allowNegative={false}  decimalScale={2} fixedDecimalScale={true} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                    const {formattedValue, value} = values;                                       
                                    setPrecio(value);                                          
                                        errors["Precio"] = ""                                    
                                }}
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    return floatValue >= 0 &&  floatValue <=999.99;
                                    }}/>
                                {errors["Precio"]!==""?<div className='camporequerido'>{errors["Precio"]}</div>:""} 
                            </div>
                        </div>
                        <div className={["row-form",tipoRenta["label"].toUpperCase()==="RENTA VARIABLE"?"displaynone":""].join(' ')}>
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-edit"></i> <span>Rendimiento:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <NumberFormat  
                                    className="input-form" value={rendimiento} thousandSeparator={false}  allowNegative={false}  decimalScale={2} fixedDecimalScale={true} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                    const {formattedValue, value} = values;                                       
                                    setRendimiento(value);                                          
                                        errors["Rendimiento"] = ""                                    
                                }}
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    return floatValue >= 0 &&  floatValue <=99.99;
                                    }}/>
                                {errors["Rendimiento"]!==""?<div className='camporequerido'>{errors["Rendimiento"]}</div>:""} 
                            </div>
                        </div>
                        <div className={["row-form",tipoRenta["label"].toUpperCase()==="RENTA VARIABLE"?"displaynone":""].join(' ')}>
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-edit"></i> <span>Plazo:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <NumberFormat  
                                    className="input-form" value={plazo} thousandSeparator={false}  allowNegative={false}  decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                    const {formattedValue, value} = values;                                       
                                    setPlazo(value);                                          
                                        errors["Plazo"] = ""                                    
                                }}
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    return floatValue >= 0 &&  floatValue <=99.99;
                                    }}/>
                                {errors["Plazo"]!==""?<div className='camporequerido'>{errors["Plazo"]}</div>:""} 
                            </div>
                        </div>
                    </div>
                    <div className="textCenter clear-both margin-top">
                        <div className="btnGuardar" onClick={actualizarU} >
                            GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={props.closeEdit}>
                            SALIR
                        </div>
                    </div>
                </div>
            </div>      
        </div>
    </div>
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarTitulo);
