import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import {updateParametros} from "../../actions/administracion";
import "../../config";
const EditarGestionCatalogo = (props) => {
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    let [errors] = useState({"codigoErr":""},{"nombreErr":""},{"descripcionErr":""},{"valorErr":""},{"EstadoErr":""});
    let [parametroId,setParametroId] =  useState(props.parametroSeleccionado!==null?props.parametroSeleccionado["ParametroId"]:"-1")
    let [codigo,setCodigo] = useState(props.parametroSeleccionado!==null?props.parametroSeleccionado["Codigo"]:"")
    let [nombre,setNombre] = useState(props.parametroSeleccionado!==null?props.parametroSeleccionado["Nombre"]:"")
    let [descripcion,setDescripcion] = useState(props.parametroSeleccionado!==null?props.parametroSeleccionado["Descripcion"]:"")
    let [valor,setValor] = useState(props.parametroSeleccionado!==null?props.parametroSeleccionado["Valor"]:"")

    //estado
    let cambioEstado=null;
    if(props.parametroSeleccionado!==null && props.parametroSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.parametroSeleccionado!==null && props.parametroSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.parametroSeleccionado!==null && props.parametroSeleccionado["Estado"] == "D"){
        cambioEstado ={value:'D', label:"Desactivado"}
    }
    if(props.parametroSeleccionado===null || props.parametroSeleccionado["Estado"]===null){
        cambioEstado ={value:'A', label:"Activo"}
    }
    
    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ]
    
    function closeWindowEditParam(){
        props.closeEditarParametro()
    }
    if (!currentUser) {
        return <Redirect to="/login" />;
      }
    function actualizar(){
        let valid=true
       
        if(codigo == ""){
            errors["codigoErr"] = "Ingrese código"
            valid = false
        }
        if(nombre == ""){
            errors["nombreErr"] = "Ingrese nombre"
            valid = false
        }
        if(valor == ""){
            errors["valorErr"] = "Ingrese valor"
            valid = false
        }
        if(!valid){
            return
        }
        dispatch(updateParametros(
            parametroId,
            codigo,
            nombre,
            descripcion,
            valor,
            currentUser["userId"],
            estadoSelect["value"],
            "0","","","ASC",""            
            )).then(() => {     
    
             }).catch(() => {}
        );
        
        closeWindowEditParam()

    }

    return (
<div className="overlay3 fade-in">
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
            <div id="formulario-estilo">
                <h2>
                    Parámetros               
                </h2>  
                <div className='vertical-scroll customScroll'>               
                    <div className="columna-1-formulario-ventana-perfil-edit-evento-user"> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Código:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                    type="text"
                                    value={codigo}
                                    maxLength={50}
                                    placeholder="Ingresar código"
                                    className="input-form"
                                    onChange={(e) => {
                                        setCodigo(e.target.value.toUpperCase());
                                        errors["codigoErr"] = ""
                                    }}
                                ></input>
                                {errors["codigoErr"]!==""?<div className='camporequerido'>{errors["codigoErr"]}</div>:""}
                            </div> 
                        </div>  
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Nombre:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                    type="text"
                                    value={nombre}
                                    maxLength={200}
                                    placeholder="Ingresar nombre"
                                    className="input-form"
                                    onChange={(e) => {
                                        setNombre(e.target.value);
                                        errors["nombreErr"] = ""
                                    }}
                                ></input>
                                {errors["nombreErr"]!==""?<div className='camporequerido'>{errors["nombreErr"]}</div>:""}
                            </div> 
                        </div>   
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Valor:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                    type="text"
                                    value={valor}
                                    maxLength={1000}
                                    placeholder="Ingresar valor"
                                    className="input-form"
                                    onChange={(e) => {
                                        setValor(e.target.value);
                                        errors["valorErr"] = ""
                                    }}
                                ></input>
                                {errors["valorErr"]!==""?<div className='camporequerido'>{errors["valorErr"]}</div>:""}
                            </div> 
                        </div>
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <Select  className="input-form" id="estadoSelectCombo"  value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)
                                }} placeholder="Seleccione" options={optionsEstado}/>
                            </div>
                        </div>           
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Descripción:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <textarea
                                type="text"
                                value={descripcion}
                                maxLength={1000}
                                placeholder="Descripción"
                                className="text-area-form"
                                onChange={(e) => {
                                    setDescripcion(e.target.value);
                                    errors["descripcionErr"] = "" 
                                }}
                                ></textarea> 
                                {errors["descripcionErr"]!==""?<div className='camporequerido'> {errors["descripcionErr"]}</div>:""}                                     
                            </div>                       
                        </div>          
                    </div>                    
                </div>  
                <div className="textCenter clear-both">
                    <div className="btnGuardar" onClick={actualizar} >
                    GUARDAR
                    </div>{""}
                    <div className="btnCancelar" onClick={closeWindowEditParam}>
                        SALIR
                    </div>
                </div>
            </div>      
        </div>
    </div>
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarGestionCatalogo);
