import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {Menu,MenuItem,MenuButton,SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {getCatalogos,deleteCatalogo} from "../../actions/administracion";
import EditarGestionCatalogo from "./EditarGestionCatalogo";
import GestionItems from "./GestionItems";
import "../../config";
const GestionCatalogo = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'calc(100% - 50px)', margin: 20 }
    const {listaCatalogosPag,catalogosLoading} = useSelector((state) => state.administracion);
    let [editCatalogoOpen, setEditCatalogoOpen] = useState("")
    let[itemCatalogo,setItemCatalogo]=useState("")
    let [catalogoSeleccionado, setCatalogoSeleccionado] = useState("null")    
    let [loadCatalogs, setLoadCatalogs] = useState(false)  
   
    if(loadCatalogs===false){
        setLoadCatalogs(true)
        dispatch(getCatalogos("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }

    let [catalogoFilter, setCatalogoFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaCatalogosPag!==null?listaCatalogosPag:[]]);  
    if(listaCatalogosPag!==null && listaCatalogosPag!=gridInfor && catalogoFilter==="")
        setGridInfor(listaCatalogosPag)

    function filterCatalogo(cata,catalogoFilter){
        //value => 
        setGridInfor(cata.filter(function(item) {
            if (item["Codigo"].toLowerCase().includes(catalogoFilter.toLocaleLowerCase()) || 
                item["Nombre"].toLowerCase().includes(catalogoFilter.toLocaleLowerCase()))
                return true;
            return false;
        }))
    }
    function openEditCatalogo(){
        setEditCatalogoOpen("EDIT")
    }
    function openNewCatalogo(){
        setCatalogoSeleccionado(null)
        setEditCatalogoOpen("EDIT")
    }
    function closeCatalogo(){
        setEditCatalogoOpen("")
    }
    function closeWindowCatalogo(){
        setLoadCatalogs(false)
        props.closeGestionCatalogoClick()
    }
    function closeItemCatalogo(){
        setItemCatalogo("")
    }
    function formatDateTime(estado){
		switch(estado){
			case "A":
				return "ACTIVO"
		}
		return "INACTIVO"
	}
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Items',
            onClick:()=>{   
                setCatalogoSeleccionado(rowProps.data)
                setItemCatalogo("ITEM")
            }
        },     
        {
            label: 'Editar',
            onClick:()=>{   
                setCatalogoSeleccionado(rowProps.data)
                setEditCatalogoOpen("EDIT")
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar catálogo',
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{                    
                           dispatch(deleteCatalogo(rowProps.data["CatalogoId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                });               
            }
        }
        ]
    }, [])  
    //console.log(listaCatalogosPag)
    let columns= [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Codigo',header: 'Código', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'Nombre',header: 'Nombre', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400 },        
        { name: 'Estado',header: 'Estado', defaultFlex: 1, xdefaultLocked: 'end' ,render: ({ data }) => formatDateTime(data["Estado"])},                  
    ]
  return (
    <div
      className={[
        "windowContentInner",
        "fade-in"
      ].join(" ")}
    >
        <div className={['loading_cont',catalogosLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>
        {editCatalogoOpen==="EDIT" ? <EditarGestionCatalogo closeCatalogo={closeCatalogo} catalogoSeleccionado={catalogoSeleccionado}/>  :  ""}
        <div className="borderContent ventanaGeneral">
            <div className="innerShadow-height-100">
                <h2>
                
                    Catálogos
                    <div className="floatright">
                        <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                            <MenuItem onClick={openNewCatalogo}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                        </Menu>
                    </div>
                </h2> 
                <div className="titleTable-search"><div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={catalogoFilter} onChange={(e) => {
                  setCatalogoFilter(e.target.value);
                  filterCatalogo(listaCatalogosPag!==null?listaCatalogosPag:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div> 
                <DataGrid
                idProperty="CatalogoId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}
                selection={{ mode: 'single' }}                    
                dataSource={gridInfor}
                renderRowContextMenu={renderRowContextMenu}
                theme="amber-dark"
            />
                <div className="dividerLine"></div>
                <div className="textCenter">
                    <div className="btnCancelar" onClick={closeWindowCatalogo}>
                        SALIR
                    </div>
                </div> 
            </div>
        </div>        
         {itemCatalogo==="ITEM" ?  <GestionItems  closeItemCatalogo={closeItemCatalogo} catalogoSeleccionado={catalogoSeleccionado}/>:""}     
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionCatalogo);
