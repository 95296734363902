import {NotificationManager} from "react-notifications";

import {
  GET_CLIENT_PARAMETERS,
  SET_MESSAGE,
  PARAMETERS_FAIL,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAIL,
  GET_ITEMS_CATALOGUE,
  GET_ITEMS_CATALOGUE_FAIL,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  GET_USERS,
  GET_USERS_FAIL,
  DELETE_USER,
  DELETE_USER_FAIL,
  UPDATE_EVENT_ALL_SERVICE_INIT,
  GET_NEWS,
  GET_NEWS_INIT,
  GET_NEWS_FAIL,
  UPDATE_STATUS_BY_KEY_ADM,
  UPDATE_NEWS,
  UPDATE_NEWS_FAIL,
  DELETE_NEWS,
  DELETE_NEWS_FAIL,
  GET_NEWS_EMISOR,
  GET_NEWS_EMISOR_INIT,
  GET_NEWS_EMISOR_FAIL,
  UPDATE_NEWS_EMISOR,
  UPDATE_NEWS_EMISOR_FAIL,
  DELETE_NEWS_EMISOR,
  DELETE_NEWS_EMISOR_FAIL,
  GET_GESTION_CATALOGOS,
  GET_GESTION_CATALOGOS_FAIL,
  UPDATE_GESTION_CATALOGOS,
  UPDATE_GESTION_CATALOGOS_FAIL,
  DELETE_GESTION_CATALOGOS,
  DELETE_GESTION_CATALOGOS_FAIL,
  GET_CATALOGOS_PADRE,
  GET_CATALOGOS_PADRE_FAIL,
  GET_ITEMS_CATALOGOS_PADRE,
  GET_ITEMS_CATALOGOS_PADRE_FAIL,
  DELETE_ITEM_CATALOGO,
  DELETE_ITEM_CATALOGO_FAIL,
  UPDATE_ITEM_CATALOGO,
  UPDATE_ITEM_CATALOGO_FAIL,
  GET_ITEM_CATALOGOS,
  GET_ITEM_CATALOGOS_FAIL,
  GET_TITLE,
  GET_TITLE_INIT,
  GET_TITLE_FAIL,
  UPDATE_TITLE,
  UPDATE_TITLE_FAIL,
  DELETE_TITLE,
  DELETE_TITLE_FAIL,
  LOAD_EVENT_TITLE,
  LOAD_EVENT_TITLE_INIT,
  LOAD_EVENT_TITLE_FAIL,
  GET_PARAMETROS_INI,
  GET_PARAMETROS,
  GET_PARAMETROS_FAIL,
  UPDATE_PARAMETROS,
  UPDATE_PARAMETROS_FAIL,
  DELETE_PARAMETROS,
  DELETE_PARAMETROS_FAIL,
  //plantilla
  DELETE_EVENTO_PLANTILLA,
  DELETE_EVENTO_PLANTILLA_FAIL,
  UPDATE_EVENTO_PLANTILLA,
  UPDATE_EVENTO_PLANTILLA_FAIL,
  GET_EVENTO_PLANTILLA,
  GET_EVENTO_PLANTILLA_FAIL,
  GET_EVENTO_PLANTILLA_INI,
  DELETE_BANNER,
  DELETE_BANNER_FAIL,
  UPDATE_BANNER,
  UPDATE_BANNER_FAIL,
  GET_BANNER,
  GET_BANNER_FAIL,
  BANNER_INI,
  SEND_EMAIL_PASSWORD,
  SEND_EMAIL_PASSWORD_FAIL,
  RECUPERAR_INI,
  USUARIOS_INI,
  CATALOGOS_INI,
  ITEMS_INI,
  LOGIN_UPDATE
} from "./types";

import AdmService from "../services/administracion.service";
import UserService from "../services/user.service";

// inicio banner
export const getBanner = (page,filterField,filer,typeOrd,sort) => (dispatch) => {
  dispatch({
    type: BANNER_INI,
  });
  return AdmService.getBanner(page,filterField,filer,typeOrd,sort).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_BANNER,
          payload: { data: data },
        });  
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_BANNER_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_BANNER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateBanner = (bannerId,codigo,descripcion,url,urlPreview,tipo,principal,estado,fechaInicio,fechaFin,urlThumbnail,openUrl,page,filterField,filer,typeOrd,sort) => (dispatch) => {
  dispatch({
    type: BANNER_INI,
  });
  return AdmService.updateBanner(bannerId,codigo,descripcion,url,urlPreview,tipo,principal,estado,fechaInicio,fechaFin,urlThumbnail,openUrl).then(
    (response) => {
      if(response===0){
        dispatch(getBanner(page,filterField,filer,typeOrd,sort))
        dispatch({
          type: UPDATE_BANNER,
        });        
        NotificationManager.success(
          "Banner guardado",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else{        
        dispatch({
          type: UPDATE_BANNER_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_BANNER_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const deleteBanner =(bannerId,page,filterField,filer,typeOrd,sort)=> (dispatch) => {
  dispatch({
    type: BANNER_INI,
  });
    return AdmService.deleteBanner(bannerId).then(
    (response) => {
      if(response===0){
        dispatch(getBanner(page,filterField,filer,typeOrd,sort))
        dispatch({
          type: DELETE_BANNER,
        });        
        NotificationManager.success(
          "Banner eliminado",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else{            
        dispatch({
          type: DELETE_BANNER_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar banner",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();          
      dispatch({
        type: DELETE_BANNER_FAIL,
      });          
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });            
      return Promise.reject();
    }
  );
};

// fin banner
//inicio evento plantilla
export const getEventoPlantilla = (page,filterField,filer,typeOrd,sort) => (dispatch) => {
  dispatch({
    type: GET_EVENTO_PLANTILLA_INI,
  });
  return AdmService.getEventoPlantilla(page,filterField,filer,typeOrd,sort).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_EVENTO_PLANTILLA,
          payload: { data: data },
        });  
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_EVENTO_PLANTILLA_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_EVENTO_PLANTILLA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateEventoPlantilla = (eventoTraceTmpId,tipoEvento,espera,validaRF,descripcion, mensajeCliente,duracion,orden,manual,textoBoton,estado,page,filterField,filer,typeOrd,sort) => (dispatch) => {
  dispatch({
    type: GET_EVENTO_PLANTILLA_INI,
  });
  return AdmService.updateEventoPlantilla(eventoTraceTmpId,tipoEvento,espera,validaRF,descripcion, mensajeCliente,duracion,orden,manual,textoBoton,estado).then(
    (response) => {
      if(response===0){
        dispatch(getEventoPlantilla(page,filterField,filer,typeOrd,sort))
        dispatch({
          type: UPDATE_EVENTO_PLANTILLA,
        });        
        NotificationManager.success(
          "Plantilla juego guardado",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else{        
        dispatch({
          type: UPDATE_EVENTO_PLANTILLA_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_EVENTO_PLANTILLA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const deleteEventoPlantilla =(eventoTraceTmpId,page,filterField,filer,typeOrd,sort)=> (dispatch) => {
  dispatch({
    type: GET_EVENTO_PLANTILLA_INI,
  });
    return AdmService.deleteEventoPlantilla(eventoTraceTmpId).then(
    (response) => {
      if(response===0){
        dispatch(getEventoPlantilla(page,filterField,filer,typeOrd,sort))
        dispatch({
          type: DELETE_EVENTO_PLANTILLA,
        });        
        NotificationManager.success(
          "Plantilla juego eliminado",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else{            
        dispatch({
          type: DELETE_EVENTO_PLANTILLA_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar plantilla juego",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();          
      dispatch({
        type: DELETE_EVENTO_PLANTILLA_FAIL,
      });          
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });            
      return Promise.reject();
    }
  );
};
// fin evento plantilla
export const deleteParametros =(parametroId,page,filterField,filer,typeOrd,sort)=> (dispatch) => {
  dispatch({
    type: GET_PARAMETROS_INI,
  });
    return AdmService.deleteParametros(parametroId).then(
    (response) => {
      if(response===0){
        dispatch(getParametros(page,filterField,filer,typeOrd,sort))
        dispatch({
          type: DELETE_PARAMETROS,
        });        
        NotificationManager.success(
          "parámetro eliminado",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else{            
        dispatch({
          type: DELETE_PARAMETROS_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar parámetro",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();          
      dispatch({
        type: DELETE_PARAMETROS_FAIL,
      });          
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });            
      return Promise.reject();
    }
  );
};

export const updateParametros = (parametroId,codigo,nombre,descripcion,valor,usuarioId,estado,page,filterField,filer,typeOrd,sort) => (dispatch) => {
  dispatch({
    type: GET_PARAMETROS_INI,
  });
  return AdmService.updateParametros(parametroId,codigo,nombre,descripcion,valor,usuarioId,estado).then(
    (response) => {
      if(response===0){
        dispatch(getParametros(page,filterField,filer,typeOrd,sort))
        dispatch({
          type: UPDATE_PARAMETROS,
        });        
        NotificationManager.success(
          "Parámetro guardado",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else{        
        dispatch({
          type: UPDATE_PARAMETROS_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_PARAMETROS_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getParametros = (page,filterField,filer,typeOrd,sort) => (dispatch) => {
  dispatch({
    type: GET_PARAMETROS_INI,
  });
  return AdmService.getParametrosPag(page,filterField,filer,typeOrd,sort).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_PARAMETROS,
          payload: { data: data },
        });  
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_PARAMETROS_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_PARAMETROS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
//fin parametros

export const exeCargarTitulo = (eventoId) => (dispatch) => {
  dispatch({
    type: LOAD_EVENT_TITLE_INIT,
  });
  return AdmService.exeCargarTitulo(eventoId).then(
    (data) => {
      if(data!=null){
        switch(data){
          case 0:
            dispatch({
              type: LOAD_EVENT_TITLE,
              payload: { data: data },
            });

            NotificationManager.success(
              "Los títulos fueron cargados",
              "Sistema",
              3000,
              () => {},
              4
            );    

            break;
          case -2:
            dispatch({
              type: LOAD_EVENT_TITLE_FAIL,
              payload: { data: data },
            });
            NotificationManager.error(
              "No se puede cargar los títulos, el evento está en ejecución",
              "Sistema",
              3000,
              () => {},
              4
            );   
            break;
          default:
            dispatch({
              type: LOAD_EVENT_TITLE_FAIL,
              payload: { data: data },
            });
            NotificationManager.error(
              "No se puede cargar los títulos al evento",
              "Sistema",
              3000,
              () => {},
              4
            );   
            break;
        }
          
        return Promise.resolve();
      }else{
        dispatch({
          type: LOAD_EVENT_TITLE_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOAD_EVENT_TITLE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


//inicio itemcatalogo
export const getItemsCatalogoPadre = () => (dispatch) => {
  return AdmService.getItemsCatalogosPadre().then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_ITEMS_CATALOGOS_PADRE,
          payload: { data: data },
        });  
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_ITEMS_CATALOGOS_PADRE_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ITEMS_CATALOGOS_PADRE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteItemCatalogo = (itemId,catalogoId,page,filterField,filer,typeOrd,sort)=> (dispatch) => {
  dispatch({
		type: ITEMS_INI,
	  });
    return AdmService.deleteItemCatalogo(itemId).then(
    (response) => {
      if(response===0){
        dispatch(getItemCatalogos(catalogoId,page,filterField,filer,typeOrd,sort))
        dispatch(getItemsCatalogoPadre())
        dispatch({
          type: DELETE_ITEM_CATALOGO,
        });     
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        });   
        NotificationManager.success(
          "Item catálogo Eliminado",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else{            
        dispatch({
          type: DELETE_ITEM_CATALOGO_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar item catálogo",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();          
      dispatch({
        type: DELETE_ITEM_CATALOGO_FAIL,
      });          
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });            
      return Promise.reject();
    }
  );
};

export const updateItemCatalogo = (itemId,codigo,nombre,activo,editar,itemPadreId,catalogoId,nombreCorto,estado,page,filterField,filer,typeOrd,sort,uploadId) => (dispatch) => {
  dispatch({
		type: ITEMS_INI,
	  });
  return AdmService.updateItemCatalogo(itemId,codigo,nombre,activo,editar,itemPadreId,catalogoId,nombreCorto,estado,uploadId).then(
    (response) => {
      if(response===0){
        dispatch(getItemCatalogos(catalogoId,page,filterField,filer,typeOrd,sort))
        dispatch(getItemsCatalogoPadre())
        dispatch({
          type: UPDATE_ITEM_CATALOGO,
        });       
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        }); 
        NotificationManager.success(
          "Item catálogo guardado",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else{        
        dispatch({
          type: UPDATE_ITEM_CATALOGO_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_ITEM_CATALOGO_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getItemCatalogos = (catalogoId,page,filterField,filer,typeOrd,sort) => (dispatch) => {
  dispatch({
		type: ITEMS_INI,
	  });
  return AdmService.getItemsCatalogosPag(catalogoId,page,filterField,filer,typeOrd,sort).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_ITEM_CATALOGOS,
          payload: { data: data },
        });  
        
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_ITEM_CATALOGOS_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ITEM_CATALOGOS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
//fin itencatalogo
//catalogos

export const getCatalogosPadre = () => (dispatch) => {

  return AdmService.getCatalogosPadre().then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_CATALOGOS_PADRE,
          payload: { data: data },
        });  
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        });
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_CATALOGOS_PADRE_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_CATALOGOS_PADRE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteCatalogo =(catalogoId,page,filterField,filer,typeOrd,sort)=> (dispatch) => {

  dispatch({
		type: CATALOGOS_INI,
	  });
    return AdmService.deleteGestionCatalogo(catalogoId).then(
    (response) => {
      if(response===0){
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        });        
        dispatch(getCatalogos(page,filterField,filer,typeOrd,sort))
        dispatch(getCatalogosPadre())
        dispatch({
          type: DELETE_GESTION_CATALOGOS,
        });        
        NotificationManager.success(
          "Catálogo eliminado",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else{            
        dispatch({
          type: DELETE_GESTION_CATALOGOS_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar catálogo",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();          
      dispatch({
        type: DELETE_GESTION_CATALOGOS_FAIL,
      });          
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });            
      return Promise.reject();
    }
  );
};

export const updateGestionCatalogo = (catalogoId,codigo,nombre,activo,editar, catalogoPadreId,estado,page,filterField,filer,typeOrd,sort) => (dispatch) => {
  dispatch({
		type: CATALOGOS_INI,
	  });
  return AdmService.updateGestionCatalogo(catalogoId,codigo,nombre,activo,editar, catalogoPadreId,estado).then(
    (response) => {
      if(response===0){
       
        dispatch(getCatalogos(page,filterField,filer,typeOrd,sort))
        dispatch(getCatalogosPadre())

        dispatch({
          type: UPDATE_GESTION_CATALOGOS,
        });   
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
            .then(() => {
            })
            .catch(() => {
            });
        NotificationManager.success(
          "Catálogo guardado",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else{        
        dispatch({
          type: UPDATE_GESTION_CATALOGOS_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_GESTION_CATALOGOS_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getCatalogos = (page,filterField,filer,typeOrd,sort) => (dispatch) => {
  dispatch({
		type: CATALOGOS_INI,
	  });
  return AdmService.getGestionCatalogosPag(page,filterField,filer,typeOrd,sort).then(
    (data) => {
      if(data!=null){
       
        dispatch({
          type: GET_GESTION_CATALOGOS,
          payload: { data: data },
        });  
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        });
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_GESTION_CATALOGOS_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_GESTION_CATALOGOS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const getNoticiaEmisor =(noticiaId,page,filterField,filer,typeOrd,sort)=> (dispatch) => {
  dispatch({
    type: GET_NEWS_EMISOR_INIT,
  }); 
  return AdmService.getNoticiaEmisor(noticiaId,page,filterField,filer,typeOrd,sort).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_NEWS_EMISOR,
          payload: { data: data },
        });  
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_NEWS_EMISOR_FAIL,
        });
      }      
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_NEWS_EMISOR_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );  
}
export const deleteNoticiaEmisor =(noticiaId,noticiaEmisorId)=> (dispatch) => {
  dispatch({
    type: GET_NEWS_EMISOR_INIT,
  });
    return AdmService.deleteNoticiaEmisor(noticiaEmisorId).then(
        (response) => {
          if(response===0){
            dispatch(getNoticiaEmisor(noticiaId,"0","","","ASC",""))
            dispatch({
                  type: DELETE_NEWS_EMISOR,
            });        
            NotificationManager.success(
                "Emisor eliminado",
                "Sistema",
                3000,
                () => {},
                4
            );    
          }else{
            
            dispatch({
                  type: DELETE_NEWS_FAIL,
                });
                NotificationManager.error(
                    "Error al eliminar el emisor",
                    "Sistema",
                    3000,
                    () => {},
                    4
                );
            }            
            return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
    
          dispatch({
            type: DELETE_NEWS_EMISOR_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
    
          return Promise.reject();
      }
    );
  };
  export const updateNoticiaEmisor =(noticiaEmisorId,emisorId,noticiaId,rangoIni, rangoFin, estado)=> (dispatch) => {
    dispatch({
      type: GET_NEWS_EMISOR_INIT,
    });
      return AdmService.updateNoticiaEmisor(noticiaEmisorId,emisorId,noticiaId,rangoIni, rangoFin, estado).then(
          (response) => {
            if(response===0){
              dispatch(getNoticiaEmisor(noticiaId,"0","","","ASC",""))
              dispatch({
                    type: UPDATE_NEWS_EMISOR,
              });        
              NotificationManager.success(
                  "Datos del emisor registrados",
                  "Sistema",
                  3000,
                  () => {},
                  4
              );    
            }else{
              
              dispatch({
                    type: UPDATE_NEWS_EMISOR_FAIL,
                  });
                  NotificationManager.error(
                      "Error al registrar datos del emsior",
                      "Sistema",
                      3000,
                      () => {},
                      4
                  );
              }            
              return Promise.resolve();
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
      
            dispatch({
              type: UPDATE_NEWS_EMISOR_FAIL,
            });
      
            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });
      
            return Promise.reject();
        }
      );
    };

export const getNoticias =(page,filterField,filer,typeOrd,sort)=> (dispatch) => {
  dispatch({
    type: GET_NEWS_INIT,
  }); 
  return AdmService.getNoticias(page,filterField,filer,typeOrd,sort).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_NEWS,
          payload: { data: data },
        });  
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_NEWS_FAIL,
        });
      }      
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_NEWS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );  
}

export const deleteNoticia =(noticiaId)=> (dispatch) => {
  dispatch({
    type: GET_NEWS_INIT,
  });
    return AdmService.deleteNoticia(noticiaId).then(
        (response) => {
          if(response===0){
            dispatch(getNoticias("0","","","ASC",""))
            dispatch({
                  type: DELETE_NEWS,
            });        
            NotificationManager.success(
                "Noticia eliminada",
                "Sistema",
                3000,
                () => {},
                4
            );    
          }else{
            
            dispatch({
                  type: DELETE_NEWS_FAIL,
                });
                NotificationManager.error(
                    "Error al eliminar noticia",
                    "Sistema",
                    3000,
                    () => {},
                    4
                );
            }            
            return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
    
          dispatch({
            type: DELETE_NEWS_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
    
          return Promise.reject();
      }
    );
  };

export const updateNoticia =(noticiaId,codigo, titulo, descripcion, tipo, nivel, estado, imageUrl, tipoNoticiaId,imagenId)=> (dispatch) => {
    dispatch({
      type: GET_NEWS_INIT,
    });
      return AdmService.updateNoticia(noticiaId,codigo, titulo, descripcion, tipo, nivel, estado, imageUrl, tipoNoticiaId,imagenId).then(
          (response) => {
            if(response===0){
              dispatch(getNoticias("0","","","ASC",""))
              dispatch({
                    type: UPDATE_NEWS,
              });        
              NotificationManager.success(
                  "Noticia registrada",
                  "Sistema",
                  3000,
                  () => {},
                  4
              );    
            }else{
              
              dispatch({
                    type: UPDATE_NEWS_FAIL,
                  });
                  NotificationManager.error(
                      "Error al registrar la noticia",
                      "Sistema",
                      3000,
                      () => {},
                      4
                  );
              }            
              return Promise.resolve();
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
      
            dispatch({
              type: UPDATE_NEWS_FAIL,
            });
      
            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });
      
            return Promise.reject();
        }
      );
    };
export const deleteUsuario =(usuarioId)=> (dispatch) => {
  dispatch({
    type: UPDATE_EVENT_ALL_SERVICE_INIT,
  });
  dispatch({
		type: USUARIOS_INI,
	  });
    return UserService.deleteUsuario(usuarioId).then(
        (response) => {
          if(response===0){
            dispatch(getUsuarios("0","","","ASC",""))
            dispatch({
                  type: DELETE_USER,
                });        
                NotificationManager.success(
      "Usuario Eliminado",
      "Sistema",
      3000,
      () => {},
      4
  );    
          }else{
            
            dispatch({
                  type: DELETE_USER_FAIL,
                });
                NotificationManager.error(
      "Error al eliminar usuario",
      "Sistema",
      3000,
      () => {},
      4
  );
          }            
                return Promise.resolve();
              },
              (error) => {
                const message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
          
                dispatch({
                  type: DELETE_USER_FAIL,
                });
          
                dispatch({
                  type: SET_MESSAGE,
                  payload: message,
                });
          
                return Promise.reject();
      }
    );
  };

export const getUsuarios = (page,filterField,filer,typeOrd,sort) => (dispatch) => {
  dispatch({
		type: USUARIOS_INI,
	  });
  return UserService.getUsuarios(page,filterField,filer,typeOrd,sort).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_USERS,
          payload: { data: data },
        });  
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_USERS_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_USERS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const updateUsuario = (usuarioId,nombre,nick,email,rol,clave,estado,uploadId) => (dispatch) => {
  dispatch({
    type: UPDATE_EVENT_ALL_SERVICE_INIT,
  });
  dispatch({
		type: USUARIOS_INI,
	  });
    return UserService.updateUsuario(usuarioId,nombre,nick,email,rol,clave,estado,uploadId).then(
        (response) => {
          if(response===0){
            dispatch(getUsuarios("0","","","ASC",""))
            dispatch({
                  type: UPDATE_USER,
                });        
                NotificationManager.success(
                  "Usuario guardado",
                  "Sistema",
                  3000,
                  () => {},
                  4
              );    
          } if(response===-2){
            dispatch({
              type: UPDATE_USER_FAIL,
            });
            NotificationManager.error(
                "Error, correo ya existe!",
                "Sistema",
                3000,
                () => {},
                4
            );
          }if(response ===-1){
            
            dispatch({
                  type: UPDATE_USER_FAIL,
                });
                NotificationManager.error(
                    "Error al guardar",
                    "Sistema",
                    3000,
                    () => {},
                    4
                );
          }            
                return Promise.resolve();
              },
              (error) => {
                const message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
          
                dispatch({
                  type: UPDATE_USER_FAIL,
                });
          
                dispatch({
                  type: SET_MESSAGE,
                  payload: message,
                });
          
                return Promise.reject();
      }
    );
  };


export const getItemsCatalogo = (codigos) => (dispatch) => {
  return AdmService.getItemsCatalogo(codigos).then(
    (data) => {
      if(data!=null){

        dispatch({
          type: GET_ITEMS_CATALOGUE,
          payload: { data: data },
        });  
        /*dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        });*/
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_ITEMS_CATALOGUE_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ITEMS_CATALOGUE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updatePerfilUsuario = (usuarioId,nombre,nick,email,clave,uploadId) => (dispatch) => {
  return AdmService.updatePerfilUsuario(usuarioId,nombre,nick,email,clave,uploadId).then(
    (response) => {
      dispatch({
        type: UPDATE_USER_PROFILE,
      });
      
      console.log(response)
      localStorage.setItem("user", JSON.stringify(response[0]));

      dispatch({ 
        type: LOGIN_UPDATE,
        payload: { key:"user",value:response[0] },
      }); 

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_USER_PROFILE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const getParameterForClient = () => (dispatch) => {
  return AdmService.getParameterForClient().then(
    (response) => {
      dispatch({
        type: GET_CLIENT_PARAMETERS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: PARAMETERS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const setStatusByKey = (key,value) => (dispatch) => {
  dispatch({ 
    type: UPDATE_STATUS_BY_KEY_ADM,
    payload: { key:key,value:value },
  });  
  return Promise.resolve();
}
export const setStatusByKeyAdm = (key,value) => (dispatch) => {
  dispatch({ 
    type: UPDATE_STATUS_BY_KEY_ADM,
    payload: { key:key,value:value },
  });  
  return Promise.resolve();
}

export const getTitulos =(page,filterField,filer,typeOrd,sort)=> (dispatch) => {
  dispatch({
    type: GET_TITLE_INIT,
  }); 
  return AdmService.getTitulos(page,filterField,filer,typeOrd,sort).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_TITLE,
          payload: { data: data },
        });  
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_TITLE_FAIL,
        });
      }      
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_TITLE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );  
}
export const deleteTitulo =(tituloId)=> (dispatch) => {
  dispatch({
    type: GET_TITLE_INIT,
  });
    return AdmService.deleteTitulo(tituloId).then(
        (response) => {
          if(response===0){
            dispatch(getTitulos("0","","","ASC",""))
            dispatch({
                  type: DELETE_TITLE,
            });        
            NotificationManager.success(
                "Titulo eliminado",
                "Sistema",
                3000,
                () => {},
                4
            );    
          }else{
            
            dispatch({
                  type: DELETE_TITLE_FAIL,
                });
                NotificationManager.error(
                    "Error al eliminar el titulo",
                    "Sistema",
                    3000,
                    () => {},
                    4
                );
            }            
            return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
    
          dispatch({
            type: DELETE_TITLE_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
    
          return Promise.reject();
      }
    );
  };

  export const updateTitulo =(tituloId, codigo, emisor, tipoRenta, tipoValor, valorNominal, precio, rendimiento, plazo, plazoDias, estado)=> (dispatch) => {
    dispatch({
      type: GET_TITLE_INIT,
    });
      return AdmService.updateTitulo(tituloId, codigo, emisor, tipoRenta, tipoValor, valorNominal, precio, rendimiento, plazo, plazoDias, estado).then(
          (response) => {
            if(response===0){
              dispatch(getTitulos("0","","","ASC",""))
              dispatch({
                    type: UPDATE_TITLE,
              });        
              NotificationManager.success(
                  "Título registrado",
                  "Sistema",
                  3000,
                  () => {},
                  4
              );    
            }else{
              
              dispatch({
                    type: UPDATE_TITLE_FAIL,
                  });
                  NotificationManager.error(
                      "Error al registrar el título",
                      "Sistema",
                      3000,
                      () => {},
                      4
                  );
              }            
              return Promise.resolve();
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
      
            dispatch({
              type: UPDATE_TITLE_FAIL,
            });
      
            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });
      
            return Promise.reject();
        }
      );
    };
    // recuperar contrseña 

export const sendEmailPassword = (email) => (dispatch) => {
  dispatch({
    type: RECUPERAR_INI,
  });
  return UserService.getPassword(email).then(
    (response) => {
      if(response===0){

        dispatch({
          type: SEND_EMAIL_PASSWORD,
        });        
        NotificationManager.success(
          "Se ha enviado una notificación a su correo!",
          "Sistema",
          3000,
          () => {},
          4
        );    
      }else if(response ===-2){        
        dispatch({
          type: SEND_EMAIL_PASSWORD_FAIL,
        });
        NotificationManager.error(
          "Error, no se encontró el correo",
          "Sistema",
          3000,
          () => {},
          4
        );
      }            
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SEND_EMAIL_PASSWORD_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
// fin recuperar contraseña

