export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//Sala de espera
export const SHOW_BANNER = "SHOW_BANNER";
export const SHOW_BANNER_INIT = "SHOW_BANNER_INIT";
export const SHOW_BANNER_FAIL = "SHOW_BANNER_FAIL";

//Eventos
export const GET_EVENT_BY_USER = "GET_EVENT_BY_USER";
export const GET_EVENT_BY_USER_FAIL = "GET_EVENT_BY_USER_FAIL";
export const GET_EVENT_BY_USER_INIT = "GET_EVENT_BY_USER_INIT";
export const UPDATE_STATUS_BY_KEY_EVENT = "UPDATE_STATUS_BY_KEY_EVENT";


//Titulos
export const GET_EVENT_TITLE_BY_USER = "GET_EVENT_TITLE_BY_USER";
export const GET_EVENT_TITLE_BY_USER_FAIL = "GET_EVENT_TITLE_BY_USER_FAIL";
export const GET_EVENT_TITLE_BY_USER_INIT = "GET_EVENT_TITLE_BY_USER_INIT";

//Actualiza estado
export const UPDATE_STATUS_BY_KEY = "UPDATE_STATUS_BY_KEY";

//Administracion
export const GET_CLIENT_PARAMETERS = "GET_CLIENT_PARAMETERS";
export const PARAMETERS_FAIL = "PARAMETERS_FAIL";

//PORTAFOLIO

export const GET_PORTAFOLIO_CLIENTE_ACT = "GET_PORTAFOLIO_CLIENTE_ACT";
export const GET_PORTAFOLIO_CLIENTE_ACT_INI = "GET_PORTAFOLIO_CLIENTE_ACT_INI";
export const GET_PORTAFOLIO_CLIENTE_ACT_FAIL = "GET_PORTAFOLIO_CLIENTE_ACT_FAIL";

//Negociaciones

export const GET_NEGOCIACIONES_CLIENTE = "GET_NEGOCIACIONES_CLIENTE";
export const GET_NEGOCIACIONES_CLIENTE_FAIL = "GET_NEGOCIACIONES_CLIENTE_FAIL";

//PErfil usuario

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

//Gestion Evento


export const UPDATE_EVENT_SERVICE = "UPDATE_EVENT_SERVICE";
export const GET_EVENT_LIST_SERVICE_INI = "GET_EVENT_LIST_SERVICE_INI";
export const UPDATE_EVENT_SERVICE_FAIL = "UPDATE_EVENT_SERVICE_FAIL";

export const GET_EVENT_LIST_SERVICE = "GET_EVENT_LIST_SERVICE";
export const GET_EVENT_LIST_SERVICE_FAIL = "GET_EVENT_LIST_SERVICE_FAIL";





//obtener catalogo
export const GET_ITEMS_CATALOGUE = "GET_ITEMS_CATALOGUE";
export const GET_ITEMS_CATALOGUE_FAIL = "GET_ITEMS_CATALOGUE_FAIL";

//modificar tabla de eventos
export const UPDATE_EVENT_ALL_SERVICE = "UPDATE_EVENT_ALL_SERVICE";
export const UPDATE_EVENT_ALL_SERVICE_FAIL = "UPDATE_EVENT_ALL_SERVICE_FAIL";
export const UPDATE_EVENT_ALL_SERVICE_INIT = "UPDATE_EVENT_ALL_SERVICE_INIT";

//eliminar evento    
export const DELETE_EVENT_ALL_SERVICE = "DELETE_EVENT_ALL_SERVICE";
export const DELETE_EVENT_ALL_SERVICE_FAIL = "DELETE_EVENT_ALL_SERVICE_FAIL";

//obtener todos la gestion de eventos 

export const GET_ALL_GESTION_EVENT_LIST_SERVICE = "GET_ALL_GESTION_EVENT_LIST_SERVICE";
export const GET_ALL_GESTION_EVENT_LIST_SERVICE_FAIL = "GET_ALL_GESTION_EVENT_LIST_SERVICE_FAIL";

//Obtener titulos por evento buscador 

export const GET_ALL_TITLES_FOR_EVENT = "GET_ALL_TITLES_FOR_EVENT";
export const GET_ALL_TITLES_FOR_EVENT_FAIL = "GET_ALL_TITLES_FOR_EVENT_FAIL";

//actualizar gestion titulos por evento 

export const UPDATE_GESTION_EVENT_TITLE = "UPDATE_GESTION_EVENT_TITLE";
export const UPDATE_GESTION_EVENT_TITLE_FAIL = "UPDATE_GESTION_EVENT_TITLE_FAIL";


export const DELETE_EVENT_TITLE = "DELETE_EVENT_TITLE";
export const DELETE_EVENT_TITLE_FAIL = "DELETE_EVENT_TITLE_FAIL";

//usurio por evento
export const GET_USER_EVENT = "GET_USER_EVENT";
export const GET_USER_EVENT_FAIL = "GET_USER_EVENT_FAIL";
export const GET_USER_EVENT_INI = "GET_USER_EVENT_INI";

export const UPDATE_USER_EVENT_GESTION = "UPDATE_USER_EVENT_GESTION";
export const UPDATE_USER_EVENT_GESTION_FAIL = "UPDATE_USER_EVENT_GESTION_FAIL";

export const DELETE_USER_EVENT = "DELETE_USER_EVENT";
export const DELETE_USER_EVENT_FAIL = "DELETE_USER_EVENT_FAIL";

export const GET_USER_ALL_EVENT_SIN_ASIG = "GET_USER_ALL_EVENT_SIN_ASIG";
export const GET_USER_ALL_EVENT_SIN_ASIG_FAIL = "GET_USER_ALL_EVENT_SIN_ASIG_FAIL";

export const SEND_INVITATION_BY_USER = "SEND_INVITATION_BY_USER";
export const SEND_INVITATION_BY_USER_FAIL = "SEND_INVITATION_BY_USER_FAIL";
export const SEND_INVITATION_BY_USER_INIT = "SEND_INVITATION_BY_USER_INIT";

//usuarios
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const GET_USERS = "GET_USERS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

//ayuda

export const DELETE_HELP_EVENT = "DELETE_HELP_EVENT";
export const DELETE_HELP_EVENT_FAIL = "DELETE_HELP_EVENT_FAIL";

export const UPDATE_HELP_EVENT = "UPDATE_HELP_EVENT";
export const UPDATE_HELP_EVENT_FAIL = "UPDATE_HELP_EVENT_FAIL";

export const GET_HELP_EVENT = "GET_HELP_EVENT";
export const GET_HELP_EVENT_FAIL = "GET_HELP_EVENT_FAIL";

//Noticias
export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_INIT = "GET_NEWS_INIT";
export const GET_NEWS_FAIL = "GET_NEWS_FAIL";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const UPDATE_NEWS_FAIL = "UPDATE_NEWS_FAIL";
export const DELETE_NEWS = "DELETE_NEWS";
export const DELETE_NEWS_FAIL = "DELETE_NEWS_FAIL";

//Noticias emisor
export const GET_NEWS_EMISOR = "GET_NEWS_EMISOR";
export const GET_NEWS_EMISOR_INIT = "GET_NEWS_EMISOR_INIT";
export const GET_NEWS_EMISOR_FAIL = "GET_NEWS_EMISOR_FAIL";
export const UPDATE_NEWS_EMISOR = "UPDATE_NEWS_EMISOR";
export const UPDATE_NEWS_EMISOR_FAIL = "UPDATE_NEWS_EMISOR_FAIL";
export const DELETE_NEWS_EMISOR = "DELETE_NEWS_EMISOR";
export const DELETE_NEWS_EMISOR_FAIL = "DELETE_NEWS_EMISOR_FAIL";

//Adm
export const UPDATE_STATUS_BY_KEY_ADM = "UPDATE_STATUS_BY_KEY_ADM";

export const GET_GESTION_CATALOGOS = "GET_GESTION_CATALOGOS";
export const GET_GESTION_CATALOGOS_FAIL = "GET_GESTION_CATALOGOS_FAIL";

export const UPDATE_GESTION_CATALOGOS = "UPDATE_GESTION_CATALOGOS";
export const UPDATE_GESTION_CATALOGOS_FAIL = "UPDATE_GESTION_CATALOGOS_FAIL";

export const DELETE_GESTION_CATALOGOS = "DELETE_GESTION_CATALOGOS";
export const DELETE_GESTION_CATALOGOS_FAIL = "DELETE_GESTION_CATALOGOS_FAIL";

export const GET_CATALOGOS_PADRE = "GET__CATALOGOS_PADRE";
export const GET_CATALOGOS_PADRE_FAIL = "GET__CATALOGOS_PADRE_FAIL";

export const GET_ITEMS_CATALOGOS_PADRE = "GET__ITEMS_CATALOGOS_PADRE";
export const GET_ITEMS_CATALOGOS_PADRE_FAIL = "GET__ITEMS_CATALOGOS_PADRE_FAIL";

export const DELETE_ITEM_CATALOGO = "DELETE_ITEM_CATALOGO";
export const DELETE_ITEM_CATALOGO_FAIL = "DELETE_ITEM_CATALOGO_FAIL";

export const UPDATE_ITEM_CATALOGO = "UPDATE_ITEM_CATALOGO";
export const UPDATE_ITEM_CATALOGO_FAIL = "UPDATE_ITEM_CATALOGO_FAIL";

export const GET_ITEM_CATALOGOS = "GET_ITEM_CATALOGOS";
export const GET_ITEM_CATALOGOS_FAIL = "GET_ITEM_CATALOGOS_FAIL";

//titulos
export const GET_TITLE = "GET_TITLE";
export const GET_TITLE_INIT = "GET_TITLE_INIT";
export const GET_TITLE_FAIL = "GET_TITLE_FAIL";
export const UPDATE_TITLE = "UPDATE_TITLE";
export const UPDATE_TITLE_FAIL = "UPDATE_TITLE_FAIL";
export const DELETE_TITLE = "DELETE_TITLE";
export const DELETE_TITLE_FAIL = "DELETE_TITLE_FAIL";
export const LOAD_EVENT_TITLE = "LOAD_EVENT_TITLE";
export const LOAD_EVENT_TITLE_INIT = "LOAD_EVENT_TITLE_INIT";
export const LOAD_EVENT_TITLE_FAIL = "LOAD_EVENT_TITLE_FAIL";

export const GET_PARAMETROS = "GET_PARAMETROS";
export const GET_PARAMETROS_INI = "GET_PARAMETROS_INI";
export const GET_PARAMETROS_FAIL = "GET_PARAMETROS_FAIL";

export const UPDATE_PARAMETROS = "UPDATE_PARAMETROS";
export const UPDATE_PARAMETROS_FAIL = "UPDATE_PARAMETROS_FAIL";

export const DELETE_PARAMETROS = "DELETE_PARAMETROS";
export const DELETE_PARAMETROS_FAIL = "DELETE_PARAMETROS_FAIL";

//plantilla juego
export const DELETE_EVENTO_PLANTILLA = "DELETE_EVENTO_PLANTILLA";
export const DELETE_EVENTO_PLANTILLA_FAIL = "DELETE_EVENTO_PLANTILLA_FAIL";

export const UPDATE_EVENTO_PLANTILLA = "UPDATE_EVENTO_PLANTILLA";
export const UPDATE_EVENTO_PLANTILLA_FAIL = "UPDATE_EVENTO_PLANTILLA_FAIL";

export const GET_EVENTO_PLANTILLA = "GET_EVENTO_PLANTILLA";
export const GET_EVENTO_PLANTILLA_FAIL = "GET_EVENTO_PLANTILLA_FAIL";
export const GET_EVENTO_PLANTILLA_INI = "GET_EVENTO_PLANTILLA_INI";

export const GET_BANNER = "GET_BANNER";
export const GET_BANNER_FAIL = "GET_BANNER_FAIL";

export const UPDATE_BANNER = "UPDATE_BANNER";
export const UPDATE_BANNER_FAIL = "UPDATE_BANNER_FAIL";

export const DELETE_BANNER = "DELETE_BANNER";
export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL";
export const BANNER_INI = "BANNER_INI";

//recuperar contraseña 

export const SEND_EMAIL_PASSWORD = "SEND_EMAIL_PASSWORD";
export const SEND_EMAIL_PASSWORD_FAIL = "SEND_EMAIL_PASSWORD_FAIL";

export const RECUPERAR_INI = "RECUPERAR_INI";

export const PROCESS_FILE_PLAYERS = "PROCESS_FILE_PLAYERS";
export const PROCESS_FILE_PLAYERS_FAIL = "PROCESS_FILE_PLAYERS_FAIL";
export const PROCESS_FILE_PLAYERS_INI = "PROCESS_FILE_PLAYERS_INI";

//loading usuarios 
export const USUARIOS_INI = "USUARIOS_INI";
//loading ayuda evento 
export const HELP_EVENT_INI = "HELP_EVENT_INI";
//loading catalgoos 
export const CATALOGOS_INI = "CATALOGOS_INI";
//loading items ITEMS_INI
export const ITEMS_INI = "ITEMS_INI";

//Variacion de precio
export const GET_VARIATION_PRICE = "GET_VARIATION_PRICE";
export const GET_VARIATION_PRICE_FAIL = "GET_VARIATION_PRICE_FAIL";

//seleccionar usuarios por evento
export const SELECT_USER_EVENT = "SELECT_USER_EVENT";
export const SELECT_USER_EVENT_FAIL = "SELECT_USER_EVENT_FAIL";

//eliminar usuarios por evento
export const DELETE_USERS_FOR_EVENT = "DELETE_USERS_FOR_EVENT";
export const DELETE_USERS_FOR_EVENT_FAIL = "DELETE_USERS_FOR_EVENT_FAIL";

//Usuario evento
export const GET_USER_EVENT_COMBO = "GET_USER_EVENT_COMBO";
export const GET_USER_EVENT_FAIL_COMBO = "GET_USER_EVENT_FAIL_COMBO";

export const LOGIN_UPDATE = "LOGIN_UPDATE";

export const NOTICE_ESP_EVENT_INI = "NOTICE_ESP_EVENT_INI";
export const GET_NOTICE_ESP_EVENT = "GET_NOTICE_ESP_EVENT";
export const GET_NOTICE_ESP_EVENT_FAIL = "GET_NOTICE_ESP_EVENT_FAIL";

export const UPDATE_NOTICIA_ESP_EVENT = "UPDATE_NOTICIA_ESP_EVENT";
export const UPDATE_NOTICIA_ESP_EVENT_FAIL = "UPDATE_NOTICIA_ESP_EVENT_FAIL";

export const DELETE_NOTICIA_ESP_EVENT = "DELETE_NOTICIA_ESP_EVENT";
export const DELETE_NOTICIA_ESP_EVENT_FAIL = "DELETE_NOTICIA_ESP_EVENT_FAIL";



