import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {Menu,MenuItem,MenuButton,SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {getBanner,deleteBanner} from "../../actions/administracion";
import EditarBanner from "./EditarBanner";
import "../../config";
const Banner = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'100%', margin: 20 }
    const {listaBannerPag,bannerLoading} = useSelector((state) => state.administracion);   
    let [openEditarBanner, setOpenEditarBanner] = useState("") 
    let [bannerSeleccionado,setBannerSeleccionado]=useState("null")
    let [loadBanner, setBanner] = useState(false) 
    if(loadBanner===false){
        setBanner(true)
        dispatch(getBanner("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
    }
    function closeWindowBanner(){
        props.closeBannerClick()
    }
    function closeEditarBanner(){
        setOpenEditarBanner("")
    }
    function openNewBanner(){
        setBannerSeleccionado(null)
        setOpenEditarBanner("EDIT")
    } 
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
          
        {
            label: 'Editar',
            onClick:()=>{   
                setBannerSeleccionado(rowProps.data)
                setOpenEditarBanner("EDIT")
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar '+rowProps.data["Codigo"],
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{                    
                            dispatch(deleteBanner(rowProps.data["BannerId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                });               
            }
        }
        ]
    }, [])  
    let columns= [
        /*{ name: 'Codigo',header: 'Código', defaultFlex: 1, xdefaultLocked: 'end', },        */
        { name: 'Url',header: 'Url', defaultFlex: 1, xdefaultLocked: 'end',  },                  
        { name: 'UrlPreview',header: 'Avance Url', defaultFlex: 1, xdefaultLocked: 'end',},
        { name: 'FechaInicio',header: 'Fecha inicio', defaultFlex: 1, xdefaultLocked: 'end',},
        { name: 'FechaFin',header: 'Fecha fin', defaultFlex: 1, xdefaultLocked: 'end',},
    ]
  return (
    <div
      className={[
        "windowContentInner",
        "fade-in"
      ].join(" ")}
    >
         <div className={['loading_cont',bannerLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>    
        {openEditarBanner==="EDIT" ? <EditarBanner closeEditarBanner={closeEditarBanner} bannerSeleccionado={bannerSeleccionado}/>  : 
        <div className="borderContent ventanaGeneral">
            <div className="innerShadow-height-100">
                <h2>
                    Banner
                    <div className="floatright">
                        <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                            <MenuItem onClick={openNewBanner}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                        </Menu>
                    </div>
                </h2>  
                <DataGrid
                idProperty="BannerId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}
                selection={{ mode: 'single' }}                    
                dataSource={listaBannerPag!==null?listaBannerPag:[]}
                renderRowContextMenu={renderRowContextMenu} 
                theme="amber-dark"
            />
                <div className="dividerLine"></div>
                <div className="textCenter">
                    <div className="btnCancelar" onClick={closeWindowBanner}>
                        SALIR
                    </div>
                </div> 
            </div>
        </div>
        }
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Banner);
