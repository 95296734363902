import {
SET_MESSAGE,
GET_ALL_GESTION_EVENT_LIST_SERVICE,
GET_ALL_GESTION_EVENT_LIST_SERVICE_FAIL,
GET_ALL_TITLES_FOR_EVENT,
GET_ALL_TITLES_FOR_EVENT_FAIL,
UPDATE_GESTION_EVENT_TITLE,
UPDATE_GESTION_EVENT_TITLE_FAIL,
DELETE_EVENT_TITLE,
DELETE_EVENT_TITLE_FAIL,
UPDATE_STATUS_BY_KEY_EVENT
} from "../actions/types";


const initialState = {      
    listaTitulosPorEvento:null,
    listaTitulosBuscador:null
    
}

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {      
      case UPDATE_STATUS_BY_KEY_EVENT:
        switch(payload.key){
          case 'listaTitulosPorEvento':
            return {
              ...state,
              listaTitulosPorEvento:payload.value,                
            };          
          case 'listaEventoAyuda':
              return {
                ...state,
                listaEventoAyuda:payload.value,                
              };    
		   case 'listaUsuarioPorEvento':
              return {
                ...state,
                listaUsuarioPorEvento:payload.value,                
              };  
              case 'listaEventoNoticiaEsp':
                return {
                  ...state,
                  listaEventoNoticiaEsp:payload.value,                
                }; 
        }
        break
        case GET_ALL_GESTION_EVENT_LIST_SERVICE:            
        return {
            ...state,             
            listaTitulosPorEvento: payload.data,
        };
        case GET_ALL_GESTION_EVENT_LIST_SERVICE_FAIL: 
        return {
            ...state,
        };     
        case GET_ALL_TITLES_FOR_EVENT:            
        return {
            ...state,             
            listaTitulosBuscador: payload.data,
        };
        case GET_ALL_TITLES_FOR_EVENT_FAIL: 
        return {
            ...state,
        };        
        case UPDATE_GESTION_EVENT_TITLE:
            return {
                ...state,             
              };
        case UPDATE_GESTION_EVENT_TITLE_FAIL: 
          return {
            ...state,
          };          
          case DELETE_EVENT_TITLE:
            return {
                ...state,             
              };
        case DELETE_EVENT_TITLE_FAIL: 
          return {
            ...state,
          };          
        default:
            return state;
    }
  }