import {
  GET_EVENT_BY_USER,
  GET_EVENT_TITLE_BY_USER,
  GET_EVENT_BY_USER_FAIL,
  GET_EVENT_BY_USER_INIT,
  SET_MESSAGE,
  UPDATE_STATUS_BY_KEY,
  GET_PORTAFOLIO_CLIENTE_ACT,
  GET_PORTAFOLIO_CLIENTE_ACT_FAIL,
  GET_PORTAFOLIO_CLIENTE_ACT_INI,
  GET_NEGOCIACIONES_CLIENTE,
  GET_NEGOCIACIONES_CLIENTE_FAIL,
  GET_VARIATION_PRICE,
  GET_VARIATION_PRICE_FAIL,
} from "./types";

import BannerService from "../services/juego.service";
export const getVariacionPrecio = (eventoId) => (dispatch) => {  
    return BannerService.getVariacionPrecio(eventoId).then(
    (data) => {
      if(data!=null){
        dispatch({ 
          type: GET_VARIATION_PRICE,
          payload: { data: data },
        });  
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_VARIATION_PRICE_FAIL,
        });
        return Promise.reject();
      }          
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
  
      dispatch({
        type: GET_VARIATION_PRICE_FAIL,
      });
  
      return Promise.reject();
    }
    );  
  };
  

export const getNegociacionesPorUsuario = (eventoId,usuarioId) => (dispatch) => {  
  return BannerService.getNegociacionesPorUsuario(eventoId,usuarioId).then(
  (data) => {
    if(data!=null){
      dispatch({ 
        type: GET_NEGOCIACIONES_CLIENTE,
        payload: { data: data },
      });  
      return Promise.resolve();
    }else{
      dispatch({
        type: GET_NEGOCIACIONES_CLIENTE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "Error al obtener las negociaciones",
      });
      return Promise.reject();
    }          
  },
  (error) => {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    dispatch({
      type: GET_NEGOCIACIONES_CLIENTE_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  }
  );  
};

export const getEstadoCuentaPortafolio = (eventoId,usuarioId) => (dispatch) => {  
  dispatch({
    type: GET_PORTAFOLIO_CLIENTE_ACT_INI,
  });  
  return BannerService.getEstadoCuentaPortafolio(eventoId,usuarioId).then(
  (data) => {
    if(data!=null){
      dispatch({ 
        type: GET_PORTAFOLIO_CLIENTE_ACT,
        payload: { data: data },
      });  
      return Promise.resolve();
    }else{
      dispatch({
        type: GET_PORTAFOLIO_CLIENTE_ACT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "Error al obtener el estado de cuenta",
      });
      return Promise.reject();
    }          
  },
  (error) => {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    dispatch({
      type: GET_PORTAFOLIO_CLIENTE_ACT_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  }
  );  
};

export const getTitulosEventoById = (eventId) => (dispatch) => {
  dispatch({
    type: GET_EVENT_BY_USER_INIT,
  });
  return BannerService.getTitulosEventoById(eventId).then(
  (data) => {
    if(data!=null){
      dispatch({ 
        type: GET_EVENT_TITLE_BY_USER,
        payload: { data: data },
      });  
      return Promise.resolve();
    }else{
      dispatch({
        type: GET_EVENT_BY_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "Error al obtener titulos",
      });
      return Promise.reject();
    }          
  },
  (error) => {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    dispatch({
      type: GET_EVENT_BY_USER_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  }
  );  
};
export const setStatusByKey = (key,value) => (dispatch) => {
  dispatch({ 
    type: UPDATE_STATUS_BY_KEY,
    payload: { key:key,value:value },
  });  
  return Promise.resolve();
}
export const saveNegociacion = (eventId,userId,jugada,listCompra,listVenta) => (dispatch) => {
  return BannerService.saveNegociacion(eventId,userId,jugada,listCompra,listVenta).then(
    (data) => {
      dispatch(getTitulosEventoById(eventId))
            .then(() => {            
            })
            .catch(() => {
            });
      
      dispatch(getNegociacionesPorUsuario(eventId,userId))
            .then(() => {            
            })
            .catch(() => {
            });
    
      return Promise.reject();         
    },
    (error) => {
      return Promise.reject();
    }
  );
};
export const getEventosPorUsuario = (userId) => (dispatch) => {
    dispatch({
        type: GET_EVENT_BY_USER_INIT,
      });
    return BannerService.getEventosPorUsuario(userId).then(
      (data) => {
        if(data!=null){
          dispatch({ 
            type: GET_EVENT_BY_USER,
            payload: { data: data },
          });  
          return Promise.resolve();
        }else{
          dispatch({
            type: GET_EVENT_BY_USER_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: "Error al obtener banners",
          });
          return Promise.reject();
        }          
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_EVENT_BY_USER_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  