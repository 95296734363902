import React, { Component } from "react";
import NumberFormat from 'react-number-format'
import marca1 from '../../image/marca1.png';
import marca2 from '../../image/marca2.png';
import '../../config'
import {formatNumber,formatNumberFixed0} from "../general/Util"

class ItemNegiciacionRV extends Component {    
        
    constructor(props) {
        super(props);
        this.state = {
            compra: '',
            venta: '',
			typeVenta: "",
			typeCompra: "",
            barra: {
                width: '100%',
            } 
        }
    }

    componentDidUpdate(prevProps) {
        try{
            if (prevProps.refreshInput !== this.props.refreshInput && this.props.refreshInput===true) {
            this.setState({compra: 0,
                venta: 0,
                typeVenta: "",
                typeCompra: "" })
            }
        }catch(ex){
            
        }
      }
   
    checkWidthBar(precio){
        try{
            if(precio!=undefined){                
                let widthTmp = this.props.maxValue<=100?precio:((precio/this.props.maxValue)*100)
                if(this.state.barra.width!=(widthTmp+"%")){
                    this.setState({barra:{width:widthTmp + "%"}})
                }        
            }         
        }catch(ex){

        }     
        return this.state.barra
    }
    getStyleByIndex(index){
        switch(index){
            case 0:
                return "bgYellow"
            case 1:
                return "bgPurple"
            case 2:
                return "bgRed"
            case 3:
                return "bgGreen"
            case 4:
                return "bgYellow"
            case 5:
                return "bgPurple"
            case 6:
                return "bgRed"
            case 7:
                return "bgGreen"
        }
    }
    
    handleChangeIngresos(event){
        
    }
    render() {
        return (
            <div className="rowCustom" title={this.props.item["Emisor"] + " - VEF: $" + formatNumber(this.props.item["SaldoTitulo"] * this.props.item["Precio"])}>
                <div className="oneCol"><img src={this.props.item["fileNombreUrl"]!==""?global.config.URL_UPLOAD + this.props.item["fileNombreUrl"]:global.config.URL_IMAGE_EMISOR + this.props.item["EmisorCodigo"]  + ".png"} onError={(e) => e.target.src = global.config.URL_IMAGE_EMISOR + 'onerror-rv.png'} alt="Logo1" /></div>
                <div className="twoCol">{this.props.item["EmisorNombrCorto"]}</div>
                {/*<div className="threeCol">#ACC</div>*/}
                <div className="fourCol">{this.props.item["SaldoTitulo"]}</div>
                <div className="fiveCol"><div className={["percentBarHorzontal","baranimacion",this.getStyleByIndex(this.props.index)].join(' ')} style={this.checkWidthBar(this.props.item["Precio"])}>{formatNumberFixed0(this.props.item["Precio"])}</div></div>
                {this.props.compra===true?
                    <div className="paso rightColTab">
                        <div className="pasoItemComra sixCol">
                            <div className={["cantidadCustom",this.state.typeCompra].join(' ')}>
                                <NumberFormat placeholder='0' disabled={this.state.typeCompra===""?false:true} value={this.state.compra} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true} thousandSeparator={','} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
									
            const {formattedValue, value} = values;
            this.props.updateValueItemsCompra(this.props.index,this.props.item["EventoTituloId"],value,this.props.item["Precio"])//!==""?value:"0"
            this.setState({compra: value})//!==""?value:"0"
			
			if(value==="" || value=="0.00" || value=="0"){
				this.setState({typeVenta: ""})
			}else{
				this.setState({typeVenta: "disabledelement"})
			}
        }}/>
                            </div>
                        </div>
                        <div className="pasoItemVenta sevenCol">
                            <div className={["cantidadCustom",this.state.typeVenta].join(' ')}>
                                <NumberFormat placeholder='0' disabled={this.state.typeVenta===""?false:true} value={this.state.venta} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true} thousandSeparator={','} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
									
            const {formattedValue, value} = values;
            this.props.updateValueItemsVenta(this.props.index,this.props.item["EventoTituloId"],value!==""?value:"0",this.props.item["Precio"])//!==""?value:"0"
            this.setState({venta: value!==""?value:"0"})
			
			if(value==="" || value=="0.00"  || value=="0"){
				this.setState({typeCompra: ""})
			}else{
				this.setState({typeCompra: "disabledelement"})
			}
        }}/>
                            </div>
                        </div>
                </div>:""}
            </div>
        )
    }
}
export default ItemNegiciacionRV;