module.exports = global.config = {
    URL_SERVICE:'https://servicio.juegobvq.com/WsJuegoBvq/WsService.svc/',
    URL_IMAGE_EMISOR:'https://servicio.juegobvq.com/WsJuegoBvq/images/emisor/',
    URL_IMAGE_NOTICIA:'https://servicio.juegobvq.com/WsJuegoBvq/images/noticias/',    
    URL_UPLOAD:'https://servicio.juegobvq.com/WsJuegoBvq/Upload/',    
	URL_AUDIO:'https://servicio.juegobvq.com/WsJuegoBvq/audios/',    
    URL_WEB_SOCKET: 'wss://servicio.juegobvq.com/WsSocketServer/server.ashx',
    URL_CERTIFICADO:'https://servicio.juegobvq.com/WsJuegoBvq/certificados/',
    //URL_WEB_SOCKET: 'ws://localhost/WebSocketServer/server.ashx',
    LISTA_CATALOGOS: "NIVELES,MODALIDAD,TIPO_JUEGO,ESTADO_JUEGO,TIPO_RENTA,TIPO_VALOR,EMISOR,TIPO_NOT,TIPO_DUR,NOT_ESPECIAL,EMISOR"
}