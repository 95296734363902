import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";
import Select from 'react-select';
import {getUsuariosSinA} from "../../actions/evento";
import {selectUserEvent} from "../../actions/evento";
import DataGrid,{ Column,Selection }  from '@inovua/reactdatagrid-community';

import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import "../../config";
//updateGestionEventoUsuario = (eventoUsuarioId,usuarioId,eventoId,usuarioIdRegistro,estado,monto,saldo)
const SeleccionarUsuariosEvento = (props) => {     
    const { user: currentUser } = useSelector((state) => state.auth);
    const {listaUsuariosSinAsig} = useSelector((state) => state.evento);
	let [loadListaUsuarios,setLoadListaUsuarios] = useState(true);
   
    const closeSeleccionarUsuarios = ()=>{
        props.closeUsuariosEventoOpen()
    }
    const  checkBoxesMode  = useState("onClick");
    const  allMode  = useState("allPages");
    const dispatch = useDispatch();
    if(loadListaUsuarios===true){
		setLoadListaUsuarios(false)
        dispatch(getUsuariosSinA(props.eventoSeleccionadoId))
        .then(() => {
        })
        .catch(() => {
        });
    }    
    const gridStyle = { minHeight: '300px', height:'100%', margin: 20 }
    const toArray = selected => Object.keys(selected).map(id => id * 1);
    let columns= [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'value', header: 'Id', defaultVisible: false, defaultWidth: 60, type: 'number' },
        { name: 'label',header: 'Usuarios', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, },
        
        
       
    ]
    

    const [selected, setSelected] = useState({  0:true }); 
  
    const onSelectionChange = useCallback(({ selected }) => {
        setSelected(selected)
    }, [])

    function actualizar(){
      
       dispatch(selectUserEvent(
            props.eventoSeleccionadoId,
            toArray(selected).toString(),
            currentUser["userId"],
            )).then(() => {     
    
             }).catch(() => {}
        )
        
        closeSeleccionarUsuarios()
    }
    function onCheckBoxesModeChanged({ value }) {
        this.setState({ checkBoxesMode: value });
      }
    
      function onAllModeChanged({ value }) {
        this.setState({ allMode: value });
      }
  


    return (
<div className="overlay3 fade-in">    
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow-height-100">
            <div id="formulario-estilo">
                <h2>
                    Seleccionar usuarios                    
                </h2>  
                <div className='vertical-noscroll customScroll'>               
                    <div className="columna-1-formulario-ventana-perfil-edit-evento-user"> 
                    <DataGrid                        
                        idProperty="value"
                        selected={selected} 
                        checkboxColumn     
                        onSelectionChange={onSelectionChange}   
                        style={gridStyle}               
                        columns={columns}                                                                            
                        dataSource={listaUsuariosSinAsig!==null?listaUsuariosSinAsig:[]}                        
                        theme="amber-dark"
                    />   
                   
                    </div> 
                    <div className="textCenter clear-both">
                   
                        <div className="btnGuardar" onClick={actualizar} >
                        GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeSeleccionarUsuarios}>
                            SALIR
                        </div>
                    </div>
                </div> 
            </div>      
        </div>
    </div>
</div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(SeleccionarUsuariosEvento);
