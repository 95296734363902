import axios from "axios";
import authHeader from "./auth-header";
import '../config'
require('dotenv').config()

// recuperar clave getPassword
const getPassword =(email) =>{
  const params = {"strEmail":email}
    return axios 
      .post(global.config.URL_SERVICE + "RecuperarClave", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        return result;  
    });
}
// fin recuperar clave
// inicia servicios para  usuario
const deleteUsuario=(usuarioId)=>{
  const params = {"strUsuarioId":usuarioId}
    return axios 
      .post(global.config.URL_SERVICE + "EliminarUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        return result;  
      });
}

const updateUsuario =(usuarioId,nombre,nick,email,rol,clave,estado,uploadId) =>{
  const params = {"strUsuarioId":usuarioId,"strNombre":nombre,"strNick":nick,"strEmail":email,"strRol":rol,"strClave":clave,"strEstado":estado,"strUploadId":uploadId}
    return axios 
      .post(global.config.URL_SERVICE + "GestionUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        return result;  
    });
}
const getUsuarios = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
      .post(global.config.URL_SERVICE + "ObtenerUsuariosPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
      return result;  
  });
}
//terminan los servicos par usuario					   
const API_URL = process.env.URL_SERVICE;// "http://localhost:8080/api/test/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getUsuarios,
  updateUsuario,
  deleteUsuario,
  getPassword
};