import React, { Component} from "react";

class MoreConnection extends Component {    
    constructor(props) {
        super(props);
        this.state = {
        }
    }
   
    render() {         
        return (
            <div className='nojuego'> 
                <div className="customBoxAviso">
                    <div className="innerShadow">
                        Has abierto el juego en otro dispositivo
                    </div>
                </div>
            </div>
        )
    }
}

export default MoreConnection;