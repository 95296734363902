import axios from "axios";
import '../config'

const deleteUsersEvent=(eventoId)=>{
  const params = {"strEventoId":eventoId}
      return axios 
          .post(global.config.URL_SERVICE + "EliminarUsuariosEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              return result;  
          });
}

//Seleccionar usuarios por evento
const selectUserEvent = (eventoId,usuarioId,usuarioIdRegistro) => {
  const params = {"strEventoId":eventoId,"strUsuarioId":usuarioId,"strUsuarioIdRegistro":usuarioIdRegistro}
    return axios
        .post(global.config.URL_SERVICE + "SeleccionarUsuariosEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result===0){
              return result;  
            }else{
              return null
            }
        });
}


const processArchivoUsuarios = (eventoId,uploadId)=>{
  const params = {"strEventoId":eventoId,"uploadId":uploadId}
  return axios 
      .post(global.config.URL_SERVICE + "CargarArchivoUsuariosEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
      return result;  
  });
}

//inicia gestion de noticias especiales evento
const getEventoNoticiaEspecial = (eventoId,page,filterField,filer,typeOrd,sort)=>{
  const params = {"strEventoId":eventoId,"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
      .post(global.config.URL_SERVICE + "ObtenerEventoNoticiaEspPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
      return result;  
  });
}
const updateGestionNoticiaEspecial = (eventoNoticiaEspId,eventoId,tipo,jugada,estado) => {
  const params = {"strEventoNotId":eventoNoticiaEspId,"strEventoId":eventoId,"strJugada":jugada,"strTipo":tipo,"strEstado":estado}
    return axios
        .post(global.config.URL_SERVICE + "GestionEventoNoticiaEspecial", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result===0){
              return result;  
            }else{
              return null
            }
        });
}
const deleteNoticiaEspecial=(eventoNoticiaEspId)=>{
  const params = {"strEventoNoticiaId":eventoNoticiaEspId}
      return axios 
          .post(global.config.URL_SERVICE + "EliminarEventoNoticiaEsp", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              return result;  
          });
}

//inicia gestion de ayuda 
const getEventoAyuda = (eventoId,page,filterField,filer,typeOrd,sort)=>{
  const params = {"strEventoId":eventoId,"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
      .post(global.config.URL_SERVICE + "ObtenerEventoAyudaPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
      return result;  
  });
}

const updateGestionEventoAyuda = (eventoAyudaId,eventoId,jugada,tipo,estado) => {
  const params = {"strEventoAyudaId":eventoAyudaId,"strEventoId":eventoId,"strJugada":jugada,"strTipo":tipo,"strEstado":estado}
    return axios
        .post(global.config.URL_SERVICE + "GestionEventoAyuda", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result===0){
              return result;  
            }else{
              return null
            }
        });
}
const deleteGestionEventoAyuda=(eventoAyudaId)=>{
  const params = {"strEventoAyudaId":eventoAyudaId}
      return axios 
          .post(global.config.URL_SERVICE + "EliminarEventoAyuda", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              return result;  
          });
}
//termina ayuda					  
const enviarInvitacionPorEvento = (eventoId,usuarioId)=>{
  const params = {"strEventoId":eventoId}
    return axios 
        .post(global.config.URL_SERVICE + "EnviarInvitacionPorEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            return result;  
    });
}

const enviarInvitacionPorUsuario = (eventoId,usuarioId)=>{
  const params = {"strEventoId":eventoId,"strUsuarioId":usuarioId}
    return axios 
        .post(global.config.URL_SERVICE + "EnviarInvitacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            return result;  
    });
}

const getUsuarioEventoSinAsig = (eventoId)=>{
  const params = {"strEventoId":eventoId}
    return axios 
        .post(global.config.URL_SERVICE + "ObtenerUsuariosEventoSinAsig", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            return result;  
    });
}

const deleteEventoUsuario=(eventoUsuarioId)=>{
  const params = {"strEventoUsuarioId":eventoUsuarioId}
      return axios 
          .post(global.config.URL_SERVICE + "EliminarEventoUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              return result;  
          });
}
const updateGestionEventoUsuario = (eventoUsuarioId,usuarioId,eventoId,usuarioIdRegistro,estado,monto,saldo) => {
  const params = {"strEventoUsuarioId":eventoUsuarioId,"strUsuarioId":usuarioId,"strEventoId":eventoId,"strUsuarioIdRegistro":usuarioIdRegistro,"strEstado":estado,"strMonto":monto,"strSaldo":saldo}
    return axios
        .post(global.config.URL_SERVICE + "GestionEventoUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result===0){
              return result;  
            }else{
              return null
            }
        });
}

const getUsuarioEvento = (eventoId)=>{
  const params = {"strEventoId":eventoId}
    return axios 
        .post(global.config.URL_SERVICE + "ObtenerUsuarioEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            return result;  
    });
}
/*finaliza gestion evento usuario*/

const deleteEvento=(eventoId)=>{
  const params = {"strEventoId":eventoId}
      return axios 
          .post(global.config.URL_SERVICE + "EliminarEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              return result;  
          });
}

const updateEvento =(eventoId,modalidadNombreSelect,tipoEventoNombreSelect,nombreInstitucion,nombreEvento,logo,montoJugador,tipoDuracionNombreSelect,duracion,tiempoNoticia,tiempoJugada,estado,tiempoSiguienteJugada,tiempoVarPrecios,tiempoRecSaldos,usuarioId,nivelNombreSelect,estadoActId,ultimaJugada,notificacionEspecial,startDate,juegaRentaFija,forzarCompraRF) =>{

  const params = {"strEventoId":eventoId,"strModalidadId":modalidadNombreSelect,"strTipoEvento":tipoEventoNombreSelect,"strNombreInstitucion":nombreInstitucion,"strNombreEvento":nombreEvento,"strLogo":logo,"strFechaInicio":startDate,"strMontoJugador":montoJugador,"strTipoDuracion":tipoDuracionNombreSelect,"strDuracion":duracion,"strTiempoNoticia":tiempoNoticia,"strTiempoJugada":tiempoJugada,"strUsuarioId":usuarioId,"strJuegaRentaFija":juegaRentaFija,"strEstado":estado,"strTiempoSiguienteJugada":tiempoSiguienteJugada,"strTiempoVarPrecios":tiempoVarPrecios,"strTiempoRecSalados":tiempoRecSaldos,"strNivelId":nivelNombreSelect,"strEstadoActId":estadoActId,"strUltimaJugada":ultimaJugada,"strNotificaEspecial":notificacionEspecial,"strForzarCompraRF":forzarCompraRF}
      return axios 
          .post(global.config.URL_SERVICE + "GestionEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              return result;  
          });
}

const getEventos = (page,filterField, filer,typeOrd,sort) => {
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
    return axios 
        .post(global.config.URL_SERVICE + "ObtenerEventos", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result.length>0){
              return result;  
            }else{
              return null
            }
        });
}

const updateGestionEvento = (eventoId,tiempoNoticia,tiempoJugada,tiempoVarPrecios,tiempoRecSalados) => {
  const params = {"strEventoId":eventoId,"strTiempoNoticia":tiempoNoticia,"strTiempoJugada":tiempoJugada,"strTiempoVarPrecios":tiempoVarPrecios,"strTiempoRecSalados":tiempoRecSalados}
    return axios
        .post(global.config.URL_SERVICE + "ActualizarUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result.length>0){
              return result;  
            }else{
              return null
            }
        });
}
  
const getParameterForClient = () => {
    const params = {}
    return axios
        .post(global.config.URL_SERVICE + "ObtenerParametrosCliente", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result.length>0){
              localStorage.setItem("parameters", JSON.stringify(result));
              return result;  
            }else{
              return null
            }
        });
  }
  const getUsuarioEventoCombo = (eventoId)=>{
    const params = {"strEventoId":eventoId}
      return axios 
          .post(global.config.URL_SERVICE + "ObtenerUsuariosEventoCombo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              return result;  
      });
  }
  
  export default {
      getParameterForClient,      
      updateGestionEvento,
      getEventos,
      updateEvento,
      deleteEvento,
      //metodos de usuario evento
      getUsuarioEvento,
      updateGestionEventoUsuario,
      deleteEventoUsuario,
      getUsuarioEventoSinAsig,
      enviarInvitacionPorUsuario,
      enviarInvitacionPorEvento,
	    updateGestionEventoAyuda,
      deleteGestionEventoAyuda,
      getEventoAyuda,
      processArchivoUsuarios,
      deleteUsersEvent,
      selectUserEvent,
      getUsuarioEventoCombo,
      getEventoNoticiaEspecial,
      updateGestionNoticiaEspecial,
      deleteNoticiaEspecial
  };
    