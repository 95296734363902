import React, { Component } from "react";
import NumberFormat from 'react-number-format'
import marca1 from '../../image/marca1.png';
import marca2 from '../../image/marca2.png';
import '../../config'
import {formatNumber} from "../general/Util"

class ItemUpPrecio extends Component {    
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="jugada up fade-in">
                <ul>
                    <li><div className="imgLogoJugadas greenStyle"><img src={this.props.item["ImagenEmisor"]!==""?global.config.URL_UPLOAD + this.props.item["ImagenEmisor"]:global.config.URL_IMAGE_EMISOR + this.props.item["Codigo"]  + ".png"} onError={(e) => e.target.src = global.config.URL_IMAGE_EMISOR + 'onerror-rv.png'} alt="Logo Marca 1" /></div></li>
                    <li><div className="upArrow"><i className="fas fa-arrow-up"></i></div></li>
                    <li><div className="cantidadJugada">$ {formatNumber(this.props.item["Variacion"]>0?this.props.item["Variacion"]:this.props.item["Variacion"]*-1)}<span>{this.props.item["Nombre"]}</span></div></li>                                
                </ul>
            </div>
        )
    }
}
export default ItemUpPrecio;