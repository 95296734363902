import {NotificationManager} from "react-notifications";
import {
    SET_MESSAGE,
    GET_ALL_GESTION_EVENT_LIST_SERVICE,
    GET_ALL_GESTION_EVENT_LIST_SERVICE_FAIL,
    GET_EVENT_LIST_SERVICE_FAIL,
    GET_ALL_TITLES_FOR_EVENT,
    GET_ALL_TITLES_FOR_EVENT_FAIL,    
    UPDATE_EVENT_ALL_SERVICE_INIT,
    UPDATE_GESTION_EVENT_TITLE,
    UPDATE_GESTION_EVENT_TITLE_FAIL,
    DELETE_EVENT_TITLE,
    DELETE_EVENT_TITLE_FAIL,
    UPDATE_STATUS_BY_KEY_EVENT
  } from "./types";
import configGestionEventService from "../services/titulosPorEvento.service";


export const deleteEventoTitulo =( eventoTituloId,eventoId)=> (dispatch) => {  
    return configGestionEventService.deleteEventoTitulo(eventoTituloId).then(
        (response) => {
          if(response===0){
            dispatch(getTitulosPotEvento(eventoId))
            dispatch({
                  type: DELETE_EVENT_TITLE,
                });        
                NotificationManager.success(
                    "Título Eliminado",
                    "Sistema",
                    3000,
                    () => {},
                    4
                );    
          }else{
            
            dispatch({
                  type: DELETE_EVENT_TITLE_FAIL,
                });
                NotificationManager.error(
                    "Error al eliminar título",
                    "Sistema",
                    3000,
                    () => {},
                    4
                );
          }            
                return Promise.resolve();
              },
              (error) => {
                const message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
          
                dispatch({
                  type: DELETE_EVENT_TITLE_FAIL,
                });
          
                dispatch({
                  type: SET_MESSAGE,
                  payload: message,
                });
          
                return Promise.reject();
      }
    );
  };
  export const updateTitulo = (eventoTituloId,eventoId,tituloId,valorNominal,precio,rendimiento,plazo,plazoDias,estado,saldo,maduracion,jugadaAct) => (dispatch) => {
    
      return configGestionEventService.updateGestionEventoTitulo(eventoTituloId,eventoId,tituloId,valorNominal,precio,rendimiento,plazo,plazoDias,estado,saldo,maduracion,jugadaAct).then(
          (response) => {
            if(response===0){
              dispatch(getTitulosPotEvento(eventoId))
              dispatch({
                    type: UPDATE_GESTION_EVENT_TITLE,
                  });        
                  NotificationManager.success(
                    "Titulo guardado",
                    "Sistema",
                    3000,
                    () => {},
                    4
                );    
            }else{
              
              dispatch({
                    type: UPDATE_GESTION_EVENT_TITLE_FAIL,
                  });
                  NotificationManager.error(
                      "Error al guardar título",
                      "Sistema",
                      3000,
                      () => {},
                      4
                  );
            }            
                  return Promise.resolve();
                },
                (error) => {
                  const message =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
            
                  dispatch({
                    type: UPDATE_GESTION_EVENT_TITLE_FAIL,
                  });
            
                  dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                  });
            
                  return Promise.reject();
        }
      );
    };

export const getTitulosBuscador = () => (dispatch) => {
      return configGestionEventService.obtenerTitulosBuscador().then(
        (data) => {
          if(data!=null){
            dispatch({
              type: GET_ALL_TITLES_FOR_EVENT,
              payload: { data: data },
            });  
            return Promise.resolve();
          }else{
            dispatch({
              type: GET_ALL_TITLES_FOR_EVENT_FAIL,
            });
          }
    
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
    
          dispatch({
            type: GET_EVENT_LIST_SERVICE_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
    
          return Promise.reject();
        }
      );
    };
export const getTitulosPotEvento = (eventoId) => (dispatch) => {
      return configGestionEventService.obtenerTitulosPorEvento(eventoId).then(
        (data) => {
          if(data!=null){
            dispatch({
              type: GET_ALL_GESTION_EVENT_LIST_SERVICE,
              payload: { data: data },
            });  
            return Promise.resolve();
          }else{
            dispatch({
              type: GET_ALL_GESTION_EVENT_LIST_SERVICE_FAIL,
            });
          }
    
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
    
          dispatch({
            type: GET_EVENT_LIST_SERVICE_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
    
          return Promise.reject();
        }
      );
    };
  
    export const setStatusByKey = (key,value) => (dispatch) => {
      dispatch({ 
        type: UPDATE_STATUS_BY_KEY_EVENT,
        payload: { key:key,value:value },
      });  
      return Promise.resolve();
    }