export const ROL_ADMIN = 'A';
export const ROL_USUARIO = 'U';
export const ROL_DIRECTOR = 'R';


export function enableFuncionalityForRole(rol,fun){
  const arrListAdminFun = ["LIST_USR","CONF_EVENTO","UPORN","ADM","FUNC_ADM","VER_ESTCTA","ENV_ESTCTA","ING_ORND","STAT_GAME","CHAT"]
  const arrListRuedaFun = ["LIST_USR","CONF_EVENTO","UPORN","ADM","VER_ESTCTA","ENV_ESTCTA","ING_ORND","STAT_GAME","CHAT"]
  const arrListUsuarioFun = ["MOS_AYU"]
  const arrListCajaFun = ["LIST_USR","VER_ESTCTA","ING_ORND","FUNC_CAJ","UPORN"]
  const arrListVisFun = ["LIST_USR","UPORN"]//"VER_ESTCTA"

  switch(rol){
    case "ROLE_ADMIN":
      return arrListAdminFun.filter(value => (value === fun)).length>0
      break
    case "ROLE_RUEDA":
      return arrListRuedaFun.filter(value => (value === fun)).length>0
      break
    case "ROLE_USER":
      return arrListUsuarioFun.filter(value => (value === fun)).length>0
      break
    case "ROLE_CAJA":
      return arrListCajaFun.filter(value => (value === fun)).length>0
      break
    case "ROLE_VISITANTE":
      return arrListVisFun.filter(value => (value === fun)).length>0
      break
  }
}
export function getRole(roles,type){
    switch(type){
        case "A":
            return roles.filter(value => (value["Codigo"] === "ADMIN")).length>0
        case "U":
            return roles.filter(value => value["Codigo"] === "USUARIO").length>0
        case "R":
            return roles.filter(value => (value["Codigo"] === "ADMIN" || value["Codigo"] === "RUEDA")).length>0
        default:
            return false
    }    
}
export function getMax(arr, prop) {
  if(arr===null) return 0;
    var max;
    for (var i=0 ; i<arr.length ; i++) {
        if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
            max = arr[i];
    }
    return max[prop];
}
export function getMaxRV(arr, prop) {
  if(arr===null) return 0;
    var max;
    for (var i=0 ; i<arr.length ; i++) {
        if(arr[i]["TipoRentaCod"]!=="RV") continue;
        
        if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
            max = arr[i];
    }
    return max[prop];
}
export function getTotalAccion(arr) {
  if(arr===null) return 0;
    var suma=0;
    for (var i=0 ; i<arr.length ; i++) {
        suma = suma + (arr[i]["SaldoTitulo"] * arr[i]["Precio"])
    }
    return suma;
}
export function formatDateMont(date) {
  const arrMonth = ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"];
  let day = date.getDate().toString()
  if(day.length==1)
    day = "0" + day
  return  day + "/"+ arrMonth[date.getMonth()] + "/" + date.getFullYear()
}

export function formatDateTime(dateT) {
  let date = new Date(dateT)
  
  let month = (date.getMonth() + 1).toString()
  if(month.length==1)
  month = "0" + month

  let day = date.getDate().toString()
  if(day.length==1)
    day = "0" + day

  let hours = date.getHours().toString()
  if(hours.length==1)
    hours = "0" + hours

  let minutes = date.getMinutes().toString()
  if(minutes.length==1)
    minutes = "0" + minutes

  let seconds = date.getSeconds().toString()
  if(seconds.length==1)
    seconds = "0" + seconds

  return  day + "/"+ month + "/" + date.getFullYear() + " " + hours + ":" + minutes + ":" + seconds
}

export function formatNumber(num){
    return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatNumberFixed0(num){
  return num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatTime(serverTimeinSeconds)
  { 
    if(serverTimeinSeconds<0) serverTimeinSeconds = 0
    let seconds = Math.floor(Number(serverTimeinSeconds));
    let days = Math.floor(seconds / (24*60*60));
    seconds -= Math.floor(days    * (24*60*60));
    let hours    = Math.floor(seconds / (60*60));
    seconds -= Math.floor(hours   * (60*60));
    let minutes  = Math.floor(seconds / (60));
    seconds -= Math.floor(minutes * (60));

    let dDisplay = days > 0 ? days + (days === 1 ? 'd ' : 'd ') : '';
    let hDisplay = hours > 0 ? hours + (hours === 1 ? ':' : ':') : '';
    let mDisplay = minutes > 0 ? minutes + (minutes === 1 ? ':' : ':') : '';
    let sDisplay = seconds > 0 ? seconds + (seconds === 1 ? '' : '') : '';

    if(sDisplay.length===0)
      sDisplay = "00"
    if(mDisplay.length===0)
      mDisplay = "00:"
    if(hDisplay.length===0)
      hDisplay = "00:"

    if(sDisplay.length===1)
      sDisplay = "0" + sDisplay
    if(mDisplay.length===1)
      mDisplay = "0" + mDisplay
    if(hDisplay.length===1)
      hDisplay = "0" + hDisplay

      if(mDisplay.length===2)
      mDisplay = "0" + mDisplay
      if(hDisplay.length===2)
      hDisplay = "0" + hDisplay

    return dDisplay + hDisplay + mDisplay + sDisplay;
  }
  export function generateMessageAdm(strType){
    return JSON.stringify({"ADM":strType})
  }
  export function generateMessageAdmParams(strType,params){
    return JSON.stringify({"ADM":strType,"PARAMS":params})
  }
  export function generateMessageChatParams(strType,strTo,strMsg,from){
    return JSON.stringify({"ADM":strType,"TO":strTo,"MSG":strMsg,"TYPE":"MSG","FROM":from})
  }
  export function getTextoPosicion(pos){
    switch(pos){
      case 1:
        return "PRIMER LUGAR";
      case 2:
        return "SEGUNDO LUGAR";
      case 3:
      return "TERCER LUGAR";
      case 4:
        return "CUARTO LUGAR";
      case 5:
        return "QUINTO LUGAR";
      case 6:
        return "SEXTO LUGAR";
      case 7:
        return "SEPTIMO LUGAR";
      case 8:
      return "OCTAVO LUGAR";
      case 9:
        return "NOVENO LUGAR";
      case 10:
        return "DECIMO LUGAR";      
    }
    return "";
  }
  export const listaAyudas = [
    {
      element: ".pasoRentaFija",
      intro: "Titulos de renta fija a la venta",
      position: 'right',            
    }, 
    {
    element: ".colBarras",
    intro: "Títulos de renta variable",
    position: 'right',
    },                             
    {
      element: ".pasoDescJugada",
      intro: "Número de jugada y evento que se está realizando",
      position: 'right',            
    },                 
    {
      element: ".pasoPrecioUpDown",
      intro: "Variación de Precios de Acciones: Dos empresas suben sus precios y dos empresas bajan sus precios",
      position: 'right',            
    },     
    {
      element: ".pasoNotPositiva",
      intro: "Detalle - Noticia POSITIVA, para tu análisis respectivo, que afecta el precio a dos empresas que cotizan acciones",
      position: 'right',            
    },                       
    {
      element: ".pasoNotNegativa",
      intro: "Detalle - Noticia NEGATIVA, para tu análisis respectivo, que afecta el precio a dos empresas que cotizan acciones",
      position: 'right',            
    },         
    {
      element: ".btnCompra",
      intro: "Opción para Comprar/Vender Acciones y Papeles de Renta Fija",
      position: 'right',                          
    },
    {
      element: ".pasoNegociacion",
      intro: "Ingreso de negociaciones",
      position: 'right',                  
    },
    {
      element: ".pasoNegRV",
      intro: "Títulos de renta variable, puedes comprar o vender en cualquier jugada",
      position: 'right',
    },      
    {
      element: ".pasoNegRF",
      intro: "Títulos de fija, solo disponibles en la jugada que salen a la venta los títulos",
      position: 'right',                  
    },  
    {
      element: ".pasoItemComra",
      intro: "Ingresa la cantidad de títulos a comprar",
      position: 'right',                  
    },      
    {
      element: ".pasoItemVenta",
      intro: "Ingresa la cantidad de títulos a vender",
      position: 'right',                  
    },      
    {
      element: ".pasoUserRow",
      intro: "Lista de usuarios, aqui podras ver los usuarios conectados al juego",
      position: 'right'
    },
    {
      element: ".pasoOpcionesUsr",
      intro: "Puede ver o enviar el estado de cuenta del portafolio, así como ingresar órdenes de negociación",
      position: 'right'
    },       	    
    {
      element: ".pasoOpcionesJug",
      intro: "Acciones especiales del juego",
      position: 'left',            
    },               
    {
      element: ".pasoHistorialTran",
      intro: "Historial de Transacciones, para revisar tus operaciones",
      position: 'right',            
      },
      {
      element: ".pasoFitroHT",
      intro: "Revisión filtrada de tus transacciones",
      position: 'right',            
      },    
      {
      element: ".pasoHTLeft",
      intro: "Regresa al Historial de Transacciones",
      position: 'right',            
      },    
      {
      element: ".pasoHTRight",
      intro: "Ver detalle de Noticias positivas y negativas, además de progresión de precios de acciones",
      position: 'right',            
      },      
      {
      element: ".chatBtn",
      intro: "Envía mensajes al director de rueda",
      position: 'right',      
      },              
      {
      element: ".pasoConfig",
      intro: "Cerrar sesión",
      position: 'left',            
      }, 
      {
      element: ".pasoSound",
      intro: "Activa/Desactiva audio",
      position: 'right',            
      }, 	
      {
      element: ".pasoAyuda",
      intro: "Ayuda",
      position: 'right',            
      },     
      {
        element: ".ayudaBarAcc",
        intro: "Precio Monetario de Acciones",
        position: 'right',                    
      },     
      {
        element: ".ayudaBarAccLogo",
        intro: "Empresas que cotizan acciones",
        position: 'right',                    
      },
      {
        element: ".ayudaBarAccCantidad",
        intro: "Número de Acciones disponibles para la venta",
        position: 'left',                    
      },
      {
        element: ".ayudaBarAccCantidad",
        intro: "Número de Acciones disponibles para la venta",
        position: 'left',                    
      },
      {
        element: ".timeProximaJugada",
        intro: "Tiempo de juego, para tomar tu decisión, para comprar y/o vender",
        position: 'left',                    
      },  
      {
        element: ".timeTotal",
        intro: "Tiempo de Juego Total",
        position: 'left',                    
      },  
      {
        element: ".ayudaUsuarioPer",
        intro: "Nombre del participante, evento y configuración del perfil",
        position: 'right',                    
      },  
      {
        element: ".ayudaGuardarOrden",
        intro: "Ingresa tu orden de transacción",
        position: 'right',                    
      },      
      {
        element: ".ayudaIniciarJuego",
        intro: "Inicia el juego",
        position: 'right',                    
      },              
  ]
  export function numberToOrdinal(number){
    let arrOrdinal = ["1er","2do","3er","4to","5to","6to","7mo","8vo","9no","10mo"]
    if(arrOrdinal.length<=number){
      return number
    }else{
      return arrOrdinal[number-1]
    }
  }