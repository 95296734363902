import React, { useState, useEffect } from "react";
import imagenEmisor from '../image/logoRF1.png';
import ItemNegociacionRV from './juego/ItemNegociacionRV';
import ItemNegociacionRF from './juego/ItemNegociacionRF';

const Negociacion = (props) => {
	let [itemsCompra] = useState({});
	let [itemsVenta] = useState({});
    
    function btnNegociacionCancelar(){
        props.btnNegociacionCancelar();
    }
    function btnEnviarNegociacion(){        		
        props.btnEnviarNegociacion(props.evento,props.jugada,itemsCompra,itemsVenta);
    }
    function updateValueItemsVenta(index,id,value,precio){
        itemsVenta[index] = {"id": id,"value":value,"precio":precio};
    }
    function updateValueItemsCompra(index,id,value,precio){
        itemsCompra[index] = {"id": id,"value":value,"precio":precio};
    }
    return (
        <div className='fade-in'>
        <div className='pasoNegociacion content'>
            <div className="borderContent tableContent">
                <div className={["innerShadow3","negociacion",props.isMaximized===true?"minimizar-orden":""].join(' ')}>
                    <div className="titleCont">      
                        <h3>RENTA VARIABLE</h3>
                        <div className="titleRight">
                            <h3>COMPRAR</h3>
                            <h3>VENDER</h3>
                        </div>
                    </div> 
                    <div className="pasoNegRV tableContainer">  
                            {
                                props.currentTitle!=null?props.currentTitle.filter(value => value["TipoRentaCod"] === 'RV').map((item, i) => { 
                                    return <ItemNegociacionRV key={i} index={i} item={item} updateValueItemsVenta={updateValueItemsVenta} updateValueItemsCompra={updateValueItemsCompra} evento={props.eventoId} jugada={props.jugada} maxValue={props.maxValue} compra={true}/>
                                }):""
                            }                             
                    </div>
                    
                    <div className="dividerLine"></div>
                    
                    <div className="titleCont">      
                        <h3>RENTA FIJA</h3>
                        <div className="titleRight">
                            <h3>COMPRAR</h3>            
                        </div>
                    </div> 
                    <div className='pasoNegRF'>
                    {
                        props.currentTitle!=null?props.currentTitle.filter(value => value["TipoRentaCod"] === 'RF').map((item, i) => { 
                            return <ItemNegociacionRF key={i} index={i} item={item} evento={props.eventoId} jugada={props.jugada} compra={true} updateValueItemsCompra={updateValueItemsCompra}/>
                        }):""
                    }                          
                    </div>   

                    {/*<div className="rowCustom2">
                            <div className="oneCol"><div className="imgLogo gris"><img src={imagenEmisor} alt="El Juego de la Bolsa" /></div></div>
                            <div className="twoCol">Ministerio de Finanzas Bonos del Estado </div>
                            <div className="threeCol">1.500.000 - 5.23% - 180 días - 1/4</div>            
                            <div className="fourCol"><div className="cantidadCustom"><input type="text"/></div></div>
                    </div>
                    
                    
                    <div className="rowCustom2">
                            <div className="oneCol"><div className="imgLogo yellowStyle"><img src={imagenEmisor} alt="El Juego de la Bolsa" /></div></div>
                            <div className="twoCol">Ministerio de Finanzas Bonos del Estado </div>
                            <div className="threeCol">1.500.000 - 5.23% - 180 días - 1/4</div>            
                            <div className="fourCol"><div className="cantidadCustom"><input type="text"/></div></div>
                    </div>
                */}       
                    </div>
                </div>
            </div>
            <div className="btnContainer"><div className="btnEnviarCompra ayudaGuardarOrden" onClick={btnEnviarNegociacion}>Enviar</div><div className="btnCancelar" onClick={btnNegociacionCancelar}>Cancelar</div></div>
        </div>
    );
}
export default Negociacion;