import React, { Component } from "react";
import NumberFormat from 'react-number-format'
import '../../config'

class ItemRentaFija extends Component {    
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="valorRentaFija">        
                <div className="imgLogo gris"><img src={this.props.item["fileNombreUrl"]!==""?global.config.URL_UPLOAD + this.props.item["fileNombreUrl"]:global.config.URL_IMAGE_EMISOR + this.props.item["EmisorCodigo"]  + ".png"} onError={(e) => e.target.src = global.config.URL_IMAGE_EMISOR + 'onerror-rf.png'} alt="Logo Renta Fija 1" /></div>
                <div className="bold">{this.props.item["Emisor"]}</div>
                <div className="bold">{this.props.item["TipoValorNombre"]}</div>
                <div className="blueStyle">
                    <div className='rflabelsize'>Valor unitario:</div>
                    <NumberFormat value={this.props.item["Precio"]} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} thousandSeparator={','} decimalSeparator={'.'} prefix={"$ "}/>
                </div>
                <div className="blueStyle">
                    <div className='rflabelsize'>Interés:</div>
                    <NumberFormat value={this.props.item["Rendimiento"]} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} thousandSeparator={','} decimalSeparator={'.'} suffix={" %"}/>
                </div>
                <div className="blueStyle">                
                    <div className='rflabelsize'>Plazo/Vigencia:</div>{this.props.item["Maduracion"] + "/" + this.props.item["Plazo"]}
                    {/*<NumberFormat value={this.props.item["PlazoDias"]} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false} thousandSeparator={','} decimalSeparator={'.'} suffix={" días"}/>*/}
                </div>
            </div>
        )
    }
}
export default ItemRentaFija;