import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import NumberFormat from 'react-number-format';

import {updateNoticiaEmisor,getItemsCatalogo} from "../../actions/administracion";
import "../../config";

const EditarNoticiaEmisor = (props) => {
    const dispatch = useDispatch();

    const { user: currentUser } = useSelector((state) => state.auth);
    const { listaCatalogos} = useSelector((state) => state.administracion);

    if(listaCatalogos===undefined || listaCatalogos===null){
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        });
      }

    let [noticiaEmisorId, setNoticiaEmisorId] = useState(props.noticiaSeleccionado!==null?props.noticiaSeleccionado["NoticiaEmisorId"]:"-1")
    let [rangoIni, setRangoIni] = useState(props.noticiaSeleccionado!==null?props.noticiaSeleccionado["RangoIni"]:0)    
    let [rangoFin, setRangoFin] = useState(props.noticiaSeleccionado!==null?props.noticiaSeleccionado["RangoFin"]:0)    
    let [emisorId, setEmisorId] = useState({"value":props.noticiaSeleccionado!==null?props.noticiaSeleccionado["EmisorId"]:"-1","label":props.noticiaSeleccionado!==null?props.noticiaSeleccionado["EmisorNombre"]:""});
    
    let [errors] = useState({"EmisorId":""},{"RangoIni":""},{"RangoFin":""},{"Estado":""});
    
    let cambioEstado={value:'A', label:"Activo"}
    
    if(props.noticiaSeleccionado!==null && props.noticiaSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.noticiaSeleccionado!==null && props.noticiaSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }

    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 
    function actualizarU(){        
        let valid=true                
        if(emisorId["value"] == "-1"){
            errors["EmisorId"] = "Seleccione un emisor"
            valid = false
        }
        if(rangoFin == "-1" || rangoFin == ""){
            errors["RangoFin"] = "Ingrese el rango final"
            valid = false
        }
        if(rangoIni == "-1" || rangoIni == ""){
            errors["RangoIni"] = "Ingrese el rango inicial"
            valid = false
        }
        if(parseFloat(rangoIni)>parseFloat(rangoFin)){
            errors["RangoFin"] = "El rango final debe ser mayor que el rango inicial"
            valid = false
        }
        if(!valid){
            return
        }
        dispatch(updateNoticiaEmisor(
            props.noticiaSeleccionado!==null?props.noticiaSeleccionado["NoticiaEmisorId"]:"-1",
            emisorId["value"],
            props.noticiaId,
            rangoIni,
            rangoFin,
            estadoSelect["value"]
            )).then(() => {     
    
            }).catch(() => {}
        );
        props.closeEdit()       
    }
return (
<div className="overlay3 fade-in">
    <div className="borderContent ventanaPerfil nuevo-noticia">
        <div className="innerShadow3">
            <div id="formulario-estilo">
                <h2>
                     Emisor
                </h2>  
                <div className='vertical-scroll customScroll'>      
                    <div className="columna-1-formulario-ventana-noticia">                         
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-calendar-day"></i> <span>Emisor:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={emisorId} onChange={(e) => {
                                    setEmisorId(e)
                                    errors["EmisorId"] = ""
                                }} placeholder="Seleccione" options={listaCatalogos!==null && listaCatalogos["EMISOR"]?listaCatalogos["EMISOR"]:[]}
                                /> 
                                {errors["EmisorId"]!==""?<div className='camporequerido'>{errors["EmisorId"]}</div>:""}
                            </div>                       
                        </div>    
                        <div className="row-form">  
                            <div className="col-30">
                            <label>
                                <i className="fas fa-clock"></i> <span>Rango inicial:</span>
                            </label>                 
                            </div>
                            <div className="col-70">
                            <NumberFormat className="input-form" value={rangoIni} thousandSeparator={false} maxLength={3} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                const {formattedValue, value} = values;
                                setRangoIni(value);
                                errors["RangoIni"]=""                 
                            }}/>
                            {errors["RangoIni"]!==""?<div className='camporequerido'>{errors["RangoIni"]}</div>:""}
                            </div>
                        </div> 
                        <div className="row-form">  
                            <div className="col-30">
                            <label>
                                <i className="fas fa-clock"></i> <span>Rango Final:</span>
                            </label>                 
                            </div>
                            <div className="col-70">
                            <NumberFormat className="input-form" value={rangoFin} thousandSeparator={false} maxLength={3} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                const {formattedValue, value} = values;
                                setRangoFin(value);
                                errors["RangoFin"]=""                 
                            }}/>
                            {errors["RangoFin"]!==""?<div className='camporequerido'>{errors["RangoFin"]}</div>:""}
                            </div>
                        </div>               
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-calendar-day"></i> <span>Estado:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)                                                                                                                                                                                            
                                    }} placeholder="Seleccione" options={optionsEstado!==null?optionsEstado:[]}
                                /> 
                            </div>                       
                        </div> 

                        
                    </div>
                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizarU} >
                            GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={props.closeEdit}>
                            SALIR
                        </div>
                    </div>
                </div>
            </div>      
        </div>
    </div>
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarNoticiaEmisor);
