import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import '../../config'
import axios from 'axios';

let reloadDocuments = null

function UploadFile(props) {
    reloadDocuments = props.reloadDocuments
    const onDrop = useCallback((acceptedFiles) => {
        
        acceptedFiles.forEach((file) => {
                const reader = new FileReader()

                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    axios.post(global.config.URL_SERVICE + 'UploadFile?fileName=' + file.path,reader.result,
                        {
                            headers: {'Content-Type': 'multipart/form-data'},
                            onUploadProgress: function(progressEvent) {
                                var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                                
                            }                        
                        }
                        ).then(function(result){                            
                            reloadDocuments(result.data.replace("<string xmlns=\"http://schemas.microsoft.com/2003/10/Serialization/\">","").replace("</string>",""))
                        })
                        .catch(function(){
                        
                    });
            }
            reader.readAsArrayBuffer(file)
        })
    }, [])
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop})
    
    const files = acceptedFiles.map(file => (
        <div></div>      
    ))         

    const filesSave = props.uploadId!=undefined && props.uploadId!==null && props.uploadId.toString()!=="-1" && props.uploadId!=="0"?  
        <li>
            <div className='containerDelete'><a href={global.config.URL_UPLOAD + props.fileNombreUrl} target='_blank'>{props.fileNombre}</a>
                <i className="far fa-times-circle cursor-pointer" onClick={() => {
                    props.reloadDocuments("{\"UploadId\":\"-1\",\"fileNombre\":\"\",\"fileNombreUrl\":\"\"}")  
                }}></i>
            </div>
        </li>
    :""
    return (
      <section className="container-upload">
        <div {...getRootProps({className: 'dropzone'})}>            
            {props.disableUpload!==false?<input {...getInputProps()} className="selectFile"/>:""}
            <div>
                {/*<img src={img1} alt="Cédula" title="texto"/>*/}
                {props.disableUpload!==false?<div className="boxStyleSelect">Arrastra el archivo para subirlo o <a href='/#' onClick={e=>e.preventDefault()}>pulsa aquí</a></div>:""}
            </div>                    
        </div>        
        <aside className='archivosctl'>
            <ul>{files}{filesSave}</ul>          
        </aside>
      </section>
    );
  }
  
  export default UploadFile;