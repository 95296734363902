import {NotificationManager} from "react-notifications";
import {
    GET_CLIENT_PARAMETERS,
    SET_MESSAGE,
    PARAMETERS_FAIL,
    UPDATE_EVENT_SERVICE,
    UPDATE_EVENT_SERVICE_FAIL,
    GET_EVENT_LIST_SERVICE,
    GET_EVENT_LIST_SERVICE_FAIL,   
    UPDATE_EVENT_ALL_SERVICE,
    UPDATE_EVENT_ALL_SERVICE_FAIL,
    UPDATE_EVENT_ALL_SERVICE_INIT,
    DELETE_EVENT_ALL_SERVICE,
    DELETE_EVENT_ALL_SERVICE_FAIL,
    //eventos por usuario
    GET_USER_EVENT,
    GET_USER_EVENT_FAIL,
    UPDATE_USER_EVENT_GESTION,
    UPDATE_USER_EVENT_GESTION_FAIL,
    DELETE_USER_EVENT,
    DELETE_USER_EVENT_FAIL,
    GET_USER_ALL_EVENT_SIN_ASIG,
    GET_USER_ALL_EVENT_SIN_ASIG_FAIL,
    SEND_INVITATION_BY_USER,
    SEND_INVITATION_BY_USER_FAIL,
    SEND_INVITATION_BY_USER_INIT,
    UPDATE_STATUS_BY_KEY_EVENT,
    DELETE_HELP_EVENT,
    DELETE_HELP_EVENT_FAIL,
    UPDATE_HELP_EVENT,
    UPDATE_HELP_EVENT_FAIL,
    GET_HELP_EVENT,
    GET_HELP_EVENT_FAIL,
    GET_EVENT_LIST_SERVICE_INI,
    PROCESS_FILE_PLAYERS,
    PROCESS_FILE_PLAYERS_FAIL,
    PROCESS_FILE_PLAYERS_INI,
    GET_USER_EVENT_INI,
    HELP_EVENT_INI,
    SELECT_USER_EVENT,
    SELECT_USER_EVENT_FAIL,
    DELETE_USERS_FOR_EVENT,
    DELETE_USERS_FOR_EVENT_FAIL,
    GET_USER_EVENT_COMBO,
    GET_USER_EVENT_FAIL_COMBO,
    NOTICE_ESP_EVENT_INI,
    GET_NOTICE_ESP_EVENT,
    GET_NOTICE_ESP_EVENT_FAIL,
    UPDATE_NOTICIA_ESP_EVENT,
    UPDATE_NOTICIA_ESP_EVENT_FAIL,
    DELETE_NOTICIA_ESP_EVENT,
    DELETE_NOTICIA_ESP_EVENT_FAIL
  } from "./types";
  
  import configEventService from "../services/evento.service";
  import adminService from "../services/administracion.service";
/*Inicia gestion evento usuario*/
//
export const deleteUsersEvent =(eventoId)=> (dispatch) => {
  dispatch({
    type: UPDATE_EVENT_ALL_SERVICE_INIT,
  });
    return configEventService.deleteUsersEvent(eventoId).then(
        (response) => {
          if(response===0){
            dispatch(getUsuarioEvento(eventoId))
            dispatch(getUsuariosSinA(eventoId))
            dispatch({
                  type: DELETE_USERS_FOR_EVENT,
                });        
                NotificationManager.success(
      "Jugadores Eliminados",
      "Sistema",
      3000,
      () => {},
      4
  );    
          }else{
            
            dispatch({
                  type: DELETE_USERS_FOR_EVENT_FAIL,
                });
                NotificationManager.error(
      "Error al eliminar jugadores",
      "Sistema",
      3000,
      () => {},
      4
  );
          }            
                return Promise.resolve();
              },
              (error) => {
                const message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
          
                dispatch({
                  type: DELETE_USERS_FOR_EVENT_FAIL,
                });
          
                dispatch({
                  type: SET_MESSAGE,
                  payload: message,
                });
          
                return Promise.reject();
      }
    );
  };
export const selectUserEvent = (eventoId,usuarioId,usuarioIdRegistro) => (dispatch) => {

  return configEventService.selectUserEvent(eventoId,usuarioId,usuarioIdRegistro).then(
      (response) => {
        if(response===0){
          dispatch(getUsuarioEvento(eventoId))
          dispatch(getUsuariosSinA(eventoId))
          dispatch({
                type: SELECT_USER_EVENT,
              });        
              NotificationManager.success(
                "Usuarios guardados",
                "Sistema",
                3000,
                () => {},
                4
            );    
        }else{
          
          dispatch({
                type: SELECT_USER_EVENT_FAIL,
              });
              NotificationManager.error(
                  "Error al guardar",
                  "Sistema",
                  3000,
                  () => {},
                  4
              );
        }            
              return Promise.resolve();
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
        
              dispatch({
                type: SELECT_USER_EVENT_FAIL,
              });
        
              dispatch({
                type: SET_MESSAGE,
                payload: message,
              });
        
              return Promise.reject();
    }
  );
};
export const processArchivoUsuarios = (eventoId,uploadId) => (dispatch) => {
  dispatch({
    type: PROCESS_FILE_PLAYERS_INI,
  });  
  return configEventService.processArchivoUsuarios(eventoId,uploadId).then(
      (data) => {
        if(data!=null){
          dispatch({
            type: PROCESS_FILE_PLAYERS,
            payload: { data: data },
          });  
          dispatch(getUsuarioEvento(eventoId))

          NotificationManager.success(
            "Los usuarios fueron cargados ",
              "Carga de usuarios",            
              3000,
              () => {},
              4
          );
          
          return Promise.resolve();
        }else{
          dispatch({
            type: PROCESS_FILE_PLAYERS_FAIL,
          });

          NotificationManager.error(
            "Error al cargar los usuarios",
              "Carga de usuarios",            
              3000,
              () => {},
              4
          );
        }

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: PROCESS_FILE_PLAYERS_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const enviarInvitacionPorEvento = (eventoId,usuarioId) => (dispatch) => {
  dispatch({
    type: SEND_INVITATION_BY_USER_INIT,
  });  
  return configEventService.enviarInvitacionPorEvento(eventoId,usuarioId).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: SEND_INVITATION_BY_USER,
          payload: { data: data },
        });  

        NotificationManager.success(
          "Las invitaciones fueron enviadas ",
            "Envío de invitación",            
            3000,
            () => {},
            4
        );
        
        return Promise.resolve();
      }else{
        dispatch({
          type: SEND_INVITATION_BY_USER_FAIL,
        });

        NotificationManager.error(
          "Error al enviar las invitaciones",
            "Envío de invitación",            
            3000,
            () => {},
            4
        );
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SEND_INVITATION_BY_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const enviarInvitacionPorUsuario = (eventoId,usuarioId) => (dispatch) => {
  dispatch({
    type: SEND_INVITATION_BY_USER_INIT,
  });  
  return configEventService.enviarInvitacionPorUsuario(eventoId,usuarioId).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: SEND_INVITATION_BY_USER,
          payload: { data: data },
        });  

        NotificationManager.success(
          "Las invitaciones fueron enviadas ",
            "Envío de invitación",            
            3000,
            () => {},
            4
        );
        
        return Promise.resolve();
      }else{
        dispatch({
          type: SEND_INVITATION_BY_USER_FAIL,
        });

        NotificationManager.error(
          "Error al enviar las invitaciones",
            "Envío de invitación",            
            3000,
            () => {},
            4
        );
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SEND_INVITATION_BY_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getUsuariosSinA = (eventoId) => (dispatch) => {  
  return configEventService.getUsuarioEventoSinAsig(eventoId).then(
    (data) => {
      if(data!=null){
        dispatch({
          type: GET_USER_ALL_EVENT_SIN_ASIG,
          payload: { data: data },
        });  
        return Promise.resolve();
      }else{
        dispatch({
          type: GET_USER_ALL_EVENT_SIN_ASIG_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_USER_EVENT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteEventoU =(eventoUsuarioId,eventoId)=> (dispatch) => {
  dispatch({
    type: UPDATE_EVENT_ALL_SERVICE_INIT,
  });
    return configEventService.deleteEventoUsuario(eventoUsuarioId,eventoId).then(
        (response) => {
          if(response===0){
            dispatch(getUsuarioEvento(eventoId))
            dispatch({
                  type: DELETE_USER_EVENT,
                });        
                NotificationManager.success(
      "Usuario Eliminado",
      "Sistema",
      3000,
      () => {},
      4
  );    
          }else{
            
            dispatch({
                  type: DELETE_USER_EVENT_FAIL,
                });
                NotificationManager.error(
      "Error al eliminar evento",
      "Sistema",
      3000,
      () => {},
      4
  );
          }            
                return Promise.resolve();
              },
              (error) => {
                const message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
          
                dispatch({
                  type: DELETE_USER_EVENT_FAIL,
                });
          
                dispatch({
                  type: SET_MESSAGE,
                  payload: message,
                });
          
                return Promise.reject();
      }
    );
  };
export const updateGestionEventoUsuario = (eventoUsuarioId,usuarioId,eventoId,usuarioIdRegistro,estado,monto,saldo) => (dispatch) => {
  dispatch({
    type: UPDATE_EVENT_ALL_SERVICE_INIT,
  });
    return configEventService.updateGestionEventoUsuario(eventoUsuarioId,usuarioId,eventoId,usuarioIdRegistro,estado,monto,saldo).then(
        (response) => {
          if(response===0){
            dispatch(getUsuarioEvento(eventoId))
            dispatch(getUsuariosSinA(eventoId))
            dispatch({
                  type: UPDATE_USER_EVENT_GESTION,
                });        
                NotificationManager.success(
                  "Evento guardado",
                  "Sistema",
                  3000,
                  () => {},
                  4
              );    
          }else{
            
            dispatch({
                  type: UPDATE_USER_EVENT_GESTION_FAIL,
                });
                NotificationManager.error(
                    "Error al guardar",
                    "Sistema",
                    3000,
                    () => {},
                    4
                );
          }            
                return Promise.resolve();
              },
              (error) => {
                const message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
          
                dispatch({
                  type: UPDATE_USER_EVENT_GESTION_FAIL,
                });
          
                dispatch({
                  type: SET_MESSAGE,
                  payload: message,
                });
          
                return Promise.reject();
      }
    );
  };
export const getUsuarioEvento = (eventoId) => (dispatch) => {
  dispatch({
    type: GET_USER_EVENT_INI,
  });  
      return configEventService.getUsuarioEvento(eventoId).then(
        (data) => {
          if(data!=null){
            dispatch({
              type: GET_USER_EVENT,
              payload: { data: data },
            });  
            return Promise.resolve();
          }else{
            dispatch({
              type: GET_USER_EVENT_FAIL,
            });
          }
    
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
    
          dispatch({
            type: GET_USER_EVENT_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
    
          return Promise.reject();
        }
      );
    };
/*finaliza gestion evento usuario*/
  export const deleteEvento =( eventoId)=> (dispatch) => {
    dispatch({
      type: UPDATE_EVENT_ALL_SERVICE_INIT,
    });
      return configEventService.deleteEvento(eventoId).then(
          (response) => {
            if(response===0){
              dispatch(getEventos("0","","","ASC",""));
              dispatch({
                    type: DELETE_EVENT_ALL_SERVICE,
                  });        
                  NotificationManager.success(
        "Evento Eliminado",
        "Sistema",
        3000,
        () => {},
        4
    );    
            }else{
              
              dispatch({
                    type: DELETE_EVENT_ALL_SERVICE_FAIL,
                  });
                  NotificationManager.error(
        "Error al eliminar evento",
        "Sistema",
        3000,
        () => {},
        4
    );
            }            
                  return Promise.resolve();
                },
                (error) => {
                  const message =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
            
                  dispatch({
                    type: UPDATE_EVENT_ALL_SERVICE_FAIL,
                  });
            
                  dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                  });
            
                  return Promise.reject();
        }
      );
    };

  export const updateEvento = (eventoId,modalidadNombreSelect,tipoEventoNombreSelect,nombreInstitucion,nombreEvento,logo,montoJugador,tipoDuracionNombreSelect,duracion,tiempoNoticia,tiempoJugada,estado,tiempoSiguienteJugada,tiempoVarPrecios,tiempoRecSaldos,usuarioId,nivelNombreSelect,estadoActId,ultimaJugada,notificacionEspecial,startDate,juegaRentaFija,forzarCompraRF) => (dispatch) => {
    dispatch({
      type: UPDATE_EVENT_ALL_SERVICE_INIT,
    });
      return configEventService.updateEvento(eventoId,modalidadNombreSelect,tipoEventoNombreSelect,nombreInstitucion,nombreEvento,logo,montoJugador,tipoDuracionNombreSelect,duracion,tiempoNoticia,tiempoJugada,estado,tiempoSiguienteJugada,tiempoVarPrecios,tiempoRecSaldos,usuarioId,nivelNombreSelect,estadoActId,ultimaJugada,notificacionEspecial,startDate,juegaRentaFija,forzarCompraRF).then(
          (response) => {
            if(response===0){
              dispatch(getEventos("0","","","ASC",""));
              dispatch({
                    type: UPDATE_EVENT_ALL_SERVICE,
                  });        
                  NotificationManager.success(
        "Evento guardado",
        "Sistema",
        3000,
        () => {},
        4
    );    
            }else{
              
              dispatch({
                    type: UPDATE_EVENT_ALL_SERVICE_FAIL,
                  });
                  NotificationManager.error(
        "Error al guardar el evento",
        "Sistema",
        3000,
        () => {},
        4
    );
            }            
                  return Promise.resolve();
                },
                (error) => {
                  const message =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
            
                  dispatch({
                    type: UPDATE_EVENT_ALL_SERVICE_FAIL,
                  });
            
                  dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                  });
            
                  return Promise.reject();
        }
      );
    };

  export const getEventos = (page,filterField, filer,typeOrd,sort) => (dispatch) => {
    dispatch({
      type: UPDATE_EVENT_ALL_SERVICE_INIT,
    });
      return configEventService.getEventos(page,filterField, filer,typeOrd,sort).then(
        (data) => {
          if(data!=null){
            dispatch({
              type: GET_EVENT_LIST_SERVICE,
              payload: { data: data },
            });  
            return Promise.resolve();
          }else{
            dispatch({
              type: GET_EVENT_LIST_SERVICE_FAIL,
            });
          }
    
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
    
          dispatch({
            type: GET_EVENT_LIST_SERVICE_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
    
          return Promise.reject();
        }
      );
    };
  

  export const updateGestionEvento = (eventoId,tiempoNoticia,tiempoJugada,tiempoVarPrecios,tiempoRecSalados) => (dispatch) => {
    return configEventService.updateGestionEvento(eventoId,tiempoNoticia,tiempoJugada,tiempoVarPrecios,tiempoRecSalados).then(
      (response) => {
        dispatch({
          type: UPDATE_EVENT_SERVICE,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_EVENT_SERVICE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  export const getParameterForClient = () => (dispatch) => {
    return configEventService.getParameterForClient().then(
      (response) => {
        dispatch({
          type: GET_CLIENT_PARAMETERS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: PARAMETERS_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const setStatusByKey = (key,value) => (dispatch) => {
    dispatch({ 
      type: UPDATE_STATUS_BY_KEY_EVENT,
      payload: { key:key,value:value },
    });  
    return Promise.resolve();
  }
  export const getEventoNoticiaEspecial = (eventoId,page,filterField,filer,typeOrd,sort) => (dispatch) => {
    dispatch({
      type: NOTICE_ESP_EVENT_INI,
      });
    return configEventService.getEventoNoticiaEspecial(eventoId,page,filterField,filer,typeOrd,sort).then(
      (data) => {
        if(data!=null){
          dispatch({
            type: GET_NOTICE_ESP_EVENT,
            payload: { data: data },
          });  
          return Promise.resolve();
        }else{
          dispatch({
            type: GET_NOTICE_ESP_EVENT_FAIL,
          });
        }
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
  
        dispatch({
          type: GET_NOTICE_ESP_EVENT_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getEventoAyuda = (eventoId,page,filterField,filer,typeOrd,sort) => (dispatch) => {
    dispatch({
      type: HELP_EVENT_INI,
      });
    return configEventService.getEventoAyuda(eventoId,page,filterField,filer,typeOrd,sort).then(
      (data) => {
        if(data!=null){
          dispatch({
            type: GET_HELP_EVENT,
            payload: { data: data },
          });  
          return Promise.resolve();
        }else{
          dispatch({
            type: GET_HELP_EVENT_FAIL,
          });
        }
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
  
        dispatch({
          type: GET_HELP_EVENT_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  
  export const updateGestionAyuda = (eventoAyudaId,eventoId,jugada,tipo,estado,page,filterField,filer,typeOrd,sort) => (dispatch) => {
    dispatch({
      type: HELP_EVENT_INI,
      });
      return configEventService.updateGestionEventoAyuda(eventoAyudaId,eventoId,jugada,tipo,estado,page,filterField,filer,typeOrd,sort).then(
      (response) => {
        if(response===0){
          dispatch(getEventoAyuda(eventoId,page,filterField,filer,typeOrd,sort))
          dispatch({
            type: UPDATE_HELP_EVENT,
          });        
          NotificationManager.success(
            "Ayuda guardado",
            "Sistema",
            3000,
            () => {},
            4
          );    
        }else{        
          dispatch({
            type: UPDATE_HELP_EVENT_FAIL,
          });
          NotificationManager.error(
            "Error al guardar",
            "Sistema",
            3000,
            () => {},
            4
          );
        }            
              return Promise.resolve();
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
        
              dispatch({
                type: UPDATE_HELP_EVENT_FAIL,
              });
        
              dispatch({
                type: SET_MESSAGE,
                payload: message,
              });
        
              return Promise.reject();
        }
      );
    };
  
  export const deleteEventoAyuda =(eventoAyudaId,eventoId,page,filterField,filer,typeOrd,sort)=> (dispatch) => {
    dispatch({
      type: HELP_EVENT_INI,
      });
      return configEventService.deleteGestionEventoAyuda(eventoAyudaId).then(
      (response) => {
        if(response===0){
          dispatch(getEventoAyuda(eventoId,page,filterField,filer,typeOrd,sort))
          dispatch({
            type: DELETE_HELP_EVENT,
          });        
          NotificationManager.success(
            "Ayuda Eliminado",
            "Sistema",
            3000,
            () => {},
            4
          );    
        }else{            
          dispatch({
            type: DELETE_HELP_EVENT_FAIL,
          });
          NotificationManager.error(
            "Error al eliminar ayuda",
            "Sistema",
            3000,
            () => {},
            4
          );
        }            
        return Promise.resolve();
      },
      (error) => {
        const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();          
        dispatch({
          type: DELETE_HELP_EVENT_FAIL,
        });          
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });            
        return Promise.reject();
      }
    );
  };

export const getUsuarioEventoCombo = (eventoId) => (dispatch) => {    
      return configEventService.getUsuarioEventoCombo(eventoId).then(
        (data) => {
          if(data!=null){
            dispatch({
              type: GET_USER_EVENT_COMBO,
              payload: { data: data },
            });  
            return Promise.resolve();
          }else{
            dispatch({
              type: GET_USER_EVENT_FAIL_COMBO,
            });
          }
    
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
    
          dispatch({
            type: GET_USER_EVENT_FAIL_COMBO,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
    
          return Promise.reject();
        }
      );
    };

  export const updateGestionNoticiaEspecial = (eventoNoticiaEspId,eventoId,tipo,jugada,estado,page,filterField,filer,typeOrd,sort) => (dispatch) => {
      dispatch({
        type: NOTICE_ESP_EVENT_INI,
        });
        return configEventService.updateGestionNoticiaEspecial(eventoNoticiaEspId,eventoId,tipo,jugada,estado,page,filterField,filer,typeOrd,sort).then(
        (response) => {
          if(response===0){
            dispatch(getEventoNoticiaEspecial(eventoId,page,filterField,filer,typeOrd,sort))
            dispatch({
              type: UPDATE_NOTICIA_ESP_EVENT,
            });        
            NotificationManager.success(
              "Noticia especial guardada",
              "Sistema",
              3000,
              () => {},
              4
            );    
          }else{        
            dispatch({
              type: UPDATE_NOTICIA_ESP_EVENT_FAIL,
            });
            NotificationManager.error(
              "Error al guardar la información",
              "Sistema",
              3000,
              () => {},
              4
            );
          }            
                return Promise.resolve();
              },
              (error) => {
                const message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
          
                dispatch({
                  type: UPDATE_NOTICIA_ESP_EVENT_FAIL,
                });
          
                dispatch({
                  type: SET_MESSAGE,
                  payload: message,
                });
          
                return Promise.reject();
          }
        );
      };
    
    export const deleteNoticiaEspecial =(eventoNoticiaEspId,eventoId,page,filterField,filer,typeOrd,sort)=> (dispatch) => {
      dispatch({
        type: NOTICE_ESP_EVENT_INI,
        });
        return configEventService.deleteNoticiaEspecial(eventoNoticiaEspId).then(
        (response) => {
          if(response===0){
            dispatch(getEventoNoticiaEspecial(eventoId,page,filterField,filer,typeOrd,sort))
            dispatch({
              type: DELETE_NOTICIA_ESP_EVENT,
            });        
            NotificationManager.success(
              "Noticia especial Eliminada",
              "Sistema",
              3000,
              () => {},
              4
            );    
          }else{            
            dispatch({
              type: DELETE_NOTICIA_ESP_EVENT_FAIL,
            });
            NotificationManager.error(
              "Error al eliminar la noticia especial",
              "Sistema",
              3000,
              () => {},
              4
            );
          }            
          return Promise.resolve();
        },
        (error) => {
          const message =
          (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();          
          dispatch({
            type: DELETE_NOTICIA_ESP_EVENT_FAIL,
          });          
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });            
          return Promise.reject();
        }
      );
    };
    
    