import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import {getTitulosBuscador} from "../../actions/titulosPorEvento";
import {updateTitulo} from "../../actions/titulosPorEvento";
import "../../config";
const EditarTitulosPorEvento = (props) => {
    //variables    
    let [tituloEvento, setTituloEvento] = useState(null)    
    let [loadService, setLoadService] = useState(false)
    const {listaTitulosBuscador} = useSelector((state) => state.titulosPorEvento);
    //variables formulario
    let [eventoTituloId,setEventoTituloId] =useState(props.tituloPorEventoSeleccionado!==null?props.tituloPorEventoSeleccionado["EventoTituloId"]:"")
    let [maduracion,setMaduracion] =useState(props.tituloPorEventoSeleccionado!==null?props.tituloPorEventoSeleccionado["Maduracion"].toString():"1")
    let [plazo,setPlazo] =useState(props.tituloPorEventoSeleccionado!==null?props.tituloPorEventoSeleccionado["Plazo"]:"0")
    let [plazoDias,setPlazoDias] =useState(props.tituloPorEventoSeleccionado!==null?props.tituloPorEventoSeleccionado["PlazoDias"]:"0")
    let [precio,setPrecio] = useState(props.tituloPorEventoSeleccionado!==null?props.tituloPorEventoSeleccionado["Precio"]:"0")
    let [rendimiento,setRendimiento] = useState(props.tituloPorEventoSeleccionado!==null?props.tituloPorEventoSeleccionado["Rendimiento"]:"0")
    let [valorNominal, setValorNominal] = useState(props.tituloPorEventoSeleccionado!==null?props.tituloPorEventoSeleccionado["ValorNominal"]:"0")
    let [saldoTitulo, setSaldoTitulo] = useState(props.tituloPorEventoSeleccionado!==null?props.tituloPorEventoSeleccionado["SaldoTitulo"]:"")
    let [jugadaAct, setJugadaAct] = useState(1)
    let [valorSelect,setValorSelect] = useState(null)
    /*if(props.tituloPorEventoSeleccionado===null){
        setSaldoTitulo(valorNominal)
    }*/
    //variable selelct titulos

    let [tituloSelect,setTituloSelect] = useState(null)
    //variable de errores
     let [errors] = useState({"precioErr":""},{"tituloSelectErr":""},{"maduracionErr":""},{"plazoErr":""},{"plazoDiasErr":""},{"rendimientoErr":""},{"valorNominalErr":""},{"plazoDiasErr":""},{"saldoTituloErr":""});
    
    const dispatch = useDispatch();
    if(loadService ===false){
        setLoadService(true)
        setTituloEvento(props.tituloPorEventoSeleccionado);            
        if(listaTitulosBuscador===null){
            dispatch(getTitulosBuscador())
            .then(() => {
            })
            .catch(() => {
            });
        }    
    }   
    //cambiar tipo renta 
    let [cambiarRenta,setCambiarRenta] =useState(false)
    // select estado
    let cambioEstado=useState(null)
    if(props.tituloPorEventoSeleccionado!==null && props.tituloPorEventoSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.tituloPorEventoSeleccionado!==null && props.tituloPorEventoSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.tituloPorEventoSeleccionado!==null && props.tituloPorEventoSeleccionado["Estado"] == "D"){
        cambioEstado ={value:'D', label:"Desactivado"}
    }
    if(props.tituloPorEventoSeleccionado===null){
        cambioEstado ={value:'A', label:"Activo"}
    }

    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 
    //select de titulo 
    //let [tituloNombreSelect, setTituloNombreSelect] = useState({"value":props.tituloPorEventoSeleccionado!==null?props.tituloPorEventoSeleccionado["TituloId"]:"-1","label":props.eventoSeleccionado!==null?props.eventoSeleccionado["TituloId"]:""});
    //obtener lista titulosBuscador
    
    

    if(props.tituloPorEventoSeleccionado!==null){
        if(listaTitulosBuscador!==null && tituloSelect===null){
            setTituloSelect(listaTitulosBuscador.filter(value => value["value"] ===props.tituloPorEventoSeleccionado["TituloId"])[0])
        }
    }

    function closeWindowEditarTitulosPorEvento(){
        props.closeEditTitulosPorEventoClick()
    }
    //funcion para realizar el update 
    function actualizarTitulo(){
        let valid=true
        if(precio === ""){       
            errors["precioErr"] = "Ingrese precio"
            valid = false
        }
        if(tituloSelect===null){
            errors["tituloSelectErr"] = "Ingrese un título"
            valid = false
        }
        if(maduracion === ""){
            errors["maduracionErr"] = "Ingrese maduración"
            valid = false
        }
        if(valorNominal ===""){
            
            errors["valorNominalErr"] = "Ingrese valor"
            valid = false
        }
        if(rendimiento ==="" && cambiarRenta===false){
            errors["rendimientoErr"] = "Ingrse rendimiento"
            valid = false
        }
        if(plazo===""  && cambiarRenta===false){
            errors["plazoErr"] = "Ingrese plazo"
            valid = false
        }
        if(plazoDias ===""  && cambiarRenta===false){
            errors["plazoDiasErr"] = "Ingrese plazo en días"
            valid = false
        }
        if(saldoTitulo ===""){
            errors["saldoTituloErr"] = "Ingrese saldo"
            valid = false
        }

        if(!valid){
            return
        }
        if(cambiarRenta===true){
            setRendimiento(0)
            setPlazo(0)
            setPlazoDias(0)
        }
        //jugada actual        
        dispatch(updateTitulo(props.tituloPorEventoSeleccionado!==null?props.tituloPorEventoSeleccionado['EventoTituloId']:"-1",props.eventoSeleccionadoId,tituloSelect["value"],valorNominal,precio,rendimiento,plazo,plazoDias,estadoSelect["value"],saldoTitulo,maduracion,jugadaAct))
            .then(() => {     
        
            })
            .catch(() => {});
        closeWindowEditarTitulosPorEvento()
    }
    
    return (
<div className="overlay3 fade-in">
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
            <div id="formulario-estilo">
                <h2>
                    Títulos por evento                       
                </h2>  
                <div className='vertical-noscroll customScroll'>               
                    <div className="columna-1-formulario-ventana-perfil">                         
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-building"></i> <span>Institución:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={tituloSelect} onChange={(e) => {
                                        setTituloSelect(e) 
                                      
                                        if(e["tipoRenta"]==="RV")  {
                                            setCambiarRenta(true)
                                          
                                        }else{
                                            setCambiarRenta(false)
                                        }  
                                                                       
                                        errors["tituloSelectErr"]=""                                        
                                        }} placeholder="Seleccione" options={listaTitulosBuscador!==null?listaTitulosBuscador:[]}
                                /> 
                                {errors["tituloSelectErr"]!==""?<div className='camporequerido'> {errors["tituloSelectErr"]}</div>:""}                                     
                            </div>
                        </div> 
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-dollar-sign"></i> <span>Precio:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <NumberFormat  
                                    className="input-form" value={precio} thousandSeparator={false }  allowNegative={false} decimalScale={2} fixedDecimalScale={true} decimalSeparator={'.'} prefix={"$"}   onValueChange={(values) => {
                                    const {formattedValue, value} = values;
                                    setPrecio(value);      
                                    errors["precioErr"]=""                              
                                }}
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    return floatValue >= 0 &&  floatValue <= 999.99;
                                  }}/> 
                                
                                {errors["precioErr"]!==""?<div className='camporequerido'>{errors["precioErr"]}</div>:""}                                           
                            </div>
                        </div>  
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-dollar-sign"></i> <span>Valor nominal:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <NumberFormat                                  
                                    className="input-form" value={valorNominal} thousandSeparator={false}  allowNegative={false} decimalScale={2} fixedDecimalScale={true} decimalSeparator={'.'} prefix={"$"} onValueChange={(values) => {
                                    const {formattedValue, value} = values;
                                    setValorNominal(value)    
                                    if(props.tituloPorEventoSeleccionado===null){
                                        setSaldoTitulo(value)
                                    }                                     
                                    errors["valorNominalErr"]=""                             
                                }}
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    return floatValue >= 0 &&  floatValue <= 999.99;
                                  }}/> 
                                {errors["valorNominalErr"]!==""?<div className='camporequerido'>{errors["valorNominalErr"]}</div>:""}
                            </div>
                        </div>  
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <Select  className="input-form" id="estadoSelectCombo"  value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)
                                }} placeholder="Seleccione" options={optionsEstado}/>
                            </div>
                        </div>                
                        {props.tituloPorEventoSeleccionado===null || props.tituloPorEventoSeleccionado["TipoRentaCod"]===null || props.tituloPorEventoSeleccionado["TipoRentaCod"] === "RF"?

                        <div>
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Rendimiento:</span>                
                                    </label>
                                </div>
                                <div className="col-70">
                                    <NumberFormat  
                                        className="input-form" value={rendimiento}  allowNegative={false}  thousandSeparator={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                        const {formattedValue, value} = values;                                       
                                        setRendimiento(value);                                                                          
                                        errors["rendimientoErr"] =""                                  
                                    }}
                                    isAllowed={(values) => {
                                        const {floatValue} = values;
                                        return floatValue >= 0 &&  floatValue <= 999.99;
                                      }}/> 
                                    {errors["rendimientoErr"]!==""?<div className='camporequerido'>{errors["rendimientoErr"]}</div>:""}
                                </div>
                            </div>
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Plazo:</span>                
                                    </label>
                                </div>
                                <div className="col-70">
                                    <NumberFormat  
                                        className="input-form" value={plazo} thousandSeparator={false}  allowNegative={false}  decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                        const {formattedValue, value} = values;                                       
                                        setPlazo(value);                                          
                                         errors["plazoErr"] = ""                                    
                                    }}
                                    isAllowed={(values) => {
                                        const {floatValue} = values;
                                        return floatValue >= 0 &&  floatValue <= 999.99;
                                      }}/>
                                    {errors["plazoErr"]!==""?<div className='camporequerido'>{errors["plazoErr"]}</div>:""} 
                                </div>
                            </div>
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Plazo días:</span>                
                                    </label>
                                </div>
                                <div className="col-70">
                                    <NumberFormat  
                                        className="input-form" value={plazoDias} thousandSeparator={false}  allowNegative={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                        const {formattedValue, value} = values;                                                                
                                        setPlazoDias(value); 
                                        errors["plazoDiasErr"] = ""                                  
                                    }}
                                    isAllowed={(values) => {
                                        const {floatValue} = values;
                                        return floatValue >= 0 &&  floatValue <= 999.99;
                                      }}/>
                                    {errors["plazoDiasErr"]!==""?<div className='camporequerido'>{errors["plazoDiasErr"]}</div>:""} 
                                </div>
                            </div>

                        </div>
                        :""}                 
                    </div>                    
                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizarTitulo}>
                        GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeWindowEditarTitulosPorEvento}>
                            SALIR
                        </div>
                    </div>
                </div> 
            </div>      
        </div>
    </div>
</div>


    );

}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarTitulosPorEvento);
