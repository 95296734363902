import React, { useState,useEffect,useCallback} from "react"
import { Redirect,useHistory  } from 'react-router-dom'
import { connect,useDispatch,useSelector } from "react-redux"
import DataGrid  from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/amber-dark.css'
import {formatDateTime,enableFuncionalityForRole} from "./general/Util"
import { setStatusByKey,getEventosPorUsuario,getNegociacionesPorUsuario } from "../actions/juego";

import '../config'

const SeleccionJuego = (props) => {    
    const { user: currentUser } = useSelector((state) => state.auth);  
    const { listEventos: currentEventos,forceSelectEvento:forceSelectEvento} = useSelector((state) => state.juego);
    const [activeIndex, setActiveIndex] = useState(2)
    const [eventoSeleccionadoId, setEventoSeleccionadoId] = useState(-1)


    const gridStyle = { minHeight: '300px', margin: 20 }
    let columns= [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'NombreEvento',header: 'Nombre Evento', defaultFlex: 1, xdefaultLocked: 'start',minWidth: 350, },
        { name: 'FechaInicio',header: 'Fecha Inicio', defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => formatDateTime(data["FechaInicio"]) },
        { name: 'tipoEvento',header: 'Tipo Evento', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'tipoDuracion',header: 'Tipo Duracion', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'Duracion',header: 'Duracion', defaultFlex: 1, xdefaultLocked: 'end' },
      ]

    let history = useHistory();
    const dispatch = useDispatch();

    const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
        setEventoSeleccionadoId(data["EventoId"])
        setActiveIndex(selectedMap)
    }, [])

    const renderRowContextMenu = useCallback((menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
          {
            label: 'Ir al evento',
            onClick:()=>{
                setEventoSeleccionadoId(rowProps.data["EventoId"])

                let userId = currentUser["userId"]
                if(enableFuncionalityForRole(currentUser["role"],"UPORN"))
                  userId = -1
            
                dispatch(getNegociacionesPorUsuario(rowProps.data["EventoId"],userId))
                    .then(() => {
                    })
                    .catch(() => {
                    });

                dispatch(setStatusByKey("eventoSeleccionado",rowProps.data["EventoId"]))
                history.push('/sala') 
            }
          },          
        ]
    }, [])
    
    if (!currentUser) {
        return <Redirect to="/login" />;
    }  
    if((currentEventos===null || currentEventos.length<=0) && !forceSelectEvento){
        return <Redirect to="/sala" />;
      }
    function btnCancel(){
        history.push('/sala') 
    }
    function btnActualizarEventos(){
        dispatch(getEventosPorUsuario(currentUser["userId"]))
          .then(() => {
          })
          .catch(() => {
          });
    }
    return (
        <div> 
            <div className='nojuego'>SELECCIONA UN EVENTO</div>                     
            {currentEventos!==undefined && currentEventos!==null && currentEventos.length>0?<DataGrid
                    idProperty="EventoUsuarioId"
                    style={gridStyle}
                    theme="default-light"                    
                    columns={columns}
                    enableSelection={true}                    
                    dataSource={currentEventos===null?[]:currentEventos}
                    theme="amber-dark"
                    renderRowContextMenu={renderRowContextMenu}
                />:""
            }
            <div className="btnContainer"><div className="btnGeneral" title="Actualiza lista de eventos" onClick={btnActualizarEventos}>ACTUALIZAR</div><div className="btnCancelar" onClick={btnCancel}>Cerrar</div></div>
        </div>
    );
}
export default SeleccionJuego;