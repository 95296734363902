import React, { useState, useEffect,PureComponent } from "react";
import img01 from '../image/img01.jpg';
import img02 from '../image/img02.jpg';
import leftArrow from '../image/arrowRight.svg';
import rightArrow from '../image/arrowLeft.svg';
import DataGrid  from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/amber-dark.css'
import {formatDateTime} from "./general/Util"

import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,Area,
  } from 'recharts';

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].payload["Emisor"]} - Jugada${payload[0].payload["Jugada"]} - ${payload[0].value}`}</p>          
        </div>
      );
    }
  
    return null;
  };
const ScrollFooter = (props) => {
  const [content, setContent] = useState(0);
  let [index, setIndex] = useState(0);
  let [maxIndex, setMaxIndex] = useState(1);
  let [isMaximized, setIsMaximized] = useState(false);
  let [createInterval, setCreateInterval] = useState(false); 
  let [negociacionFilter, setNegociacionFilter] = useState("");   
  let [gridInfor, setGridInfor] = useState([props.negociaciones]);     

  let [indexPrecio, setIndexPrecio] = useState(0);

  if(props.negociaciones!=gridInfor && negociacionFilter==="")
    setGridInfor(props.negociaciones)

  const gridStyle = { minHeight: '95px', margin: 20,height: "calc(100% - 41px)" }
    let columns= [
        { name: 'EstadoNego',header:'Estado', defaultFlex: 1,minWidth: 120 },
        { name: 'Jugada', header: 'Jugada', defaultFlex: 1,minWidth: 120 },
        { name: 'Nombre',header: 'Jugador', defaultFlex: 1, xdefaultLocked: 'end',minWidth: 180  },
        { name: 'Tipo',header: 'Tipo', defaultFlex: 1, xdefaultLocked: 'start',minWidth: 120 },
        { name: 'Emisor',header: 'Emisor', defaultFlex: 1, xdefaultLocked: 'start',minWidth: 170 },
        { name: 'TipoValor',header: 'Tipo Valor', defaultFlex: 1, xdefaultLocked: 'start',minWidth: 150 },                
        { name: 'Cantidad', header: 'Cantidad', defaultFlex: 1,minWidth: 120 },
        { name: 'ValorNominal', header: 'Valor Nominal', defaultFlex: 1,minWidth: 130 },     
        { name: 'Precio', header: 'Precio', defaultFlex: 1,minWidth: 120 },             
        { name: 'FechaRegistro',header: 'Fecha Registro', defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => formatDateTime(data["FechaRegistro"]),minWidth: 120 },                
        { name: 'Razon', header:'Razón', defaultFlex: 1,minWidth: 700 },
      ]

  useEffect(() => {
    
  }, []);

  function clickOp(){
    setIndex(1)
  }

  function next(){
      if(index>=maxIndex){        
        setIndex(maxIndex)
      }else{
        setIsMaximized(false)
        props.setMaximizeOrders(false)
        setIndex(index+1)
      }        
  }
  function back(){
    if(index<=0){
      setIndex(0)
    }else{
      setIndex(index-1)
    }        
}
function btnMaximizeClick(){
    let tmpBol = !isMaximized;
    setIsMaximized(tmpBol)
    props.setMaximizeOrders(tmpBol)
}

function changePrecio(){
    if((indexPrecio+1)>props.listaVariacionPrecio.length-1){
        setIndexPrecio(0);            
    }else{
        setIndexPrecio(indexPrecio+1);
    }
  }
function filterNegociaciones(nego,negociacionFilter){
  //value => 
  setGridInfor(nego.filter(function(item) {
    if (item["EstadoNego"].toString().toLowerCase().includes(negociacionFilter.toLocaleLowerCase()) || item["Jugada"].toString().toLowerCase().includes(negociacionFilter.toLocaleLowerCase()) ||item["Nombre"].toLowerCase().includes(negociacionFilter.toLocaleLowerCase()) || item["Tipo"].toLowerCase().includes(negociacionFilter.toLocaleLowerCase()) || item["Emisor"].toLowerCase().includes(negociacionFilter.toLocaleLowerCase()) || item["TipoRenta"].toLowerCase().includes(negociacionFilter.toLocaleLowerCase()) || item["TipoValor"].toLowerCase().includes(negociacionFilter.toLocaleLowerCase()) )
        return true;
    return false;
  }))
}
useEffect(() => {
    const timer=setTimeout(() => {
        if((indexPrecio+1)>props.listaVariacionPrecio.length-1){
            setIndexPrecio(0);            
        }else{
            setIndexPrecio(indexPrecio+1);
        }
    }, 5000);
    
    return () => clearTimeout(timer);
}, [indexPrecio,props.listaVariacionPrecio]);
if(createInterval===false && props.listaVariacionPrecio.length>0){
    setCreateInterval(true)    
}
  return (
      <div className="arrowCont">
         <div className="arrowControlsContainer">            
            <div className={["pasoHTLeft leftArrow",index==0?"arrowDisabled":""].join(' ')} onClick={back}><img src={leftArrow} alt="left arrow" /></div>
            <div className={["pasoHTRight rightArrow",index==maxIndex?"arrowDisabled":""].join(' ')} onClick={next}><img src={rightArrow} alt="right arrow" /></div>
        </div>
        <div className="footerColContainer">       
            <div className={["pasoHistorialTran","fade-in",index!==0?"displaynone":""].join(' ')}>
                    <div className="tableTransaction borderContent">
                        <div className={["innerShadow2",isMaximized===true?"maximizar-ordenes-foother":""].join(' ')}>
                            <div className="btnMaximizeGrid" onClick={btnMaximizeClick}><i className={["far",isMaximized===false?"fa-window-maximize":"fa-window-restore"].join(' ')}></i></div>
                            <div className="titleTable">HISTORIAL DE TRANSACCIONES<div className='floatright'><div className="pasoFitroHT filterNeg"><input type="text" placeholder="Filtrar" value={negociacionFilter} onChange={(e) => {
                  setNegociacionFilter(e.target.value);
                  filterNegociaciones(props.negociaciones,e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div>                            
                            <div className="table-content-order">                                                                                        
                                <DataGrid
                                    idProperty="NegociacionId"
                                    style={gridStyle}
                                    theme="default-light"                    
                                    columns={columns}
                                    enableSelection={true}
                                    dataSource={gridInfor}
                                    theme="amber-dark"
                                    showEmptyRows={false}
                                />
                            </div>                                                                                                                
                        </div>
                    </div> 
                </div>    
            <div className={["fade-in",index!==1?"displaynone":""].join(' ')}>
                <div className={["footerCol borderContent",props.tipoNotEspecial!=="NE_CRUSH" && props.tipoNotEspecial!=="NE_CONTRASPLIT"?"":"displaynone"].join(' ')}>
                <div className={["innerShadow2"].join(' ')}>
                    <h2>NOTICIA POSITIVA</h2>
                    <div className="noticeContainer">
                        <div className="imageNotice noticeStyle1">
                            <img src={props.noticiaPosObj!==null && props.noticiaPosObj["ImageUrl"]!==""? global.config.URL_UPLOAD + props.noticiaPosObj["ImageUrl"]:img01} alt="Noticia 1" />
                        </div>
                        <h3>{props.noticiaPos!==""?props.noticiaPos:"La noticia se mostrará cuando inicie el juego"}</h3>
                        {/*<p>Madrid – Adidas confirmaba en el día de ayer el haber terminado con éxito la colocación de una emisión de bonos por valor de 1.000 millones de euros.</p>*/}
                    </div>
                </div>
                </div>
            
                <div className={["footerCol borderContent",props.tipoNotEspecial!=="NE_BOOM" && props.tipoNotEspecial!=="NE_SPLIT"?"":"displaynone"].join(' ')}>
                <div className={["innerShadow2"].join(' ')}>
                    <h2>NOTICIA NEGATIVA</h2>
                    <div className="noticeContainer">
                        <div className="imageNotice noticeStyle2">
                            <img src={props.noticiaNegObj!==null && props.noticiaNegObj["ImageUrl"]!==""? global.config.URL_UPLOAD + props.noticiaNegObj["ImageUrl"]:img02} alt="Noticia 2" />
                        </div>
                        <h3>{props.noticiaNeg!==""?props.noticiaNeg:"La noticia se mostrará cuando inicie el juego"}</h3>
                        {/*<p>Madrid – Adidas confirmaba en el día de ayer el haber terminado con éxito la colocación de una emisión de bonos por valor de 1.000 millones de euros.</p>*/}
                    </div>
                </div>
                </div>
            
                <div className="footerCol borderContent">
                    <div className="innerShadow2">
                    <h2>PROGRESIÓN - {props.listaVariacionPrecio[indexPrecio]!==undefined && props.listaVariacionPrecio[indexPrecio].length>0? props.listaVariacionPrecio[indexPrecio][0]["Emisor"].toUpperCase():""}</h2>
                    <LineChart
                        width={400}
                        height={120}
                        data={props.listaVariacionPrecio[indexPrecio]}
                        margin={{top: 15, right: 30, left: 5, bottom: 5,}}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />                        
                        <Line type="monotone" dataKey="Precio" stroke="#8884d8" activeDot={{ r: 8 }} />                        
                    </LineChart>                    
                    </div>
                </div>    
            </div>
        </div>
      </div>    
  );
};

export default ScrollFooter;