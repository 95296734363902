import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {Menu,MenuItem,MenuButton,SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {getParametros,deleteParametros} from "../../actions/administracion";
import EditarGestionparametros from "./EditarGestionparametros";
import "../../config";
const GestionParametros = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'calc(100% - 50px)', margin: 20 } 
    const {listaParametrosPag,cargandoParametros} = useSelector((state) => state.administracion);   
    let [openEditarParam, setOpenEditarParam] = useState("") 
    let [parametroSeleccionado,setParametroSeleccioando]=useState("null")
    let [loadParametros, setLoadParametros] = useState(false) 
    if(loadParametros===false){
        setLoadParametros(true)
        dispatch(getParametros("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }
    let [gestionParametrosFilter, setGestionParametrosFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaParametrosPag!==null?listaParametrosPag:[]]);     

    if(listaParametrosPag!==null && listaParametrosPag!=gridInfor && gestionParametrosFilter==="")
        setGridInfor(listaParametrosPag)

    function filterParametros(para,gestionParametrosFilter){
        //value => 
        setGridInfor(para.filter(function(item) {
            if (item["Codigo"].toLowerCase().includes(gestionParametrosFilter.toLocaleLowerCase()) || 
                item["Nombre"].toLowerCase().includes(gestionParametrosFilter.toLocaleLowerCase())
               ) 
                return true;
            return false;
        }))
    }
    //salir de ventana parametros
    function closeWindowParametro(){
        props.closeGestionParametrosClick()
    }
    //metodo salir de editar parametros 
    function closeEditarParametro(){
        setOpenEditarParam("")
    }

    //abrir ventana nuevo parametro
    
    function openNewParametro(){
        setParametroSeleccioando(null)
        setOpenEditarParam("EDIT")
    } 
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
          
        {
            label: 'Editar',
            onClick:()=>{   
                setParametroSeleccioando(rowProps.data)
                setOpenEditarParam("EDIT")
                console.log(rowProps.data)
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar '+rowProps.data["Nombre"],
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{                    
                           dispatch(deleteParametros(rowProps.data["ParametroId"],"0","","","ASC",""))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                });               
            }
        }
        ]
    }, [])  
    //console.log(listaCatalogosPag)
    let columns= [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Codigo',header: 'Código', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'Nombre',header: 'Nombre', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, },        
        { name: 'Valor',header: 'Valor', defaultFlex: 1, xdefaultLocked: 'end' },                  
        { name: 'FechaRegistro',header: 'Fecha registro', defaultFlex: 1, xdefaultLocked: 'end' },
    ]
  return (
    <div
      className={[
        "windowContentInner",
        "fade-in"
      ].join(" ")}
    >
        <div className={['loading_cont',cargandoParametros!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>    
        {openEditarParam==="EDIT" ? <EditarGestionparametros closeEditarParametro={closeEditarParametro} parametroSeleccionado={parametroSeleccionado}/>  : ""}
        <div className="borderContent ventanaGeneral">
            <div className="innerShadow-height-100">
                <h2>
                    Parámetros
                    <div className="floatright">
                        <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                            <MenuItem onClick={openNewParametro}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                        </Menu>
                    </div>
                </h2>  
                <div className="titleTable-search"><div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={gestionParametrosFilter} onChange={(e) => {
                  setGestionParametrosFilter(e.target.value);
                  filterParametros(listaParametrosPag!==null?listaParametrosPag:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div>
                <DataGrid
                idProperty="CatalogoId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}
                selection={{ mode: 'single' }}                    
                dataSource={gridInfor}
                renderRowContextMenu={renderRowContextMenu}
                theme="amber-dark"
            />
                <div className="dividerLine"></div>
                <div className="textCenter">
                    <div className="btnCancelar" onClick={closeWindowParametro}>
                        SALIR
                    </div>
                </div> 
            </div>
        </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionParametros);
