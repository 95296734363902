import React, { useState,useEffect} from "react";
import logoBVQ from '../../image/logoBVQ2.svg';

function BarGroup(props) {
    let barPadding = 2
    let barColour = '#348AA7'
    let heigthBarMax = 400-100
    let widthScale = d => d * 10
  
    let width = (heigthBarMax * props.d.value)/100 //widthScale(props.d.value)
    let yMid = props.barHeight * 0.5
    
    return <g className="bar-group">
      <text className="name-label" x="-6" y={yMid} alignmentBaseline="middle" >{props.d.name}</text>
      <rect y={heigthBarMax-width} width={props.barHeight - barPadding} height={width} fill={barColour} />      
      {props.i<3?<rect x="173" y={heigthBarMax} width={props.barHeight - barPadding} height={40} fill={barColour} transform="skewX(-30)"/>:props.i>4?<rect x="-173" y={heigthBarMax} width={props.barHeight - barPadding} height={40} fill={barColour} transform="skewX(30)"/>:<rect x="0" y={heigthBarMax} width={props.barHeight - barPadding} height={40} fill={barColour} transform="skewX(0)"/>}
      <image href={logoBVQ} x="-20" y="330" height="30px" width="30px"/>
      <text className="value-label" x={22} y={heigthBarMax-width+13} alignmentBaseline="middle" >{props.d.value}</text>
      <path class="fbase" data-name="base" d="M478.23,451h180V551h-327Z" transform="translate(0)" fill-rule="evenodd" fill="url(#linear-gradient-3)"></path> 
    </g>
  }

const Demo2 = (props) => {
    let [barHeight, setBarHeight] = useState(30);
    let [data] = useState([
        { name: 'Mon', value: 20 },
        { name: 'Tue', value: 40 },
        { name: 'Wed', value: 35 },
        { name: 'Thu', value: 50 },
        { name: 'Fri', value: 55 },
        { name: 'Sat', value: 40 },
        { name: 'Sun', value: 90 },
        { name: 'Sun', value: 10 }
      ]);
      let barGroups = data.map((d, i) => <g transform={`translate(${i * barHeight},0)`}>
      <BarGroup d={d} barHeight={barHeight} i={i}/>
    </g>)             
  return (
      
    <div className='barras'>
        <svg width="800" height="500" >
            <g className="container">
                <g className="chart" transform="translate(100,60)">
                {barGroups}
                </g>
            </g>
        </svg>
    </div>
  );
};
export default Demo2;