import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {Menu,MenuItem,MenuButton,SubMenu} from '@szhsin/react-menu';
import {getItemCatalogos,deleteItemCatalogo,setStatusByKey} from "../../actions/administracion";
import EditarGestionItems from "./EditarGestionItems";
import '@szhsin/react-menu/dist/index.css';
const GestionItems = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'calc(100% - 50px)', margin: 20 }
    const {listaItemsPag, itemsLoading} = useSelector((state) => state.administracion);
    const [editarItemOpen,setEditarItemOpen] = useState("")
    const [itemSeleccionado,setItemSeleccionado] = useState(null)
    const [catalogoId,setCatalogoId] = useState(null)
    const [loadItemCat,setLoadItemCat] = useState(false)
    let itemId = useState(null);
    if(listaItemsPag===null && loadItemCat===false){
        setLoadItemCat(true)
        dispatch(getItemCatalogos(props.catalogoSeleccionado["CatalogoId"],"0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }
    if(catalogoId===null){
        setCatalogoId(props.catalogoSeleccionado["CatalogoId"])
    }
    let [itemFilter, setItemFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaItemsPag!==null?listaItemsPag:[]]);   
    if(listaItemsPag!==null && listaItemsPag!=gridInfor && itemFilter==="")
        setGridInfor(listaItemsPag)

    function filterCatalogo(itemC,itemFilter){
        //value => 
        setGridInfor(itemC.filter(function(item) {
            if (item["Codigo"].toLowerCase().includes(itemFilter.toLocaleLowerCase()) || 
                item["Nombre"].toLowerCase().includes(itemFilter.toLocaleLowerCase()))
                return true;
            return false;
        }))
    }
    function closeWindowItem(){
        dispatch(setStatusByKey("listaItemsPag",null))
        .then(() => {
        })
        .catch(() => {
        });
        props.closeItemCatalogo()
    }
    function closeEditItem(){
        setEditarItemOpen("")
    }
    function newEditItem(){
        setItemSeleccionado(null)
        setEditarItemOpen("EDIT_ITEM")
    }
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [ 
        {
            label: 'Editar',
            onClick:()=>{   
                setItemSeleccionado(rowProps.data)
                itemId = rowProps.data["ItemId"]
                setEditarItemOpen("EDIT_ITEM")
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar item catálogo',
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{                    
                        dispatch(deleteItemCatalogo(rowProps.data["ItemId"],rowProps.data["CatalogoId"],"0","","","ASC",""))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                           
                        }
                       
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                });               
            }
        }
        ]
    }, [])
    let columns= [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Codigo',header: 'Código', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'Nombre',header: 'Nombre', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, }, 
        { name: 'NombreCorto',header: 'Nombre corto', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, }, 

    ]  
  return (
    <div className= "overlay3 fade-in">
        <div className={['loading_cont',itemsLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>
        {editarItemOpen=="EDIT_ITEM"? <EditarGestionItems closeEditItem={closeEditItem} itemSeleccionado={itemSeleccionado} catalogoId={catalogoId} catalogoCod={props.catalogoSeleccionado!==null?props.catalogoSeleccionado["Codigo"]:""}/>  : ""}
        <div className="borderContent ventanaPortafolio">
            <div className="innerShadow-height-100">
                <h2>
                Item catálogo
                <div className="floatright">
                    <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                        <MenuItem onClick={newEditItem}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                    </Menu>
                </div>
                </h2> 
                <div className="titleTable-search"><div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={itemFilter} onChange={(e) => {
                  setItemFilter(e.target.value);
                  filterCatalogo(listaItemsPag!==null?listaItemsPag:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div>
                <DataGrid
                    idProperty="ItemId"
                    style={gridStyle}
                    theme="default-light"                    
                    columns={columns}
                    enableSelection={true}
                    selection={{ mode: 'single' }}                    
                    dataSource={gridInfor}
                    renderRowContextMenu={renderRowContextMenu}
                    theme="amber-dark"
                />
                
                <div className="dividerLine"></div>
                <div className="textCenter">
                    <div className="btnCancelar" onClick={closeWindowItem}>
                        SALIR
                    </div>
                </div>      
            </div>       
        </div>
        
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionItems);
