import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {NotificationManager} from "react-notifications";
import UploadFile from "./UploadFile"

import { updatePerfilUsuario } from "../../actions/administracion";
import "../../config";

const Sala = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  let history = useHistory();
  const dispatch = useDispatch();

  let [errors] = useState({"email":""},{"nombre":""},{"nick":""});

  let [nombre, setNombre] = useState(
    currentUser !== null ? currentUser["Nombre"] : ""
  );
  let [nick, setNick] = useState(
    currentUser !== null ? currentUser["displayName"] : ""
  );
  let [email, setEmail] = useState(
    currentUser !== null ? currentUser["email"] : ""
  );
  let [clave, setClave] = useState("");
  let [uploadId, setUploadId] = useState(currentUser!==null?currentUser["UploadId"]:"-1")
  let [fileNombre, setFileNombre] = useState(currentUser!==null?currentUser["fileNombre"]:"")
  let [fileNombreUrl, setFileNombreUrl] = useState(currentUser!==null?currentUser["fileNombreUrl"]:"")



  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  function actualizarPerfil() {
      let valid = true
    if((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))){
        errors["email"] = "Ingrese email válido"
        valid = false
    }
    if(nombre == ""){
        errors["nombre"] = "Ingrese nombre"
        valid = false
    }

    if(nick ==""){
        errors["nick"] = "Ingrese apodo"
        valid = false
    }
    if(!valid){
        return
    }
    dispatch(updatePerfilUsuario(currentUser["userId"], nombre, nick, email, clave,uploadId))
      .then(() => {        
      })
      .catch(() => {});

      NotificationManager.success(
            "Información actualizada",
            "Sistema",
            3000,
            () => {},
            4
        ); 
        
  }
  function closeWindow() {
    props.closeProfileClick();
  } 
  function reloadDocuments(file){
    const result = JSON.parse(file);
    setUploadId(result["UploadId"])
    setFileNombre(result["fileNombre"])
    setFileNombreUrl(result["fileNombreUrl"])
  }
  return (
    <div
      className={[
        "overlay",
        "fade-in",
        props.openProfile ? "animationShow" : "animationHide",
      ].join(" ")}
    >
      <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
          <h2>
            Configuración <i className="fas fa-cog"></i>
          </h2>
          <div className='profileLeftContent'>
            <i className="far fa-address-card"></i> <span>Nombre:</span>{" "}
          </div>
          <div className='profileRightContent clear-both'>{nombre}
              {/*<input
                type="text"
                value={nombre}
                onChange={(e) => {
                  setNombre(e.target.value);
                  errors["nombre"]=""
                }}
                
              ></input>*/}
              {/*errors["nombre"]!==""?<div className='camporequerido'>{errors["nombre"]}</div>:""*/}
          </div>
          <div className='profileLeftContent'>
            <i className="far fa-address-card"></i> <span>Nickname:</span>
          </div>
          <div className='profileRightContent'>            
              <input
                type="text"
                value={nick}
                
                onChange={(e) => {
                  setNick(e.target.value);
                  errors["nick"]=""
                }}
              ></input>
              {errors["nick"]!==""?<div className='camporequerido'>{errors["nick"]}</div>:""}
          </div>
          <div className='profileLeftContent'>
            <i className="far fa-envelope"></i> <span>Email:</span>
          </div>
          <div className='profileRightContent'>            
              <input
                type="text"
                value={email}
                required="required" 
                onChange={(e) => {
                  setEmail(e.target.value);
                  errors["email"]=""
                }}
              ></input>
              {errors["email"]!==""?<div className='camporequerido'>{errors["email"]}</div>:""}
          </div>
          <div className='profileLeftContent'>
          <i className="fas fa-lock"></i> <span>Contraseña:</span> 
          </div>
          <div className='profileRightContent'>          
              <input
                type="text"
                value={clave}
                onChange={(e) => {
                  setClave(e.target.value);
                }}
              ></input>
              
          </div>
          <div className='profileLeftContent'>
          <i className="fas fa-file-image"></i> <span>Imagen:</span> 
          </div>
          <div className='profileRightContent'>          
            <UploadFile reloadDocuments={reloadDocuments} uploadId={uploadId} fileNombre={fileNombre} fileNombreUrl={fileNombreUrl}></UploadFile>
          </div>          
          <div className="dividerLine"></div>
          <div>
            <p>
              <i className="fas fa-volume-up"></i> <span>Sonido:</span>{" "}
              Encendido
            </p>
          </div>
          <div>
            <div className="dividerLine"></div>
          </div>
          <div>
            <i className="fas fa-palette"></i> <span>Tema:</span> Oscuro
          </div>
          <div className="dividerLine"></div>
          <div className="textCenter">
            <div className="btnGuardar" onClick={actualizarPerfil}>
              GUARDAR
            </div>{" "}
            <div className="btnCancelar" onClick={closeWindow}>
              CANCELAR
            </div>
          </div>
        </div>
      </div>    
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Sala);
