import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import logoJuegodelaBolsa from '../image/logoJuegodelaBolsa2.svg';
import logoBVQ from '../image/logoBVQ2.svg';

import { login } from "../actions/auth";
import { getParameterForClient } from "../actions/administracion";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Campo requerido!
      </div>
    );
  }
};


const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);          
  const [shown, setShown] = React.useState(false);

  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const switchShown = () => setShown(!shown);

  
  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          props.history.push("/sala");
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  dispatch(getParameterForClient())
    .then(() => {
      
    })
    .catch(() => {
    });


  if (isLoggedIn) {
    return <Redirect to="/sala" />;
  }


  return (
    <div>
      <div className="loginHeader">  
        <div className="logoEljuegodelaBolsa loginLogo1"><img src={logoJuegodelaBolsa} alt="El Juego de la Bolsa" /></div>
        <div className="divider"></div>
        <div className="logoBVQ loginLogo2"><img src={logoBVQ} alt="Bolsa de Valores de Quito" /></div>
      </div>
      <div className="box-login">
        <h1 className="titleLogin">Iniciar Sesión</h1>            
        <Form onSubmit={handleLogin} ref={form}>
          <Input type="text" placeholder="Nombre de usuario" name="uname" required className="inputUsername" value={username} onChange={onChangeUsername} validations={[required]}/>    
            <Input  type={shown ? 'text' : 'password'} placeholder="Contraseña" name="psw" required className="inputPassword" value={password} onChange={onChangePassword} validations={[required]}/>
            <div className="inputPasswordSee" onClick={switchShown} title="Ver Contraseña">
              {shown ?  <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
            </div>
          <button className="inputIniciarSesion" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>INICIAR SESION</span>
            </button>
          <div className="inputCondiciones">
          <Input type='checkbox' id="agree" required /> Acepto la <a href="/condiciones" target='_blank'>Política de privacidad de datos, términos y condiciones del servicio</a>, contenidos en este aplicativo.
          </div>
          <a href="/recuperar" className="linkRecuperarContrasena">¿Olvidaste tu contraseña?</a>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}

          <CheckButton style={{ display: "none" }} ref={checkBtn}/>
        </Form>
      </div>
    </div>    
  );
};

export default Login;
