import React, { useState, useEffect } from "react";
import { Redirect} from 'react-router-dom';
import Confetti from 'react-confetti'
import { connect,useDispatch,useSelector } from "react-redux";
import {setStatusByKey} from '../actions/juego'
import {setStatusByKeyAdm} from '../actions/administracion'
import Portafolio from "./Portafolio";
import {enableFuncionalityForRole,generateMessageAdm,formatNumber,getTextoPosicion,numberToOrdinal} from "./general/Util"
import Websocket from 'react-websocket';
import axios from "axios";
import '../config'
import CompartirJuego from './CompartirJuego'; 
import Portafoliofj from './Portafoliofj'; 
import leftArrow from '../image/arrowLeft.svg';
import rightArrow from '../image/arrowRight.svg';
import '../styles/finjuego.css'

let refWebSocket = null

const FinEvento = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);  
    const dispatch = useDispatch();
    const { eventoFinalizado,mostrarPosicion,listaPosicion } = useSelector((state) => state.juego);
    const { listParamsGame} = useSelector((state) => state.administracion);    

    let [adminFun] = useState(currentUser!==null?enableFuncionalityForRole(currentUser["role"],"ADM"):false);    
    let [showTop,setShowTop] = useState(false);    
    let [downloading,setDownloading] = useState(false);        
    let [createInterval,setCreateInterval] = useState(true);            
    let [showPaso,setShowPaso] = useState(true);        
    let [nextPost,setNextPost] = useState(0);        
    let [listaUsuarioFin,setListaUsuarioFin] = useState(listaPosicion!==null?listaPosicion:[]);        
    let [maxPosition,setMaxPosition] = useState((listParamsGame===null || listParamsGame.length===0)?(listaPosicion.length-1>9)?9:listaPosicion.length-1:(listaPosicion!==null && listParamsGame!==null && parseInt(listParamsGame.filter(value => value["Codigo"] === 'RANKIN_MAX')[0]["Valor"])>=listaPosicion.length?listaPosicion.length-1:parseInt(listParamsGame.filter(value => value["Codigo"] === 'RANKIN_MAX')[0]["Valor"])-1));
    let [indexPos,setIndexPos] = useState(maxPosition);     
    let [intervalT,setIntervalT] = useState(0);  
    let [loadParameters,setLoadParameters] = useState(true);         
    let [loadCssClass,setLoadCssClass] = useState(true);         

    if(loadCssClass===true){
        setLoadCssClass(false)
        document.getElementById('root').classList.add("root-fn");        
    }

    if(loadParameters===true && (listParamsGame===null || listParamsGame.length===0)){
        setLoadParameters(false)
        axios
          .post(global.config.URL_SERVICE + "ObtenerParametrosJuego", {},{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              if(result.length>0){
                localStorage.setItem("paramgame", JSON.stringify(result));
                dispatch(setStatusByKeyAdm("listaEventoAyuda",result))
                return result;  
              }else{
                return null
              }
          });
    }

    let someProp = false
    const config = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 150,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: "10px",
        height: "10px",
        perspective: "500px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
      };

      if (!currentUser) {
        return <Redirect to="/login" />;
      }  


    function handleData(data) {
        let result = JSON.parse(data);
        if(result["NETX_EVENT"]==="MOS_POS"){
            try{
                dispatch(setStatusByKey("listaPosicion",result["MSG"]))
                let listPost = JSON.parse(result["MSG"]);
                setListaUsuarioFin(listPost)
                let indexMaxTmop = (listParamsGame===null || listParamsGame.length===0) ?(listaPosicion.length-1>9)?9:listaPosicion.length-1:listPost!==null && parseInt(listParamsGame.filter(value => value["Codigo"] === 'RANKIN_MAX')[0]["Valor"])>=listPost.length?listPost.length-1:parseInt(listParamsGame.filter(value => value["Codigo"] === 'RANKIN_MAX')[0]["Valor"])-1
                setMaxPosition(indexMaxTmop)
                setIndexPos(indexMaxTmop)
                showPaso = true
                setShowPaso(showPaso)            
                dispatch(setStatusByKey("mostrarPosicion",true))                
            }catch(ex){

            }
        }
    }
    function mostrarResultadosClick(){
        //dispatch(setStatusByKey("mostrarPosicion",true))
        refWebSocket.sendMessage(generateMessageAdm("MOS_RES"))
    }
    function btnMostrarClasificacion(){
        setShowTop(true)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    function btnOcultarClasificacion(){
        setShowTop(false)
    }    
    function descargarCertificado(){
        setDownloading(true)
        const params = {"strEventoId":eventoFinalizado["EventoId"],"strUsuarioId":currentUser["userId"]}
        axios
        .post(global.config.URL_SERVICE + "DescargarCertificado", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            setDownloading(false)
            let itemR = response["data"].split("\\\"").join("\"");     
            
            if(itemR!=="" && itemR!=="-1" && itemR!==-1){
                
                const link = document.createElement('a');
                link.href = global.config.URL_CERTIFICADO + itemR;
                link.target = '_blank'
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            
        });
    }
    
    if(createInterval===true && mostrarPosicion===true){
        setCreateInterval(false)
        clearInterval(intervalT)
        intervalT = setInterval(() => {
            if(showPaso===true){
                showPaso = false
                setShowPaso(showPaso)
                nextPost = 1
                setNextPost(nextPost)
            }else{
                if(nextPost>1){                                        
                    if(nextPost===2){
                        indexPos = indexPos-1
                        if(indexPos<0){
                            clearInterval(intervalT)
                            indexPos = 0
                            return
                        }                           

                        showPaso = true
                        setShowPaso(showPaso)
                        nextPost=0                
                        setNextPost(nextPost) 
                        setIndexPos(indexPos)
                    }else{
                        if(indexPos<=0){
                            clearInterval(intervalT)
                            indexPos = 0
                        }                            
                        nextPost++                
                        setNextPost(nextPost)
                    }                        
                }else{
                    nextPost++
                    setNextPost(nextPost)
                }
                
            }            
        }, 3000,indexPos,showPaso,nextPost);
        setIntervalT(intervalT)
    }

    if (!currentUser) {
        return <Redirect to="/login" />;
    }  
    
    function getStyleByIndex(ind){
        switch(ind){
            case 0:
                return ""
            case 1:
                return "bg2toLugar"
            case 2:
                return "bg3toLugar"            
            default:
                return "bg" + (ind+1) + "toLugar"
        }
    }

    function back_Click(){        
        clearInterval(intervalT)
               
        indexPos++
        if(indexPos>=maxPosition){            
            indexPos = maxPosition
        }    
        showPaso = true
        setShowPaso(showPaso)
        nextPost=0                
        setNextPost(nextPost) 
        setIndexPos(indexPos)                 
        setCreateInterval(true)
    }
    function next_Click(){
        
        clearInterval(intervalT)
        indexPos--
        if(indexPos<=0){            
            indexPos = 0
        }           
        showPaso = true
        setShowPaso(showPaso)
        nextPost=0                
        setNextPost(nextPost) 
        setIndexPos(indexPos)                 
        setCreateInterval(true)
    }
    
    const url = "http://puntopagobvq.com/";
    const titulo =currentUser["Nombre"];
    const resumen ="está jugando el juego de la BVQ";
	
    return (
        <div>
            {mostrarPosicion===true?<div className="arrowControlsContainer winSelector">
                <div className={["leftArrow",maxPosition===indexPos || showPaso===true || mostrarPosicion===false || showTop===true?"displaynone":""].join(' ')} onClick={back_Click} >
                    <img src={rightArrow} alt="right arrow"/>
                </div>
                <div className={["rightArrow",indexPos===0  || showPaso===true || mostrarPosicion===false || showTop===true?"displaynone":""].join(' ')} onClick={next_Click}>                
                    <img src={leftArrow} alt="left arrow"/>
                </div>
            </div>:""
            }
        
            <div className='fade-in'>         
                <div className={(downloading===true)? "containerLoading overlay-loading":"containerLoading overlay-loading displaynone"}>
                    <div className='single6'></div>
                </div>   
                {eventoFinalizado!==null?<Websocket ref={Websocket => {
                    refWebSocket = Websocket;
                    }} url={global.config.URL_WEB_SOCKET + "/" + eventoFinalizado["EventoId"] + "?USR=" + currentUser["userId"] + "&CS=1&ROL=R&UNAME=" + encodeURIComponent(currentUser["displayName"])  + "&IMG=" + encodeURIComponent(currentUser["fileNombreUrl"]!==null?currentUser["fileNombreUrl"]:"")} onMessage={handleData.bind(this)}/>:""}
                <div className={mostrarPosicion===false?"displaynone":""}>
                    {showPaso==false && indexPos===0?<Confetti active={ someProp } config={ config }/>:""}
                    <div className="overlay2">
                        {
                            showPaso===false?
                            <div className={[currentUser["role"]==='ROLE_USER'|| 1===1?"winContainer " + getStyleByIndex(indexPos) + " w3-container w3-center w3-animate-top":"winContainer-adm",showTop===true?"displaynone":""].join(' ')}>
                            {currentUser["role"]==='ROLE_USER' || 1===1?
                            <div>
                                <Portafoliofj namePortafolio={listaUsuarioFin!==null && listaUsuarioFin.length>0 && indexPos<listaUsuarioFin.length?listaUsuarioFin[indexPos]["Pos"]["Nombre"]:""} index={indexPos+1} posicion={listaUsuarioFin!==null && listaUsuarioFin.length>0 && indexPos<listaUsuarioFin.length?listaUsuarioFin[indexPos]["Port"]:null} saldoEfectivo={listaUsuarioFin!==null && listaUsuarioFin.length>0 && indexPos<listaUsuarioFin.length?listaUsuarioFin[indexPos]["Saldo"]:0}/>         
                                {currentUser["role"]==='ROLE_USER'?
                                <div>
                                    <div className="btnDownload" onClick={descargarCertificado}>Descargar Certificado</div>                        
                                    <CompartirJuego url={url} titulo={titulo} resumen={resumen}  />                            
                                </div>:""
                                }                                
                                <div className="btnDownload" onClick={btnMostrarClasificacion}>Mostrar clasificación completa</div>
                                
                            </div>:
                            <div >
                                <div className="txtWin">EXCELENTE JUEGO FINALIZADO</div>
                            </div>
                                                                                                            
                                                                                                        
                            }                        
                            </div>:
                            <div className='winContainer-adm'>
                                <div className='expectativa-puesto'>
                                    <h1 className="ml6">                                     
                                        <div className="w3-container w3-center w3-animate-top">
                                            <span className="text-wrapper">                                        
                                                <span className="letters fade-in"><h1>{numberToOrdinal(indexPos+1)} LUGAR ES.....</h1></span>
                                            </span>
                                            <div className="wrapper">
                                                <div id="preloader_4">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </h1>
                                    
                                </div>
                            </div>                        
                        }                                                        
                        <div className={["topRank",showTop===true?"w3-container w3-center w3-animate-top":"displaynone"].join(' ')}>
                            <div className="borderContent">
                                <div className="innerShadow2">
                                        <div className="closeBtn" onClick={btnOcultarClasificacion}></div>
                                        <div className="topRankTitle">Ranking</div>
                                        <div className="topRankContent">
                                            <div className="col1">
                                                {listaUsuarioFin!==undefined && listaUsuarioFin!==null?listaUsuarioFin.map((item, i) => {                                                 
                                                    return (i<1000 && item["Pos"]!==undefined)?<div className={"top" + item["Pos"]["Posicion"] + "Rank"}>{item["Pos"]["Posicion"]}</div>:""
                                                }):""
                                                }
                                            </div>
                                            <div className="col2">
                                                {listaUsuarioFin!==undefined && listaUsuarioFin!==null?listaUsuarioFin.map((item, i) => { 
                                                    return (i<1000 && item["Pos"]!==undefined)?<div className={"top" + item["Pos"]["Posicion"]}><div className="profileImageList"></div><div className='itemNameRanking' title={item["Pos"]["Nombre"]}>{item["Pos"]["Nombre"]}</div><div className="cantidad">${formatNumber(item["Pos"]["Saldo"])}</div></div>:""
                                                }):""
                                                }                                            
                                            </div>                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div className={mostrarPosicion===true?"displaynone":""}>
                <div className="overlay2">
                        <div className="winContainer-nopos">                        
                            <div className="borderContent">
                                <div className="innerShadow2">
                                    <div className="txtGold-evento">EVENTO FINALIZADO</div>
                                    <div className="txtWhite-evento">{eventoFinalizado!==null?eventoFinalizado["NombreEvento"]:""}</div>
                                    <br/>
                                    {adminFun===false?<div className="colorWhite-evento">En unos instantes el director de rueda mostrará los resultados, espere por favor...</div>:""}
                                </div>
                            </div>
                            {adminFun===true?<div className="btnDownload" onClick={mostrarResultadosClick}>Mostrar resultados</div>:""}
                        </div>        
                    </div>
                </div>
            </div>
        </div>
    );
}
export default FinEvento;