import React, { useState, useEffect } from "react";
import { Redirect} from 'react-router-dom';
import { connect,useDispatch,useSelector } from "react-redux";
import {NotificationManager} from "react-notifications"

import ItemNegociacionRV from './juego/ItemNegociacionRV';
import ItemNegociacionRF from './juego/ItemNegociacionRF';
import Select from 'react-select';
import axios from "axios";
import '../config'
import {getUsuarioEventoCombo} from "../actions/evento";

const CajaNegociacion = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);  
    let {listTitleEvent: currentTitle,eventoSeleccionado: eventoSeleccionado,jugadaIndex} = useSelector((state) => state.juego);
    const {listaEventoUsuarioCombo} = useSelector((state) => state.evento);   
    let [negociacionLoading,setNegociacionLoading] = useState(false);
    let [userSelect,setUserSelect] = useState(null)
    let [loadUsrEven, setLoadUsrEven] = useState(false);
    let [refreshInput, setrefreshInput] = useState(false);    

    const dispatch = useDispatch();
   
    let [itemsCompra] = useState({});
    let [itemsVenta] = useState({});
    
    if(listaEventoUsuarioCombo===null && loadUsrEven===false){
        setLoadUsrEven(true)
        dispatch(getUsuarioEventoCombo(eventoSeleccionado))
        .then(() => {
        })
        .catch(() => { 
        });
        
    }
    
    function btnNegociacionCancelar(){
        props.btnNegociacionCancelar()
    }
    function btnEnviarNegociacion(){        		
        if(userSelect===null || userSelect["value"]<0){
            NotificationManager.error(
                "Seleccione un usuario",
                "Sistema",
                5000,
                () => {},
                4
            ); 
            return
        }
        setNegociacionLoading(true)        
        setrefreshInput(false)
        const params = {"strEventId":eventoSeleccionado.toString(),"strUserId":userSelect["value"],"strJugada":jugadaIndex,"strCompra":JSON.stringify(itemsCompra),"strVenta":JSON.stringify(itemsVenta)}
        axios
        .post(global.config.URL_SERVICE + "GuardarCompraJugada", params,{headers: {'Content-Type': 'application/json; charset=utf-8'}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");     
            const result = JSON.parse(itemR);      
            setNegociacionLoading(false)            
            if(result>=0){
                NotificationManager.success(
                    "Negociación registrada",
                    "Sistema",
                    5000,
                    () => {},
                    4
                );                                 
                setUserSelect(null)
                setrefreshInput(true)                
                itemsCompra = {}
                itemsVenta = {}
                //limpiar controles    
            }else{
                if(result===-2){
                    NotificationManager.error(
                        "Necesitas compra por lo menos un título de Renta Fija",
                        "Sistema",
                        5000,
                        () => {},
                        4
                    ); 
                }
                else
                    if(result===-3)
                        NotificationManager.error(
                            "Ingresa COMPRAS/VENTAS",
                            "Sistema",
                            5000,
                            () => {},
                            4
                        ); 
                    else
                        NotificationManager.error(
                        "Error al registrar la negociación",
                        "Sistema",
                        5000,
                        () => {},
                        4
                    ); 
            }
        });
    }
    function updateValueItemsVenta(index,id,value,precio){
        console.log("updateValueItemsVenta " + index + " - ")
        itemsVenta[index] = {"id": id,"value":value,"precio":precio};
    }
    function updateValueItemsCompra(index,id,value,precio){
		itemsCompra[index] = {"id": id,"value":value,"precio":precio};
    }

    return (
        <div
      className={[
        "overlay",
        "fade-in"
      ].join(" ")}
    >
        <div className={['loading_cont',negociacionLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>        
        <div className="borderContent ventanaPortafolio">
            <div className="innerShadow-height-ing-compra">
                <h2>
                    Ingreso órdenes
                    <div className="floatright col-70-orn-caja">
                        <Select className="input-form" id="InstitucionCombo" value={userSelect} onChange={(e) => {
                            setUserSelect(e) 
                            /*setrefreshInput(true)                
                            itemsCompra = {}
                            itemsVenta = {}*/
                            }} placeholder="Seleccione" options={listaEventoUsuarioCombo!==null?listaEventoUsuarioCombo:[]}
                        /> 
                    </div>
                </h2>  
                <div className='pasoNegociacion content-nego-caja'>
                    <div className="tableContent">
                        <div className="innerShadow3 negociacion">
                            <div className="titleCont">      
                                <h3>RENTA VARIABLE</h3>
                                <div className="titleRight">
                                    <h3>COMPRAR</h3>
                                    <h3>VENDER</h3>
                                </div>
                            </div> 
                            <div className="pasoNegRV tableContainer">  
                                    {
                                        currentTitle!=null?currentTitle.filter(value => value["TipoRentaCod"] === 'RV').map((item, i) => { 
                                            return <ItemNegociacionRV key={i} index={i} item={item} updateValueItemsVenta={updateValueItemsVenta} updateValueItemsCompra={updateValueItemsCompra} evento={props.eventoId} jugada={props.jugada} maxValue={props.maxValue} compra={true} refreshInput={refreshInput}/>
                                        }):""
                                    }                             
                            </div>
                            
                            <div className="dividerLine"></div>
                            
                            <div className="titleCont">      
                                <h3>RENTA FIJA</h3>
                                <div className="titleRight">
                                    <h3>COMPRAR</h3>            
                                </div>
                            </div> 
                            <div className='pasoNegRF'>
                            {
                                currentTitle!=null?currentTitle.filter(value => value["TipoRentaCod"] === 'RF').map((item, i) => { 
                                    return <ItemNegociacionRF key={i} index={i} item={item} evento={props.eventoId} jugada={props.jugada} compra={true} updateValueItemsCompra={updateValueItemsCompra} refreshInput={refreshInput}/>
                                }):""
                            }                          
                            </div>     
                        </div>
                    </div>
                </div>
                <div className="btnContainer"><div className="btnEnviar ayudaGuardarOrden" onClick={btnEnviarNegociacion}>Enviar</div><div className="btnCancelar" onClick={btnNegociacionCancelar}>Cerrar</div></div>
            </div>
        </div>
    </div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(CajaNegociacion);