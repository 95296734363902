import {
  GET_CLIENT_PARAMETERS,
  SET_MESSAGE,
  PARAMETERS_FAIL,
  UPDATE_EVENT_SERVICE,
  UPDATE_EVENT_SERVICE_FAIL,
  GET_EVENT_LIST_SERVICE,
  GET_EVENT_LIST_SERVICE_FAIL,    
  UPDATE_EVENT_ALL_SERVICE,
  UPDATE_EVENT_ALL_SERVICE_FAIL,
  UPDATE_EVENT_ALL_SERVICE_INIT,
  UPDATE_STATUS_BY_KEY_EVENT,
  DELETE_EVENT_ALL_SERVICE,
  DELETE_EVENT_ALL_SERVICE_FAIL,
  GET_USER_EVENT,
  GET_USER_EVENT_FAIL,
  UPDATE_USER_EVENT_GESTION,
  UPDATE_USER_EVENT_GESTION_FAIL,
  DELETE_USER_EVENT,
  DELETE_USER_EVENT_FAIL,
  GET_USER_ALL_EVENT_SIN_ASIG,
  GET_USER_ALL_EVENT_SIN_ASIG_FAIL,
  SEND_INVITATION_BY_USER,
  SEND_INVITATION_BY_USER_FAIL,
  SEND_INVITATION_BY_USER_INIT,
  DELETE_HELP_EVENT,
  DELETE_HELP_EVENT_FAIL,
  UPDATE_HELP_EVENT,
  UPDATE_HELP_EVENT_FAIL,
  GET_HELP_EVENT,
  GET_HELP_EVENT_FAIL,
  PROCESS_FILE_PLAYERS,
  PROCESS_FILE_PLAYERS_FAIL,
  PROCESS_FILE_PLAYERS_INI,
  GET_USER_EVENT_INI,
  HELP_EVENT_INI,
  SELECT_USER_EVENT,
  SELECT_USER_EVENT_FAIL,
  DELETE_USERS_FOR_EVENT,
  DELETE_USERS_FOR_EVENT_FAIL,
  GET_USER_EVENT_COMBO,
  GET_USER_EVENT_FAIL_COMBO,
  UPDATE_NOTICIA_ESP_EVENT,
  UPDATE_NOTICIA_ESP_EVENT_FAIL,
  NOTICE_ESP_EVENT_INI,
  DELETE_NOTICIA_ESP_EVENT,
  DELETE_NOTICIA_ESP_EVENT_FAIL,
  GET_NOTICE_ESP_EVENT,
  GET_NOTICE_ESP_EVENT_FAIL,
} from "../actions/types";

const listParams = ( localStorage.getItem("parameters")!==null && localStorage.getItem("parameters")!=="")?JSON.parse(localStorage.getItem("parameters")):[];

const initialState = {
  listParams : listParams,        
  listaEventos:null,
  listaEventosCount:0,
  listaEventoAyuda:null,
  listaEventosAyudaCount:0,					   
  mostrarEventoError: false,
  iniciaEventoAccion: false,
  listaUsuarioPorEvento:null,
  listaUsuariosSinAsig:null,
  loadingSendNotification: false,
  loadEventosList: false,
  loadingUploadFile: false,
  loadingUserEvtList:false,
  ayudaEventoLoading:false,
  listaEventoUsuarioCombo:null,
  listaEventoNoticiaEsp:null,
  listaEventoNoticiaEspCount:0,
  loadingNoticiaEsp: false,
}

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_STATUS_BY_KEY_EVENT:
        switch(payload.key){
          case 'mostrarEventoError':
            return {
              ...state,
              mostrarEventoError:payload.value,                
            };
          case 'iniciaEventoAccion':
            return {
              ...state,
              iniciaEventoAccion:payload.value,                
            };
          case 'listaUsuarioPorEvento':
            return {
              ...state,
              listaUsuarioPorEvento:payload.value,                
            };
			    case 'listaEventoAyuda':
            return {
              ...state,
              listaEventoAyuda:payload.value,                
            };
          case 'listaEventoNoticiaEsp':
            return {
              ...state,
              listaEventoNoticiaEsp:payload.value,                
            };
        }
        break
      case GET_CLIENT_PARAMETERS:
          return {
              ...state,
              listParams: payload.data                
            };
      case PARAMETERS_FAIL: 
        return {
          ...state,
          listEventos: [],
        };        
      case SEND_INVITATION_BY_USER:
        return {
            ...state,
            loadingSendNotification:false
          };
      case SEND_INVITATION_BY_USER_FAIL:
        return {
            ...state,
            loadingSendNotification:false
          };
      case SEND_INVITATION_BY_USER_INIT:
        return {
          ...state,
          loadingSendNotification:true
        };
      case PARAMETERS_FAIL: 
        return {
          ...state,
          listEventos: [],
        };
        case UPDATE_EVENT_SERVICE:
          return {
              ...state,             
            };
      case UPDATE_EVENT_SERVICE_FAIL: 
        return {
          ...state,
        };
        case GET_EVENT_LIST_SERVICE:            
          return {
              ...state,             
              listaEventos: payload.data[0],
              listaEventosCount: payload.data[1],
              loadEventosList: false
            };
      case GET_EVENT_LIST_SERVICE_FAIL: 
        return {
          ...state,
          loadEventosList: false
        };              
         case  UPDATE_EVENT_ALL_SERVICE:
          return {
              ...state,   
              mostrarEventoError: false,
              iniciaEventoAccion: false,        
              loadEventosList: false
            };
      case  UPDATE_EVENT_ALL_SERVICE_FAIL: 
        return {
          ...state,
          mostrarEventoError: true,
          iniciaEventoAccion: false,
          loadEventosList: false
        };
        case  DELETE_EVENT_ALL_SERVICE:
          return {
              ...state,   
              mostrarEventoError: false,
              iniciaEventoAccion: false,        
            };
      case  DELETE_EVENT_ALL_SERVICE_FAIL: 
        return {
          ...state,
          mostrarEventoError: true,
          iniciaEventoAccion: false,
        };
        case GET_USER_EVENT:            
        return {
            ...state,             
            listaUsuarioPorEvento: payload.data,
            loadingUserEvtList:false,
			loadEventosList: false
        };
        case GET_USER_EVENT_FAIL: 
        return {
            ...state,
            loadingUserEvtList:false,
			loadEventosList: false			
        }; 
        case UPDATE_USER_EVENT_GESTION:
          return {
              ...state,             
			  loadingUserEvtList:false,
			  loadEventosList: false
            };
      case UPDATE_USER_EVENT_GESTION_FAIL: 
        return {
          ...state,
		  loadingUserEvtList:false,
		  loadEventosList: false
        };                 
         case GET_USER_ALL_EVENT_SIN_ASIG:            
        return {
            ...state,             
            listaUsuariosSinAsig: payload.data,
        };
        case GET_USER_ALL_EVENT_SIN_ASIG_FAIL: 
        return {
            ...state,
        }; 
        case    DELETE_USER_EVENT:
          return {
              ...state,   
              mostrarEventoError: false,
              iniciaEventoAccion: false, 
			  loadingUserEvtList:false,
			  loadEventosList: false
            };
      case  DELETE_USER_EVENT_FAIL: 
        return {
          ...state,
          mostrarEventoError: true,
          iniciaEventoAccion: false,
        };
	  case DELETE_HELP_EVENT:
          return {
              ...state,   
              mostrarEventoError: false,
              iniciaEventoAccion: false, 
              ayudaEventoLoading:false       
            };
        case  DELETE_HELP_EVENT_FAIL: 
        return {
          ...state,
          mostrarEventoError: true,
          iniciaEventoAccion: false,
          ayudaEventoLoading:false
        };
        case GET_HELP_EVENT:            
        return {
            ...state,             
            listaEventoAyuda: payload.data[0],
            listaEventosAyudaCount: payload.data[1],
            ayudaEventoLoading:false
          };
        case GET_HELP_EVENT_FAIL: 
        return {
          ...state,
          ayudaEventoLoading:false
        }; 
        case UPDATE_HELP_EVENT:
          return {
              ...state,    
              ayudaEventoLoading:false         
            };
      case UPDATE_HELP_EVENT_FAIL: 
        return {
          ...state,
          ayudaEventoLoading:false
        };      
      case UPDATE_EVENT_ALL_SERVICE_INIT:
        return {
          ...state,
          loadEventosList: true
        }; 
      case PROCESS_FILE_PLAYERS:
        return {
          ...state,
          loadingUploadFile: false
        }; 
      case PROCESS_FILE_PLAYERS_FAIL:
        return {
          ...state,
          loadingUploadFile: false
        }; 
      case PROCESS_FILE_PLAYERS_INI:
        return {
          ...state,
          loadingUploadFile: true
        };   
      case GET_USER_EVENT_INI:
        return {
          ...state,
          loadingUserEvtList: true,
        };   
      case HELP_EVENT_INI: 
        return {
          ...state,
          ayudaEventoLoading:true
        };          
	  case SELECT_USER_EVENT : 
        return {
          ...state,
          
        };    
      case SELECT_USER_EVENT_FAIL : 
        return {
          ...state,
          
        }; 
        case DELETE_USERS_FOR_EVENT: 
        return {
          ...state,
          
        };    
        case DELETE_USERS_FOR_EVENT_FAIL : 
        return {
          ...state,
          
        }; 
        case GET_USER_EVENT_FAIL_COMBO : 
        return {
          ...state,
          
        };
        case GET_USER_EVENT_COMBO:            
        return {
            ...state,             
            listaEventoUsuarioCombo: payload.data
          };
        case NOTICE_ESP_EVENT_INI: 
        return {
          ...state,
          loadingNoticiaEsp:true
        };
        case GET_NOTICE_ESP_EVENT_FAIL: 
        return {
          ...state,
          loadingNoticiaEsp:false
        };  
        case UPDATE_NOTICIA_ESP_EVENT: 
        return {
          ...state,
        };    
        case UPDATE_NOTICIA_ESP_EVENT_FAIL : 
        return {
          ...state,
          loadingNoticiaEsp:false
        }; 
        case DELETE_NOTICIA_ESP_EVENT: 
        return {
          ...state,
        };    
        case DELETE_NOTICIA_ESP_EVENT_FAIL : 
        return {
          ...state,
          loadingNoticiaEsp:false
        }; 
        case GET_NOTICE_ESP_EVENT:            
        return {
            ...state,             
            listaEventoNoticiaEsp: payload.data[0],
            listaEventoNoticiaEspCount: payload.data[1],
            loadingNoticiaEsp:false
          };
      default:
          return state;
  }
}


