import React, { useState, useEffect,useRef  } from "react";

const VentanaChat = (props) => {    
    
    let [textoMensaje, setTextoMensaje] = useState("");
    let [textochat, setTextochat] = useState("");
    const messagesEndRef = useRef(null)

    function handleKeyPress(event) {
        if(event.key === 'Enter'){
            props.sendMessageChat(props.enableChat===true?props.userChatId:-1000,props.enableChat===true?"rueda: " + textoMensaje:props.userNameChat  + ": " + textoMensaje)
            setTextoMensaje("")                        
        }
      }
    function closeChat() {
        props.closeChat()
    }
    function showMessage(messageList){
        if(messageList===undefined || messageList===null || messageList[(props.rolname==='ROLE_ADMIN' || props.rolname==='ROLE_RUEDA')?props.userChatId:"-1000"]===undefined) return ''
        let strMsg = ''

        messageList[(props.rolname==='ROLE_ADMIN' || props.rolname==='ROLE_RUEDA')?props.userChatId:"-1000"].map((item, i) => { 
            let msgIt = JSON.parse(item['msg']);
            if(item['tipo']=='I')                
                strMsg = strMsg + '<div class="contenedorOrigen"><div class="text-chat-origen">' + msgIt['MSG'] + '</div></div>'
            else
                strMsg = strMsg + '<div class="contenedorDestino"><div class="text-chat-destino">' + msgIt['MSG'] + '</div></div>'
        })
        return strMsg
    }
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
      }
    useEffect(() => {
        scrollToBottom()
      }, [props.listMessage]);
    return (
        <div className="overlay fade-in animationShowChat">
        <div className='chatventana'> 
                <div className="customBoxAviso">
                    <div className="innerShadow-chat">
                        <div className="chatTitle">CHAT - {props.enableChat===true?props.userNameChat.toUpperCase():"DIRECTOR DE RUEDA"}<div className='style-cerrar-chat' onClick={closeChat}><i className="fa fa-times-circle" aria-hidden="true"></i></div></div>
                        <div ref={messagesEndRef} className='contenidoChat'>
                            {
                                 props.listMessage[(props.rolname==='ROLE_ADMIN' || props.rolname==='ROLE_RUEDA')?props.userChatId:"-1000"]!==undefined?props.listMessage[(props.rolname==='ROLE_ADMIN' || props.rolname==='ROLE_RUEDA')?props.userChatId:"-1000"].map((item, i) => {
                                    let msgIt = JSON.parse(item['msg']);
                                    if(item['tipo']=='I')                
                                        return <div className="contenedorOrigen"><div className="text-chat-origen">{msgIt['MSG'] }</div></div>
                                    else
                                        return <div className="contenedorDestino"><div className="text-chat-destino">{msgIt['MSG'] }</div></div>
                                }):""
                            }
                        </div>                       
                        <div className='escribirchat'>
                            <input className='buttonChat' value={textoMensaje} type='text' onKeyPress={handleKeyPress} onChange={(e) => {
                                    setTextoMensaje(e.target.value);
                            }}/>
                            <button><i className="fas fa-paper-plane"></i></button>
                        </div>                       
                    </div> 
                    
                </div>
            </div>
        </div>
    );
}
export default VentanaChat;