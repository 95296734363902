import React, { useState, useRef ,Component} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'; 
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share'; 
import { Redirect } from 'react-router-dom';

const CompartirJuego = (props) => {
    
       
      
  return (
    <div>
        <FacebookShareButton url={props.url} quote={props.titulo+"-"+props.resumen}>  
          <FacebookIcon round size={32} />  
        </FacebookShareButton>  
        <TwitterShareButton url={props.url} title={props.titulo+"-"+props.resumen}>
          <TwitterIcon round size={32} />  
        </TwitterShareButton> 
    </div>    
  );
};

export default CompartirJuego;
