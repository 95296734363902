import React, { useState, useEffect } from "react";
import { connect,useDispatch,useSelector } from "react-redux";

import {getMax,formatNumber,getTotalAccion,formatDateMont,enableFuncionalityForRole} from "./general/Util"
import ItemPortafolioRV from './juego/ItemPortafolioRV';
import { getEstadoCuentaPortafolio} from "../actions/juego"

const Portafoliofj = (props) => {    
    const { estadoCuentaCliente: currentTitle,loadEstadoCuenta:loadEstadoCuenta,estadoCuentaClienteEfec:estadoCuentaClienteEfec,eventoFinalizado } = useSelector((state) => state.juego);
    const dispatch = useDispatch();

	const { user: currentUser } = useSelector((state) => state.auth);  
    let [enableSend, setEnableSend] = useState(currentUser!==null?enableFuncionalityForRole(currentUser["role"],"ADM"):false);
    
    if(currentTitle===null){        
        dispatch(getEstadoCuentaPortafolio(eventoFinalizado["EventoId"],currentUser["userId"]))
        .then(() => {            
        })
        .catch(() => {
        });
  }  
    return (
        <div>
            <div className="innerShadow3 customScroll ventanaAyudaPortafolio finjuego">
                    <h2 className='textcenter'>EL JUEGO DE LA BOLSA - INVESTOR III</h2>
                    <div className='ventana-portafolio'>
                    {/*RESUMEN PORTAFOLIO - {props.namePortafolio.to UpperCase()}*/}
                    <div className="titleCont">       
                        <div ><div className='label-port1 position'>PUESTO:</div><span className='position'>#{props.index}</span></div>
                        <div><div className='label-port1 fondoJuegoPos'>REPORTE DE JUGADOR:</div><span className='fondoJuegoPos'>{props.namePortafolio!==null?props.namePortafolio.toUpperCase():""}</span></div>                                              
                        <div><div className='label-port1-rigth'>Reporte de Portafolio y Dinero</div></div>
                    </div>                     
                    
                    <div className={["titleCont",props.posicion!=null && props.posicion.filter(value => value["TipoRentaCod"] === 'RV').length>0?"":"displaynone"].join(' ')}>
                        <div className="titleCont">
                            <div className="dividerLine"></div>
                            <h3>RENTA VARIABLE</h3>    
                        </div>                                            
                    </div> 
                    <div className={["tableContainer",props.posicion!=null && props.posicion.filter(value => value["TipoRentaCod"] === 'RV').length>0?"":"displaynone"].join(' ')}>  
                        
                        {
                            props.posicion!=null?props.posicion.filter(value => value["TipoRentaCod"] === 'RV').map((item, i) => { 
                                return <ItemPortafolioRV key={i} index={i} item={item} evento={props.eventoId} jugada={props.jugada} maxValue={getMax(props.posicion.filter(value => value["TipoRentaCod"] === 'RV'),"SaldoTitulo")} compra={false}/>
                            }):""
                        }                             
                        <div className="rowCustom">
                            <div className="fiveColPortafolioTotal">TOTAL DINERO EN ACCIONES</div>
                            <div className="fourColPortafolioTotal">${formatNumber(getTotalAccion(props.posicion!==null?props.posicion.filter(value => value["TipoRentaCod"] === 'RV'):[]))} </div>
                            
                        </div> 
                    </div>                    

                    <div className="dividerLine"></div>
                    <div className="tableContainer">
                        <div className="rowCustom">
                            <div className="fiveColPortafolioTotal">SALDO EN EFECTIVO</div>
                            <div className="fourColPortafolioTotal">${formatNumber(props.saldoEfectivo)}</div>
                        </div>
                    </div> 
                    <div className="dividerLine"></div>
                    <div className="tableContainer">
                        <div className="rowCustom">
                            <div className="fiveColPortafolioTotal font-fin-juego">TOTAL</div>
                            <div className="fourColPortafolioTotal font-fin-juego">${formatNumber(props.saldoEfectivo + getTotalAccion(props.posicion!==null?props.posicion.filter(value => value["TipoRentaCod"] === 'RV'):[]))}</div>
                        </div>
                    </div>                     
                </div>
            </div>                    
        </div>
    );
}
export default Portafoliofj;