import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {NotificationManager} from "react-notifications";
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {enableFuncionalityForRole} from "./Util";
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/amber-dark.css'
import {getEventos} from "../../actions/evento"
import {deleteEvento} from "../../actions/evento"
import {getItemsCatalogo,exeCargarTitulo} from "../../actions/administracion"
import {formatDateTime} from "./Util"
import EditarEvento from "./EditarEvento"
import CrearEvento from "./CrearEvento"
import TitulosPorEvento from "./TitulosPorEvento"
import GestionEventoUsuario from "./GestionEventoUsuario"
import GestionEventoAyuda from "./GestionEventoAyuda"
import GestionEventoNoticiaEsp from "./GestionEventoNoticiaEsp"
import "../../config";
import axios from "axios";

import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

const AdministracionEvento = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { listEventos: currentEventos,setStatusByKey:setStatusByKey} = useSelector((state) => state.juego);
  const { loadEventosList} = useSelector((state) => state.evento);
  
  const { listaCatalogos,cargarTitulosLoading,titulosLoading,ayudaEventoLoading} = useSelector((state) => state.administracion);
  const [activeIndex, setActiveIndex] = useState(2)
  //envio parametro id para obtener titulosPorEvento
  const [eventoSeleccionadoId, setEventoSeleccionadoId] = useState(-1)
  //recibo datos tituloPorEvento
  const [eventoSeleccionado, setEventoSeleccionado] = useState(null)
  let [createEvent, setCreateEvent] = useState(false)
  let [editEvent, setEditEvent] = useState("")
  let [titulosPorEvent, setTitulosPorEvent] = useState("")
  let [gestionEventoUsuarioOpen, setGestionEventoUsuarioOpen] = useState("")
  let [gestionEventoAyudaOpen, setGestionEventoAyudaOpen] = useState("")
  let [gestionEventoNotEspOpen, setGestionEventoNotEspOpen] = useState("")
  let [cargarEventos,setCargarEventos] = useState(false)  
  let [generarReporteFinal,setGenerarReporteFinal] = useState(false)    


  const closeNewEventClick = () =>{
      setEditEvent("")
  }
  const closeTitulosPorEventoClick = () =>{
      setTitulosPorEvent("")
  }
  const closeCreateEventClick = () =>{  
      setCreateEvent(false)
      setEventoSeleccionado(null)
  }

  const crearEvento = () =>{
      setEventoSeleccionado(null)
      setEventoSeleccionadoId(-1)
      setEditEvent("EDIT")
  }
  const closeGestionUsuario = ()=>{
    setGestionEventoUsuarioOpen ("")
  } 
  const closeGestionAyuda = ()=>{
    setGestionEventoAyudaOpen("")
  }
  const closeGestionNoticiaEsp = () =>{
    setGestionEventoNotEspOpen("")
  }
    function actualizar(){
      dispatch(getEventos("0","","","DESC","FechaInicio"))
      .then(() => {
      })
      .catch(() => {
      });
    }
    const { listaEventos,listaEventosCount } = useSelector((state) => state.evento);
    const gridStyle = { minHeight: '300px',height:'calc(100% - 50px)', margin: 20 }
  let columns= [
      /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
      { name: 'NombreInstitucion',header: 'Institución ', defaultFlex: 1, xdefaultLocked: 'start',minWidth: 180, },
      { name: 'NombreEvento',header: 'Evento', defaultFlex: 1, xdefaultLocked: 'start',minWidth: 350, },
      { name: 'TipoEventoNombre',header: 'Tipo Evento', defaultFlex: 1, xdefaultLocked: 'end',minWidth: 180},
      { name: 'ModalidadNombre',header: 'Modalidad', defaultFlex: 1, xdefaultLocked: 'end',minWidth: 180 },
      { name: 'TipoDuracionNombre',header: 'Tipo Duración', defaultFlex: 1, xdefaultLocked: 'end',minWidth: 180 },      
      { name: 'Duracion',header: 'Duración', defaultFlex: 1, xdefaultLocked: 'end',minWidth: 180 },      
      { name: 'NivelNombre',header: 'Nivel', defaultFlex: 1, xdefaultLocked: 'end',minWidth: 180 },
      { name: 'FechaInicio',header: 'Fecha Inicio', defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => formatDateTime(data["FechaInicio"]),minWidth: 180 },
      
    ]
    let [configEvento] = useState(currentUser!==null?enableFuncionalityForRole(currentUser["role"],"CONF_EVENTO"):false);  
  let history = useHistory();
  const dispatch = useDispatch();

  //(listaEventos===undefined || listaEventos===null) && 
  if(cargarEventos===false){
    setCargarEventos(true)
    dispatch(getEventos("0","","","ASC",""))
    .then(() => {
    })
    .catch(() => {
    });
  }
  if(listaCatalogos===undefined || listaCatalogos===null){
    dispatch(getItemsCatalogo("TIPO_JUEGO,MODALIDAD,TIPO_DUR,NIVELES,ESTADO_JUEGO"))
    .then(() => {
    })
    .catch(() => {
    });
  }
  function abrirEditarEvento(){
    
  }
  const options = [
    'one', 'two', 'three'
  ];
  const defaultOption = options[0];

  let [eventoUsuarioFilter, setEventoUsuarioFilter] = useState("");   
  let [gridInfor, setGridInfor] = useState([listaEventos!==undefined && listaEventos!==null?listaEventos:[]]);  

  if(listaEventos!==undefined && listaEventos!==null && listaEventos!=gridInfor && eventoUsuarioFilter==="")
	setGridInfor(listaEventos)
  
  const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [          
          {
            label: 'Ayuda evento',
            onClick:()=>{                                          
              setEventoSeleccionadoId(rowProps.data["EventoId"])  
              setGestionEventoAyudaOpen("GESTION_AYUDA")
            }
          },
          {
            label: 'Asignar títulos',
            onClick:()=>{   
                confirmAlert({
                    title: 'Carga de títulos',
                    message: 'La información anterior se va eliminar; ¿Confirma la carga de títulos?',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{
                            dispatch(exeCargarTitulo(rowProps.data["EventoId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {}
                      }
                    ]
                  });
              } 
            },          
          {
            label: 'Editar evento',
            onClick:()=>{       
                  setEventoSeleccionado(rowProps.data)
                  setEventoSeleccionadoId(rowProps.data["EventoId"])                  
                  setEditEvent("EDIT")
            }
          },          
          {
            label: 'Eliminar evento',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar evento',
                    message: 'Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{
                          dispatch(deleteEvento(rowProps.data["EventoId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        //actualizar();
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                          
                        } 
                      }
                    ]
                  });
            },
      },		  
      {
        label: 'Noticias especiales',
            onClick:()=>{                                                      
              setEventoSeleccionadoId(rowProps.data["EventoId"])                  
              setGestionEventoNotEspOpen("GESTION_NOTESP")
              
            }        
      },
          {
            label: 'Títulos evento',
            onClick:()=>{                                                      
              setEventoSeleccionadoId(rowProps.data["EventoId"])                  
              setTitulosPorEvent("DATA")
            }
          },			
          {
            label: 'Usuarios evento',
            onClick:()=>{     
             setEventoSeleccionado(rowProps.data)                                
              setEventoSeleccionadoId(rowProps.data["EventoId"])  
              setGestionEventoUsuarioOpen("GESTION_USUARIOS")
            }
          },
          {
            label: 'Reporte Final',
            onClick:()=>{                   
              setGenerarReporteFinal(true)
              const params = {"strEventoId":rowProps.data["EventoId"]}
              axios.post(global.config.URL_SERVICE + "ReporteRankingEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
              .then((response) => {
                setGenerarReporteFinal(false)
                let itemR = response["data"].split("\\\"").join("\"");        
                if(itemR!=="" && itemR!=="-1" && itemR!==-1){
                  const link = document.createElement('a');
                  link.href = global.config.URL_UPLOAD + itemR;
                  link.target = '_blank'
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }else{
                  NotificationManager.error(
                    "Error al generar el archvio",
                    "Sistema",
                    3000,
                    () => {},
                    4
                  );
                }     
              });
            }
          },
      ]
    }, [])  

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  function filterUsuariosPorEvento(nego,eventoUsuarioFilter){
    //value => NombreInstitucion
    setGridInfor(nego.filter(function(item) {
      if (item["NombreEvento"].toLowerCase().includes(eventoUsuarioFilter.toLocaleLowerCase()) || item["NombreInstitucion"].toLowerCase().includes(eventoUsuarioFilter.toLocaleLowerCase()) ||
       item["TipoEventoNombre"].toLowerCase().includes(eventoUsuarioFilter.toLocaleLowerCase()) ||  item["ModalidadNombre"].toLowerCase().includes(eventoUsuarioFilter.toLocaleLowerCase()) ||
       item["TipoDuracionNombre"].toLowerCase().includes(eventoUsuarioFilter.toLocaleLowerCase()) ||  item["NivelNombre"].toLowerCase().includes(eventoUsuarioFilter.toLocaleLowerCase()) )
          return true;
      return false;
    }))
 }
  function configurarEvento() {
     let valid = true
     
     if(!valid){
        return
    }

      
        
  }
  function closeWindow() {
    setCargarEventos(false)
    props.closeNewEventClick();
  }

  return (
    <div
      className={[
        "windowContentInner",
        "fade-in",
        props.openNewEvent ? "animationShow" : "animationHide",
      ].join(" ")}
    >      
      <div className={['loading_cont',(loadEventosList!==true && cargarTitulosLoading!==true && titulosLoading!==true && ayudaEventoLoading!==true && generarReporteFinal!==true)?"displaynone":""].join(' ')}><div className='single6'></div></div>           
      {editEvent==="EDIT" ? <EditarEvento closeNewEventClick={closeNewEventClick} eventoSeleccionado={eventoSeleccionado}/>  : ""}
      <div className="borderContent ventanaGeneral">
        <div className="innerShadow-height-100">
          <h2>
            Administración de eventos
            <div className="floatright">
                <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                    <MenuItem onClick={crearEvento}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                </Menu>
            </div>
          </h2> 
          <div className="titleTable-search"><div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={eventoUsuarioFilter} onChange={(e) => {
              setEventoUsuarioFilter(e.target.value);
              filterUsuariosPorEvento(listaEventos!==undefined && listaEventos!==null?listaEventos:[],e.target.value)
            }}/><i className="fas fa-search"></i></div></div></div>           
          {listaEventos!==null?
           <DataGrid
           idProperty="EventoId"
           style={gridStyle}
           theme="default-light"                    
           columns={columns}
           enableSelection={true}
           selection={{ mode: 'single' }}                    
           dataSource={gridInfor}
           renderRowContextMenu={renderRowContextMenu}
           theme="amber-dark" 
       />:""}

          <div className="dividerLine"></div>
          <div className="textCenter">
            <div className="btnCancelar" onClick={closeWindow}>
              SALIR
            </div>
          </div>
        </div>
      </div>
      
     {titulosPorEvent==="DATA" ? <TitulosPorEvento closeTitulosPorEventoClick={closeTitulosPorEventoClick} eventoSeleccionadoId={eventoSeleccionadoId}/>:""}
     {gestionEventoUsuarioOpen==="GESTION_USUARIOS" ?  <GestionEventoUsuario  closeGestionUsuario={closeGestionUsuario} eventoSeleccionadoId={eventoSeleccionadoId} eventoSeleccionado={eventoSeleccionado}/>:""}     
	   {gestionEventoAyudaOpen==="GESTION_AYUDA" ?  <GestionEventoAyuda  closeGestionAyuda={closeGestionAyuda} eventoSeleccionadoId={eventoSeleccionadoId}/>:""}     
     {gestionEventoNotEspOpen==="GESTION_NOTESP" ?  <GestionEventoNoticiaEsp  closeGestionNoticiaEsp={closeGestionNoticiaEsp} eventoSeleccionadoId={eventoSeleccionadoId}/>:""}          
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(AdministracionEvento);
