import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {NotificationManager} from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import NumberFormat from 'react-number-format';
import {updateEvento} from "../../actions/evento";
import {getEventos} from "../../actions/evento";
import {getItemsCatalogo} from "../../actions/administracion";
import UploadFile from './UploadFile';
import "../../config";
import axios from "axios";

import {   
  UPDATE_EVENT_ALL_SERVICE,
  UPDATE_EVENT_ALL_SERVICE_FAIL,
  UPDATE_EVENT_ALL_SERVICE_INIT,
  
} from "../../actions/types";

const EditarEvento = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { listaCatalogos} = useSelector((state) => state.administracion);

  let [errors] = useState({"tiempoNoticia":""},{"tiempoJugada":""},{"tiempoVarPrecios":""},{"tiempoRecSalados":""},{"ultimaJugada":""},{"tiempoRecSaldos":""},
  {"tiempoVarPrecios":""},{"tiempoSigJugada":""},{"montoJugador":""},{"duracion":""},{"nombreInstitucion":""},{"nombreEvento":""},{"logo":""},
  {"tipoEventoNombreSelect":""},{"modalidadNombreSelect":""},{"tipoDuracionNombreSelect":""},{"nivelNombreSelect":""},{"estadoActualIdSelect":""});
  //crear las varialbes por cada item
  let [nombreInstitucion, setNombreInstitucion] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["NombreInstitucion"]:"")
  let [nombreEvento, setNombreEvento] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["NombreEvento"]:"")
  let [logo, setLogo] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["Logo"]:"")
  let [duracion, setDuracion] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["Duracion"].toString():"0")
 // let [juegaRentaFija, setJuegaRentaFija] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["JuegaRentaFija"]:"")
  //let [estado, setEstado] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["Estado"]:"")
  let [tiempoNoticia, setTiempoNoticia] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["TiempoNoticia"].toString():"0")
  let [montoJugador, setMontoJugador] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["MontoJugador"].toString():"0")
  let [tiempoJugada, setTiempoJugada] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["TiempoJugada"].toString():"0")
  let [tiempoSigJugada, setTiempoSigJugada] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["TiempoSiguienteJugada"].toString():"0")
  let [tiempoVarPrecios, setTiempoVarPercios] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["TiempoVarPrecios"].toString():"0")
  let [tiempoRecSaldos, setTiempoRecSaldos] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["TiempoRecSalados"].toString():"0")
  let [ultimaJugada, setUltimaJugada] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["UltimaJugada"].toString():"1")

  let [uploadId, setUploadId] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["UploadId"].toString():"0")
  let [fileNombre, setFileNombre] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["fileNombre"].toString():"0")
  let [fileNombreUrl, setFileNombreUrl] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["fileNombreUrl"].toString():"0")
  //obtener elementos seleccionados  
  let [tipoEventoNombreSelect, setTipoEventoNombreSelect] = useState({"value":props.eventoSeleccionado!==null?props.eventoSeleccionado["TipoEvento"]:"-1","label":props.eventoSeleccionado!==null?props.eventoSeleccionado["TipoEventoNombre"]:""});
  let [modalidadNombreSelect, setModalidadNombreSelect] = useState({"value":props.eventoSeleccionado!==null?props.eventoSeleccionado["ModalidadId"]:"-1","label":props.eventoSeleccionado!==null?props.eventoSeleccionado["ModalidadNombre"]:""});
  let [tipoDuracionNombreSelect, setTipoDuracionNombreSelect] = useState({"value":props.eventoSeleccionado!==null?props.eventoSeleccionado["TipoDuracion"]:"-1","label":props.eventoSeleccionado!==null?props.eventoSeleccionado["TipoDuracionNombre"]:""});
  let [nivelNombreSelect, setNivelNombreSelect] = useState({"value":props.eventoSeleccionado!==null?props.eventoSeleccionado["NivelId"]:"1","label":props.eventoSeleccionado!==null?props.eventoSeleccionado["NivelNombre"]:"Básico"});
  let [estadoActualIdSelect, setEstadoActualIdSelect] = useState({"value":props.eventoSeleccionado!==null?props.eventoSeleccionado["EstadoActId"]:"17","label":props.eventoSeleccionado!==null?props.eventoSeleccionado["EstadoActNombre"]:"Activo"});
  let [validacionTiemposVirtual,setValidacionTiemposVirtual] = useState(null);
  let [loadCatalog,setLoadCatalog] = useState(true);
  let [loadModalidad,setLoadModalidad]= useState(false);

  //PSA Ojo cambiar a codigo
  if(modalidadNombreSelect !==null && modalidadNombreSelect["value"]===4 && loadModalidad ===false){
    setLoadModalidad(true)
    //setValidacionTiemposVirtual(4)
  }
  
// select estado
let cambioEstado=null;
if(props.eventoSeleccionado!==null && props.eventoSeleccionado["Estado"] == "I"){
     cambioEstado ={value:'I', label:"Inactivo"}
}
if(props.eventoSeleccionado!==null && props.eventoSeleccionado["Estado"] == "A"){
     cambioEstado ={value:'A', label:"Activo"}
}
if(props.eventoSeleccionado!==null && props.eventoSeleccionado["Estado"] == "D"){
     cambioEstado ={value:'D', label:"Desactivado"}
}
if(props.eventoSeleccionado===null){
     cambioEstado ={value:'A', label:"Activo"}
}

  let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
  const optionsEstado = [
    { value: 'I', label: 'Inactivo' },
    { value: 'A', label: 'Activo' },
  ]
//checkbox notificacion especial
    const [checked, setChecked] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["NotificaEspecial"]:"");
    const handleChecked = ({ target }) => {
        setChecked(target.checked);
    }
//checkbox juega renta fija
    const [checkJrf, setCheckJrf] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["JuegaRentaFija"]:true);
    const handleCheckedJrf = ({ target }) => {
        setCheckJrf(target.checked);
    }

    const [forzarCompraRF, setForzarCompraRF] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["ForzarCompraRF"]:true);
    const handleCheckedFCRF = ({ target }) => {
        setForzarCompraRF(target.checked);
    }

  const dispatch = useDispatch();
  function actualizar(){
     dispatch(getEventos("0","","","ASC",""))
    .then(() => {
    })
    .catch(() => {
    });
  }

  let { listaEventos,listaEventosCount } = useSelector((state) => state.evento);


//hora
 //const [startDate, setStartDate] = useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["FechaInicio"]:"");
 const [startDate, setStartDate] =useState(props.eventoSeleccionado!==null?props.eventoSeleccionado["FechaInicio"]:new Date());
    
  
  if(listaEventos===null){
    dispatch(getEventos("0","","","ASC",""))
    .then(() => {
    })
    .catch(() => {
    });
  }
  if((listaCatalogos===undefined || listaCatalogos===null || (listaCatalogos["EMISOR"]===undefined || listaCatalogos["EMISOR"].length===0)) && loadCatalog===true){
	  setLoadCatalog(false)
    dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
    .then(() => {
    })
    .catch(() => { 
    });
  }
  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  function actualizarEvento(){
    
     let valid = true
     if(tiempoNoticia == ""){
       
       errors["tiempoNoticia"] = "ingrese tiempo noticia"
       valid = false
     }
     if(ultimaJugada ==""){
       errors["ultimaJugada"]="Ingrese ultima jugada"
       valid = false
     }
     if(tiempoRecSaldos == ""){
       errors["tiempoRecSaldos"] ="Ingrese tiempo saldos"
       valid = false
     }
     if(tiempoVarPrecios == ""){
      errors["tiempoVarPrecios"] ="Ingrese tiempo precio"
      valid = false
     }
     if(tiempoSigJugada ==""){
       errors["tiempoSigJugada"] = "Ingrese tiempo siguiente jugada"
       valid = false
     }
      if(tiempoJugada ==""){
        errors["tiempoJugada"] = "Ingrese tiempo jugada"
        valid = false
      }
      if(montoJugador==""){
        errors["montoJugador"] = "Ingrese monto jugador"
        valid = false
      }
      if(duracion ==""){
        errors["duracion"] = "Ingrese tiempo de duración"
        valid = false
      }
      if(nombreInstitucion == ""){
        errors["nombreInstitucion"] = "Ingrese institución"
        valid = false
      }
      if(nombreEvento == ""){
        errors["nombreEvento"] = "Ingrese evento"
        valid = false
      }
      /*if(logo == ""){
        errors["logo"]= "Ingrese logo"
        valid = false;
      }*/
      if(tipoEventoNombreSelect["value"]==="-1"){
        errors["tipoEventoNombreSelect"] = "Seleccione un tipo de evento"
        valid = false;
      }      
      if(modalidadNombreSelect["value"]==="-1"){
        errors["modalidadNombreSelect"] = "Seleccione una modalidad"
        valid = false;
      }
      if(tipoDuracionNombreSelect["value"]==="-1"){
        errors["tipoDuracionNombreSelect"] = "Seleccione tipo de duración"
        valid = false;
      }
      if(nivelNombreSelect["value"]==="-1"){
        errors["nivelNombreSelect"] = "Seleccione un nivel"
        valid = false;
      } 
      if(estadoActualIdSelect["value"]==="-1"){
        errors["estadoActualIdSelect"] = "Seleccione Estado actual"
        valid = false;
      } 

    if(!valid){
        return
    }
    
      var params = paramsEvento(props.eventoSeleccionado!==null?props.eventoSeleccionado['EventoId']:"-1",modalidadNombreSelect["value"],tipoEventoNombreSelect["value"],nombreInstitucion,nombreEvento, logo, montoJugador, tipoDuracionNombreSelect["value"], duracion, tiempoNoticia, tiempoJugada, estadoSelect["value"],tiempoSigJugada, tiempoVarPrecios, tiempoRecSaldos, currentUser["userId"],nivelNombreSelect["value"], estadoActualIdSelect["value"], ultimaJugada,checked,startDate,checkJrf,forzarCompraRF)
      dispatch({
        type: UPDATE_EVENT_ALL_SERVICE_INIT,
      });
      axios 
          .post(global.config.URL_SERVICE + "GestionEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);

              switch(result){
                case 0:
                  dispatch(getEventos("0","","","ASC",""));
                  dispatch({
                    type: UPDATE_EVENT_ALL_SERVICE,
                  });        
                  NotificationManager.success(
                    "Evento guardado",
                    "Sistema",
                    3000,
                    () => {},
                    4
                  ); 
                  closeWindow();
                  break
                case -2:
                  dispatch(getEventos("0","","","ASC",""));
                  dispatch({
                    type: UPDATE_EVENT_ALL_SERVICE_FAIL,
                  });
                  NotificationManager.warning(
                      "El número de jugadas es superior al número de noticias",
                      "Sistema",
                      3000,
                      () => {},
                      4
                  );
                  closeWindow();
                  break
                default:
                  dispatch({
                    type: UPDATE_EVENT_ALL_SERVICE_FAIL,
                  });
                  NotificationManager.error(
                      "Error al guardar el evento",
                      "Sistema",
                      3000,
                      () => {},
                      4
                  );
                  break
              }              

          });
      
    //actualizar();    
  }
  function paramsEvento(eventoId,modalidadNombreSelect,tipoEventoNombreSelect,nombreInstitucion,nombreEvento,logo,montoJugador,tipoDuracionNombreSelect,duracion,tiempoNoticia,tiempoJugada,estado,tiempoSiguienteJugada,tiempoVarPrecios,tiempoRecSaldos,usuarioId,nivelNombreSelect,estadoActId,ultimaJugada,notificacionEspecial,startDate,juegaRentaFija,forzarCompraRF){

    return {"strEventoId":eventoId,"strModalidadId":modalidadNombreSelect,"strTipoEvento":tipoEventoNombreSelect,"strNombreInstitucion":nombreInstitucion,"strNombreEvento":nombreEvento,"strLogo":logo,"strFechaInicio":startDate,"strMontoJugador":montoJugador,"strTipoDuracion":tipoDuracionNombreSelect,"strDuracion":duracion,"strTiempoNoticia":tiempoNoticia,"strTiempoJugada":tiempoJugada,"strUsuarioId":usuarioId,"strJuegaRentaFija":juegaRentaFija,"strEstado":estado,"strTiempoSiguienteJugada":tiempoSiguienteJugada,"strTiempoVarPrecios":tiempoVarPrecios,"strTiempoRecSalados":tiempoRecSaldos,"strNivelId":nivelNombreSelect,"strEstadoActId":estadoActId,"strUltimaJugada":ultimaJugada,"strNotificaEspecial":notificacionEspecial,"strForzarCompraRF":forzarCompraRF}
  }  
  function closeWindow() {
    setValidacionTiemposVirtual(null)
    props.closeNewEventClick();
  }
  function reloadDocuments(file){
    const result = JSON.parse(file);
    setLogo(result["UploadId"])
    setUploadId(result["UploadId"])
    setFileNombre(result["fileNombre"])
    setFileNombreUrl(result["fileNombreUrl"])
  }
  
  return (
  <div className="overlay3 fade-in">
    <div className="borderContent ventana-evento-edit">
      <div className="innerShadow3">
        <div id="formulario-estilo">
          <h2>
            Evento <i className="fas fa-cog"></i>
          </h2>
          <div className='vertical-scroll customScroll'>
            <div id="columna-1-formulario"> 
              <div className="row-form">
                <div className="col-30">
                  <label>
                    <i className="fas fa-wrench"></i> <span>Tipo evento:</span>
                  </label>
                </div> 
                <div className="col-70">          
                  <Select  className="input-form" id="tipoEventoNombreCombo"  value={tipoEventoNombreSelect} onChange={(e) => {
                    setTipoEventoNombreSelect(e)
                    setNombreEvento("Juego de Bolsa - " + e["label"] + " - " + nombreInstitucion)
                    errors["tipoEventoNombreSelect"]=""
                  }} placeholder="Seleccione" options={(listaCatalogos!==undefined && listaCatalogos!==null)?listaCatalogos["TIPO_JUEGO"]:[]}/> 
                  {errors["tipoEventoNombreSelect"]!==""?<div className='camporequerido'>{errors["tipoEventoNombreSelect"]}</div>:""}                    
                </div>
              </div>
              <div className="row-form">
                <div className="col-30">
                  <label>
                    <i className="fas fa-edit"></i> <span>Institución:</span>                
                  </label> 
                </div>            
                <div className="col-70">
                  <input
                      type="text"
                      placeholder="Ingresar institución"
                      value={nombreInstitucion}
                      maxLength={1000}
                      className="input-form"
                      onChange={(e) => {
                        setNombreInstitucion(e.target.value);
                        setNombreEvento("Juego de Bolsa - " + tipoEventoNombreSelect["label"] + " - " + e.target.value)
                        errors["nombreInstitucion"] = ""
                      }}
                    ></input>
                  {errors["nombreInstitucion"]!==""?<div className='camporequerido'>{errors["nombreInstitucion"]}</div>:""}
                </div>                
              </div>   
              <div className="row-form"> 
                <div className="col-30">             
                  <label>
                    <i className="fas fa-edit"></i> <span>Evento:</span>
                  </label>
                </div>  
                <div className="col-70">
                  <input
                      type="text"
                      value={nombreEvento}
                      maxLength={1000}
                      placeholder="Ingresar evento"
                      className="input-form"
                      onChange={(e) => {
                        setNombreEvento(e.target.value);
                        errors["nombreEvento"] = ""
                      }}
                  ></input>
                  {errors["nombreEvento"]!==""?<div className='camporequerido'>{errors["nombreEvento"]}</div>:""}
                </div> 
              </div>   
              <div className="row-form"> 
                <div className="col-30">             
                  <label>
                    <i className="fas fa-file-image"></i> <span>Logo:</span>   
                  </label>   
                </div> 
                <div className="col-70">     
                  <UploadFile reloadDocuments={reloadDocuments} uploadId={uploadId} fileNombre={fileNombre} fileNombreUrl={fileNombreUrl}></UploadFile>                  
                  {errors["logo"]!==""?<div className='camporequerido'>{errors["logo"]}</div>:""}
                </div> 
              </div>
              <div className="row-form">
                <div className="col-30">
                  <label >
                    <i className="fas fa-calendar-day"></i> <span>Fecha inicio:</span>
                  </label>  
                </div>  
                <div className="col-70">                         
                  <DatePicker                
                    selected={ Date.parse(startDate)}
                    onChange={date => setStartDate(date)}
                    showTimeSelect
                    showMonthDropdown 
                    showYearDropdown
                    dateFormat="dd/MM/yyyy h:mm aa"
                    timeIntervals={5}
                    className="input-form"
                  />
                </div>
              </div>  
              <div className="row-form">
                <div className="col-30">
                  <label>
                    <i className="fas fa-wrench"></i> <span>Modalidad:</span>
                  </label>               
                </div>  
                <div className="col-70">
                  <Select className="input-form" id="modalidadNombreCombo" value={modalidadNombreSelect} onChange={(e) => {
                    setModalidadNombreSelect(e)                     
                    //setValidacionTiemposVirtual(e.value)                    
                      errors["modalidadNombreSelect"]=""
                  }} placeholder="Seleccione" options={(listaCatalogos!==undefined && listaCatalogos!==null)?listaCatalogos["MODALIDAD"]:[]}/> 
                  {errors["modalidadNombreSelect"]!==""?<div className='camporequerido'>{errors["modalidadNombreSelect"]}</div>:""}                    
                </div>  
              </div>  
              <div className="row-form">
                <div className="col-30">
                  <label>
                    <i className="fas fa-wrench"></i> <span>Tipo duración:</span>
                  </label>       
                </div> 
                <div className="col-70">  
                  <Select className="input-form" id="InstitucionCombo" value={tipoDuracionNombreSelect} onChange={(e) => {
                    setTipoDuracionNombreSelect(e) 
                    errors["tipoDuracionNombreSelect"]=""
                  }} placeholder="Seleccione" options={listaCatalogos!==undefined && listaCatalogos!==null?listaCatalogos["TIPO_DUR"]:[]}/> 
                  {errors["tipoDuracionNombreSelect"]!==""?<div className='camporequerido'>{errors["tipoDuracionNombreSelect"]}</div>:""}
                </div> 
              </div>                   
              <div className="row-form">
                <div className="col-30">
                  <label >
                    <i className="fas fa-clock"></i> <span>Duración:</span>
                  </label>
                </div> 
                <div className="col-70">
                  <NumberFormat className="input-form" value={duracion} maxLength={3} allowNegative={false} thousandSeparator={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    setDuracion(value);           
                    errors["duracion"] = ""      
                  }}/>
                  {errors["duracion"]!==""?<div className='camporequerido'>{errors["duracion"]}</div>:""}
                </div>   
              </div> 
              
              <div className="row-form">  
                <div className="col-30">
                  <label>
                    <i className="fas fa-check-square"></i> <span>Juega renta fija:</span>
                  </label>   
                </div>                         
                <div className="col-70">
                  <Checkbox 
                    right="true" 
                    defaultChecked={checkJrf} 
                    text="Juega renta fija"
                    onChange={handleCheckedJrf}
                  />    
                </div>                    
              </div> 
              <div className="row-form">
                <div className="col-30">
                  <label>
                    <i className="fas fa-bell"></i> <span>Noticia especial:</span>
                  </label>  
                </div> 
                <div className="col-70">                                       
                  <Checkbox 
                    right="true"                     
                    defaultChecked={checked} 
                    text="Notificacion especial"
                    onChange={handleChecked}
                    />       
                </div>                                                 
              </div>
              <div className="row-form">
                <div className="col-30">
                  <label>
                    <i className="fas fa-check-square"></i> <span>Forzar compra renta fija:</span>
                  </label>  
                </div> 
                <div className="col-70">                                       
                  <Checkbox 
                    right="true"                     
                    defaultChecked={forzarCompraRF} 
                    text="Forzar compra RF"
                    onChange={handleCheckedFCRF}
                    />       
                </div>                                                 
              </div>                                                                                                              
            </div>
            <div id="columna-2-formulario">
              <div className="row-form"> 
                <div className="col-30">    
                  <label>
                    <i className="fas fa-wrench"></i> <span>Estado actual del evento:</span>                
                  </label>
                </div>  
                <div className="col-70">                                
                  <Select  className="input-form" id="estadoActualIdSelect"  value={estadoActualIdSelect} onChange={(e) => {
                    setEstadoActualIdSelect(e)                    
                    errors["estadoActualIdSelect"]=""
                  }} placeholder="Seleccione" options={listaCatalogos!==undefined && listaCatalogos!==null?listaCatalogos["ESTADO_JUEGO"]:[]}/>  
                  {errors["estadoActualIdSelect"]!==""?<div className='camporequerido'>{errors["estadoActualIdSelect"]}</div>:""}                                     
                 </div> 
              </div> 
              <div className="row-form">
                <div className="col-30">
                  <label>
                    <i className="fas fa-wrench"></i> <span>Estado:</span>
                  </label>
                </div>     
                <div className="col-70">     
                  <Select  className="input-form" id="estadoSelectCombo"  value={estadoSelect} onChange={(e) => {
                    setEstadoSelect(e)
                  }} placeholder="Seleccione" options={optionsEstado}/>             
                </div>
              </div>   
              
              <div className="row-form">
                <div className="col-30">
                  <label>
                    <i className="fas fa-dollar-sign"></i> <span>Monto jugador:</span>
                  </label> 
                </div> 
                <div className="col-70">
                  <NumberFormat  className="input-form" value={montoJugador} thousandSeparator={false} allowNegative={false} decimalScale={2} fixedDecimalScale={true} decimalSeparator={'.'} prefix={"$"} onValueChange={(values) => {
                      const {formattedValue, value} = values;
                      setMontoJugador(value); 
                      errors["montoJugador"] = ""                
                  }}
                  isAllowed={(values) => {
                    const {floatValue} = values;
                    return floatValue >= 0 &&  floatValue <= 999999.99;
                  }}/>            
                  {errors["montoJugador"]!==""?<div className='camporequerido'>{errors["montoJugador"]}</div>:""}
                </div>
              </div> 
              <div className="row-form">
                <div className="col-30">
                  <label>
                    <i className="fas fa-wrench"></i> <span>Nivel:</span>
                  </label> 
                </div>        
                <div className="col-70">     
                  <Select className="input-form" id="nivelNombreSelectCombo" value={nivelNombreSelect} onChange={(e) => {
                    setNivelNombreSelect(e) 
                    errors["nivelNombreSelect"]=""
                  }} placeholder="Seleccione" options={listaCatalogos!==undefined && listaCatalogos!==null?listaCatalogos["NIVELES"]:[]}/>           
                  {errors["nivelNombreSelect"]!==""?<div className='camporequerido'>{errors["nivelNombreSelect"]}</div>:""}
                </div>
              </div>
              
              
              
              <div className="row-form">
                <div className="col-30">
                  <label>
                    <i className="fas fa-edit"></i> <span>Última jugada:</span>                
                  </label>   
                </div> 
                <div className="col-70">        
                  <NumberFormat  
                    className="input-form" value={ultimaJugada} thousandSeparator={false} allowNegative={false} maxLength={3} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    setUltimaJugada(value);
                    errors["ultimaJugada"] = ""
                  }}/>     
                  {errors["ultimaJugada"]!==""?<div className='camporequerido'>{errors["ultimaJugada"]}</div>:""}                                   
                </div> 
              </div> 
                       
              {validacionTiemposVirtual===4?
              <div>
                <div className="row-form">
                  <div className="col-30">
                    <label>
                      <i className="fas fa-clock"></i> <span>Tiempo jugada:</span>                  
                    </label> 
                  </div> 
                  <div className="col-70">            
                    <NumberFormat  className="input-form" value={tiempoJugada} maxLength={3} thousandSeparator={false} allowNegative={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                      const {formattedValue, value} = values;
                      setTiempoJugada(value);
                      errors["tiempoJugada"] =""                 
                    }}/>
                    {errors["tiempoJugada"]!==""?<div className='camporequerido'>{errors["tiempoJugada"]}</div>:""}                          
                  </div>
                </div> 
                <div className="row-form">  
                  <div className="col-30">
                    <label>
                      <i className="fas fa-clock"></i> <span>Tiempo noticia:</span>
                    </label>                 
                  </div>
                  <div className="col-70">
                    <NumberFormat className="input-form" value={tiempoNoticia} thousandSeparator={false} maxLength={3} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                      const {formattedValue, value} = values;
                      setTiempoNoticia(value);
                      errors["tiempoNoticia"]=""                 
                    }}/>
                    {errors["tiempoNoticia"]!==""?<div className='camporequerido'>{errors["tiempoNoticia"]}</div>:""}
                  </div>
                </div>
                <div className="row-form"> 
                  <div className="col-30">
                    <label>
                      <i className="fas fa-clock"></i> <span>Tiempo precios:</span>                 
                    </label>
                  </div>   
                  <div className="col-70">                  
                    <NumberFormat  className="input-form" value={tiempoVarPrecios} allowNegative={false} maxLength={3} thousandSeparator={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                      const {formattedValue, value} = values;
                      setTiempoVarPercios(value);
                      errors["tiempoVarPrecios"] = ""                   
                    }}/>
                    {errors["tiempoVarPrecios"]!==""?<div className='camporequerido'>{errors["tiempoVarPrecios"]}</div>:""}                                       
                  </div> 
                </div> 
                <div className="row-form">
                  <div className="col-30">
                    <label>
                      <i className="fas fa-clock"></i> <span>Tiempo rec saldos:</span>                   
                    </label>
                  </div>  
                  <div className="col-70">           
                    <NumberFormat  className="input-form" value={tiempoRecSaldos} maxLength={3} allowNegative={false} thousandSeparator={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                      const {formattedValue, value} = values;
                      setTiempoRecSaldos(value);
                      errors["tiempoRecSaldos"] = ""                  
                    }}/>              
                    {errors["tiempoRecSaldos"]!==""?<div className='camporequerido'>{errors["tiempoRecSaldos"]}</div>:""}                       
                  </div>
                </div>      
                <div className="row-form">
                  <div className="col-30">
                    <label>
                      <i className="fas fa-clock"></i> <span>Tiempo sig jugada:</span>                  
                    </label> 
                  </div> 
                  <div className="col-70">                         
                    <NumberFormat  className="input-form" value={tiempoSigJugada} maxLength={3} allowNegative={false} thousandSeparator={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                      const {formattedValue, value} = values;
                      setTiempoSigJugada(value);
                      errors["tiempoSigJugada"]=""                  
                    }}/> 
                    {errors["tiempoSigJugada"]!==""?<div className='camporequerido'>{errors["tiempoSigJugada"]}</div>:""}                         
                  </div> 
                </div> 
              </div>
              :""}
              
            </div>
          </div>
        </div>
        <div className="textCenter">
          <div className="btnGuardar" onClick={actualizarEvento}>
              GUARDAR
          </div>{" "}
          <div className="btnCancelar" onClick={closeWindow}>
            SALIR
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarEvento);
