import React from "react";
import { Redirect  } from 'react-router-dom';
import { connect,useSelector } from "react-redux";

const NoJuego = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);  
    const { listParams: listParams } = useSelector((state) => state.administracion);  
   
    function getParamUrl(listParamsP){
        let arrResult = listParamsP.filter(value => (value["Codigo"] === "URL_NO_JUEGOS"))
        if(arrResult.length>0)
            return arrResult[0]["Valor"]
        else
            return "//puntopagobvq.com/"
    }

    if (!currentUser) {
        return <Redirect to="/login" />;
      }  

    return (
        <div> 
            <div className='nojuego'> 
                <div className="customBoxAviso">
                    <div className="innerShadow">
                        No tienes juegos disponibles, puedes inscribirte ahora!!
                    </div>                        
                </div>
            </div>
            <iframe src={getParamUrl(listParams)} className='iframepuntopago' title='Compra un evento'></iframe>
        </div> 
    )
};
const mapStateToProps = state => ({
});
export default connect(mapStateToProps, {    
})(NoJuego);