import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {NotificationContainer,NotificationManager} from "react-notifications";
import Select from 'react-select';

import DataGrid,{ Column }  from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/amber-dark.css'
import {getEventos} from "../../actions/evento"
import {getItemsCatalogo} from "../../actions/administracion"
import {formatDateTime} from "../general/Util"

import "../../config";

const Sala = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { listEventos: currentEventos,eventoSeleccionado: eventoSeleccionado,setStatusByKey:setStatusByKey} = useSelector((state) => state.juego);
  const { listaCatalogos} = useSelector((state) => state.administracion);
  const [activeIndex, setActiveIndex] = useState(2)
  const [eventoSeleccionadoId, setEventoSeleccionadoId] = useState(-1)
  const [modalidad, setModalidad] = useState(null)
  

  const { listaEventos,listaEventosCount } = useSelector((state) => state.evento);
  
  const gridStyle = { minHeight: '300px', margin: 20 }


  let history = useHistory();
  const dispatch = useDispatch();

  if(listaEventos===null){
    dispatch(getEventos("0","","","ASC",""))
    .then(() => {
    })
    .catch(() => {
    });
  }
  //Aqui no establas llamando al servicio con el codigo de estados
  //estabas llamando solo en la otra pagina, pero como se guarda en cache te funciono una ves
  //cuando seborro la cache llamo a este meotdo pero sin el codigo del catalogo ok? ok 
  if(listaCatalogos===null){
    dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
    .then(() => {
    })
    .catch(() => {
    });
  }

  const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
    alert(data["NombreEvento"])
    setEventoSeleccionadoId(data["EventoId"])
    setActiveIndex(selectedMap)
    

}, [])

  const options = [
    'one', 'two', 'three'
  ];
  const defaultOption = options[0];
  

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  function configurarEvento() {
     let valid = true
     
     if(!valid){
        return
    }

      
        
  }
  function closeWindow() {
    props.closeNewEventClick();
  }
  
  return (
    <div
      className={[
        "overlay",
        "fade-in",
        "animationShow"
      ].join(" ")}
    >
      <div className="borderContent ventanaPortafolio">
        <div className="innerShadow3">
          <h2>
            Nuevo <i className="fas fa-cog"></i>
          </h2>        


          <div className="dividerLine"></div>
          <div className="textCenter">
            <div className="btnGuardar">
                GUARDAR
            </div>{" "}
            <div className="btnCancelar" onClick={closeWindow}>
              SALIR
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Sala);
