import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

import {getNoticias,deleteNoticia,setStatusByKey} from "../../actions/administracion";
import "../../config";
import EditarNoticia from "./EditarNoticia"
import GestionNoticiaEmisor from "./GestionNoticiaEmisor"


const GestionNoticias = (props) => {    
    const { listaNoticias,listaNoticiasCount,noticiasLoading} = useSelector((state) => state.administracion);
    let [editGestionNoticiaOpen, setEditGestionNoticiaOpen] = useState("")
    let [noticiaSeleccionado, setNoticiaSeleccionado] = useState("null") 
    let [getNews, setGetNews] = useState(false) 
       
    const dispatch = useDispatch();

    function closeGestionNoticias(){
		dispatch(setStatusByKey("listaNoticias",null))
        props.closeGestionNoticiasClick();
    }
    if(listaNoticias===null && getNews===false){
        setGetNews(true)
        dispatch(getNoticias("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }

    const gridStyle = { minHeight: '300px',height:'calc(100% - 50px)', margin: 20 }
    let [gestionNoticiasFilter, setGestionNoticiasFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaNoticias!==null?listaNoticias:[]]);     

    if(listaNoticias!==null && listaNoticias!=gridInfor && gestionNoticiasFilter==="")
        setGridInfor(listaNoticias)

    function filterNoticias(noti,gestionNoticiasFilter){
        //value => 
        setGridInfor(noti.filter(function(item) {
            if (item["Descripcion"].toLowerCase().includes(gestionNoticiasFilter.toLocaleLowerCase()) || 
                item["TipoNombre"].toLowerCase().includes(gestionNoticiasFilter.toLocaleLowerCase()) ||
                item["NivelNombre"].toLowerCase().includes(gestionNoticiasFilter.toLocaleLowerCase()) ||
                item["TipoNoticiaEspNombre"].toLowerCase().includes(gestionNoticiasFilter.toLocaleLowerCase())) 
                return true;
            return false;
        }))
    }
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Editar',
            onClick:()=>{   
                setNoticiaSeleccionado(rowProps.data)
                setEditGestionNoticiaOpen("EDIT")
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar noticia',
                    message: '¿Seguro desea eliminar la noticia?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{
                            dispatch(deleteNoticia(rowProps.data["NoticiaId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {}
                      }
                    ]
                  });
               
            }
        },
        {
            label: 'Asignar emisores',
            onClick:()=>{   
                setNoticiaSeleccionado(rowProps.data)
                setEditGestionNoticiaOpen("EMISORES")
            }
        },          
		{
            label: 'Duplicar',
            onClick:()=>{   
				let item = rowProps.data
				item["NoticiaId"] = item["NoticiaId"] * -1
                setNoticiaSeleccionado(rowProps.data)
                setEditGestionNoticiaOpen("EDIT")
            }
        },          
        ]
    }, [])  
    let columns= [
        /*{ name: 'Codigo',header: 'Codigo', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 100, },
        { name: 'Titulo',header: 'Titulo', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 200, },*/
        { name: 'Descripcion',header: 'Descripcion', defaultFlex: 1, xdefaultLocked: 'Descripcion',minWidth: 400, },
        { name: 'TipoNombre',header: 'Tipo', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'NivelNombre',header: 'Nivel', defaultFlex: 1, xdefaultLocked: 'end' },          
        { name: 'TipoNoticiaEspNombre',header: 'Tipo especial', defaultFlex: 1, xdefaultLocked: 'end' },          
        
    ]

    const closeEdit = ()=>{
        setEditGestionNoticiaOpen("")
    }
    const crearNuevo = ()=>{
        setNoticiaSeleccionado(null)
        setEditGestionNoticiaOpen("EDIT")
    } 

return (
<div className="windowContentInner fade-in"> 
    <div className={['loading_cont',noticiasLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>    
    {editGestionNoticiaOpen==="EDIT" ? <EditarNoticia closeEdit={closeEdit} noticiaSeleccionado={noticiaSeleccionado} />  : ""}  
    {editGestionNoticiaOpen==="EMISORES" ? <GestionNoticiaEmisor close={closeEdit} noticiaSeleccionado={noticiaSeleccionado} />  : ""}  
    <div className="borderContent ventanaGeneral">
        <div className="innerShadow-height-100">
            <h2>
            Noticias
            <div className="floatright"> 
                <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                    <MenuItem onClick={crearNuevo}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                </Menu>
            </div>
            </h2> 
            <div className="titleTable-search"><div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={gestionNoticiasFilter} onChange={(e) => {
                  setGestionNoticiasFilter(e.target.value);
                  filterNoticias(listaNoticias!==null?listaNoticias:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div> 
            <DataGrid
                idProperty="NoticiaId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}
                selection={{ mode: 'single' }}                    
                dataSource={gridInfor}
                renderRowContextMenu={renderRowContextMenu}
                theme="amber-dark"
            />
             
            <div className="dividerLine"></div>
            <div className="textCenter">
                <div className="btnCancelar" onClick={closeGestionNoticias}>
                    SALIR
                </div>
            </div>      
        </div>       
    </div>
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionNoticias);