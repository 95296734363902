import axios from "axios";
import '../config'

const register = (username, email, password) => {  
  return axios.post(global.config.URL_SERVICE + "signup", {
    username,
    email,
    password,
  },{headers: {'Content-Type': 'application/json; charset=utf-8',}});
};

const login = (username, password) => {  
  const params = {"strJson":'{"Usuario":"' + username + '","Clave":"' + password + '"}'}    
  return axios
    .post(global.config.URL_SERVICE + "ValidarUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      //itemR = itemR.substring(1,itemR.length-1)
      const result = JSON.parse(itemR);
      if(result.length>0){
        if (result[0].accessToken) {
          localStorage.setItem("user", JSON.stringify(result[0]));

          axios
          .post(global.config.URL_SERVICE + "ObtenerParametrosJuego", {},{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              if(result.length>0){
                localStorage.setItem("paramgame", JSON.stringify(result));
                return result;  
              }else{
                return null
              }
          });
        }
  
        return result[0];  
      }else{
        return null
      }
      //return null;
    });
};

const loginByToken = (token) => {  
  const params = {"strToken":token}    
  return axios
    .post(global.config.URL_SERVICE + "ValidarUsuarioPorToken", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      //itemR = itemR.substring(1,itemR.length-1)
      const result = JSON.parse(itemR);
      if(result.length>0){
        if (result[0].accessToken) {
          localStorage.setItem("user", JSON.stringify(result[0]));

          axios
          .post(global.config.URL_SERVICE + "ObtenerParametrosJuego", {},{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              if(result.length>0){
                localStorage.setItem("paramgame", JSON.stringify(result));
                return result;  
              }else{
                return null
              }
          });
        }
  
        return result[0];  
      }else{
        return null
      }
      //return null;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("eventos");
};

export default {
  register,
  login,
  logout,
  loginByToken
};
