import React, { useState, useEffect } from "react";
import { connect,useDispatch,useSelector } from "react-redux";
import ItemPortafolioRV from './juego/ItemPortafolioRV';
import ItemPortafolioRF from './juego/ItemPortafolioRF';
import Select from 'react-select';
import axios from "axios";
import '../config'
import {getUsuarioEventoCombo} from "../actions/evento";
import {getMax,formatDateMont,formatNumber,getTotalAccion} from "./general/Util"
import { getEstadoCuentaPortafolio} from "../actions/juego"

const CajaPortafolio = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);  
    let {listEventos: currentEventos,eventoSeleccionado: eventoSeleccionado,jugadaIndex,estadoCuentaCliente: currentTitle,estadoCuentaClienteEfec:estadoCuentaClienteEfec,loadEstadoCuenta} = useSelector((state) => state.juego);
    let [currentEventObj, setCurrentEventObj] = useState(null);
    const {listaEventoUsuarioCombo} = useSelector((state) => state.evento);   
    let [userSelect,setUserSelect] = useState(null)
    let [loadUsrEven, setLoadUsrEven] = useState(false);
    let [namePortafolio,setNamePortafolio] = useState("S/N")
    let [nombreEvento,setNombreEvento] = useState(" ")    
    let [duracionEvento,setDuracionEvento] = useState(1)    
   
    const dispatch = useDispatch();

    if(currentEventos!==null && currentEventObj===null){
        const arrTmp = currentEventos.filter(value => value["EventoId"] === eventoSeleccionado)
        if(arrTmp.length>0){
            setDuracionEvento(arrTmp[0]["Duracion"])
            setNombreEvento(arrTmp[0]["NombreEvento"])
            setCurrentEventObj(arrTmp[0])
        }
    }    
   
    if(listaEventoUsuarioCombo===null && loadUsrEven===false){
        setLoadUsrEven(true)
        dispatch(getUsuarioEventoCombo(eventoSeleccionado))
        .then(() => {
        })
        .catch(() => { 
        });
        
    }
    
    function btnCerrarPortafolio(){
        props.btnCerrarPortafolio()
    }
    function ObtenerPortafolioPorUsuario(userId,user){
        setNamePortafolio(user["Nombre"])
        dispatch(getEstadoCuentaPortafolio(eventoSeleccionado,userId))
            .then(() => {            
            })
            .catch(() => {
            });
    }

    return (
        <div
      className={[
        "overlay",
        "fade-in"
      ].join(" ")}
    >
        <div className={['loading_cont',loadEstadoCuenta===false?"displaynone":""].join(' ')}><div className='single6'></div></div>        
        <div className="borderContent ventanaPortafolio">
            <div className="innerShadow-height-ing-compra">
                <h2>
                    PORTAFOLIO
                    <div className="floatright col-70-orn-caja">
                        <Select className="input-form" id="InstitucionCombo" value={userSelect} onChange={(e) => {
                            setUserSelect(e) 
                            if(e!==null && e["value"]>0){
                                ObtenerPortafolioPorUsuario(e["value"],e)
                            }                            
                            }} placeholder="Seleccione" options={listaEventoUsuarioCombo!==null?listaEventoUsuarioCombo:[]}
                        /> 
                    </div>
                </h2>  
                <div className="content-nego-caja">
                <div className="innerShadow3 negociacion">
                    <h2 className='textcenter'>EL JUEGO DE LA BOLSA - INVESTOR III</h2>
                    <div className='ventana-portafolio'>
                    {/*RESUMEN PORTAFOLIO - {props.namePortafolio.toUpperCase()}*/}
                    <div className="titleCont">      
                        <div><div className='label-port1'>REPORTE DE JUGADOR:</div><span className=''>{namePortafolio}</span></div>                      
                        <div ><div className='label-port1'>FECHA:</div><span className=''>{formatDateMont(new Date())}</span></div>
                        <div><div className='label-port1'>EVENTO:</div><span className=''>{nombreEvento}</span></div>
                        <div><div className='label-port1'>JUGADA:</div><span className=''>{jugadaIndex}/{duracionEvento}</span></div>
                        <div><div className='label-port1-rigth'>Reporte de Portafolio y Dinero</div></div>
                    </div>                     
                    
                    <div className={["titleCont",currentTitle!=null && currentTitle.filter(value => value["TipoRentaCod"] === 'RV').length>0?"":"displaynone"].join(' ')}>
                        <div className="titleCont">
                            <div className="dividerLine"></div>
                            <h3>RENTA VARIABLE</h3>    
                        </div>                                            
                    </div> 
                    <div className={["tableContainer",currentTitle!=null && currentTitle.filter(value => value["TipoRentaCod"] === 'RV').length>0?"":"displaynone"].join(' ')}>  
                        {
                            currentTitle!=null?currentTitle.filter(value => value["TipoRentaCod"] === 'RV').map((item, i) => { 
                                return <ItemPortafolioRV key={i} index={i} item={item} evento={props.eventoId} jugada={props.jugada} maxValue={getMax(currentTitle.filter(value => value["TipoRentaCod"] === 'RV'),"SaldoTitulo")} compra={false}/>
                            }):""
                        }                             
                        <div className="rowCustom">
                            <div className="fiveColPortafolioTotal">TOTAL DINERO EN ACCIONES</div>
                            <div className="fourColPortafolioTotal">${formatNumber(getTotalAccion(currentTitle.filter(value => value["TipoRentaCod"] === 'RV')))} </div>
                            
                        </div> 
                    </div>                    

                    <div className={["titleCont",currentTitle!=null && currentTitle.filter(value => value["TipoRentaCod"] === 'RF').length>0?"":"displaynone"].join(' ')}>
                        <div className="dividerLine"></div>
                        <div className="titleCont">
                            <h3>RENTA FIJA</h3>                        
                        </div>                        
                    </div>
                    {                        
                        currentTitle!=null?currentTitle.filter(value => value["TipoRentaCod"] === 'RF').map((item, i) => { 
                            return <ItemPortafolioRF key={i} index={i} item={item} evento={props.eventoId} jugada={props.jugada} maxValue={getMax(currentTitle.filter(value => value["TipoRentaCod"] === 'RF'),"SaldoTitulo")} compra={false}/>
                        }):""
                    }

                    <div className="dividerLine"></div>
                    <div className="tableContainer">
                        <div className="rowCustom">
                            <div className="fiveColPortafolioTotal">SALDO EN EFECTIVO</div>
                            <div className="fourColPortafolioTotal">${formatNumber(estadoCuentaClienteEfec)}</div>
                        </div>
                    </div> 
                    <div className="dividerLine"></div>
                    <div className="tableContainer">
                        <div className="rowCustom">
                            <div className="fiveColPortafolioTotal">TOTAL</div>
                            <div className="fourColPortafolioTotal">${formatNumber(estadoCuentaClienteEfec + getTotalAccion(currentTitle.filter(value => value["TipoRentaCod"] === 'RV')))}</div>
                        </div>
                    </div>                     
                    </div>
                    </div>                    
                </div>
                <div className="btnContainer"><div className="btnCancelar" onClick={btnCerrarPortafolio}>Cerrar</div></div>
            </div>                                            
        </div>        
    </div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(CajaPortafolio);