import React,{useState} from "react";
import { Redirect  } from 'react-router-dom';
import { connect,useSelector } from "react-redux";


import audCampana from '../audio/campana.mp3'

const AperturaRueda = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);  
  let [createTimeout, setCreateTimeout] = useState(true);

  if (!currentUser) {
    return <Redirect to="/login" />;
  }else{
    if(createTimeout){
      setCreateTimeout(false)
      setTimeout(()=>{
        props.changeStatus("JUEGO")
      },25000)
    }      
  }
  
  function onEndVideo(){
    props.changeStatus("JUEGO")
  }

  return (
    <div>
        {/*<audio src={audCampana} autoPlay={true} playsInline></audio>*/}
      <div className="title-apertura">
        <div className="customBoxAviso">
          <div className="innerShadow">
          Apertura de rueda bursátil
          </div>
        </div>
      </div>
      <div className='apertura-cont-vid'>
          <video src="http://servicio.juegobvq.com/WsJuegoBvq/videos/campana-2021.mp4" playsInline controls autoPlay onEnded={onEndVideo}></video>
          {/*<div className="ringing-bell"><i className="fas fa-bell campanaIcon animated faa-ring animated fa-5x"></i></div>*/}
      </div>
    </div>
  );
};
const mapStateToProps = state => ({  
});
export default connect(mapStateToProps, {    
})(AperturaRueda);