import React, { useState} from "react";
import { Redirect } from 'react-router-dom';
import { connect,useDispatch,useSelector } from "react-redux";
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import axios from "axios";
import '../config'

import RentaFija from './juego/RentaFija'
import ItemRentaVariable from './juego/ItemRentaVariable'
import ScrollFooter from './ScrollFooter'
import Negociacion from "./Negociacion";
import Portafolio from "./Portafolio";
import ItemUpPrecio from "./juego/ItemUpPrecio";
import ItemDownPrecio from "./juego/ItemDownPrecio";
import MensajeJuego from "./MensajeJuego";
import VentanaChat from "./VentanaChat";
import Ranking from "./Ranking";

import {getMax,generateMessageAdm,generateMessageAdmParams,getMaxRV,generateMessageChatParams} from "./general/Util"

import imagenNoticiaPositivaBig from '../image/imgNoticiaBig.jpg';
import imagenNoticiaNegativaBig from '../image/imgNoticiaBig2.jpg';
import { saveNegociacion,getEstadoCuentaPortafolio,setStatusByKey,getVariacionPrecio} from "../actions/juego"
import {NotificationManager} from "react-notifications"
import { confirmAlert } from 'react-confirm-alert'
import { _getProperty } from "gsap/gsap-core";
 
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
const Juego = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { user: currentUser } = useSelector((state) => state.auth);  
    const { listEventos: currentEventos,listTitleEvent: currentTitle,mostrarPortafilioCl:mostrarPortafilioCl,modalidadCodigo:modalidadCodigo,listaVariacionPrecio,showRankingUsr } = useSelector((state) => state.juego);
    const { titulosRVCount } = useSelector((state) => state.juego );    
    let [isMaximized, setIsMaximized] = useState(false);    
        
    const dispatch = useDispatch();

    let [statusJuego, setStatusJuego] = useState("");
    let [negociacionJuego, setNegociacionJuego] = useState("");
    let [showPortfolio, setShowPortfolio] = useState(false);
    let [namePortfolio, setNamePortfolio] = useState("");
    let [userPortafolioId, setUserPortafolioId] = useState(-1);    
    let [userFilter, setUserFilter] = useState("");   
    let [showUserList, setShowUserList] = useState(windowDimensions.width>650?true:false);
    let [ordenUserId, setOrdenUserId] = useState(-1);
    let [mostratBotonesRigth, setMostratBotonesRigth] = useState(true);
    let [eventoFinalizado, setEventoFinalizado] = useState(false);
    let [mostrarAyuda, setMostrarAyuda] = useState(false);
    let [listaVariacionPre, setListaVariacionPre] = useState(false);    
    let [userChatId, setUserChatId] = useState(-1); 
    let [userNameChat, setUserNameChat] = useState(-1); 
    let [forzarMostrarOrden, setForzarMostrarOrden] = useState(true);
    let [guardandoOrden, setGuardandoOrden] = useState(false);    
    let [openRanking, setOpenRanking] = useState(false);    
    
    
    
  if (!currentUser) {
    return <Redirect to="/login" />;
  }  

  if(mostrarPortafilioCl===true){
    //setMostrarAyuda(true)
    openPortafolioCliente(currentUser["userId"].toString(),currentUser["displayName"])
    dispatch(setStatusByKey("mostrarPortafilioCl",false))
  }else{
    if(mostrarAyuda===true){
        setMostrarAyuda(false)
        setShowPortfolio(false)
    }
  }

  if(props.pasoJugada!=="ORN" && forzarMostrarOrden===false){
    setForzarMostrarOrden(true)
  }

  if(negociacionJuego==="COMPRA" && props.pasoJugada!=="ORN"){
      setNegociacionJuego("")
      setForzarMostrarOrden(true)
  }else{
    if(currentUser["role"]==="ROLE_USER" && negociacionJuego!=="COMPRA" && props.pasoJugada==="ORN" && forzarMostrarOrden){
        setNegociacionJuego("COMPRA")
        setMostratBotonesRigth(false)
    }    
  }  




  if(listaVariacionPre===false && props.currentEventObj!==null){
      setListaVariacionPre(true)
      dispatch(getVariacionPrecio(props.currentEventObj["EventoId"]))
        .then(() => {            
        })
        .catch(() => {
        });
  }

  function openPortafolioCliente(userId,nick){
      if(props.currentEventObj!==null){
            dispatch(getEstadoCuentaPortafolio(props.currentEventObj["EventoId"],userId))
            .then(() => {            
            })
            .catch(() => {
            });
        setUserPortafolioId(userId)
        setNamePortfolio(nick)
        setShowPortfolio(true)
      }      
  }
  function validateStyleEvento(currentEventos,statusJuego,showNoticia,upDownPrecio,tipoNotEspecial){
      if(props.currentEventObj!==null){
        if((props.currentEventObj["JuegaRentaFija"]===true && props.startJuego===false) && (currentTitle!==null && currentTitle.filter(value => value["TipoRentaCod"] === 'RF').length>0)){
            if(statusJuego==="NOT" || showNoticia==true || upDownPrecio==true)
                if(tipoNotEspecial==="")
                    return ""
                else{
                    if(tipoNotEspecial==="NE_BOOM" || tipoNotEspecial==="NE_SPLIT")
                        return "noticiaEspecialPos"
                    else
                        return "noticiaEspecialNeg"
                }
            else
                return "twoRowsContainer"
        }else{
            if(statusJuego==="NOT" || showNoticia==true  || upDownPrecio==true)
                if(tipoNotEspecial==="")
                    if(props.currentEventObj["JuegaRentaFija"]===true && (currentTitle!==null && currentTitle.filter(value => value["TipoRentaCod"] === 'RF').length>0))
                        return ""
                    else
                        if(showNoticia==true)
                            return ""
                        else 
                            return "twoRowsContainerNot"
                else{
                    if(tipoNotEspecial==="NE_BOOM" || tipoNotEspecial==="NE_SPLIT")
                        return "noticiaEspecialPos"
                    else
                        return "noticiaEspecialNeg"
                }
            else

                return "oneRowsContainer"
        }
      }
      return "oneRowsContainer"
  }
  function btnNegociacion(){
    setNegociacionJuego("COMPRA")
    setMostratBotonesRigth(false)    
    props.openOrden(true)
  }
  function btnNegociacionCancelar(){
    setNegociacionJuego("")
    setOrdenUserId(-1)   
    setMostratBotonesRigth(true)
    setForzarMostrarOrden(false)
    props.openOrden(false)     
  }
  function btnEnviarNegociacion(evento,jugada,itemsCompra,itemsVenta){
      if(guardandoOrden===true)return
      setGuardandoOrden(true)
    dispatch(setStatusByKey("loadingAccionEvt",true))        
    const params = {"strEventId":evento.toString(),"strUserId":ordenUserId===-1?currentUser["userId"].toString():ordenUserId,"strJugada":jugada,"strCompra":JSON.stringify(itemsCompra),"strVenta":JSON.stringify(itemsVenta)}
    axios
      .post(global.config.URL_SERVICE + "GuardarCompraJugada", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        setGuardandoOrden(false)
        dispatch(setStatusByKey("loadingAccionEvt",false))        
        let itemR = response["data"].split("\\\"").join("\"");     
        const result = JSON.parse(itemR);      
        
        if(result>=0){    
            NotificationManager.success(
                "Negociación registrada",
                "Sistema",
                5000,
                () => {},
                4
            ); 
            setForzarMostrarOrden(false)
            setMostratBotonesRigth(true)
            setNegociacionJuego("")                
        }else{            
            if(result===-2)
                props.mostrarNotificacionVentana('<div class="tituloErrorNeg">Necesitas compra por lo menos un título de Renta Fija</div>')
            else
                if(result===-3)
                    NotificationManager.error(
                        "Ingresa COMPRAS/VENTAS",
                        "Sistema",
                        5000,
                        () => {},
                        4
                    ); 
                else
                    NotificationManager.error(
                    "Error al registrar la negociación",
                    "Sistema",
                    5000,
                    () => {},
                    4
                ); 
        }
      });
  }
  
    function btnCerrarPortafolio(){
        setShowPortfolio(false)
        setNamePortfolio("")
    }
    function sendMessageChat(strTo,strMessage){
        props.sendMessageChat(generateMessageChatParams("MSG",strTo, strMessage,(currentUser["role"].toString()==="ROLE_ADMIN" || currentUser["role"].toString()==="ROLE_RUEDA")?"-1000":currentUser["userId"].toString()),strTo)        
    }
    function sendViewPortafolio(){
        props.sendMessage(generateMessageAdmParams("AYU",userPortafolioId))
        NotificationManager.success(
            "La ayuda del portafolio fue enviada",
            "Sistema",
            3000,
            () => {},
            4
        );    
        //props.sendViewPortafolio()
    }
    function sendViewPortafolioByParam(userId){
        props.sendMessage(generateMessageAdmParams("AYU",userId))
        NotificationManager.success(
            "La ayuda del portafolio fue enviada",
            "Sistema",
            3000,
            () => {},
            4
        );    
        //props.sendViewPortafolio()
    }
    function setMaximizeOrders(maximize){
        setIsMaximized(maximize);
    }
    function hideShowUsers(){
        setShowUserList(!showUserList)
    }
    function btnSiguienteAccionClick(){
        dispatch(setStatusByKey("loadingAccionEvt",true))        
        
        if(props.pasoJugada!="NES"){
            props.sendMessage(generateMessageAdm("JUG"))
        }else{
            props.sendMessage(generateMessageAdm("EJNOTESP"))
        }
    }
    function btnInicarJuegoClick(){      
        dispatch(setStatusByKey("loadingAccionEvt",true))          
        props.sendMessage(generateMessageAdm("START"))
    }
    function btnShowRanking(){
        setOpenRanking(true)        
        props.sendMessage(generateMessageAdm("RANKING"))
    }
    function btnEnviarAyudaClick(){
        dispatch(setStatusByKey("loadingAccionEvt",true))        
        props.sendMessage(generateMessageAdmParams("AYU","-1"))
        NotificationManager.success(
            "La ayuda del portafolio fue enviada",
            "Sistema",
            3000,
            () => {},
            4
        );    
    }
    function btnFinEventoClick(){
        confirmAlert({
            title: 'Finalizar evento',
            message: 'Seguro desea finalizar el evento?.',
            buttons: [
              {
                label: 'Si',
                onClick: () =>{
                    props.sendMessage(generateMessageAdmParams("FEV","-1"))
                }
              },
              {
                label: 'No',
                onClick: () => {
                  
                }
              }
            ]
          });        
    }    
    function ingresoOrdenesManual(userId){
        setOrdenUserId(userId)        
        btnNegociacion()
    }    
    function showChat(userId,name){
        setUserChatId(userId)
        setUserNameChat(name)

    }
    function mostrarOcultarBotones(){
        setMostratBotonesRigth(!mostratBotonesRigth)
    }
    function btnNoticiaEspecialBoom(){
        dispatch(setStatusByKey("loadingAccionEvt",true))        
        props.sendMessage(generateMessageAdmParams("NOTESP","NE_BOOM"))
    }
    function btnNoticiaEspecialCrush(){
        dispatch(setStatusByKey("loadingAccionEvt",true))        
        props.sendMessage(generateMessageAdmParams("NOTESP","NE_CRUSH"))
    }
    function btnNoticiaEspecialSplit(){
        dispatch(setStatusByKey("loadingAccionEvt",true))        
        props.sendMessage(generateMessageAdmParams("NOTESP","NE_SPLIT"))
    }
    function btnNoticiaEspecialContralSplit(){
        dispatch(setStatusByKey("loadingAccionEvt",true))        
        props.sendMessage(generateMessageAdmParams("NOTESP","NE_CONTRASPLIT"))
    }
    function closeMessageJuegoClick(){
        props.closeMessageJuegoClick()
    }
    function closeChat(){
        setUserChatId(-1)
    }    
    function btnCerrarRanking(){
        setOpenRanking(false)
        dispatch(setStatusByKey("showRankingUsr",false))          
    }
    function BlockUser(userId,userName){
        confirmAlert({
            title: 'Suspensión',
            message: 'Esta seguro que desea suspender al participante ' + userName +'?.',
            buttons: [
              {
                label: 'Si',
                onClick: () =>{
                    props.sendMessage(generateMessageAdmParams("USRBLOCK",userId))
                }
              },
              {
                label: 'No',
                onClick: () => {
                  
                } 
              }
            ]
          });        
    }
  return (
      <div>
        {
            showPortfolio==true?<div className='windowContentInner fade-in animationShow'>
                <Portafolio namePortafolio={namePortfolio} btnCerrarPortafolio={btnCerrarPortafolio} sendViewPortafolio={sendViewPortafolio} nombreEvento={props.currentEventObj!=null?props.currentEventObj["NombreEvento"]:""} jugada={props.jugada} duracion={props.currentEventObj!=null?props.currentEventObj["Duracion"]:""}/>
            </div>:""
        }
            {userChatId>0?<VentanaChat userChatId={userChatId} userNameChat={userNameChat} closeChat={closeChat} sendMessageChat={sendMessageChat} enableChat={props.enableChat} listMessage={props.listMessage} rolname={currentUser["role"].toString()}/>:""}
            {props.mostrarNotificacion===true?<MensajeJuego closeMessageJuegoClick={closeMessageJuegoClick} mensajeNotificacion={props.mensajeNotificacion}/>:""}
            <div className="mainContentInner">                
				{negociacionJuego==="COMPRA"?<Negociacion btnNegociacionCancelar={btnNegociacionCancelar} btnEnviarNegociacion={btnEnviarNegociacion} currentTitle={currentTitle} evento={props.evento} jugada={props.jugada} maxValue={getMax(currentTitle,"Precio")} isMaximized={isMaximized} /> :""}       
                <div className={["content","fade-in",validateStyleEvento(currentEventos,statusJuego,props.showNoticia,props.upDownPrecio,props.tipoNotEspecial),negociacionJuego!=="COMPRA"?"":"displaynone",isMaximized===false?"":"minimize-cont-ordenes"].join(' ')}>
                    <div className={["pasoRentaFija","borderContent colRentaFija"].join(' ')}>
                        <div className="innerShadow3">
                        <h3>VALORES DE RENTA FIJA QUE SALEN A LA VENTA</h3>
                            <RentaFija titulos={currentTitle===null?[]:currentTitle}/>                        
                        </div>
                    </div> 
                    <div className={["pasoNotPositiva","borderContent","fade-in","noticiaPositivaBig",(props.showNoticia===true && props.tipoNotEspecial!=="NE_CRUSH" && props.tipoNotEspecial!=="NE_CONTRASPLIT")?"":"displaynone"].join(' ')}>
                        <div className="innerShadow3">
                        <h3>NOTICIA POSITIVA</h3>                    
                        <div className="imgNoticiaPositiva"><img src={props.noticiaPosObj!==null && props.noticiaPosObj["ImageUrl"]!==""? global.config.URL_UPLOAD + props.noticiaPosObj["ImageUrl"]:imagenNoticiaPositivaBig} alt="El Juego de la Bolsa" /></div>
                        <div className="txtNoticiaPositiva">{props.noticiaPos}</div>
                        </div>
                    </div>
                    
                    <div className={["pasoNotNegativa","borderContent","fade-in","noticiaNegativaBig",(props.showNoticia===true && props.tipoNotEspecial!=="NE_BOOM" && props.tipoNotEspecial!=="NE_SPLIT")?"":"displaynone"].join(' ')}>
                        <div className="innerShadow3">
                        <h3>NOTICIA NEGATIVA</h3>                    
                        <div className="imgNoticiaNegativa noticeStyle2"><img src={props.noticiaNegObj!==null && props.noticiaNegObj["ImageUrl"]!==""? global.config.URL_UPLOAD + props.noticiaNegObj["ImageUrl"]:imagenNoticiaNegativaBig} alt="El Juego de la Bolsa" /></div>
                        <div className="txtNoticiaNegativa">{props.noticiaNeg}</div>
                        </div>
                    </div>               
                    <div className="borderContent colBarras">
                        <div className="innerShadow3">
                        <h3>TÍTULOS RENTA VARIABLE
                        <div className='pasoDescJugada titulosRight'>{"Jugada " + props.jugada + " - " + props.textoEstadoJugada}</div>            
                        {props.currentEventObj!==null && props.currentEventObj["EstadoCodigo"]==="POR_FINALIZAR"?<div className='clear-both titulosRight'>{"Evento por FINALIZAR a espera de vencimiento de títulos"}</div>:""}
                        </h3>                                            
                        <div className={["chart-container",titulosRVCount<=2?"sizeTwoBars":titulosRVCount===3?"sizeThreeBars":titulosRVCount===4?"sizeFourBars":titulosRVCount===5?"sizeFiveBars":titulosRVCount===6?"sizeSixBars":titulosRVCount===7?"sizeSevenBars":""].join(' ')}>
                            <div className="bottomACC ayudaBarAccCantidad">#ACC</div>                            
                            {              
                                              
                                currentTitle!=null?currentTitle.filter(value => value["TipoRentaCod"] === 'RV').map((item, i) => { 
                                    if(item["TipoRentaCod"]==="RV"){
                                        return <ItemRentaVariable key={i} maxValue={getMaxRV(currentTitle,"Precio")} index={i} item={item}/>
                                    }else{
                                        return ""
                                    }
                                }):""
                            }        
                            </div>
                        </div>
                    </div>        
                    <div className="pasoPrecioUpDown borderContent colJugadas customScroll">
                        <div className="innerShadow3">
                        <h3>VARIACIÓN DE PRECIOS x JUGADAS</h3>                                    
                        <div className="containerJugadas">
                            {
                                props.listUpDownPrecio!=null?props.listUpDownPrecio.filter(value => value["Tipo"] === 'TN_ALZA').map((item, i) => { 
                                    return <ItemUpPrecio key={i} index={i} item={item}/>
                                }):""
                            }

                            {
                                props.listUpDownPrecio!=null?props.listUpDownPrecio.filter(value => value["Tipo"] === 'TN_BAJA').map((item, i) => { 
                                    return <ItemDownPrecio key={i} index={i} item={item}/>
                                }):""
                            }             
                                                        
                        </div>
                
                    </div>
                </div>
            </div>
            <ScrollFooter showNoticia={props.showNoticia} noticiaPos={props.noticiaPos} noticiaNeg={props.noticiaNeg} negociaciones={props.negociaciones} setMaximizeOrders={setMaximizeOrders} tipoNotEspecial={props.tipoNotEspecial} listaVariacionPrecio={listaVariacionPrecio} noticiaPosObj={props.noticiaPosObj} noticiaNegObj={props.noticiaNegObj}></ScrollFooter>            
    </div> 
    <div className={["pasoUserRow","userRow",props.enableUserList===false?"displaynone":showUserList===false?"minimizeUserList":""].join(' ')}>
        <div className="userRowBtn" onClick={hideShowUsers}><i className="far fa-user"></i></div>
        <div className="innerShadow3">
        <h3>DIRECTOR DE RUEDA</h3>
            <div className="userConectedTitle">Usuarios conectados: { props.userList!==null&& props.userList!==undefined?props.userList.length:"0"}</div> 
            <div className="searchUser"><input type="text" placeholder="Buscar" value={userFilter} onChange={(e) => {
                  setUserFilter(e.target.value);
                }}/><i className="fas fa-search"></i></div>            
            <div className={["userListSidebar",showUserList===false?"overflow-hidden":""].join(' ')}>
                <ul>
                    {
                        props.userList!=null?props.userList.filter(value => value["d2p1:Value"][0]["strNick"][0].toLowerCase().includes(userFilter.toLocaleLowerCase())).map((item, i) => { 
                            return <li key={item["d2p1:Key"][0]}><div className="profileImageList">{item["d2p1:Value"][0]["strImage"][0]!==""?<img className='image-user-list' src={global.config.URL_UPLOAD + item["d2p1:Value"][0]["strImage"][0]}></img>:<div className='profileImageList-icon'></div>}</div><div className="userContTxt"><div className="userNameList" title={item["d2p1:Value"][0]["strPrefix"][0] + " - " + item["d2p1:Value"][0]["strNick"][0]}><span>{item["d2p1:Value"][0]["strPrefix"][0]} - </span>{item["d2p1:Value"][0]["strNick"][0]}</div><div className="userLastPlay">Ingreso {item["d2p1:Value"][0]["strUltimaAccion"][0]}</div></div><div className="pasoOpcionesUsr userArrowRight">{(item["d2p1:Value"][0]["strPrefix"][0]==="USR" && props.enableViewEstCta===true)?<Menu menuButton={<MenuButton><i className="fas fa-chevron-right cursor-pointer color-white"></i></MenuButton>}>{props.enableViewEstCta===true?<MenuItem onClick={()=>openPortafolioCliente(item["d2p1:Key"][0],item["d2p1:Value"][0]["strNick"][0])}><i class="fas fa-sign-out-alt"></i>Ver estado de cuenta</MenuItem>:""}{props.enableSendEstCta===true?<MenuItem onClick={()=> sendViewPortafolioByParam(item["d2p1:Key"][0])}><i class="fas fa-sign-out-alt"></i>Enviar estado de cuenta</MenuItem>:""}{modalidadCodigo==="MANUAL"?props.enableIngOrdenes===true && item["d2p1:Value"][0]["strPrefix"][0]==="USR"?props.pasoJugada==="ORN"?<MenuItem onClick={()=>ingresoOrdenesManual(item["d2p1:Key"][0])}><i class="fas fa-sign-out-alt"></i>Ingreso ordenes</MenuItem>:"":"":""}{props.enableChat===true && 1==2?<MenuItem onClick={()=>showChat(item["d2p1:Key"][0],item["d2p1:Value"][0]["strNick"][0])}><i class="fas fa-sign-out-alt"></i>Enviar mensajes</MenuItem>:""}{props.enableViewEstCta===true?<MenuItem onClick={()=>BlockUser(item["d2p1:Key"][0],item["d2p1:Value"][0]["strNick"][0])}><i class="fas fa-sign-out-alt"></i>Suspensión</MenuItem>:""}</Menu>:""}</div></li>
                        }):""
                    }
                </ul>
            </div>
        </div>
    </div> 
    <div className={["userBtn",((props.enableIniciaJuego===false  && props.pasoJugada!=="ORN") || currentUser["role"]==="ROLE_CAJA" || currentUser["role"]==="ROLE_VISITANTE" || (modalidadCodigo==="VIRTUAL" && props.pasoJugada!=="ORN"))?"displaynone":"",mostratBotonesRigth===true?"":"minimizeUserList"].join(' ')}>
        <div className="moreRowBtn" onClick={mostrarOcultarBotones}><i className={["far",mostratBotonesRigth===true?"fa-minus":"fa-plus"].join(' ')}></i></div>
        <div className="innerShadow3">
        <ul>
            <li className={[props.pasoJugada==="ORN" && currentUser["role"]==="ROLE_USER"?"":"displaynone"]}><div className="btnCompra" onClick={btnNegociacion}>Compra/Venta </div></li>
            {/*<li><div className="btnVenta" onClick={props.}>Venta </div></li>*/}

            <li className={["btnNuevaJugada",props.enableUserList===false || props.iniciaJuego===true || modalidadCodigo==="VIRTUAL"?"displaynone":""].join(' ')} onClick={btnSiguienteAccionClick}><div className="btnNuevaJugada">{props.labelButtonManual}</div></li>            
            <li className={["pasoOpcionesJug btnNuevaJugada","color-white",props.enableUserList===false || props.iniciaJuego===true  || modalidadCodigo==="VIRTUAL"?"displaynone":""].join(' ')}><Menu menuButton={<MenuButton><div className="btnNuevaJugada">Juego</div></MenuButton>}><MenuItem onClick={btnEnviarAyudaClick}><i className="fas fa-address-card"></i>Enviar ayuda</MenuItem>{eventoFinalizado===false?<MenuItem onClick={btnFinEventoClick}><i className="fas fa-address-card"></i>Finalizar Evento</MenuItem>:""}{props.showBtnNotEsp===true?<MenuItem onClick={btnNoticiaEspecialBoom}><i className="fas fa-address-card"></i>Especial Boom</MenuItem>:""}{props.showBtnNotEsp===true?<MenuItem onClick={btnNoticiaEspecialCrush}><i className="fas fa-address-card"></i>Especial Crush</MenuItem>:""}{props.showBtnNotEsp===true?<MenuItem onClick={btnNoticiaEspecialSplit}><i className="fas fa-address-card"></i>Especial Split</MenuItem>:""}{props.showBtnNotEsp===true?<MenuItem onClick={btnNoticiaEspecialContralSplit}><i className="fas fa-address-card"></i>Especial ContralSplit</MenuItem>:""}<MenuItem onClick={btnShowRanking}><i className="fas fa-address-card"></i>Ranking Actual</MenuItem></Menu></li>
            <li className={["btnNuevaJugada",props.enableUserList===false || props.iniciaJuego===false  || modalidadCodigo==="VIRTUAL"?"displaynone":""].join(' ')} onClick={btnInicarJuegoClick}><div className="btnNuevaJugada ayudaIniciarJuego">Iniciar Juego</div></li>
        </ul>
        </div>
    </div>
    {(openRanking===true || showRankingUsr===true)?<Ranking eventoId={props.currentEventObj!==null?props.currentEventObj["EventoId"]:-1} btnCerrarRanking={btnCerrarRanking}/>:""}
    {/*props.enableUserList===false?<div className="chatBtn"><button onClick={()=>showChat(currentUser["userId"].toString(),currentUser["Nombre"].toString())}><i className="fas fa-paper-plane"></i></button></div>:""*/}
</div> 
  );
};
const mapStateToProps = state => ({    
    listEventos: state.listEventos,
    currentTitle: state.currentTitle,
    titulosRVCount: state.titulosRVCount
  });
export default connect(mapStateToProps, {    
})(Juego);
