import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Router, Switch, Route} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/Login";
import Register from "./components/Register";
import Sala from "./components/Sala";
import Juego from "./components/Juego";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import Header from "./components/general/Header";
import Demo from "./components/test/demo2";
import AperturaRueda from "./components/AperturaRueda";
import NoJuego from "./components/errors/NoJuego"
import MoreConnection from "./components/errors/MoreConnection"
import FinEvento from "./components/FinEvento"
import Top10 from "./components/Top10"
import SeleccionarEvento from "./components/SeleccionJuego"
import CondicionesLegales from "./components/CondicionesLegales"
import Recuperar from "./components/Recuperar";
import FinJuegoError from "./components/errors/FinJuegoError";
import Autologin from "./components/Autologin";
import Suspension from "./components/Suspension";



import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";

const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  return (
    <Router history={history}>
      <div className="mainContainer">
        <Header></Header>
        <div>
          <Switch>
            <Route exact path={["/", "/home"]} component={Login} />
            <Route exact path={["/autologin"]} component={Autologin} />            
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/recuperar" component={Recuperar} />
            <Route exact path="/sala" component={Sala} />
            <Route exact path="/juego" component={Juego} />
            <Route exact path="/apertura" component={AperturaRueda} />
            <Route exact path="/nojuegos" component={NoJuego} />  
            <Route exact path="/moreconnection" component={MoreConnection} />                        
            <Route path="/user" component={BoardUser} />
            <Route path="/mod" component={BoardModerator} />
            <Route path="/admin" component={BoardAdmin} />
            <Route path="/demo" component={Demo} />
            <Route path="/finevento" component={FinEvento} />     
            <Route path="/top" component={Top10} />
            <Route path="/seleccionar" component={SeleccionarEvento} />  
            <Route path="/condiciones" component={CondicionesLegales} />
            <Route path="/finjuego" component={FinJuegoError} />   
            <Route path="/suspension" component={Suspension} />   
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default App;
