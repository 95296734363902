import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import {getUsuariosSinA} from "../../actions/evento";
import {processArchivoUsuarios} from "../../actions/evento";
import UploadFile from "./UploadFile"
import "../../config";
const CargarUsuarios = (props) => {    
    const { user: currentUser } = useSelector((state) => state.auth);
    
    const dispatch = useDispatch();
    let [uploadId, setUploadId] = useState("-1")
    let [fileNombre, setFileNombre] = useState("")
    let [fileNombreUrl, setFileNombreUrl] = useState("")
    
    function actualizar(){
        dispatch(processArchivoUsuarios(props.eventoSeleccionadoId,uploadId))
        .then(() => {
        })
        .catch(() => {
        });
        closeEditGU()
    }

    function closeEditGU(){
        props.closeCargarUsuarios()
    }

    function reloadDocuments(file){
        const result = JSON.parse(file);
        setUploadId(result["UploadId"])
        setFileNombre(result["fileNombre"])
        setFileNombreUrl(result["fileNombreUrl"])
    }
    

    return (
    <div className="overlay3 fade-in">            
        <div className="borderContent ventanaPerfil">
        <div className="innerShadow-height-100">
            <div id="formulario-estilo">
                <h2>
                    Carga de usuarios
                </h2>  
                <div className='vertical-noscroll customScroll'>               
                    <div className="columna-1-formulario-ventana-perfil-edit-evento-user"> 
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-link"></i> <span>Archivo:</span>                
                                </label>
                            </div>
                            <div className="col-70">                                
                                <UploadFile reloadDocuments={reloadDocuments} uploadId={uploadId} fileNombre={fileNombre} fileNombreUrl={fileNombreUrl}></UploadFile>                  
                            </div> 
                        </div>                         
                    </div> 
                        

                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizar} >
                        GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeEditGU}>
                            SALIR
                        </div>
                    </div>
                </div> 
            </div>      
        </div>
    </div>
</div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(CargarUsuarios);
