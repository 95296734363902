import axios from "axios";
import '../config'

//banner
const getBanner = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerBannerPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const updateBanner = (bannerId,codigo,descripcion,url,urlPreview,tipo,principal,estado,fechaInicio,fechaFin,urlThumbnail,openUrl) => {
  const params = {"strBannerId":bannerId,"strCodigo":codigo,"strDescripcion":descripcion,"strUrl":url,"strUrlPreview":urlPreview,"strTipo":tipo,"strPrincipal":principal,"strEstado":estado,"strFechaInicio":fechaInicio,"strFechaFin":fechaFin,"strUrlThumbnail":urlThumbnail,"strOpenUrl":openUrl}
  return axios
  .post(global.config.URL_SERVICE + "GestionBanner", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return null
    }
  });
}

const deleteBanner=(bannerId)=>{
  const params = {"strBannerId":bannerId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarBanner", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
//fin banner
//evento plantilla
const getEventoPlantilla = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerEventoPlantillaPag", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const updateEventoPlantilla = (eventoTraceTmpId,tipoEvento,espera,validaRF,descripcion, mensajeCliente,duracion,orden,manual,textoBoton,estado) => {
  const params = {"strEventoTraceTmpId":eventoTraceTmpId,"strTipoEvento":tipoEvento,"strEspera":espera,"strValidaRF":validaRF,"strDescripcion":descripcion,"strMensajeCliente":mensajeCliente,"strDuracion":duracion,"strOrden":orden,"strManual":manual,"strTextoBoton":textoBoton,"strEstado":estado}
  return axios
  .post(global.config.URL_SERVICE + "GestionEventoPlantilla", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return null
    }
  });
}

const deleteEventoPlantilla=(eventoTraceTmpId)=>{
  const params = {"strEventoTraceTmpId":eventoTraceTmpId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarEventoPlantilla", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
//parametros
const getParametrosPag = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerParametrosPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
const updateParametros = (parametroId,codigo,nombre,descripcion,valor,usuarioId,estado) => {
  const params = {"strParametroId":parametroId,"strCodigo":codigo,"strNombre":nombre,"strDescripcion":descripcion,"strValor":valor,"strUsuarioId":usuarioId,"strEstado":estado}
  return axios
  .post(global.config.URL_SERVICE + "GestionParametros", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return null
    }
  });

}

const deleteParametros=(parametroId)=>{
  const params = {"strParametroId":parametroId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarParametros", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
//fin parametros

const exeCargarTitulo = (eventoId)=>{
  const params = {"strTituloId":eventoId}
  return axios 
  .post(global.config.URL_SERVICE + "CargarTitulosEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

// item catalogo
const getItemsCatalogosPadre = ()=>{
  const params = {}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerItemsPadre", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const getItemsCatalogosPag = (catalogoId,page,filterField,filer,typeOrd,sort)=>{
  const params = {"strCatalogoId":catalogoId,"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerItemCatalogosPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const updateItemCatalogo = (itemId,codigo,nombre,activo,editar,itemPadreId,catalogoId,nombreCorto,estado,uploadId) => {
  const params = {"strItemId":itemId,"strCodigo":codigo,"strNombre":nombre,"strActivo":activo,"strEditar":editar,"strItemPadreId":itemPadreId,"strCatalogoId":catalogoId,"strNombreCorto":nombreCorto,"strEstado":estado,"strUploadId":uploadId}
  return axios
  .post(global.config.URL_SERVICE + "GestionItemsCatalogo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return null
    }
  });
}

const deleteItemCatalogo=(itemId)=>{
  const params = {"strItemId":itemId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarItemCatalogo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}


//catalogos 

const getCatalogosPadre = ()=>{
  const params = {}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerCatalogosPadre", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const getGestionCatalogosPag = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerCatalogosPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const updateGestionCatalogo = (catalogoId,codigo,nombre,activo,editar, catalogoPadreId,estado) => {
  const params = {"strCatalogoId":catalogoId,"strCodigo":codigo,"strNombre":nombre,"strActivo":activo,"strEditar":editar,"strCatalogoPadreId":catalogoPadreId,"strEstado":estado}
  return axios
  .post(global.config.URL_SERVICE + "GestionCatalogos", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return null
    }
  });
}

const deleteGestionCatalogo=(catalogoId)=>{
  const params = {"strCatalogoId":catalogoId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarCatalogo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
//fin de catalogos ObtenerCatalogosPadre

const getNoticiaEmisor = (noticiaId,page,filterField,filer,typeOrd,sort)=>{
  const params = {"strNoticiaId":noticiaId,"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
      .post(global.config.URL_SERVICE + "ObtenerNoticiasEmisorPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
      return result;  
  });
}
const updateNoticiaEmisor = (noticiaEmisorId,emisorId,noticiaId,rangoIni, rangoFin, estado) => {
  const params = {"strNoticiaEmisorId":noticiaEmisorId,"strEmisorId":emisorId,"strNoticiaId":noticiaId,"strRangoIni":rangoIni,"strRangoFin":rangoFin,"strEstado":estado}
    return axios
        .post(global.config.URL_SERVICE + "GestionNoticiaEmisor", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
          if(result===0){
            return result;  
          }else{
            return -1
          }
        });
}

const deleteNoticiaEmisor=(noticiaEmisorId)=>{
  const params = {"strNoticiaEmisorId":noticiaEmisorId}
      return axios 
          .post(global.config.URL_SERVICE + "EliminarNoticiaEmisor", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              return result;  
          });
}

const getNoticias = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
      .post(global.config.URL_SERVICE + "ObtenerNoticiasPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
      return result;  
  });
}

const updateNoticia = (noticiaId,codigo, titulo, descripcion, tipo, nivel, estado, imageUrl, tipoNoticiaId,imagenId) => {
  const params = {"strNoticiaId":noticiaId,"strCodigo":codigo,"strTitulo":titulo,"strDescripcion":descripcion,"strTipo":tipo,"strNivel":nivel,"strEstado":estado,"strImageUrl":imageUrl,"strTipoNoticiaId":tipoNoticiaId,"strImagenId":imagenId}
    return axios
        .post(global.config.URL_SERVICE + "GestionNoticia", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
          if(result===0){
            return result;  
          }else{
            return null
          }
        });
}

const deleteNoticia=(noticiaId)=>{
  const params = {"strNoticiaId":noticiaId}
      return axios 
          .post(global.config.URL_SERVICE + "EliminarNoticia", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              return result;  
          });
}

const getItemsCatalogo = (codigos) =>{
  const params = {"strCodigo":codigos}
    return axios 
        .post(global.config.URL_SERVICE + "ObtenerItemsCatalogoPorCodigoCombo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result!==null && result.length !==0){
              localStorage.setItem("catalogos", JSON.stringify(result));
              return result;  
            }else{
              return "[]"
            }
        });
}
const updatePerfilUsuario = (usuarioId,nombre,nick,email,clave,uploadId) => {
  const params = {"strUsuarioId":usuarioId,"strNombre":nombre,"strNick":nick,"strEmail":email,"strClave":clave,"strImage":"","strRol":"","strUploadId":uploadId}
    return axios
        .post(global.config.URL_SERVICE + "ActualizarUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result.length>0){              
              return result;  
            }else{
              return null
            }
        });
}
const getParameterForClient = () => {
    const params = {}
    return axios
        .post(global.config.URL_SERVICE + "ObtenerParametrosCliente", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result.length>0){
              localStorage.setItem("parameters", JSON.stringify(result));
              return result;  
            }else{
              return null
            }
        });
  }

  const getTitulos = (page,filterField,filer,typeOrd,sort)=>{
    const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
    return axios 
        .post(global.config.URL_SERVICE + "ObtenerTitulosPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
        return result;  
    });
  }
  
  const updateTitulo = (tituloId, codigo, emisor, tipoRenta, tipoValor, valorNominal, precio, rendimiento, plazo, plazoDias, estado) => {
    const params = {"strTituloId":tituloId,"strCodigo":codigo,"strEmisor":emisor,"strTipoRenta":tipoRenta,"strTipoValor":tipoValor,"strValorNominal":valorNominal,"strEstado":estado,"strPrecio":precio,"strRendimiento":rendimiento,"strPlazo":plazo,"strPlazoDias":plazoDias}
      return axios
          .post(global.config.URL_SERVICE + "GestionTitulos", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result===0){
              return result;  
            }else{
              return null
            }
          });
  }
  
  const deleteTitulo=(tituloId)=>{
    const params = {"strTituloId":tituloId}
        return axios 
            .post(global.config.URL_SERVICE + "EliminarTitulo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
            .then((response) => {
                let itemR = response["data"].split("\\\"").join("\"");          
                const result = JSON.parse(itemR);
                return result;  
            });
  }

  const getParameterForGame = () => {
    const params = {}
    return axios
        .post(global.config.URL_SERVICE + "ObtenerParametrosJuego", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result.length>0){
              localStorage.setItem("paramgame", JSON.stringify(result));
              return result;  
            }else{
              return null
            }
        });
  }
  
  
  export default {
      getParameterForClient,      
      updatePerfilUsuario,
      getItemsCatalogo,
      getNoticias,
      updateNoticia,
      deleteNoticia,
      getNoticiaEmisor,
      updateNoticiaEmisor,
      deleteNoticiaEmisor,
      getGestionCatalogosPag,
      updateGestionCatalogo,
      deleteGestionCatalogo,
      getCatalogosPadre,
      getItemsCatalogosPadre,
      getItemsCatalogosPag,
      updateItemCatalogo,
      deleteItemCatalogo,
      getTitulos,
      updateTitulo,
      deleteTitulo,
      exeCargarTitulo,
      updateParametros,
      deleteParametros,
      getParametrosPag,
      updateParametros,
      deleteParametros,
      getEventoPlantilla,
      updateEventoPlantilla,
      deleteEventoPlantilla,
      getBanner,
      updateBanner,
      deleteBanner,
      getParameterForGame
  };
    