import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {updateBanner} from "../../actions/administracion";
import "../../config";
const EditarEventoPlantilla = (props) => {
    const dispatch = useDispatch();
    let [errors] = useState({"codigoErr":""},{"urlErr":""},{"urlPreviewErr":""},{"duracionErr":""},{"ordenErr":""},{"textoBotonErr":""},{"fechaInicioErr":""},{"fechaFinErr":""},{"urlthumbnailErr":""},{"openUrlErr":""});
    let [bannerId,setBannerId] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["BannerId"]:"-1")
    let [codigo,setCodigo] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["Codigo"]:"")
    let [descripcion,setDescripcion] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["Descripcion"]:"")
    let [url,setUrl] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["Url"]:"")
    let [urlPreview,setUrlPreview] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["UrlPreview"]:"")
    let [principal,setPrincipal] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["Principal"]:"")
    let [fechaInicio,setFechaInicio] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["FechaInicio"]:new Date())
    let [fechaFin,setFechaFin] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["FechaFin"]:new Date())
    let [urlThumbnail,setUrlThumbnail] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["UrlThumbnail"]:"")
    let [openUrl,setOpenUrl] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["OpenUrl"]:"")
    
    let [validaRF,setValidaRF] =  useState(null)

    let [manual,setManual] =  useState(null)
    let [textoBoton,setTextoBoton] =  useState(props.bannerSeleccionado!==null?props.bannerSeleccionado["TextoBoton"]:"")
    
    if(props.bannerSeleccionado!==null && principal==null){
        if(props.bannerSeleccionado["Principal"]===true){
            setTipo(1)
        }else{
            setTipo(0)
        }
    }
  
    //checkbox 
    const handleCheckedPrincipal = ({ target }) => {
        target.checked===true?setPrincipal(1):setPrincipal(0)
    }



    //cerrar ventana editar
    function closeWindow(){
        props.closeEditarBanner()
    }

    let cambioEstado=useState(null)
    
    if(props.bannerSeleccionado!==null && props.bannerSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.bannerSeleccionado!==null && props.bannerSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.bannerSeleccionado===null){
        cambioEstado ={value:'A', label:"Activo"}
    }
    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 

    let cambioTipo=useState(null)
    
    if(props.bannerSeleccionado!==null && props.bannerSeleccionado["Tipo"] == "I"){
        cambioTipo ={value:'I', label:"Imagen"}
    }
    if(props.bannerSeleccionado!==null && props.bannerSeleccionado["Tipo"] == "V"){
        cambioTipo ={value:'V', label:"Video"}
    }
    if(props.bannerSeleccionado===null){
        cambioTipo ={value:'I', label:"Imagen"}
    }
    let [tipo, setTipo] =  useState(cambioTipo);
    const optionsTipo = [
        { value: 'I', label: 'Imagen' },
        { value: 'V', label: 'Video' },
    ] 
    

    function actualizar(){
        let valid=true
        
        if(codigo ==""){
            errors["codigoErr"]="Ingrese código"
            valid=false
        }
        if(url ==""){
            errors["urlErr"]="Ingrese URL"
            valid=false
        }
        if(urlPreview ==""){
            errors["urlPreviewErr"]="Ingrese URL Preview"
            valid=false
        }
        
        if(urlThumbnail ==""){
            errors["urlthumbnailErr"] = "Ingrese URL"
            valid=false
        }
        if(openUrl ==""){
            errors["openUrlErr"]="Ingrese URL"
            valid=false
        }
        if(!valid){
            return
        }
        dispatch(updateBanner(
            bannerId,
            codigo,
            descripcion,
            url,
            urlPreview,
            tipo["value"],
            principal,
            estadoSelect["value"],
            fechaInicio,
            fechaFin, 
            urlThumbnail,
            openUrl,
            "0","","","ASC",""            
            )).then(() => {     
    
             }).catch(() => {}
        );
        closeWindow()
       
    }

    return (
    <div className='overlay3 fade-in'>
        <div className="borderContent ventanaBanner">
            <div className="innerShadow3">
                <div id="formulario-estilo">
                    <h2>
                        Banner
                    </h2>
                    <div className='vertical-scroll customScroll'>
                        <div id="columna-1-formulario-edit-p"> 
                            <div className="row-form"> 
                                <div className="col-30">             
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Código:</span>
                                    </label>
                                </div>  
                                <div className="col-70">
                                    <input
                                        type="text"
                                        value={codigo}
                                        maxLength={20}
                                        placeholder="Ingresar código"
                                        className="input-form"
                                        onChange={(e) => {
                                            setCodigo(e.target.value.toUpperCase());
                                            errors["codigoErr"] = ""
                                        }}
                                    ></input>
                                    {errors["codigoErr"]!==""?<div className='camporequerido'>{errors["codigoErr"]}</div>:""}
                                </div> 
                            </div>    
                            <div className="row-form"> 
                                <div className="col-30">             
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Descripción:</span>
                                    </label>
                                </div>  
                                <div className="col-70">
                                    <textarea
                                    type="text"
                                    value={descripcion}
                                    maxLength={500}
                                    placeholder="Descripción"
                                    className="text-area-form"
                                    onChange={(e) => {
                                        setDescripcion(e.target.value);                                   
                                    }}
                                    ></textarea>                                 
                                </div>                       
                            </div>  
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-photo-video"></i> <span>Tipo:</span>                
                                    </label>
                                </div>
                                <div className="col-70">
                                    <Select  className="input-form" id="estadoSelectCombo"  value={tipo} onChange={(e) => {
                                        setTipo(e)
                                    }} placeholder="Seleccione" options={optionsTipo}/>
                                </div>
                            </div>    
                            <div className="row-form"> 
                                <div className="col-30">             
                                    <label>
                                        <i className="fas fa-link"></i> <span>Url:</span>
                                    </label>
                                </div>  
                                <div className="col-70">
                                    <input
                                        type="text"
                                        value={url}
                                        maxLength={2000}
                                        placeholder="Ingresar URL"
                                        className="input-form"
                                        onChange={(e) => {
                                            setUrl(e.target.value);
                                            errors["urlErr"] = ""
                                        }}
                                    ></input>
                                    {errors["urlErr"]!==""?<div className='camporequerido'>{errors["urlErr"]}</div>:""}
                                </div> 
                            </div>   
                            <div className="row-form"> 
                                <div className="col-30">             
                                    <label>
                                        <i className="fas fa-link"></i> <span>Url Preview:</span>
                                    </label>
                                </div>  
                                <div className="col-70">
                                    <input
                                        type="text"
                                        value={urlPreview}
                                        maxLength={2000}
                                        placeholder="Ingresar URL"
                                        className="input-form"
                                        onChange={(e) => {
                                            setUrlPreview(e.target.value);
                                            errors["urlPreviewErr"] = ""
                                        }}
                                    ></input>
                                    {errors["urlPreviewErr"]!==""?<div className='camporequerido'>{errors["urlPreviewErr"]}</div>:""}
                                </div> 
                            </div>  
                            
                                            

                        </div>

                        <div id="columna-2-formulario-edit-p">
                            {/*<div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-check-square"></i> <span>Principal:</span>
                                    </label>  
                                </div> 
                                <div className="col-70">                                       
                                    <Checkbox 
                                        right="true" 
                                        className="input-form"
                                        defaultChecked={principal} 
                                        text="Validar renta fija"
                                        onChange={handleCheckedPrincipal}
                                    />       
                                </div>                                                 
                                    </div>  */}     
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-wrench"></i> <span>Estado:</span>                
                                    </label>
                                </div>
                                <div className="col-70">
                                    <Select  className="input-form" id="estadoSelectCombo"  value={estadoSelect} onChange={(e) => {
                                        setEstadoSelect(e)
                                    }} placeholder="Seleccione" options={optionsEstado}/>
                                </div>
                            </div>  
                            <div className="row-form">
                                <div className="col-30">
                                    <label >
                                        <i className="fas fa-calendar-day"></i> <span>Fecha inicio:</span>
                                    </label>  
                                </div>  
                                <div className="col-70">                         
                                    <DatePicker                
                                        selected={ Date.parse(fechaInicio)}
                                        onChange={date => setFechaInicio(date)}
                                        showTimeSelect
                                        showMonthDropdown 
                                        showYearDropdown
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        className="input-form"
                                    />
                                </div>
                            </div>  
                            <div className="row-form">
                                <div className="col-30">
                                    <label >
                                        <i className="fas fa-calendar-day"></i> <span>Fecha fin:</span>
                                    </label>  
                                </div>  
                                <div className="col-70">                         
                                    <DatePicker                
                                        selected={ Date.parse(fechaFin)}
                                        onChange={date => setFechaFin(date)}
                                        showTimeSelect
                                        showMonthDropdown 
                                        showYearDropdown
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        className="input-form"
                                    />
                                </div>
                            </div>  
                            <div className="row-form"> 
                                <div className="col-30">             
                                    <label>
                                        <i className="fas fa-link"></i> <span>Url Thumbnail:</span>
                                    </label>
                                </div>  
                                <div className="col-70">
                                    <input
                                        type="text"
                                        value={urlThumbnail}
                                        maxLength={2000}
                                        placeholder="Ingresar URL"
                                        className="input-form"
                                        onChange={(e) => {
                                            setUrlThumbnail(e.target.value);
                                            errors["urlthumbnailErr"] = ""
                                        }}
                                    ></input>
                                    {errors["urlthumbnailErr"]!==""?<div className='camporequerido'>{errors["urlthumbnailErr"]}</div>:""}
                                </div> 
                            </div>   
                            <div className="row-form"> 
                                <div className="col-30">             
                                    <label>
                                        <i className="fas fa-link"></i> <span>Open URL:</span>
                                    </label>
                                </div>  
                                <div className="col-70">
                                    <input
                                        type="text"
                                        value={openUrl}
                                        maxLength={2000}
                                        placeholder="Ingresar URL"
                                        className="input-form"
                                        onChange={(e) => {
                                            setOpenUrl(e.target.value);
                                            errors["openUrlErr"] = ""
                                        }}
                                    ></input>
                                    {errors["openUrlErr"]!==""?<div className='camporequerido'>{errors["openUrlErr"]}</div>:""}
                                </div> 
                            </div>   
                    
                        </div>
                    </div>                
                    <div className="textCenter">
                        <div className="btnGuardar" onClick={actualizar}>
                            GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeWindow}>
                            SALIR
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarEventoPlantilla);
