import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import {updateEventoPlantilla} from "../../actions/administracion";
import "../../config";
const EditarEventoPlantilla = (props) => {
    const dispatch = useDispatch();
    let [errors] = useState({"tipoEventoErr":""},{"esperaErr":""},{"estadoErr":""},{"duracionErr":""},{"ordenErr":""},{"textoBotonErr":""});
    let [eventoTraceTmpId,setEventoTraceTmpId] =  useState(props.eventoPSeleccionado!==null?props.eventoPSeleccionado["EventoTraceTmpId"]:"-1")
    let [tipoEvento,setTipoEvento] =  useState(props.eventoPSeleccionado!==null?props.eventoPSeleccionado["TipoEvento"]:"")
    let [espera,setEspera] =  useState(props.eventoPSeleccionado!==null?props.eventoPSeleccionado["Espera"]:"0")
    let [validaRF,setValidaRF] =  useState(null)
    let [descripcion,setDescripcion] =  useState(props.eventoPSeleccionado!==null?props.eventoPSeleccionado["Descripcion"]:"")
    let [mensajeCliente,setMensajeCliente] =  useState(props.eventoPSeleccionado!==null?props.eventoPSeleccionado["MensajeCliente"]:"")
    let [duracion,setDuracion] =  useState(props.eventoPSeleccionado!==null?props.eventoPSeleccionado["Duracion"]:"0")
    let [orden,setOrden] =  useState(props.eventoPSeleccionado!==null?props.eventoPSeleccionado["Orden"]:"")
    let [manual,setManual] =  useState(null)
    let [textoBoton,setTextoBoton] =  useState(props.eventoPSeleccionado!==null?props.eventoPSeleccionado["TextoBoton"]:"")
    
    if(props.eventoPSeleccionado!==null && validaRF==null){
        if(props.eventoPSeleccionado["ValidaRF"]===true){
            setValidaRF(1)
        }else{
            setValidaRF(0)
        }
    }
    if(props.eventoPSeleccionado!==null && validaRF==null){
        if(props.eventoPSeleccionado["Manual"]===true){
            setManual(1)
        }else{
            setManual(0)
        }
    }
    
    //checkbox 
    const handleCheckedValidaRF = ({ target }) => {
        target.checked===true?setValidaRF(1):setValidaRF(0)
    }

    const handleCheckedManual = ({ target }) => {
        target.checked===true?setManual(1):setManual(0)
    }

    function closeEditEventoP (){
        props.closeCatalogo();
    }
    //cerrar ventana editar
    function closeWindow(){
        props.closeEditarEventoP()
    }

    let cambioEstado=useState(null)
    
    if(props.eventoPSeleccionado!==null && props.eventoPSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.eventoPSeleccionado!==null && props.eventoPSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.eventoPSeleccionado===null){
        cambioEstado ={value:'A', label:"Activo"}
    }
    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 


    function actualizar(){
        let valid=true
       
        if(tipoEvento == ""){
            errors["tipoEventoErr"] = "Ingrese tipo de evento"
            valid = false
        }
        if(espera == ""){
            errors["esperaErr"] = "Ingrese Tiempo de espera"
            valid = false
        }
        if(duracion==""){
            errors["duracionErr"]="Ingrese tiempo de duración"
        }
        if(orden==""){
            errors["ordenErr"]="Ingrese orden"
        }
        if(textoBoton==""){
            errors["textoBotonErr"]="Ingrese texto"
        }
        if(!valid){
            return
        }
        dispatch(updateEventoPlantilla(
            eventoTraceTmpId,
            tipoEvento,
            espera,
            validaRF,
            descripcion,
            mensajeCliente,
            duracion,
            orden,
            manual,
            textoBoton, 
            estadoSelect["value"],
            "0","","","ASC",""            
            )).then(() => {     
    
             }).catch(() => {}
        );
        closeWindow()        
    }

    return (
    <div className="overlay3 fade-in">
        <div className="borderContent ventanaBanner">
            <div className="innerShadow3">
                <div id="formulario-estilo">
                    <h2>
                        Plantilla juego 
                    </h2>
                    <div className='vertical-scroll customScroll'>
                        <div id="columna-1-formulario-edit-p"> 
                            <div className="row-form"> 
                                <div className="col-30">             
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Tipo Evento:</span>
                                    </label>
                                </div>  
                                <div className="col-70">
                                    <input
                                        type="text"
                                        value={tipoEvento}
                                        maxLength={3}
                                        placeholder="Ingresar tipo de evento"
                                        className="input-form"
                                        onChange={(e) => {
                                            setTipoEvento(e.target.value.toUpperCase());
                                            errors["tipoEventoErr"] = ""
                                        }}
                                    ></input>
                                    {errors["tipoEventoErr"]!==""?<div className='camporequerido'>{errors["tipoEventoErr"]}</div>:""}
                                </div> 
                            </div>                         
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-clock"></i> <span>Tiempo espera:</span>                  
                                    </label> 
                                </div> 
                                <div className="col-70">            
                                    <NumberFormat  className="input-form" value={espera} maxLength={3} thousandSeparator={false} allowNegative={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                        const {formattedValue, value} = values;
                                        setEspera(value);
                                        errors["esperaErr"] =""                 
                                    }}/>
                                    {errors["esperaErr"]!==""?<div className='camporequerido'>{errors["esperaErr"]}</div>:""}                          
                                </div>
                            </div>  
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Órden:</span>                  
                                    </label> 
                                </div> 
                                <div className="col-70">            
                                    <NumberFormat  className="input-form" value={orden} maxLength={3}  placeholder="Ingrese órden" thousandSeparator={false} allowNegative={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                        const {formattedValue, value} = values;
                                        setOrden(value);
                                        errors["ordenErr"] =""                 
                                    }}/>
                                    {errors["ordenErr"]!==""?<div className='camporequerido'>{errors["ordenErr"]}</div>:""}                          
                                </div>
                            </div>                              
                            <div className="row-form"> 
                                <div className="col-30">             
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Texto botón:</span>
                                    </label>
                                </div>  
                                <div className="col-70">
                                    <input
                                        type="text"
                                        value={textoBoton}
                                        maxLength={500}
                                        placeholder="Ingresar Texto botón"
                                        className="input-form"
                                        onChange={(e) => {
                                            setTextoBoton(e.target.value);
                                            errors["textoBotonErr"] = ""
                                        }}
                                    ></input>
                                    {errors["textoBotonErr"]!==""?<div className='camporequerido'>{errors["textoBotonErr"]}</div>:""}
                                </div> 
                            </div>                                                            
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-clock"></i> <span>Tiempo duración:</span>                  
                                    </label> 
                                </div> 
                                <div className="col-70">            
                                    <NumberFormat  className="input-form" value={duracion} maxLength={3} thousandSeparator={false} allowNegative={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
                                        const {formattedValue, value} = values;
                                        setDuracion(value);
                                        errors["duracionErr"] =""                 
                                    }}/>
                                    {errors["duracionErr"]!==""?<div className='camporequerido'>{errors["duracionErr"]}</div>:""}                          
                                </div>
                            </div>
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-check-square"></i> <span>Manual:</span>
                                    </label>  
                                </div> 
                                <div className="col-70">                                       
                                    <Checkbox 
                                        right="true" 
                                        className="input-form"
                                        defaultChecked={manual} 
                                        text="Manual"
                                        onChange={handleCheckedManual}
                                    />       
                                </div>                                                 
                            </div>   
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-check-square"></i> <span>Validar renta fija:</span>
                                    </label>  
                                </div> 
                                <div className="col-70">                                       
                                    <Checkbox 
                                        right="true" 
                                        className="input-form"
                                        defaultChecked={validaRF} 
                                        text="Validar renta fija"
                                        onChange={handleCheckedValidaRF}
                                    />       
                                </div>                                                 
                            </div>  
                        </div>
                        <div id="columna-2-formulario-edit-p">
                            <div className="row-form"> 
                                <div className="col-30">             
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Descripción:</span>
                                    </label>
                                </div>  
                                <div className="col-70">
                                    <textarea
                                    type="text"
                                    value={descripcion}
                                    maxLength={1500}
                                    placeholder="Ingrese Descripción"
                                    className="text-area-form-edit-evento-plantilla"
                                    onChange={(e) => {
                                        setDescripcion(e.target.value);                                   
                                    }}
                                    ></textarea>                                 
                                </div>                       
                            </div>    
                            <div className="row-form"> 
                                <div className="col-30">             
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Mensaje cliente:</span>
                                    </label>
                                </div>  
                                <div className="col-70">
                                    <textarea
                                    type="text"
                                    value={mensajeCliente}
                                    maxLength={1500}
                                    placeholder="Ingrese mensaje"
                                    className="text-area-form-edit-evento-plantilla"
                                    onChange={(e) => {
                                        setMensajeCliente(e.target.value);                                   
                                    }}
                                    ></textarea>                                 
                                </div>                       
                            </div>  
                           
                            <div className="row-form">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-wrench"></i> <span>Estado:</span>                
                                    </label>
                                </div>
                                <div className="col-70">
                                    <Select  className="input-form" id="estadoSelectCombo"  value={estadoSelect} onChange={(e) => {
                                        setEstadoSelect(e)
                                    }} placeholder="Seleccione" options={optionsEstado}/>
                                </div>
                            </div>   
                    
                        </div>
                    </div>                
                    <div className="textCenter">
                        <div className="btnGuardar" onClick={actualizar}>
                            GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeWindow}>
                            SALIR
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarEventoPlantilla);
