import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import {getUsuariosSinA} from "../../actions/evento";
import {updateGestionAyuda} from "../../actions/evento";
import {getItemsCatalogo} from "../../actions/administracion";
import "../../config";
const EditarGestionEventoAyuda = (props) => {
    const dispatch = useDispatch();
    const { listaCatalogos} = useSelector((state) => state.administracion);
    let [errors] = useState({"jugadaErr":""},{"montoErr":""},{"userSelectErr":""});
    let [tipoSelect,setTipoSelect] = useState(props.ayudaPorEventoSeleccionado!==null?props.ayudaPorEventoSeleccionado["Tipo"]:"")
    let [jugada, setJugada] = useState(props.ayudaPorEventoSeleccionado!==null?props.ayudaPorEventoSeleccionado["Jugada"]:"0")
    let [tipo] = useState("P")

    function closeEditGU (){
        props.closeEditGestionAyudaUsuario();
    }
    if(listaCatalogos===null){
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        });
    }
    let cambioEstado=useState(null)
    
    if(props.ayudaPorEventoSeleccionado!==null && props.ayudaPorEventoSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.ayudaPorEventoSeleccionado!==null && props.ayudaPorEventoSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.ayudaPorEventoSeleccionado===null){
        cambioEstado ={value:'A', label:"Activo"}
    }
    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 
    function actualizar(){
      
        let valid=true
       
        if(jugada == ""){
            errors["jugadaErr"] = "Ingrese jugada"
            valid = false
        }
        if(!valid){
            return
        }

        dispatch(updateGestionAyuda(
            props.ayudaPorEventoSeleccionado!==null?props.ayudaPorEventoSeleccionado["EventoAyudaId"]:"-1",
            props.eventoSeleccionadoId,
            jugada,
            tipo,
            estadoSelect["value"],
            "0","","","ASC",""            
            )).then(() => {     
    
             }).catch(() => {}
        );        
        closeEditGU()
    }

    return (
<div className="overlay3 fade-in">
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
            <div id="formulario-estilo">
                <h2>
                    Ayuda del evento                    
                </h2>  
                <div className='vertical-noscroll customScroll'>               
                    <div className="columna-1-formulario-ventana-perfil-edit-evento-user"> 
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-edit"></i> <span>Jugada:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <NumberFormat  
                                    className="input-form" value={jugada} thousandSeparator={false }  allowNegative={false} decimalScale={0} fixedDecimalScale={false} decimalSeparator={'.'} prefix={""}   onValueChange={(values) => {
                                    const {formattedValue, value} = values;
                                    setJugada(value);      
                                    errors["JugadaErr"]=""                              
                                }}
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    return floatValue >= 0 &&  floatValue <= 999;
                                  }}/>                                 
                                {errors["jugadaErr"]!==""?<div className='camporequerido'>{errors["jugadaErr"]}</div>:""}                                           
                            </div>
                        </div>       
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <Select  className="input-form" id="estadoSelectCombo"  value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)
                                }} placeholder="Seleccione" options={optionsEstado}/>
                            </div>
                        </div>                   
                    </div> 
                    
                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizar} >
                        GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeEditGU}>
                            SALIR
                        </div>
                    </div>
                </div> 
            </div>      
        </div>
    </div>
</div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarGestionEventoAyuda);
