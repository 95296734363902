import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

import {getTitulos,deleteTitulo,setStatusByKey} from "../../actions/administracion";
import "../../config";
import EditarTitulo from "./EditarTitulo"


const GestionTitulos = (props) => {    
    const { listaTitulos,listaTitulosCount,titulosLoading} = useSelector((state) => state.administracion);
    let [editOpen, setEditOpen] = useState("")
    let [itemSeleccionado, setItemSeleccionado] = useState("null") 
    let [getInfo, setGetInfo] = useState(false) 
       
    const dispatch = useDispatch();

    function closeGestionTitulos(){
		dispatch(setStatusByKey("listaTitulos",null))
        props.closeGestionTitulosClick();
    }
    if(listaTitulos===null && getInfo===false){
        setGetInfo(true)
        dispatch(getTitulos("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }
    const gridStyle = { minHeight: '300px',height:'calc(100% - 50px)', margin: 20 }
    let [tituloFilter, setTituloFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaTitulos!==null?listaTitulos:[]]);     

    if(listaTitulos!==null && listaTitulos!=gridInfor && tituloFilter==="")
        setGridInfor(listaTitulos)

    function filterTitulo(nego,tituloFilter){
        //value => 
        setGridInfor(nego.filter(function(item) {
            if (item["Codigo"].toLowerCase().includes(tituloFilter.toLocaleLowerCase()) || 
                item["EmisorNombre"].toLowerCase().includes(tituloFilter.toLocaleLowerCase()) ||
                item["TipoValorNombre"].toLowerCase().includes(tituloFilter.toLocaleLowerCase())
               ) 
                return true;
            return false;
        }))
    }
    
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Editar',
            onClick:()=>{   
                setItemSeleccionado(rowProps.data)
                setEditOpen("EDIT")
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar título',
                    message: '¿Seguro desea eliminar el título?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{
                            dispatch(deleteTitulo(rowProps.data["TituloId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {}
                      }
                    ]
                  });
               
            }
        },                       
        ]
    }, [])  
    let columns= [
        /*{ name: 'Codigo',header: 'Codigo', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 100, },
        { name: 'Titulo',header: 'Titulo', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 200, },*/
        { name: 'Codigo',header: 'Código', defaultFlex: 1, xdefaultLocked: 'Descripcion',minWidth: 80, },
        { name: 'EmisorNombre',header: 'Emisor', defaultFlex: 1, xdefaultLocked: 'end',minWidth: 400, },
        { name: 'TipoValorNombre',header: 'Tipo valor', defaultFlex: 1, xdefaultLocked: 'end' },          
        { name: 'ValorNominal',header: 'Valor nominal', defaultFlex: 1, xdefaultLocked: 'end' },          
        { name: 'Precio',header: 'Precio', defaultFlex: 1, xdefaultLocked: 'end' },          
        { name: 'Rendimiento',header: 'Rendimiento', defaultFlex: 1, xdefaultLocked: 'end' },          
        { name: 'Plazo',header: 'Plazo', defaultFlex: 1, xdefaultLocked: 'end' },          
        
    ]

    const closeEdit = ()=>{
        setEditOpen("")
    }
    const crearNuevo = ()=>{
        setItemSeleccionado(null)
        setEditOpen("EDIT")
    } 

return (
<div className="windowContentInner fade-in"> 
    <div className={['loading_cont',titulosLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>    
    {editOpen==="EDIT" ? <EditarTitulo closeEdit={closeEdit} itemSeleccionado={itemSeleccionado} />  : ""}  
    <div className="borderContent ventanaGeneral">
        <div className="innerShadow-height-100">
            <h2>
            Administración de títulos
            <div className="floatright"> 
                <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                    <MenuItem onClick={crearNuevo}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                </Menu>
            </div>
            </h2> 
            <div className="titleTable-search"><div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={tituloFilter} onChange={(e) => {
                  setTituloFilter(e.target.value);
                  filterTitulo(listaTitulos!==null?listaTitulos:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div>
            <DataGrid
                idProperty="TituloId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}
                selection={{ mode: 'single' }}                    
                dataSource={gridInfor}
                renderRowContextMenu={renderRowContextMenu}
                theme="amber-dark"
            />
             
            <div className="dividerLine"></div>
            <div className="textCenter">
                <div className="btnCancelar" onClick={closeGestionTitulos}>
                    SALIR
                </div>
            </div>      
        </div>       
    </div>
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionTitulos);