import {
    GET_CLIENT_PARAMETERS,
    SET_MESSAGE,
    PARAMETERS_FAIL,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_FAIL,
    GET_ITEMS_CATALOGUE,
    GET_ITEMS_CATALOGUE_FAIL,
	  UPDATE_USER,
    UPDATE_USER_FAIL,
    GET_USERS,
    GET_USERS_FAIL,
    DELETE_USER,
    DELETE_USER_FAIL,
    GET_NEWS,
    GET_NEWS_INIT,
    GET_NEWS_FAIL,
    UPDATE_STATUS_BY_KEY_ADM,
    UPDATE_NEWS,
    UPDATE_NEWS_FAIL,
    DELETE_NEWS,
    DELETE_NEWS_FAIL,
    GET_NEWS_EMISOR,
    GET_NEWS_EMISOR_INIT,
    GET_NEWS_EMISOR_FAIL,
    UPDATE_NEWS_EMISOR,
    UPDATE_NEWS_EMISOR_FAIL,
    DELETE_NEWS_EMISOR,
    DELETE_NEWS_EMISOR_FAIL,
    GET_GESTION_CATALOGOS,
    GET_GESTION_CATALOGOS_FAIL,
    UPDATE_GESTION_CATALOGOS,
    UPDATE_GESTION_CATALOGOS_FAIL,
    DELETE_GESTION_CATALOGOS,
    DELETE_GESTION_CATALOGOS_FAIL,
    GET_CATALOGOS_PADRE,
    GET_CATALOGOS_PADRE_FAIL,
    GET_ALL_GESTION_EVENT_LIST_SERVICE_FAIL,
    GET_ITEMS_CATALOGOS_PADRE,
    GET_ITEMS_CATALOGOS_PADRE_FAIL,
    DELETE_ITEM_CATALOGO,
    DELETE_ITEM_CATALOGO_FAIL,
    UPDATE_ITEM_CATALOGO,
    UPDATE_ITEM_CATALOGO_FAIL,
    GET_ITEM_CATALOGOS,
    GET_ITEM_CATALOGOS_FAIL,
    GET_TITLE,
    GET_TITLE_INIT,
    GET_TITLE_FAIL,
    UPDATE_TITLE,
    UPDATE_TITLE_FAIL,
    DELETE_TITLE,
    DELETE_TITLE_FAIL,
    LOAD_EVENT_TITLE,
    LOAD_EVENT_TITLE_INIT,
    LOAD_EVENT_TITLE_FAIL,
    GET_PARAMETROS_INI,
    GET_PARAMETROS,
    GET_PARAMETROS_FAIL,
    UPDATE_PARAMETROS,
    UPDATE_PARAMETROS_FAIL,
    DELETE_EVENTO_PLANTILLA,
    DELETE_EVENTO_PLANTILLA_FAIL,
    UPDATE_EVENTO_PLANTILLA,
    UPDATE_EVENTO_PLANTILLA_FAIL,
    GET_EVENTO_PLANTILLA,
    GET_EVENTO_PLANTILLA_INI,    
    GET_EVENTO_PLANTILLA_FAIL,
    DELETE_BANNER,
    DELETE_BANNER_FAIL,
    UPDATE_BANNER,
    UPDATE_BANNER_FAIL,
    GET_BANNER,
    GET_BANNER_FAIL,
    BANNER_INI,
    SEND_EMAIL_PASSWORD,
    SEND_EMAIL_PASSWORD_FAIL,
    RECUPERAR_INI,
    USUARIOS_INI,
    CATALOGOS_INI,
    ITEMS_INI,

} from "../actions/types";

const listParams = ( localStorage.getItem("parameters")!==null && localStorage.getItem("parameters")!=="")?JSON.parse(localStorage.getItem("parameters")):[];
const listParamsGame = ( localStorage.getItem("paramgame")!==null && localStorage.getItem("paramgame")!=="")?JSON.parse(localStorage.getItem("paramgame")):[];
const listaCatalogos = ( localStorage.getItem("catalogos")!==null && localStorage.getItem("catalogos")!=="")?JSON.parse(localStorage.getItem("catalogos")):null;

const initialState = {
    listParams : listParams,   
    listaCatalogos: listaCatalogos,  
    listaUsuarios:null,
    listaUsuariosCount:0,
    mostrarEventoError: false,
    iniciaEventoAccion: false,				  
    listaNoticias: null,
    listaNoticiasCount: null,
    noticiasLoading:false,
    noticiasEmisorLoading: false,
    listaNoticiasEmisor: null,
    listaNoticiasEmisorCount: null,
    listaCatalogosPag:null,
    listaCatalogosPadre:null,
    listaItemsPag:null,
    listaItemsPadre:null,
    listaItemsCount:0,
    listaCatalogosCount:0,
    mostrarEventoError: false,
    iniciaEventoAccion: false,
    listaTitulos: null,
    listaTitulosCount: null,
    titulosLoading:false,
    cargarTitulosLoading: false,
    listaParametrosPag:null,
    listaParametrosCount:0,
    cargandoParametros: false,
    listaEventoPlantillaPag:null,
    listaEventoPlantillaCount:0,
    plantillaLoading: false,
    listaBannerPag:null,
    listaBannerCount:0,
    bannerLoading: false,
    recuperarLoading:false,
    usuariosLoading:false,
    catalogosLoading:false,
    itemsLoading:false,
    listaEventoAyuda:false,
    listParamsGame:listParamsGame
}

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case UPDATE_STATUS_BY_KEY_ADM:
        switch(payload.key){
          case 'listaNoticias':
            return {
              ...state,
              listaNoticias:payload.value,                
            };
          case 'listaItemsPag':
              return {
                ...state,
                listaItemsPag:payload.value,                
              };
          case 'listaNoticiasEmisor':
            return {
              ...state,
              listaNoticiasEmisor:payload.value,                
            };
          case 'listaTitulos':
            return {
              ...state,
              listaTitulos:payload.value,                
            };
          case 'listaEventoAyuda':   
          return {
            ...state,
            listaEventoAyuda:payload.value,                
          };      
          case 'listParamsGame':
            return {
              ...state,
              listParamsGame:payload.value,                
            };      
        }
        break
        case GET_CLIENT_PARAMETERS:
            return {
                ...state,
                listParams: payload.data                
              };
        case PARAMETERS_FAIL: 
          return {
            ...state,
            listEventos: [],
          };
          case UPDATE_USER_PROFILE:
            return {
                ...state,             
              };
        case UPDATE_USER_PROFILE_FAIL: 
          return {
            ...state,
          };
        case GET_ITEMS_CATALOGUE:
            return {
              ...state,             
              listaCatalogos: payload.data
            };
        case GET_ITEMS_CATALOGUE_FAIL: 
          return {
            ...state,
          };
		case GET_USERS:            
          return {
              ...state,             
              listaUsuarios: payload.data[0],
              listaUsuariosCount: payload.data[1],
              usuariosLoading:false
          };
        case GET_USERS_FAIL: 
          return {
              ...state,
              usuariosLoading:false
          }; 
          case    DELETE_USER:
            return {
                ...state,   
                mostrarEventoError: false,
                iniciaEventoAccion: false,    
                usuariosLoading:false    
              };
        case  DELETE_USER_FAIL: 
          return {
            ...state,
            mostrarEventoError: true,
            iniciaEventoAccion: false,
            usuariosLoading:false
          };
          case UPDATE_USER:
            return {
                ...state,      
                usuariosLoading:false       
              };
        case UPDATE_USER_FAIL: 
          return {
            ...state,
            usuariosLoading:false
          };	  
        case GET_NEWS:            
          return {
              ...state,             
              listaNoticias: payload.data[0],
              listaNoticiasCount: payload.data[1],
              noticiasLoading:false
          };
        case GET_NEWS_INIT:            
          return {
              ...state,             
              noticiasLoading:true
          };
        case GET_NEWS_FAIL:            
          return {
              ...state,             
              listaNoticias: null,
              listaNoticiasCount: null,
              noticiasLoading:false
          };
        case UPDATE_NEWS:            
          return {
              ...state,             
              noticiasLoading:false
          };    
        case UPDATE_NEWS_FAIL:            
          return {
              ...state,             
              noticiasLoading:false
          };      
        case DELETE_NEWS:            
          return {
              ...state,             
              noticiasLoading:false
          };          
        case DELETE_NEWS_FAIL:            
          return {
              ...state,             
              noticiasLoading:false
          };        
        case GET_NEWS_EMISOR_INIT:            
          return {
              ...state,             
              noticiasEmisorLoading:true
          };
        case GET_NEWS_EMISOR:            
          return {
              ...state,             
              listaNoticiasEmisor: payload.data[0],
              listaNoticiasEmisorCount: payload.data[1],
              noticiasEmisorLoading:false
          };
        case GET_NEWS_EMISOR_FAIL:            
          return {
              ...state,             
              listaNoticiasEmisor: null,
              listaNoticiasEmisorCount: null,
              noticiasEmisorLoading:false
          };
        case UPDATE_NEWS_EMISOR:            
          return {
              ...state,             
              noticiasEmisorLoading:false
          };
        case UPDATE_NEWS_EMISOR_FAIL:            
          return {
              ...state,             
              noticiasEmisorLoading:false
          };
        case DELETE_NEWS_EMISOR:            
          return {
              ...state,             
              noticiasEmisorLoading:false
          };
        case DELETE_NEWS_EMISOR_FAIL:            
          return {
              ...state,             
              noticiasEmisorLoading:false
          };
        case GET_GESTION_CATALOGOS:
          return {
            ...state,             
            listaCatalogosPag: payload.data[0],
            listaCatalogosCount: payload.data[1],
            catalogosLoading:false,
            };
        case GET_GESTION_CATALOGOS_FAIL: 
          return {
            ...state,
            catalogosLoading:false,
          }; 
        case UPDATE_GESTION_CATALOGOS:
            return {
                ...state,    
                catalogosLoading:false         
              };
        case UPDATE_GESTION_CATALOGOS_FAIL: 
          return {
            ...state,
            catalogosLoading:false
          };            
        case DELETE_GESTION_CATALOGOS:
            return {
                ...state,   
                mostrarEventoError: false,
                iniciaEventoAccion: false,   
                catalogosLoading:false     
              };
        case  DELETE_GESTION_CATALOGOS_FAIL: 
          return {
            ...state,
            mostrarEventoError: true,
            iniciaEventoAccion: false,
            catalogosLoading:false
          };
          case GET_CATALOGOS_PADRE:
            return {
              ...state,             
              listaCatalogosPadre: payload.data,
              };
          case GET_CATALOGOS_PADRE_FAIL: 
            return {
              ...state,
            }; 
          case GET_ITEM_CATALOGOS:
            return {
              ...state,             
              listaItemsPag: payload.data[0],
              listaItemsCount: payload.data[1],
              itemsLoading:false
              };
          case GET_ITEM_CATALOGOS_FAIL: 
            return {
              ...state,
              itemsLoading:false
            }; 
            case UPDATE_ITEM_CATALOGO:
            return {
                ...state,   
                itemsLoading:false          
              };
        case UPDATE_ITEM_CATALOGO_FAIL: 
          return {
            ...state,
            itemsLoading:false
          };            
        case DELETE_ITEM_CATALOGO:
            return {
                ...state,   
                mostrarEventoError: false,
                iniciaEventoAccion: false,        
                itemsLoading:false
              };
        case  DELETE_ITEM_CATALOGO_FAIL: 
          return {
            ...state,
            mostrarEventoError: true,
            iniciaEventoAccion: false,
            itemsLoading:false
          };
        case GET_ITEMS_CATALOGOS_PADRE:
          return {
            ...state,             
            listaItemsPadre: payload.data,
            };
        case GET_ITEMS_CATALOGUE_FAIL: 
          return {
            ...state,
          }; 
        case GET_TITLE:            
          return {
              ...state,             
              listaTitulos: payload.data[0],
              listaTitulosCount: payload.data[1],
              titulosLoading:false
          };
        case GET_TITLE_INIT:            
          return {
              ...state,             
              titulosLoading:true
          };
        case GET_TITLE_FAIL:            
          return {
              ...state,             
              listaTitulos: null,
              listaTitulosCount: null,
              titulosLoading:false
          };
        case UPDATE_TITLE:            
          return {
              ...state,             
              titulosLoading:false
          };    
        case UPDATE_TITLE_FAIL:            
          return {
              ...state,             
              titulosLoading:false
          };      
        case DELETE_TITLE:            
          return {
              ...state,             
              titulosLoading:false
          };          
        case DELETE_TITLE_FAIL:            
          return {
              ...state,             
              titulosLoading:false
          };        
        case LOAD_EVENT_TITLE:            
          return {
              ...state,             
              cargarTitulosLoading:false
          }; 
        case LOAD_EVENT_TITLE_INIT:            
          return {
              ...state,             
              cargarTitulosLoading:true
          }; 
        case LOAD_EVENT_TITLE_FAIL:            
          return {
              ...state,             
              cargarTitulosLoading:false
          }; 
        case GET_PARAMETROS:
          return {
            ...state,             
            listaParametrosPag: payload.data[0],
            listaParametrosCount: payload.data[1],
            cargandoParametros: false
            };
        case GET_PARAMETROS_FAIL: 
          return {
            ...state,
            cargandoParametros: false
          }; 
        case UPDATE_PARAMETROS:
          return {
              ...state,             
              cargandoParametros: false
            };
        case UPDATE_PARAMETROS_FAIL: 
          return {
            ...state,
            cargandoParametros: false
          };            
        case GET_PARAMETROS_INI: 
          return {
            ...state,
            cargandoParametros: true
          };            
          case GET_EVENTO_PLANTILLA:            
          return {
              ...state,             
              listaEventoPlantillaPag: payload.data[0],
              listaEventoPlantillaCount: payload.data[1],
              plantillaLoading: false
          };
        case GET_EVENTO_PLANTILLA_FAIL: 
          return {
              ...state,
              plantillaLoading: false
          };     
        case UPDATE_EVENTO_PLANTILLA:
            return {
                ...state,            
                plantillaLoading: false 
              };
        case UPDATE_EVENTO_PLANTILLA_FAIL: 
        return {
            ...state,
            plantillaLoading: false
          };   
        case DELETE_EVENTO_PLANTILLA:
          return {
              ...state,   
              mostrarEventoError: false,
              iniciaEventoAccion: false,  
              plantillaLoading: false      
            };
        case DELETE_EVENTO_PLANTILLA_FAIL: 
          return {
            ...state,
            mostrarEventoError: true,
            iniciaEventoAccion: false,
            plantillaLoading: false
          };     
          case GET_EVENTO_PLANTILLA_INI: 
          return {
            ...state,
            plantillaLoading:true
          };     
          case GET_BANNER:            
            return {
                ...state,             
                listaBannerPag: payload.data[0],
                listaBannerCount: payload.data[1],
                bannerLoading: false,
                
            };
          case GET_BANNER_FAIL:            
            return {
                ...state,      
                bannerLoading: false,       
               
            }; 
          case DELETE_BANNER:
            return {
                ...state,   
                mostrarEventoError: false,
                iniciaEventoAccion: false,  
                bannerLoading: false,
                  
              };
          case DELETE_BANNER_FAIL: 
            return {
              ...state,
              mostrarEventoError: true,
              iniciaEventoAccion: false,
              bannerLoading: false,

            };  
          case UPDATE_BANNER:
              return {
                  ...state,            
                  bannerLoading: false,
                };
          case UPDATE_BANNER_FAIL: 
          return {
              ...state,
               bannerLoading: false,
            };       
          case BANNER_INI: 
          return {
            ...state,
            bannerLoading:true
          };   
          case SEND_EMAIL_PASSWORD:
            return {
                ...state,            
                recuperarLoading:false,
              };
          case SEND_EMAIL_PASSWORD_FAIL: 
            return {
              ...state,
              recuperarLoading:false,
            };    
          case RECUPERAR_INI: 
            return {
              ...state,
              recuperarLoading:true
            };   
          case USUARIOS_INI: 
          return {
            ...state,
            usuariosLoading:true
          };   
          case CATALOGOS_INI: 
          return {
            ...state,
            catalogosLoading:true
          };  
        case ITEMS_INI: 
          return {
            ...state,
            itemsLoading:true
          }; 
        default:
            return state;
    }
  }


