import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import {updateNoticia,getItemsCatalogo} from "../../actions/administracion";
import UploadFile from './UploadFile'
import "../../config";

const EditarNoticia = (props) => {
    const dispatch = useDispatch();

    const { user: currentUser } = useSelector((state) => state.auth);
    const { listaCatalogos} = useSelector((state) => state.administracion);

    if(listaCatalogos===undefined || listaCatalogos===null){
        dispatch(getItemsCatalogo(global.config.LISTA_CATALOGOS))
        .then(() => {
        })
        .catch(() => {
        });
      }

    let [noticiaId, setNoticiaId] = useState(props.noticiaSeleccionado!==null?props.noticiaSeleccionado["NoticiaId"]:"-1")
    let [descripcion, setDescripcion] = useState(props.noticiaSeleccionado!==null?props.noticiaSeleccionado["Descripcion"]:"")    
    let [tipo, setTipo] = useState({"value":props.noticiaSeleccionado!==null?props.noticiaSeleccionado["Tipo"]:"-1","label":props.noticiaSeleccionado!==null?props.noticiaSeleccionado["TipoNombre"]:""});
    let [nivel, setNivel] = useState({"value":props.noticiaSeleccionado!==null?props.noticiaSeleccionado["Nivel"]:"-1","label":props.noticiaSeleccionado!==null?props.noticiaSeleccionado["NivelNombre"]:""});
    let [uploadId, setUploadId] = useState(props.noticiaSeleccionado!==null?props.noticiaSeleccionado["UploadId"]:"-1")
    let [tipoNoticiaId, setTipoNoticiaId] = useState({"value":props.noticiaSeleccionado!==null?props.noticiaSeleccionado["TipoNoticiaId"]:"-1","label":props.noticiaSeleccionado!==null?props.noticiaSeleccionado["TipoNoticiaEspNombre"]:""});    
    let [fileNombre, setFileNombre] = useState(props.noticiaSeleccionado!==null?props.noticiaSeleccionado["fileNombre"].toString():"")
    let [fileNombreUrl, setFileNombreUrl] = useState(props.noticiaSeleccionado!==null?props.noticiaSeleccionado["fileNombreUrl"].toString():"")
    
    let [errors] = useState({"Descripcion":""},{"Tipo":""},{"Nivel":""},{"Estado":""});
    
    let cambioEstado={value:'A', label:"Activo"}
    
    if(props.noticiaSeleccionado!==null && props.noticiaSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.noticiaSeleccionado!==null && props.noticiaSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }

    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 
    function reloadDocuments(file){
        const result = JSON.parse(file);
        setUploadId(result["UploadId"])
        setFileNombre(result["fileNombre"])
        setFileNombreUrl(result["fileNombreUrl"])
      }
    function actualizarU(){        
        let valid=true
        
        if(descripcion === ""){
            errors["Descripcion"] = "Ingrese una descripción"
            valid = false
        }
        if(tipo["value"] === "-1"){
            errors["Tipo"] = "Seleccione el tipo"
            valid = false
        }
        if(nivel["value"] === "-1"){
            errors["Nivel"] = "Seleccione un nivel"
            valid = false
        }
        if(!valid){
            return
        }
        dispatch(updateNoticia(
            props.noticiaSeleccionado!==null?props.noticiaSeleccionado["NoticiaId"]:"-1",
            '',
            '',
            descripcion,
            tipo["value"],
            nivel["value"],
            estadoSelect["value"],
            '',
            tipoNoticiaId["value"],
            uploadId
            )).then(() => {     
    
            }).catch(() => {}
        );
        props.closeEdit()
    }
return (
<div className="overlay3 fade-in">
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
            <div id="formulario-estilo">
                <h2>
                    Noticias
                </h2>  
                <div className='vertical-scroll customScroll'>      
                    <div className="columna-1-formulario-ventana-noticia">
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Descripción:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <textarea
                                type="text"
                                value={descripcion}
                                maxLength={1500}
                                placeholder="Texto de la noticia"
                                className="text-area-form"
                                onChange={(e) => {
                                    setDescripcion(e.target.value);
                                    errors["Descripcion"] = "" 
                                }}
                                ></textarea> 
                                {errors["Descripcion"]!==""?<div className='camporequerido'> {errors["Descripcion"]}</div>:""}                                     
                            </div>                       
                        </div>   
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Tipo:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={tipo} onChange={(e) => {
                                    setTipo(e)                 
                                    errors["Tipo"] = ""
                                }} placeholder="Seleccione" options={listaCatalogos!==null && listaCatalogos["TIPO_NOT"]?listaCatalogos["TIPO_NOT"]:[]}
                                /> 
                                {errors["Tipo"]!==""?<div className='camporequerido'> {errors["Tipo"]}</div>:""}
                            </div>                       
                        </div>    
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Nivel:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={nivel} onChange={(e) => {
                                    setNivel(e)
                                    errors["Nivel"] = ""
                                }} placeholder="Seleccione" options={listaCatalogos!==null && listaCatalogos["NIVELES"]?listaCatalogos["NIVELES"]:[]}
                                /> 
                                {errors["Nivel"]!==""?<div className='camporequerido'> {errors["Nivel"]}</div>:""}
                            </div>                       
                        </div>     
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Tipo Espacial:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={tipoNoticiaId} onChange={(e) => {
                                    setTipoNoticiaId(e)                                                                                                                                                                                            
                                }} placeholder="Seleccione" options={listaCatalogos!==null && listaCatalogos["NOT_ESPECIAL"]?listaCatalogos["NOT_ESPECIAL"]:[]}
                                /> 
                            </div>                       
                        </div>                  
                        <div className="row-form"> 
                            <div className="col-30">             
                            <label>
                                <i className="fas fa-file-image"></i> <span>Imagen:</span>   
                            </label>   
                            </div> 
                            <div className="col-70">     
                            <UploadFile reloadDocuments={reloadDocuments} uploadId={uploadId} fileNombre={fileNombre} fileNombreUrl={fileNombreUrl}></UploadFile>                  
                            </div> 
                        </div> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)                                                                                                                                                                                            
                                    }} placeholder="Seleccione" options={optionsEstado!==null?optionsEstado:[]}
                                /> 
                            </div>                       
                        </div> 

                        
                    </div>
                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizarU} >
                            GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={props.closeEdit}>
                            SALIR
                        </div>
                    </div>
                </div>
            </div>      
        </div>
    </div>
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarNoticia);
