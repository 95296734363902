import React, { useState, useEffect } from "react";
import { connect,useDispatch,useSelector } from "react-redux";

import {getMax,formatNumber,getTotalAccion,formatDateMont,enableFuncionalityForRole} from "./general/Util"
import ItemPortafolioRV from './juego/ItemPortafolioRV';
import ItemPortafolioRF from './juego/ItemPortafolioRF';

const Portafolio = (props) => {    
    const { estadoCuentaCliente: currentTitle,loadEstadoCuenta:loadEstadoCuenta,estadoCuentaClienteEfec:estadoCuentaClienteEfec } = useSelector((state) => state.juego);

	const { user: currentUser } = useSelector((state) => state.auth);  
	let [enableSend, setEnableSend] = useState(currentUser!==null?enableFuncionalityForRole(currentUser["role"],"ADM"):false);
    function btnCerrarPortafolio(){
        props.btnCerrarPortafolio()
    }
    function btnSendViewPortafolio(){
        props.sendViewPortafolio()
    }
    return (
        <div className='ventanaAyudaPortafolio'>
        <div className='content'>
            <div className="borderContent tableContent">
                <div className="innerShadow3 customScroll">
                    <h2 className='textcenter'>EL JUEGO DE LA BOLSA - INVESTOR III<i className="far fa-times-circle cursor-pointer" onClick={btnCerrarPortafolio}></i></h2>
                    <div className='ventana-portafolio'>
                    {/*RESUMEN PORTAFOLIO - {props.namePortafolio.toUpperCase()}*/}
                    <div className="titleCont">      
                        <div><div className='label-port1'>REPORTE DE JUGADOR:</div><span className=''>{props.namePortafolio.toUpperCase()}</span></div>                      
                        <div ><div className='label-port1'>FECHA:</div><span className=''>{formatDateMont(new Date())}</span></div>
                        <div><div className='label-port1'>EVENTO:</div><span className=''>{props.nombreEvento.toUpperCase()}</span></div>
                        <div><div className='label-port1'>JUGADA:</div><span className=''>{props.jugada}/{props.duracion}</span></div>
                        <div><div className='label-port1-rigth'>Reporte de Portafolio y Dinero</div></div>
                    </div>                     
                    
                    <div className={["titleCont",currentTitle!=null && currentTitle.filter(value => value["TipoRentaCod"] === 'RV').length>0?"":"displaynone"].join(' ')}>
                        <div className="titleCont">
                            <div className="dividerLine"></div>
                            <h3>RENTA VARIABLE</h3>    
                        </div>                                            
                    </div> 
                    <div className={["tableContainer",currentTitle!=null && currentTitle.filter(value => value["TipoRentaCod"] === 'RV').length>0?"":"displaynone"].join(' ')}>  
                        
                        {
                            currentTitle!=null?currentTitle.filter(value => value["TipoRentaCod"] === 'RV').map((item, i) => { 
                                return <ItemPortafolioRV key={i} index={i} item={item} evento={props.eventoId} jugada={props.jugada} maxValue={getMax(currentTitle.filter(value => value["TipoRentaCod"] === 'RV'),"SaldoTitulo")} compra={false}/>
                            }):""
                        }                             
                        <div className="rowCustom">
                            <div className="fiveColPortafolioTotal">TOTAL DINERO EN ACCIONES</div>
                            <div className="fourColPortafolioTotal">${formatNumber(getTotalAccion(currentTitle.filter(value => value["TipoRentaCod"] === 'RV')))} </div>
                            
                        </div> 
                    </div>                    

                    <div className={["titleCont",currentTitle!=null && currentTitle.filter(value => value["TipoRentaCod"] === 'RF').length>0?"":"displaynone"].join(' ')}>
                        <div className="dividerLine"></div>
                        <div className="titleCont">
                            <h3>RENTA FIJA</h3>                        
                        </div>                        
                    </div>
                    {                        
                        currentTitle!=null?currentTitle.filter(value => value["TipoRentaCod"] === 'RF').map((item, i) => { 
                            return <ItemPortafolioRF key={i} index={i} item={item} evento={props.eventoId} jugada={props.jugada} maxValue={getMax(currentTitle.filter(value => value["TipoRentaCod"] === 'RF'),"SaldoTitulo")} compra={false}/>
                        }):""
                    }

                    <div className="dividerLine"></div>
                    <div className="tableContainer">
                        <div className="rowCustom">
                            <div className="fiveColPortafolioTotal">SALDO EN EFECTIVO</div>
                            <div className="fourColPortafolioTotal">${formatNumber(estadoCuentaClienteEfec)}</div>
                        </div>
                    </div> 
                    <div className="dividerLine"></div>
                    <div className="tableContainer">
                        <div className="rowCustom">
                            <div className="fiveColPortafolioTotal">TOTAL</div>
                            <div className="fourColPortafolioTotal">${formatNumber(estadoCuentaClienteEfec + getTotalAccion(currentTitle.filter(value => value["TipoRentaCod"] === 'RV')))}</div>
                        </div>
                    </div>                     
                    </div>
                    </div>                    
                </div>
            </div>
            <div className="btnContainer">{enableSend===true?<div className="btnGuardar" onClick={btnSendViewPortafolio} title='Envía el estado de cuenta al usuario'>ENVIAR</div>:""}<div className="btnCancelar" onClick={btnCerrarPortafolio}>Cerrar</div></div>
        </div>
    );
}
export default Portafolio;