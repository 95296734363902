import React, { Component } from "react";
import NumberFormat from 'react-number-format'
import marca1 from '../../image/marca1.png';
import marca2 from '../../image/marca2.png';
import '../../config'
import {formatNumber} from "../general/Util"

class ItemNegociacionRF extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            compra: '',
            venta: '',
            barra: {
                width: '100%',
            } 
        }
    }
    
    componentDidUpdate(prevProps) {
        try{
            if (prevProps.refreshInput !== this.props.refreshInput && this.props.refreshInput===true) {
            this.setState({compra: 0,
                venta: 0,
                typeVenta: "",
                typeCompra: "" })
            }
        }catch(ex){
            
        }
      }
   
    styles = {
        barra: {
            width: '100%'
        }        
    }

    getStyleByIndex(index){
        switch(index){
            case 0:
                return "bgYellow"
            case 1:
                return "bgPurple"
            case 2:
                return "bgRed"
            case 3:
                return "bgGreen"
            case 4:
                return "bgYellow"
            case 5:
                return "bgPurple"
            case 6:
                return "bgRed"
            case 7:
                return "bgGreen"
        }
    }
    render() {
        return (
            <div className="rowCustom2" title={this.props.item["Emisor"] + " - $ " + formatNumber(this.props.item["Precio"])}>
                <div className="oneCol"><div className="imgLogo gris"><img src={this.props.item["fileNombreUrl"]!==""?global.config.URL_UPLOAD + this.props.item["fileNombreUrl"]:global.config.URL_IMAGE_EMISOR + this.props.item["EmisorCodigo"]  + ".png"} onError={(e) => e.target.src = global.config.URL_IMAGE_EMISOR + 'onerror-rf.png'} alt="Logo1" /></div></div>
                <div className="twoCol">{this.props.item["Emisor"]}
                <div className="bold size14">{this.props.item["TipoValorNombre"]}</div>
                </div>                
                <div className="threeCol-rf">                    
                    <div className="blueStyle size14">
                        <div className='rflabelsizeNeg'>Valor unitario:</div>
                        <NumberFormat value={this.props.item["Precio"]} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} thousandSeparator={','} decimalSeparator={'.'} prefix={"$ "}/>
                    </div>
                    <div className="blueStyle size14">
                        <div className='rflabelsizeNeg'>Interés:</div>
                        <NumberFormat value={this.props.item["Rendimiento"]} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} thousandSeparator={','} decimalSeparator={'.'} suffix={" %"}/>
                    </div>
                    <div className="blueStyle size14">                
                        <div className='rflabelsizeNeg'>Plazo/Vigencia:</div>{this.props.item["Maduracion"] + "/" + this.props.item["Plazo"]}
                        {/*<NumberFormat value={this.props.item["PlazoDias"]} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false} thousandSeparator={','} decimalSeparator={'.'} suffix={" días"}/>*/}
                    </div>
                </div>                            
                <div className="fourCol">
                    <div className={["cantidadCustom",this.props.item["Maduracion"]===1?"":"disabledelement"].join(' ')}>
                        <NumberFormat placeholder='0' value={this.state.compra} disabled={this.props.item["Maduracion"]===1?false:true}  thousandSeparator={true} decimalScale={0} fixedDecimalScale={true} thousandSeparator={','} decimalSeparator={'.'} prefix={""} onValueChange={(values) => {
            const {formattedValue, value} = values;
            this.props.updateValueItemsCompra("RF" + this.props.index,this.props.item["EventoTituloId"],value!==""?value:"0",this.props.item["Precio"])
            this.setState({compra: value})//!==""?value:"0"
        }}/>
                    </div>
                </div>
            </div>
        )
    }
}
export default ItemNegociacionRF;