import React from "react";
import ItemRentaFija from "./ItemRentaFija"

const RentaFija = (props) => {  
    const { titulos } = props;
  return (
    <div> 
        {
            titulos.map((item, i) => { 
                if(item["TipoRentaCod"]==="RF"){
                    return <ItemRentaFija key={i} item={item}/>
                }else{
                    return ""
                }
            })
        }             
    </div>
  );
};

export default RentaFija;