import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import {updateUsuario} from "../../actions/administracion";
import UploadFile from "./UploadFile"
import "../../config";
const EditarGestionUsuario = (props) => {
    const dispatch = useDispatch();
    let [userId, setUserId] = useState(props.usuarioSeleccionado!==null?props.usuarioSeleccionado["UsuarioId"]:"-1")
    let [errors] = useState({"nombreErr":""},{"nickErr":""},{"emailErr":""},{"rolErr":""});
    let [nombre, setNombre] = useState(props.usuarioSeleccionado!==null?props.usuarioSeleccionado["Nombre"].toString():"")
    let [nick, setNick] = useState(props.usuarioSeleccionado!==null?props.usuarioSeleccionado["Nick"].toString():"")
    let [email, setEmail] = useState(props.usuarioSeleccionado!==null?props.usuarioSeleccionado["Email"].toString():"")
    let [rol, setRol] = useState(props.usuarioSeleccionado!==null?props.usuarioSeleccionado["Rol"]:"")
    let [uploadId, setUploadId] = useState(props.usuarioSeleccionado!==null?props.usuarioSeleccionado["UploadId"]:"-1")
    let [fileNombre, setFileNombre] = useState(props.usuarioSeleccionado!==null?props.usuarioSeleccionado["fileNombre"]:"")
    let [fileNombreUrl, setFileNombreUrl] = useState(props.usuarioSeleccionado!==null?props.usuarioSeleccionado["fileNombreUrl"]:"")
    let [clave, setClave] = useState(null)
    let cambioEstado=useState(null)
    if(props.usuarioSeleccionado!==null && props.usuarioSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.usuarioSeleccionado!==null && props.usuarioSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.usuarioSeleccionado!==null && props.usuarioSeleccionado["Estado"] == "D"){
        cambioEstado ={value:'D', label:"Desactivado"}
    }
    if(props.usuarioSeleccionado===null ){
        cambioEstado ={value:'A', label:"Activo"}
    }
    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 
    let cambioRol=useState(null)
    if(props.usuarioSeleccionado!==null && props.usuarioSeleccionado["Rol"] == "ROLE_ADMIN"){
        cambioRol ={value:'ROLE_ADMIN', label:"Admin"}
    }
    if(props.usuarioSeleccionado!==null && props.usuarioSeleccionado["Rol"] == "ROLE_USER"){
        cambioRol ={value:'ROLE_USER', label:"Jugador"}
    }
    if(props.usuarioSeleccionado!==null && props.usuarioSeleccionado["Rol"] == "ROLE_RUEDA"){
        cambioRol ={value:'ROLE_RUEDA', label:"Director de rueda"}
    }
    if(props.usuarioSeleccionado!==null && props.usuarioSeleccionado["Rol"] == "ROLE_CAJA"){
        cambioRol ={value:'ROLE_CAJA', label:"Usuario Caja"}
    }
    if(props.usuarioSeleccionado!==null && props.usuarioSeleccionado["Rol"] == "ROLE_VISITANTE"){
        cambioRol ={value:'ROLE_VISITANTE', label:"Visitante"}
    }
    if(props.usuarioSeleccionado===null){
        cambioRol ={value:'ROLE_USER', label:"Jugador"}
    }
    let [rolSelect, setRolSelect] =  useState(cambioRol);
    const optionsRol = [
        { value: 'ROLE_ADMIN', label: 'Admin' }, 
        { value: 'ROLE_USER', label: 'Jugador' },      
        { value: 'ROLE_RUEDA', label: 'Director de rueda' },
        { value: 'ROLE_CAJA', label: 'Usuario Caja' },
        { value: 'ROLE_VISITANTE', label: 'Visitante' },
        
    ] 
    function crearClave(){
        let valid = true
        if(email == ""){
            errors["emailErr"] = "Ingrese email"
            valid = false
        }else{
            var valor = email
            var division = valor.split("@",1)
            var result = division.toString().replace(/[^a-zA-Z ]/g, "");
            var numeros = aleatorio(1000,9999)
            result = result+numeros
            setClave(result)
            errors["claveErr"] = "" 
        }
        return valid
    }
    function aleatorio(inferior, superior) {
        var numPosibilidades = superior - inferior;
        var aleatorio = Math.random() * (numPosibilidades + 1);
        aleatorio = Math.floor(aleatorio);
        return inferior + aleatorio;
    }
    function closeEditU(){
        props.closeUser()
    }
    function actualizarU(){
      
        let valid=true
       
        if(userId<=0 && clave ===null){       
            errors["claveErr"] = "Ingrese una contraseña"
            valid = false
        }
        if(nombre == ""){
            errors["nombreErr"] = "Ingrese nombre"
            valid = false
        }
        if(nick == ""){
            errors["nickErr"] = "Ingrese nick"
            valid = false
        }
        if(email == ""){
            errors["emailErr"] = "Ingrese email"
            valid = false
        }

        if(!valid){
            return
        }
        
        dispatch(updateUsuario(
            props.usuarioSeleccionado!==null?props.usuarioSeleccionado["UsuarioId"]:"-1",
            nombre,
            nick,
            email,
            rolSelect["value"],
            clave!==null?clave:"",
            estadoSelect["value"],
            uploadId
            )).then(() => {     
    
             }).catch(() => {}
        );
        closeEditU()   
    }
    function reloadDocuments(file){
        const result = JSON.parse(file);
        setUploadId(result["UploadId"])
        setFileNombre(result["fileNombre"])
        setFileNombreUrl(result["fileNombreUrl"])
    }
return (
<div className='overlay3 fade-in'>
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
            <div id="formulario-estilo">
                <h2>
                    Usuarios                    
                </h2>  
                <div className='vertical-scroll customScroll'>      
                    <div className="columna-1-formulario-ventana-perfil">
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Nombre:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                type="text"
                                value={nombre}
                                maxLength={1500}
                                placeholder="Ingresar nombre"
                                className="input-form"
                                onChange={(e) => {
                                    setNombre(e.target.value);
                                    errors["nombreErr"] = "" 
                                }}
                                ></input> 
                                {errors["nombreErr"]!==""?<div className='camporequerido'> {errors["nombreErr"]}</div>:""}                                     
                            </div>                       
                        </div> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Nick:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                type="text"
                                value={nick}
                                maxLength={1500}
                                placeholder="Ingresar nick"
                                className="input-form"
                                onChange={(e) => {
                                    setNick(e.target.value);
                                    errors["nickErr"] = "" 
                                }}
                                ></input> 
                                {errors["nickErr"]!==""?<div className='camporequerido'> {errors["nickErr"]}</div>:""}                                     
                            </div>                       
                        </div> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-at"></i> <span>Mail:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                type="email"
                                value={email}
                                maxLength={1500}
                                placeholder="Ingresar email"
                                className="input-form"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    errors["emailErr"] = "" 
                                }}
                                ></input> 
                                {errors["emailErr"]!==""?<div className='camporequerido'> {errors["emailErr"]}</div>:""}                                     
                            </div>                       
                        </div> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Rol:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={rolSelect} onChange={(e) => {
                                    setRolSelect(e)                                                                                                                                                                                            
                                    }} placeholder="Seleccione" options={optionsRol!==null?optionsRol:[]}
                                /> 
                            </div>                       
                        </div> 
                        <div className="row-form"> 
                            <a className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Contraseña:</span>
                                </label>
                            </a>  
                            <div className="col-70">
                                <div className="cursor-pointer" onClick={crearClave}>
                                    <i className="fas fa-key"></i>Generar
                                </div>  
                                <input
                                type="text"
                                value={clave}
                                maxLength={500}
                                placeholder="Ingresar contraseña"
                                className="input-form"
                                onChange={(e) => {
                                    setClave(e.target.value);
                                    errors["claveErr"] = "" 
                                }}
                                ></input> 
                                {errors["claveErr"]!==""?<div className='camporequerido'> {errors["claveErr"]}</div>:""}                                     
                            </div>                       
                        </div> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-file-image"></i> <span>Imagen:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <UploadFile reloadDocuments={reloadDocuments} uploadId={uploadId} fileNombre={fileNombre} fileNombreUrl={fileNombreUrl}></UploadFile>                  
                            </div>                       
                        </div> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)                                                                                                                                                                                            
                                    }} placeholder="Seleccione" options={optionsEstado!==null?optionsEstado:[]}
                                /> 
                            </div>                       
                        </div>
                    </div>
                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizarU} >
                            GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeEditU}>
                            SALIR
                        </div>
                    </div>
                </div>
            </div>      
        </div>
    </div>
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarGestionUsuario);
