import axios from "axios";
import '../config'

const getVariacionPrecio = (eventoId) => {
  const params = {"strEventoId":eventoId}
  return axios
    .post(global.config.URL_SERVICE + "ObtenerVariacionPrecio", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");     
      const result = JSON.parse(itemR);      
      if(result.length>0){
        return result;  
      }else{
        return null
      }
    });
};

const getNegociacionesPorUsuario = (eventoId,usuarioId) => {
  const params = {"strEventId":eventoId,"strUsuarioId":usuarioId}
  return axios
    .post(global.config.URL_SERVICE + "ObtenerNegociacionesPorUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");     
      const result = JSON.parse(itemR);      
      if(result.length>0){
        return result;  
      }else{
        return []
      }
    });
};


const getEstadoCuentaPortafolio = (eventoId,usuarioId) => {
  const params = {"strEventId":eventoId,"strUsuarioId":usuarioId}
  return axios
    .post(global.config.URL_SERVICE + "ObtenerEstadoCuentaPortafolioPorCliente", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");           
      const result = JSON.parse(itemR);      
      if(result.length>0){
        return result;  
      }else{
        return null
      }
    });
};

const getEventosPorUsuario = (userId) => {
    const params = {"strUserId":userId}
    return axios
      .post(global.config.URL_SERVICE + "ObtenerEventosPorUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        if(result.length>0){
          localStorage.setItem("eventos", JSON.stringify(result));
          return result;  
        }else{
          return null
        }
      });
  };

const getTitulosEventoById = (eventId) => {
  const params = {"strEventId":eventId}
  return axios
      .post(global.config.URL_SERVICE + "ObtenerTitulosPorEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        if(result.length>0){
            localStorage.setItem("titulosEvento", JSON.stringify(result));
            localStorage.setItem("titulosRVCount", result.filter(value => value["TipoRentaCod"] === 'RV').length);
            
            return result;  
        }else{
          return null
        }
      });
}

const saveNegociacion = (eventId,userId,jugada,listCompra,listVenta) => {
  const params = {"strEventId":eventId,"strUserId":userId,"strJugada":jugada,"strCompra":listCompra,"strVenta":listVenta}
  return axios
      .post(global.config.URL_SERVICE + "GuardarCompraJugada", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        return response; 
      });
}


export default {
    getEventosPorUsuario,
    getTitulosEventoById,
    saveNegociacion,
    getEstadoCuentaPortafolio,
    getNegociacionesPorUsuario,
	getVariacionPrecio
};
  