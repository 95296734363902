import React, { useState, useEffect } from "react";
import { connect,useDispatch,useSelector } from "react-redux";
import axios from "axios";
import '../config'

import {getMax,formatNumber,getTotalAccion,formatDateMont,enableFuncionalityForRole} from "./general/Util"


const Ranking = (props) => { 
    let [listaUsuarioFin,setListaUsuarioFin] = useState(null);        
    let [obtenerRanking,setObtenerRanking] = useState(true);    
    let [downloading,setDownloading] = useState(false);            

    if(obtenerRanking===true){
        setObtenerRanking(false)
        //296,props.eventoId
        setDownloading(true)
        axios
          .post(global.config.URL_SERVICE + "ObtenerTopPositions", {"strEventoId":props.eventoId,"strUsuarioId":-1},{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              setListaUsuarioFin(result);
              setDownloading(false)
          });
    }


    function btnCerrarRanking(){
        props.btnCerrarRanking()
    }
    return (
        <div className='windowContentInner fade-in animationShow'>
            <div className={(downloading===true)? "loading_cont":"loading_cont displaynone"}>
                    <div className='single6'></div>
                </div> 
            <div className='borderContent ventanaGeneral'>
                <div className="">
                    <div className={["topRank","w3-container w3-center w3-animate-top"].join(' ')}>
                        <div className="borderContent">
                            <div className="innerShadow2">
                                    <div className="topRankTitle">Ranking</div>
                                    <div className="topRankContent topRankContentForce">
                                        <div className={listaUsuarioFin!==null && listaUsuarioFin.length===0?"":"displaynone"}>No existen usuarios con negociaciones</div>
                                        <div className="col1">
                                            {listaUsuarioFin!==undefined && listaUsuarioFin!==null?listaUsuarioFin.map((item, i) => {                                                 
                                                return (i<1000 && item["Pos"]!==undefined)?<div className={"top" + item["Pos"]["Posicion"] + "Rank"}>{item["Pos"]["Posicion"]}</div>:""
                                            }):""
                                            }
                                        </div>
                                        <div className="col2">
                                            {listaUsuarioFin!==undefined && listaUsuarioFin!==null?listaUsuarioFin.map((item, i) => { 
                                                return (i<1000 && item["Pos"]!==undefined)?<div className={"top" + item["Pos"]["Posicion"]}><div className="profileImageList"></div><div className='itemNameRanking' title={item["Pos"]["Nombre"]}>{item["Pos"]["Nombre"]}</div> <div className="cantidad">${formatNumber(item["Pos"]["Saldo"])}</div></div>:""
                                            }):""
                                            }                                            
                                        </div>                       
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="btnContainer"><div className="btnCancelar" onClick={btnCerrarRanking}>Cerrar</div></div>
            </div>
        </div>
    );
}
export default Ranking;