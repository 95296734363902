import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation,Redirect,useHistory } from 'react-router-dom';

import logoJuegodelaBolsa from '../image/logoJuegodelaBolsa2.svg';
import logoBVQ from '../image/logoBVQ2.svg';

import { loginByToken } from "../actions/auth";

const Autologin = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);  
    let query = useQuery();
    const dispatch = useDispatch();
    let history = useHistory();

    if(!currentUser && query.get("token")!==null){
        dispatch(loginByToken(query.get("token")))
            .then(() => {                        
            })
            .catch(() => {
              history.push('/finevento')
            });
    }
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    if (currentUser || query.get("token")===null) {
        return <Redirect to="/sala" />;
    }
    

  return (
    <div>
      <div className="loginHeader">  
        <div className="logoEljuegodelaBolsa loginLogo1"><img src={logoJuegodelaBolsa} alt="El Juego de la Bolsa" /></div>
        <div className="divider"></div>
        <div className="logoBVQ loginLogo2"><img src={logoBVQ} alt="Bolsa de Valores de Quito" /></div>
      </div>
      <div className="box-login">
        Conectando....
      </div>
    </div>    
  );
};

export default Autologin;
