import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {Menu,MenuItem,MenuButton,SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {getEventoPlantilla,deleteEventoPlantilla} from "../../actions/administracion";
import EditarEventoPlantilla from "./EditarEventoPlantilla";
import "../../config";
const EventoPlantilla = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'calc(100% - 50px)', margin: 20 }
    const {listaEventoPlantillaPag,plantillaLoading} = useSelector((state) => state.administracion);   
    let [openEditarEventoP, setOpenEditarEventoP] = useState("") 
    let [eventoPSeleccionado,setEventoPSeleccionado]=useState("null")
    let [loadEventoP, setLoadEventoP] = useState(false) 
    if(loadEventoP===false){
        setLoadEventoP(true)
        dispatch(getEventoPlantilla("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
    }
    let [plantillaFilter, setPlantillaFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaEventoPlantillaPag!==null?listaEventoPlantillaPag:[]]);     

    if(listaEventoPlantillaPag!==null && listaEventoPlantillaPag!=gridInfor && plantillaFilter==="")
        setGridInfor(listaEventoPlantillaPag)

    function filterPlantilla(plant,plantillaFilter){
        //value => 
        setGridInfor(plant.filter(function(item) {
            if (item["TipoEvento"].toLowerCase().includes(plantillaFilter.toLocaleLowerCase()) || 
                item["MensajeCliente"].toLowerCase().includes(plantillaFilter.toLocaleLowerCase())
               ) 
                return true;
            return false;
        }))
    }

    function closeWindowEventoP(){
        props.closeEventoPlantillaClick()
    }
    function closeEditarEventoP(){
        setOpenEditarEventoP("")
    }
   function openNewEventoP(){
        setEventoPSeleccionado(null)
        setOpenEditarEventoP("EDIT")
    } 
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
          
        {
            label: 'Editar',
            onClick:()=>{   
                setEventoPSeleccionado(rowProps.data)
                setOpenEditarEventoP("EDIT")
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar '+rowProps.data["TipoEvento"],
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{                    
                            dispatch(deleteEventoPlantilla(rowProps.data["EventoTraceTmpId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                });               
            }
        }
        ]
    }, [])  
    let columns= [
        { name: 'TipoEvento',header: 'Código', defaultFlex: 1, xdefaultLocked: 'start' },
        { name: 'MensajeCliente',header: 'Mensaje Cliente', defaultFlex: 1, minWidth: 400},
        { name: 'Espera',header: 'Espera', defaultFlex: 1, xdefaultLocked: 'start',  },       
        { name: 'Orden',header: 'Orden', defaultFlex: 1},        
    ]
  return (
    <div
      className={[
        "windowContentInner",
        "fade-in"
      ].join(" ")}
    >
        <div className={['loading_cont',plantillaLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>    
        {openEditarEventoP==="EDIT" ? <EditarEventoPlantilla closeEditarEventoP={closeEditarEventoP} eventoPSeleccionado={eventoPSeleccionado}/>  : ""}
        <div className="borderContent ventanaGeneral">
            <div className="innerShadow-height-100">
                <h2>
                    Plantilla juego
                    <div className="floatright">
                        <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                            <MenuItem onClick={openNewEventoP}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                        </Menu>
                    </div>
                </h2>  
                <div className="titleTable-search"><div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={plantillaFilter} onChange={(e) => {
                  setPlantillaFilter(e.target.value);
                  filterPlantilla(listaEventoPlantillaPag!==null?listaEventoPlantillaPag:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div>
                <DataGrid
                idProperty="EventoTraceTmpId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}
                selection={{ mode: 'single' }}                    
                dataSource={gridInfor}
                renderRowContextMenu={renderRowContextMenu}
                theme="amber-dark"
            />
                <div className="dividerLine"></div>
                <div className="textCenter">
                    <div className="btnCancelar" onClick={closeWindowEventoP}>
                        SALIR
                    </div>
                </div> 
            </div>
        </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EventoPlantilla);
