import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import {updateItemCatalogo,getItemsCatalogoPadre} from "../../actions/administracion";
import UploadFile from "./UploadFile"

import "../../config";
const EditarGestionItems = (props) => {
    const dispatch = useDispatch();
    let [errors] = useState({"codigoErr":""},{"nombreErr":""},{"estadoErr":""},{"nombreCortoErr":""})

    let [codigo,setCodigo] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["Codigo"]:"")
    let [nombre, setNombre] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["Nombre"]:"")
    let [nombreCorto, setNombreCorto] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["NombreCorto"]:"")

    let [uploadId, setUploadId] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["UploadId"]:"-1")
    let [fileNombre, setFileNombre] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["fileNombre"]:"")
    let [fileNombreUrl, setFileNombreUrl] = useState(props.itemSeleccionado!==null?props.itemSeleccionado["fileNombreUrl"]:"")
    let [activo, setActivo] = useState(null)
    let [editar, setEditar] = useState(null)
    const {listaItemsPadre} = useSelector((state) => state.administracion);
    if(listaItemsPadre===null){
        dispatch(getItemsCatalogoPadre())
        .then(() => {
        })
        .catch(() => {
        });
        
    }

    let [itemPadre,setItemPadre] = useState(null)
    if(props.itemSeleccionado===null && itemPadre===null ){
        setItemPadre({value:'0', label:"Ninguno"})
    }
    // copia
    if(props.itemSeleccionado!==null && activo==null){
        if(props.itemSeleccionado["Activo"]===true){
            setActivo(1)
        }else{
            setActivo(0)
        }
    }
    if(props.itemSeleccionado!==null && editar==null){
        if(props.itemSeleccionado["Editar"]===true){
            setEditar(1)
        }else{
            setEditar(0)
        }
    }
    
    //checkbox 
    const handleCheckedActivo = ({ target }) => {
        target.checked===true?setActivo(1):setActivo(0)
    }

    const handleCheckedEditar = ({ target }) => {
        target.checked===true?setEditar(1):setEditar(0)
    }
    // fin copia

    function closeEditCat (){
        props.closeEditItem();
    }
    function reloadDocuments(file){
        const result = JSON.parse(file);
        setUploadId(result["UploadId"])
        setFileNombre(result["fileNombre"])
        setFileNombreUrl(result["fileNombreUrl"])
    }

    let cambioEstado=useState(null)
    
    if(props.itemSeleccionado!==null && props.itemSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.itemSeleccionado!==null && props.itemSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.itemSeleccionado===null){
        cambioEstado ={value:'A', label:"Activo"}
    }
    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'A', label: 'Activo' },
        { value: 'I', label: 'Inactivo' },
        
    ] 

    function actualizar(){
      
        let valid=true
       
        if(codigo == ""){
            errors["codigoErr"] = "Ingrese código"
            valid = false
        }
        if(nombre == ""){
            errors["nombreErr"] = "Ingrese nombre"
            valid = false
        }
        if(nombreCorto == ""){
            errors["nombreCortoErr"] = "Ingrese nombre corto"
            valid = false
        }
        if(estadoSelect == null){
            errors["estadoErr"] = "Ingrese estado"
            valid = false
        }
        if(!valid){
            return
        }
        dispatch(updateItemCatalogo(
            props.itemSeleccionado!==null?props.itemSeleccionado["ItemId"]:-1,
            codigo,
            nombre,
            "1",
            editar,
            0,
            props.catalogoId,
            nombreCorto,
            estadoSelect["value"],
            "0","","","ASC","",uploadId            
            )).then(() => {     
    
             }).catch(() => {}
        );      
       closeEditCat()
    }

    return (
<div className= "overlay3 fade-in">
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow-height-100">
            <div id="formulario-estilo">
                <h2>
                    Items            
                </h2>  
                <div className='vertical-scroll customScroll'>               
                    <div className="columna-1-formulario-ventana-perfil-edit-evento-user"> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Código:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                    type="text"
                                    value={codigo}
                                    maxLength={20}
                                    placeholder="Ingresar código"
                                    className="input-form"
                                    onChange={(e) => {
                                        setCodigo(e.target.value.toUpperCase());
                                        errors["codigoErr"] = ""
                                    }}
                                ></input>
                                {errors["codigoErr"]!==""?<div className='camporequerido'>{errors["codigoErr"]}</div>:""}
                            </div> 
                        </div>  
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Nombre:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                    type="text"
                                    value={nombre}
                                    maxLength={50}
                                    placeholder="Ingresar nombre"
                                    className="input-form"
                                    onChange={(e) => {
                                        setNombre(e.target.value);
                                        errors["nombreErr"] = ""
                                    }}
                                ></input>
                                {errors["nombreErr"]!==""?<div className='camporequerido'>{errors["nombreErr"]}</div>:""}
                            </div> 
                        </div> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Nombre corto:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                    type="text"
                                    value={nombreCorto!==null?nombreCorto:""}
                                    maxLength={200}
                                    placeholder="Ingresar nombre corto"
                                    className="input-form"
                                    onChange={(e) => {
                                        setNombreCorto(e.target.value);
                                        errors["nombreCortoErr"] = ""
                                    }}
                                ></input>
                                {errors["nombreCortoErr"]!==""?<div className='camporequerido'>{errors["nombreCortoErr"]}</div>:""}
                            </div> 
                        </div> 
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <Select  className="input-form" id="estadoSelectCombo"  value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)
                                    errors["estadoErr"] = ""
                                }} placeholder="Seleccione" options={optionsEstado}/>
                                {errors["estadoErr"]!==""?<div className='camporequerido'>{errors["estadoErr"]}</div>:""}
                            </div>
                        </div>                        
                        <div className="row-form">  
                            <div className="col-30">
                            <label>
                                <i className="fas fa-check-square"></i> <span>Editable:</span>
                            </label>   
                            </div>                         
                            <div className="col-70">
                            <Checkbox 
                                right="true" 
                                defaultChecked={editar} 
                                text="Juega renta fija"
                                onChange={handleCheckedEditar}
                            />    
                            </div>                    
                        </div>       
                        {props.catalogoCod==="EMISOR"?<div className="row-form">  
                            <div className="col-30">
                            <label>
                                <i className="fas fa-file-image"></i> <span>Imagen:</span>
                            </label>   
                            </div>                         
                            <div className="col-70">
                            <UploadFile reloadDocuments={reloadDocuments} uploadId={uploadId} fileNombre={fileNombre} fileNombreUrl={fileNombreUrl}></UploadFile>                  
                            </div>                    
                        </div>:""}
                    </div> 
                    
                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizar} >
                        GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeEditCat}>
                            SALIR
                        </div>
                    </div>
                </div> 
            </div>      
        </div>
    </div>
</div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarGestionItems);
