import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import banner from "./banner";
import juego from "./juego";
import administracion from "./administracion";
import evento from "./evento"
import titulosPorEvento from "./titulosPorEvento";

export default combineReducers({
  auth,
  message,
  banner,
  juego,
  administracion,
  evento,
  titulosPorEvento
});
