import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {getEventoNoticiaEspecial,deleteNoticiaEspecial,setStatusByKey} from "../../actions/evento";
import EditarGestionEventoNoticia from "./EditarGestionEventoNoticia";
import "../../config";

const GestionEventoNoticiaEsp = (props) => {
    let [editGestionNoticiaOpen, setEditGestionNoticiaOpen] = useState("")
    const {listaEventoNoticiaEsp,noticiaEventoLoading} = useSelector((state) => state.evento)
    let [noticiaPorEventoSeleccionado, setNoticiaPorEventoSeleccionado] = useState("null")    
    let [loadEventoNoticiaEsp, setLoadEventoNoticiaEsp] = useState(false)
    let eventoIdSeleccionado = props.eventoSeleccionadoId;    
    const dispatch = useDispatch();
    function closeGestionNoticiaClick(){
        dispatch(setStatusByKey("listaEventoNoticiaEsp",null))
        props.closeGestionNoticiaEsp();
    }    
    if(listaEventoNoticiaEsp===null && loadEventoNoticiaEsp ===false){
        setLoadEventoNoticiaEsp(true)
        dispatch(getEventoNoticiaEspecial(eventoIdSeleccionado,"0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }

    const gridStyle = { minHeight: '300px',height:'100%', margin: 20 }
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Editar',
            onClick:()=>{   
                setNoticiaPorEventoSeleccionado(rowProps.data)
                dispatch(getEventoNoticiaEspecial(rowProps.data["EventoNotBoomId"],"0","","","ASC",""))
                .then(() => {
                })
                .catch(() => {
                });
                setEditGestionNoticiaOpen("EDIT")        
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar noticia',
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{
                            dispatch(deleteNoticiaEspecial(rowProps.data["EventoNotBoomId"],rowProps.data["EventoId"],"0","","","ASC",""))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {}
                      }
                    ]
                  });
               
            }
        }
        ]
    }, [])  
    let columns= [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'TipoNoticiaNombre',header: 'Noticia', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'Jugada',header: 'Jugada', defaultFlex: 1, xdefaultLocked: 'end' },        
        { name: 'Estado',header: 'Estado', defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => formatEstado(data["Estado"]) },          
       
    ]

    const closeEditGestionNoticia = ()=>{
        setEditGestionNoticiaOpen("")
    }
    const crearNuevo = ()=>{
        setNoticiaPorEventoSeleccionado(null)
        setEditGestionNoticiaOpen("EDIT")
    }
	function formatEstado(estado){
		switch(estado){
			case "A":
				return "ACTIVO"
		}
		return "INACTIVO"
	}

return (
<div className="overlay3 fade-in"> 
    <div className={['loading_cont',noticiaEventoLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>
    {editGestionNoticiaOpen==="EDIT" ? <EditarGestionEventoNoticia closeEditGestionNoticia={closeEditGestionNoticia} noticiaPorEventoSeleccionado={noticiaPorEventoSeleccionado} eventoSeleccionadoId={eventoIdSeleccionado} />  :  
    <div className="borderContent ventanaGeneral">
        <div className="innerShadow-height-100">
            <h2>
            Noticias especiales por evento
            <div className="floatright">
                <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                    <MenuItem onClick={crearNuevo}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                </Menu>
            </div>
            </h2> 
            <DataGrid
                idProperty="EventoAyudaId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}
                selection={{ mode: 'single' }}                    
                dataSource={listaEventoNoticiaEsp!==null?listaEventoNoticiaEsp:[]}
                renderRowContextMenu={renderRowContextMenu}
                theme="amber-dark"
            />
             
            <div className="dividerLine"></div>
            <div className="textCenter">
                <div className="btnCancelar" onClick={closeGestionNoticiaClick}>
                    SALIR
                </div>
            </div>      
        </div>       
    </div>
    }
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionEventoNoticiaEsp);