import React, { Component } from "react";
import NumberFormat from 'react-number-format'
import marca1 from '../../image/marca1.png';
import marca2 from '../../image/marca2.png';
import '../../config'
import {formatNumber,formatNumberFixed0} from "../general/Util"

class ItemPortafolioRF extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            compra: 0,
            venta: 0,
            barra: {
                width: '100%',
            } 
        }
    }
   
    checkWidthBar(precio){
        try{
            if(precio!=undefined){                
                let widthTmp = ((precio/this.props.maxValue)*100)
                if(this.state.barra.width!=(widthTmp+"%")){
                    this.setState({barra:{width:widthTmp + "%"}})
                }        
            }         
        }catch(ex){

        }     
        return this.state.barra
    }
    getStyleByIndex(index){
        switch(index){
            case 0:
                return "bgYellow"
            case 1:
                return "bgPurple"
            case 2:
                return "bgRed"
            case 3:
                return "bgGreen"
            case 4:
                return "bgYellow"
            case 5:
                return "bgPurple"
            case 6:
                return "bgRed"
            case 7:
                return "bgGreen"
        }
    }
    
    handleChangeIngresos(event){
        console.log(event)
    }
    render() {
        return (
            <div className="rowCustom" title={this.props.item["Emisor"] + " - VEF: $" + formatNumber(this.props.item["SaldoTitulo"] * (this.props.item["Precio"]/100))}>
                <div className="oneCol">
                {this.props.index===0?<div className="colTitle">EMISOR</div>:""}
                <img src={this.props.item["fileNombreUrl"]!==""?global.config.URL_UPLOAD + this.props.item["fileNombreUrl"]:global.config.URL_IMAGE_EMISOR + this.props.item["EmisorCodigo"]  + ".png"} onError={(e) => e.target.src = global.config.URL_IMAGE_EMISOR + 'onerror-rf.png'} alt="Logo1" />
                <div className='text-aling-rf'>{this.props.item["Emisor"]}</div>
                </div>        
                <div className="threeCol">
                {this.props.index===0?<div className="colTitle">PRECIO</div>:""}
                {formatNumber(this.props.item["Precio"])}
                </div>
                <div className="fiveColPortafolio">
                {this.props.index===0?<div className="colTitle">VALOR</div>:""}
                <div className={["percentBarHorzontal","baranimacion",this.getStyleByIndex(this.props.index)].join(' ')} style={this.checkWidthBar(this.props.item["SaldoTitulo"])}>{formatNumberFixed0(this.props.item["SaldoTitulo"])}</div>
                </div>
                <div className="fourColPortafolio">
                {this.props.index===0?<div className="colTitle">MONTO</div>:""}
                {formatNumber(this.props.item["SaldoTitulo"]*this.props.item["Precio"])}
                </div>
                <div className="fourColPortafolio">
                {this.props.index===0?<div className="colTitle">MADURACIÓN</div>:""}
                {this.props.item["Maduracion"]}/{this.props.item["Plazo"]}
                </div>
                
            </div>
        )
    }
}
export default ItemPortafolioRF;