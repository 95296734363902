import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import {getUsuariosSinA} from "../../actions/evento";
import {updateGestionEventoUsuario} from "../../actions/evento";
import "../../config";
//updateGestionEventoUsuario = (eventoUsuarioId,usuarioId,eventoId,usuarioIdRegistro,estado,monto,saldo)
const EditGestionEventoUsuario = (props) => {    
    const { user: currentUser } = useSelector((state) => state.auth);
    const {listaUsuariosSinAsig} = useSelector((state) => state.evento);
	let [loadListaUsuarios,setLoadListaUsuarios] = useState(true);
	
    const dispatch = useDispatch();
    if(loadListaUsuarios===true){
		setLoadListaUsuarios(false)
        dispatch(getUsuariosSinA(props.eventoSeleccionadoId))
        .then(() => {
        })
        .catch(() => {
        });
    }    
    let [errors] = useState({"saldoErr":""},{"montoErr":""},{"userSelectErr":""});

    let [saldo, setSaldo] = useState(props.usuarioPorEventoSeleccionado!==null?props.usuarioPorEventoSeleccionado["Saldo"]:props.eventoSeleccionado["MontoJugador"])
    let [monto, setMonto] = useState(props.usuarioPorEventoSeleccionado!==null?props.usuarioPorEventoSeleccionado["Monto"]:props.eventoSeleccionado["MontoJugador"])
    let [userSelect,setUserSelect] = useState(null)
    let [userId,setUserId] =  useState(props.usuarioPorEventoSeleccionado!==null?props.usuarioPorEventoSeleccionado["UsuarioId"]:-1)
    function closeEditGU (){
        props.closeEditGestionEventoUsuario();
    }
    
    let cambioEstado=useState(null)

    if(props.usuarioPorEventoSeleccionado!==null && props.usuarioPorEventoSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.usuarioPorEventoSeleccionado!==null && props.usuarioPorEventoSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.usuarioPorEventoSeleccionado!==null && props.usuarioPorEventoSeleccionado["Estado"] == "D"){
        cambioEstado ={value:'D', label:"Desactivado"}
    }
    if(props.usuarioPorEventoSeleccionado===null){
        cambioEstado ={value:'A', label:"Activo"}
    }
    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 
    function actualizar(){        
        let valid=true
        if(userSelect ===null && userId===-1){       
            errors["userSelectErr"] = "Ingrese un usuario"
            valid = false
        }
        if(saldo == ""){
            errors["saldoErr"] = "Ingrese saldo"
            valid = false
        }
        if(monto == ""){
            errors["montoErr"] = "Ingrese monto"
            valid = false
        }
        if(!valid){
            return
        }
        dispatch(updateGestionEventoUsuario(
            props.usuarioPorEventoSeleccionado!==null?props.usuarioPorEventoSeleccionado["EventoUsuarioId"]:"-1",
            userId!==-1?userId:userSelect["value"],
            props.eventoSeleccionadoId,
            currentUser["userId"],
            estadoSelect["value"],
            monto,
            saldo
            )).then(() => {     
    
             }).catch(() => {}
        );
        closeEditGU()
    }

    return (
    <div className="overlay3 fade-in">    
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow-height-100">
            <div id="formulario-estilo">
                <h2>
                    Usuario por evento                    
                </h2>  
                <div className='vertical-noscroll customScroll'>               
                    <div className="columna-1-formulario-ventana-perfil-edit-evento-user"> 
                        {props.usuarioPorEventoSeleccionado===null?
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-user"></i> <span>Usuario:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <Select className="input-form" id="InstitucionCombo" value={userSelect} onChange={(e) => {
                                    setUserSelect(e) 
                                    errors["userSelectErr"] = ""                                                                                                                                                        
                                    }} placeholder="Seleccione" options={listaUsuariosSinAsig!==null?listaUsuariosSinAsig:[]}
                                /> 
                                {errors["userSelectErr"]!==""?<div className='camporequerido'> {errors["userSelectErr"]}</div>:""}                                     
                            </div>
                        </div> 
                        :""}
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-dollar-sign"></i> <span>Saldo:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <NumberFormat  
                                    className="input-form" value={saldo} thousandSeparator={false }  allowNegative={false} decimalScale={2} fixedDecimalScale={true} decimalSeparator={'.'} prefix={"$"}   onValueChange={(values) => {
                                    const {formattedValue, value} = values;
                                    setSaldo(value);      
                                    errors["saldoErr"]=""                              
                                }}
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    return floatValue >= 0 &&  floatValue <= 99999.99;
                                  }}/> 
                                
                                {errors["saldoErr"]!==""?<div className='camporequerido'>{errors["saldoErr"]}</div>:""}                                           
                            </div>
                        </div> 
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-dollar-sign"></i> <span>Monto:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <NumberFormat  
                                    className="input-form" value={monto} thousandSeparator={false }  allowNegative={false} decimalScale={2} fixedDecimalScale={true} decimalSeparator={'.'} prefix={"$"}   onValueChange={(values) => {
                                    const {formattedValue, value} = values;
                                    setMonto(value);      
                                    errors["montoErr"]=""                              
                                }}
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    return floatValue >= 0 &&  floatValue <= 999999.99;
                                  }}/> 
                                
                                {errors["montoErr"]!==""?<div className='camporequerido'>{errors["montoErr"]}</div>:""}                                           
                            </div>
                        </div>       
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <Select  className="input-form" id="estadoSelectCombo"  value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)
                                }} placeholder="Seleccione" options={optionsEstado}/>
                            </div>
                        </div>                   
                    </div> 
                        

                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizar} >
                        GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeEditGU}>
                            SALIR
                        </div>
                    </div>
                </div> 
            </div>      
        </div>
    </div>
</div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditGestionEventoUsuario);
