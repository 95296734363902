import axios from "axios";
import '../config'

const deleteEventoTitulo=(eventoTituloId)=>{
  const params = {"strEventoTituloId":eventoTituloId}
      return axios 
          .post(global.config.URL_SERVICE + "EliminarEventoTitulo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
          .then((response) => {
              let itemR = response["data"].split("\\\"").join("\"");          
              const result = JSON.parse(itemR);
              return result;  
          });
}

const updateGestionEventoTitulo = (eventoTituloId,eventoId,tituloId,valorNominal,precio,rendimiento,plazo,plazoDias,estado,saldo,maduracion,jugadaAct) => {
  const params = {"strEventoTituloId":eventoTituloId,"strEventoId":eventoId,"strTituloId":tituloId,"strValorNominal":valorNominal,"strPrecio":precio,"strRendimiento":rendimiento,"strPlazo":plazo,"strPlazoDias":plazoDias,"strEstado":estado,"strSaldo":saldo,"strMaduracion":maduracion,"strJugadaAct":jugadaAct}
    return axios
        .post(global.config.URL_SERVICE + "GestionEventoTitulo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result===0){
              return result;  
            }else{
              return null
            }
        });
}
const obtenerTitulosBuscador = () =>{
  const params = {}
    return axios 
        .post(global.config.URL_SERVICE + "ObtenerTitulosBuscador", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result.length>0){
              return result;  
            }else{
              return null
            }
    });
}

const obtenerTitulosPorEvento = (eventoId)=>{
  const params = {"strEventId":eventoId}
    return axios 
        .post(global.config.URL_SERVICE + "ObtenerTitulosPorEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            return result;  
    });
}



export default {
    obtenerTitulosBuscador,
    obtenerTitulosPorEvento,
    updateGestionEventoTitulo,
    deleteEventoTitulo


}