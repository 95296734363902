import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import {NotificationManager} from "react-notifications";
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {enviarInvitacionPorUsuario} from "../../actions/evento";
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {getUsuarioEvento,deleteEventoU,setStatusByKey,enviarInvitacionPorEvento,deleteUsersEvent} from "../../actions/evento";
import EditGestionEventoUsuario from "./EditGestionEventoUsuario";
import CargarUsuarios from "./CargarUsuarios";
import SeleccionarUsuariosEvento from "./SeleccionarUsuariosEvento";
import "../../config";
import axios from "axios";
import {formatNumber} from "./Util"


const GestionEventoUsuario = (props) => {    
    const { loadingSendNotification } = useSelector((state) => state.evento);
    let [editGestionEventoUsuarioOpen, setEditGestionEventoUsuarioOpen] = useState("");
    let [usuariosEventoOpen,setUsuariosEventoOpen] = useState("");
    const {listaUsuarioPorEvento,loadingUploadFile,loadingUserEvtList} = useSelector((state) => state.evento);    
    let [usuarioPorEventoSeleccionado, setUsuarioPorEventoSeleccionado] = useState("null");    
    let [loadUsrEven, setLoadUsrEven] = useState(false);
    let eventoIdSeleccionado = props.eventoSeleccionadoId;    
    let [generarReporteFinal,setGenerarReporteFinal] = useState(false)   
    
    let [eventoUsuarioFilter, setEventoUsuarioFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaUsuarioPorEvento!==null?listaUsuarioPorEvento:[]]);     

    if(listaUsuarioPorEvento!==null && listaUsuarioPorEvento!=gridInfor && eventoUsuarioFilter==="")
        setGridInfor(listaUsuarioPorEvento)
    
    const dispatch = useDispatch();
    function closeGestionUsuarioClick(){
		dispatch(setStatusByKey("listaUsuarioPorEvento",null))
        props.closeGestionUsuario();
    }
    if(listaUsuarioPorEvento===null && loadUsrEven===false){
        setLoadUsrEven(true)
        dispatch(getUsuarioEvento(eventoIdSeleccionado))
        .then(() => {
        })
        .catch(() => { 
        });
        
    }

    
    let [usuarioSelect,setUsuarioSelect] = useState(null)
    
    
    const gridStyle = { minHeight: '300px', height:'calc(100% - 50px)', margin: 20 }
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Editar',
            onClick:()=>{   
                setUsuarioPorEventoSeleccionado(rowProps.data)
                setEditGestionEventoUsuarioOpen("EDIT")
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar usuario '+rowProps.data["Nombre"],
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{
                            dispatch(deleteEventoU(rowProps.data["EventoUsuarioId"],rowProps.data["EventoId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {}
                      }
                    ]
                  });
               
            }
        },
        {
            label: 'Enviar invitación',
            onClick:()=>{   
                confirmAlert({
                    title: 'Envio invitación',
                    message: '¿Seguro desea enviar la invitación?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{
                            dispatch(enviarInvitacionPorUsuario(rowProps.data["EventoId"],rowProps.data["UsuarioId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {}
                      }
                    ]
                  });                
            }
        },
        {
            label: 'Trazabilidad de jugadas',
            onClick:()=>{                   
              setGenerarReporteFinal(true)
              const params = {"strEventoId":rowProps.data["EventoId"],"strUsuarioId":rowProps.data["UsuarioId"]}
              axios.post(global.config.URL_SERVICE + "ObtenerTrazabilidadJuego", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
              .then((response) => {
                setGenerarReporteFinal(false)
                let itemR = response["data"].split("\\\"").join("\"");        
                if(itemR!=="" && itemR!=="-1" && itemR!==-1){
                  const link = document.createElement('a');
                  link.href = global.config.URL_UPLOAD + itemR;
                  link.target = '_blank'
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }else{
                  NotificationManager.error(
                    "Error al generar el archvio",
                    "Sistema",
                    3000,
                    () => {},
                    4
                  );
                }     
              });
            }
          },  
        ]
    }, [])  
    let columns= [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Nombre',header: 'Nombre', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, },
        { name: 'Email',header: 'Email', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, },
        { name: 'Monto',header: 'Monto', classNames: 'text-rigth', defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => formatNumber(data["Monto"]!==undefined?data["Monto"]:0) },
        { name: 'Saldo',header: 'Saldo', classNames: 'text-rigth', defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => formatNumber(data["Saldo"]!==undefined?data["Saldo"]:0)},          
       
    ]

    const closeEditGestionEventoUsuario = ()=>{
        setEditGestionEventoUsuarioOpen("")
    }
    const cargarUsuarios = ()=>{
        setEditGestionEventoUsuarioOpen("CARGA")
    }
    const crearNuevo = ()=>{
        setUsuarioPorEventoSeleccionado(null)
        setEditGestionEventoUsuarioOpen("EDIT")
    }
    const seleccionarUsuarios = ()=>{
        setUsuariosEventoOpen("USUARIOS")
    }
    const closeCargarUsuarios = ()=>{
        setEditGestionEventoUsuarioOpen("")
    }
    const closeUsuariosEventoOpen= ()=>{
        setUsuariosEventoOpen("")
    }
    function filterUsuariosPorEvento(nego,eventoUsuarioFilter){
        //value => 
        setGridInfor(nego.filter(function(item) {
          if (item["Nombre"].toLowerCase().includes(eventoUsuarioFilter.toLocaleLowerCase()) || item["Email"].toLowerCase().includes(eventoUsuarioFilter.toLocaleLowerCase()))
              return true;
          return false;
        }))
    }
    const eliminarUsuarios = () =>{
        confirmAlert({
            title: 'Eliminar todos los jugadores',
            message: '¿Seguro desea eliminar a todos los jugadores?.',
            buttons: [
              {
                label: 'Si',
                onClick: () =>{
                    dispatch(deleteUsersEvent(eventoIdSeleccionado))
                    .then(() => {     
                
                    })
                    .catch(() => {});
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
    }
    const enviarInvitacionTodos =()=>{
        confirmAlert({
            title: 'Envio invitación',
            message: '¿Seguro desea enviar la invitación a todos los jugadores?.',
            buttons: [
              {
                label: 'Si',
                onClick: () =>{
                    dispatch(enviarInvitacionPorEvento(eventoIdSeleccionado))
                    .then(() => {     
                
                    })
                    .catch(() => {});
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
    }

return (
<div className="overlay3 fade-in"> 
    <div className={['loading_cont',loadingUploadFile!==true && loadingUserEvtList!==true && loadingSendNotification!==true && generarReporteFinal!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>    
    {editGestionEventoUsuarioOpen==="EDIT" ? <EditGestionEventoUsuario closeEditGestionEventoUsuario={closeEditGestionEventoUsuario} usuarioPorEventoSeleccionado={usuarioPorEventoSeleccionado} eventoSeleccionadoId={eventoIdSeleccionado} eventoSeleccionado={props.eventoSeleccionado} />  : ""}  
    {editGestionEventoUsuarioOpen==="CARGA" ? <CargarUsuarios closeCargarUsuarios={closeCargarUsuarios} eventoSeleccionadoId={eventoIdSeleccionado} eventoSeleccionado={props.eventoSeleccionado} />  : ""}  
   {usuariosEventoOpen==="USUARIOS" ? <SeleccionarUsuariosEvento closeUsuariosEventoOpen={closeUsuariosEventoOpen} eventoSeleccionadoId={eventoIdSeleccionado} />:""}
    <div className="borderContent ventanaGeneral">
        <div className="innerShadow-height-100">
            <h2>
            Usuarios del evento
            <div className="floatright">
                <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                    <MenuItem onClick={crearNuevo}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                     <MenuItem onClick={seleccionarUsuarios}><i className="fas fa-sign-out-alt"></i>Seleccionar</MenuItem> 
                    <MenuItem onClick={cargarUsuarios}><i className="fas fa-sign-out-alt"></i>Cargar Usuarios</MenuItem> 
                    <MenuItem onClick={eliminarUsuarios}><i className="fas fa-sign-out-alt"></i>Eliminar todos</MenuItem> 
                    <MenuItem onClick={enviarInvitacionTodos}><i className="fas fa-sign-out-alt"></i>Enviar invitacion a todos</MenuItem> 
                </Menu>
            </div>
            </h2> 
            <div className="titleTable-search"><div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={eventoUsuarioFilter} onChange={(e) => {
                  setEventoUsuarioFilter(e.target.value);
                  filterUsuariosPorEvento(listaUsuarioPorEvento!==null?listaUsuarioPorEvento:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div>    
            <DataGrid
                idProperty="EventoUsuarioId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}                  
																  
                dataSource={gridInfor}
                renderRowContextMenu={renderRowContextMenu}
                theme="amber-dark"
                showEmptyRows={false}
            />
             
            <div className="dividerLine"></div>
            <div className="textCenter">
                <div className="btnCancelar" onClick={closeGestionUsuarioClick}>
                    SALIR
                </div>
            </div>      
        </div>       
    </div>
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionEventoUsuario);