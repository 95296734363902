import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {getUsuarios,deleteUsuario} from "../../actions/administracion";
import EditarGestionUsuario from "./EditarGestionUsuario";
import "../../config";

//"0","","","ASC",""
const GestionUsuario = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'calc(100% - 50px)', margin: 20 }
    const {listaUsuarios,usuariosLoading} = useSelector((state) => state.administracion);
    let [editUsuarioOpen, setEditUsuarioOpen] = useState("")
    let [usuarioSeleccionado, setUsuarioSeleccionado] = useState("null")    
    let [mostrarUsuarios, setMostrarUsuarios] = useState(false)    
    
    if(listaUsuarios===null && mostrarUsuarios===false){
        setMostrarUsuarios(true)
        dispatch(getUsuarios("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }

    let [usuarioFilter, setUsuarioFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaUsuarios!==null?listaUsuarios:[]]);     

    if(listaUsuarios!==null && listaUsuarios!=gridInfor && usuarioFilter==="")
        setGridInfor(listaUsuarios)

    function filterPlantilla(usu,usuarioFilter){
        //value => 
        setGridInfor(usu.filter(function(item) {
            if (item["Nombre"].toLowerCase().includes(usuarioFilter.toLocaleLowerCase()) || 
                item["Nick"].toLowerCase().includes(usuarioFilter.toLocaleLowerCase()) ||
                item["Email"].toLowerCase().includes(usuarioFilter.toLocaleLowerCase()) 
              
               ) 
                return true;
            return false;
        }))
    }
    function openEditUser(){
        setEditUsuarioOpen("EDIT")
    }
    function openNewUser(){
        setUsuarioSeleccionado(null)
        setEditUsuarioOpen("EDIT")
    }
    function closeUser(){
        setEditUsuarioOpen("")
    }
    function closeWindowUsuario(){
        props.closeGestionUsuariosClick()
    }
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Editar',
            onClick:()=>{   
                setUsuarioSeleccionado(rowProps.data)
                setEditUsuarioOpen("EDIT")
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar usuario '+rowProps.data["Nombre"],
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{
                           dispatch(deleteUsuario(rowProps.data["UsuarioId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                  });               
            }
        }
        ]
    }, [])  
    function getRol(str){
        switch(str){
            case "ROLE_ADMIN":
                return "ADMINISTRADOR"
            case "ROLE_USER":
                return "USUARIO"
            case "ROLE_RUEDA":
                return "DIRECTOR DE RUEDA"
			case "ROLE_CAJA":
                return "USUARIO CAJA"
			case "ROLE_VISITANTE":
                return "VISITANTE"
        }

        return ""
    }
    let columns= [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Nombre',header: 'Nombre', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, },
        { name: 'Nick',header: 'Nick', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'Email',header: 'Email', defaultFlex: 1, xdefaultLocked: 'end' },                  
        { name: 'Rol',header: 'Rol', defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => getRol(data["Rol"]) },
    ]
  return (
    <div
      className={[
        "windowContentInner",
        "fade-in"
      ].join(" ")}
    >
        <div className={['loading_cont',usuariosLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>
        {editUsuarioOpen==="EDIT" ? <EditarGestionUsuario closeUser={closeUser} usuarioSeleccionado={usuarioSeleccionado}/>  : ""} 
        <div className="borderContent ventanaGeneral">
            <div className="innerShadow-height-100">
                <h2>
                    Usuarios
                    <div className="floatright">
                        <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                            <MenuItem onClick={openNewUser}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                        </Menu>
                    </div>
                </h2>  
                <div className="titleTable-search"><div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={usuarioFilter} onChange={(e) => {
                  setUsuarioFilter(e.target.value);
                  filterPlantilla(listaUsuarios!==null?listaUsuarios:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div>
                <DataGrid
                idProperty="EventoUsuarioId"
                style={gridStyle}
                theme="default-light"                    
                columns={columns}
                enableSelection={true}
                selection={{ mode: 'single' }}                    
                dataSource={gridInfor}
                renderRowContextMenu={renderRowContextMenu}
                theme="amber-dark"
            />
                <div className="dividerLine"></div>
                <div className="textCenter">
                    <div className="btnCancelar" onClick={closeWindowUsuario}>
                        SALIR
                    </div>
                </div> 
            </div>
        </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionUsuario);
