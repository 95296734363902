import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import logoJuegodelaBolsa from '../image/logoJuegodelaBolsa2.svg';
import logoBVQ from '../image/logoBVQ2.svg';
import {sendEmailPassword} from "../actions/administracion";




const Recuperar = (props) => {
    const dispatch = useDispatch();
    let [email,setEmail] = useState(null)
    let [errors] = useState({"emailErr":""});
    const {recuperarLoading} = useSelector((state) => state.administracion);

    const onKeyPress = (e) => {
        if(e.which === 13) {
          recuperarClave()
        }
      }
    
    function recuperarClave(){
      let valid = true
      if(email == ""){
        errors["emailErr"] = "Ingrese email"

      }else{
      dispatch(sendEmailPassword(
        email
        )).then(() => {     

         }).catch(() => {}
      );
      setEmail("")
      }
    }
  return (
    <div >
      <div className={['loading_cont',recuperarLoading!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>    
      <div className="loginHeader">  
        <div className="logoEljuegodelaBolsa loginLogo1"><img src={logoJuegodelaBolsa} alt="El Juego de la Bolsa" /></div>
        <div className="divider"></div>
        <div className="logoBVQ loginLogo2"><img src={logoBVQ} alt="Bolsa de Valores de Quito" /></div>
      </div>
        <div className="recuperar-clave-form">
            <h1 className="titleLogin">Recuperar contraseña</h1>                                                                          
              <input
              type="email"
              value={email}
              maxLength={300}
              placeholder="example@mail.com"
              className="recuperar-email"
              onKeyPress={onKeyPress}
              onChange={(e) => {
                  setEmail(e.target.value);  
                
                  errors["emailErr"] = ""                        
              }}
              ></input>     
              {errors["emailErr"]!==""?<div className='camporequerido'> {errors["emailErr"]}</div>:""}                                                       
              <div className="recuperar-boton" onClick={recuperarClave} >
                  ENVIAR
              </div>{" "}  
              <h4><a href="/login" className="linkRecuperarContrasena">Ingresar</a></h4>     
        </div>
    </div>     
  );
};

export default Recuperar;
