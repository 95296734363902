import React, { Component } from "react";
import NumberFormat from 'react-number-format'
import '../../config'
import {formatNumberFixed0} from "../general/Util"

class ItemRentaVariable extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            barra: {
                height: '100%'
            } 
        }
        this.checkWidthBar = this.checkWidthBar.bind(this);
    }    
    checkWidthBar(precio){
        let valRet = this.state.barra
        try{
            if(precio!=undefined){                
                let widthTmp = this.props.maxValue<=100?precio:((precio/this.props.maxValue)*100) //(this.props.item["Precio"]/100) * 100
                //console.log(precio + "- " + this.props.maxValue + " - " + widthTmp)
                valRet = {height:widthTmp + "%"}
                if(this.state.barra.height!==(widthTmp+"%")){
                    this.setState({barra:{height:widthTmp + "%"}})
                    this.state.barra.height=widthTmp + "%"                   
                }        
            }         
        }catch(ex){
            console.log("ex : " + ex + " - " + precio)
        }     
        return valRet
    }
    getStyleByIndex(index){
        switch(index){
            case 0:
                return "one"
            case 1:
                return "two"
            case 2:
                return "three"
            case 3:
                return "four"
            case 4:
                return "five"
            case 5:
                return "six"
            case 6:
                return "seven"
            case 7:
                return "eight"
        }
    }
    getStyleAyuda(index,pos){
        switch(index){
            case 0:
                return ""
            case 1:
                return ""
            case 2:
                return ""
            case 3:
                return ""
            case 4:
                return ""
            case 5:
                if(pos==1)
                    return "ayudaBarAcc"
                else
                return ""
            case 6:
                return ""
            case 7:
                if(pos==2)
                    return "ayudaBarAccLogo"
                else
                    return ""
        }
    }
    render() {
        return (
            <div title={this.props.item["Emisor"] + " - $ " + formatNumberFixed0(this.props.item["Precio"])}>    
                <div className={["bar",this.getStyleByIndex(this.props.index)].join(' ')} style={this.checkWidthBar(this.props.item["Precio"])}><div className={["percent",this.getStyleAyuda(this.props.index,1)].join(' ')}>{formatNumberFixed0(this.props.item["Precio"])}</div><div className={["logo" + (this.props.index + 1),this.getStyleAyuda(this.props.index,2)].join(' ')}><img src={this.props.item["fileNombreUrl"]!==""? global.config.URL_UPLOAD + this.props.item["fileNombreUrl"]:global.config.URL_IMAGE_EMISOR + this.props.item["EmisorCodigo"]  + ".png"} onError={(e) => e.target.src = global.config.URL_IMAGE_EMISOR + 'onerror-rv.png'} alt="Logo Marca 1" /></div><div className={"bottomPercent" + (this.props.index + 1)}>{this.props.item["SaldoTitulo"]}</div></div>
            </div>
        )
    }
}
export default ItemRentaVariable;